export const approveOptions = [
  { item: 1, name: 'Aprovar' },
  { item: 2, name: 'Não aprovar' },
]

export const lastDaysOptions = [
  { value: 1, text: 'Último dia' },
  { value: 3, text: 'Últimos 3 dias' },
  { value: 7, text: 'Últimos 7 dias' },
  { value: 14, text: 'Últimos 14 dias' },
  { value: 31, text: 'Últimos 31 dias' },
]

export const pendenceOptions = [
  { value: null, text: 'Todos' },
  { value: 1, text: 'Atrasado' },
  { value: 2, text: 'Pontual' },
]

export const specialtyOptions = [
  { value: null, text: 'Todos' },
  { value: 1, text: 'VIDA' },
  { value: 2, text: 'SONHOS E LIBERDADE FINANCEIRA' },
  { value: 3, text: 'PROTEÇÃO DO PATRIMÔNIO' },
  { value: 4, text: 'CRÉDITO' },
  { value: 5, text: 'CÂMBIO' },
  { value: 6, text: 'REAL ESTATE' },
]

export const typeOptions = [
  { value: null, text: 'Todos' },
  { value: 1, text: 'A FAZER' },
  { value: 2, text: 'EM ANDAMENTO' },
  { value: 3, text: 'CONCLUÍDOS' },
  { value: 4, text: 'RECUSADOS' },
]

export const statusStudy = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Aprovado' },
  { value: 4, text: 'Em análise pelo cliente' },
  { value: 2, text: 'Ajustar' },
  { value: 3, text: 'Recusado' },
]

export const educationDegreeOptions = [
  { value: '', text: 'Selecione' },
  { value: '1', text: 'Educação Infantil' },
  { value: '2', text: 'Ensino Fundamental' },
  { value: '3', text: 'Ensino Médio' },
  { value: '4', text: 'Superior (Graduação)' },
  { value: '5', text: 'Pós-graduação' },
  { value: '6', text: 'Mestrado' },
  { value: '7', text: 'Doutorado' },
]

export const professionOptions = [
  { value: '', text: 'Selecione' },
  { value: '1', text: 'Professor' },
  { value: '2', text: 'Técnico' },
  { value: '3', text: 'Especialista' },
  { value: '4', text: 'Gestor' },
  { value: '5', text: 'Planejador' },
  { value: '6', text: 'Consultor' },
  { value: '7', text: 'Empreendedor' },
]

export const studyStatusOptions = [
  { value: null, text: 'Todos' },
  { value: 'aprovado', text: 'Aprovados' },
  { value: 'recusado', text: 'Recusados' },
  { value: 'pendente', text: 'Pendentes' },
]

export const tempoEstudoOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: '1 dia' },
  { value: 2, text: '2 dias' },
  { value: 3, text: '3 dias' },
  { value: 4, text: '4 dias' },
  { value: 5, text: '5 dias' },
  { value: 6, text: '6 dias' },
  { value: 7, text: '7 dias' },
  { value: 8, text: '8 dias' },
  { value: 9, text: '9 dias' },
  { value: 10, text: '10 dias' },
  { value: 11, text: '11 dias' },
  { value: 12, text: '12 dias' },
  { value: 13, text: '13 dias' },
  { value: 14, text: '14 dias' },
  { value: 15, text: '15 dias' },
  { value: 16, text: '16 dias' },
  { value: 17, text: '17 dias' },
  { value: 18, text: '18 dias' },
  { value: 19, text: '19 dias' },
  { value: 20, text: '20 dias' },
  { value: 21, text: '21 dias' },
  { value: 22, text: '22 dias' },
  { value: 23, text: '23 dias' },
  { value: 24, text: '24 dias' },
  { value: 25, text: '25 dias' },
  { value: 26, text: '26 dias' },
  { value: 27, text: '27 dias' },
  { value: 28, text: '28 dias' },
  { value: 29, text: '29 dias' },
  { value: 30, text: '30 dias' },
]

export const communionRegimeOptions = [
  { value: '', text: 'Selecione' },
  { value: 'RC1', text: 'Comunhão parcial de bens' },
  { value: 'RC2', text: 'Comunhão universal de bens' },
  { value: 'RC3', text: 'Separação de bens' },
  { value: 'RC4', text: 'Participação final nos Aquestos' },
]

export const maritalStatusOptions = [
  { value: '', text: 'Selecione' },
  { value: 'S', text: 'Solteiro' },
  { value: 'C', text: 'Casado' },
  { value: 'SJ', text: 'Separado' },
  { value: 'D', text: 'Divorciado' },
  { value: 'V', text: 'Viúvo' },
  { value: 'UE', text: 'União Estável' },
  { value: 'O', text: 'Outros' },
]

export const priorityOrderOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: '1' },
  { value: 2, text: '2' },
  { value: 3, text: '3' },
  { value: 4, text: '4' },
  { value: 5, text: '5' },
]

export const priorityOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Educação dos filhos' },
  {
    value: 2,
    text: 'Manutenção das despesas na sua ausência',
  },
  {
    value: 3,
    text: 'Liberdade financeira, reduzir o ritmo ou aposentadoria',
  },
  {
    value: 4,
    text: 'Sonhos e projetos de vida',
  },
  { value: 5, text: 'Gastos com saúde' },
  { value: 6, text: 'Outros' },
]

export const dreamTypeOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Imóvel', text: 'Imóvel' },
  { value: 'Imóvel no exterior', text: 'Imóvel no exterior' },
  { value: 'Veículo', text: 'Veículo' },
  { value: 'Outros', text: 'Outros' },
]

export const dreamYearOptions = [
  { value: 'Menos de 1 ano', text: 'Menos de 1 ano' },
  { value: '1 ano', text: '1 ano' },
  { value: '2 anos', text: '2 anos' },
  { value: '3 anos', text: '3 anos' },
  { value: '4 anos', text: '4 anos' },
  { value: '5 anos', text: '5 anos' },
  { value: '6 anos', text: '6 anos' },
  { value: '7 anos', text: '7 anos' },
  { value: '8 anos', text: '8 anos' },
  { value: '9 anos', text: '9 anos' },
  { value: '10 anos ou mais', text: '10 anos ou mais' },
]

export const tempoEstudoExterior = [
  { value: null, text: 'Selecione' },
  { value: 1, text: '1 ano' },
  { value: 2, text: '2 anos' },
  { value: 3, text: '3 anos' },
  { value: 4, text: '4 anos' },
  { value: 5, text: '5 anos' },
  { value: 6, text: '6 anos' },
  { value: 7, text: '7 anos' },
  { value: 8, text: '8 anos' },
  { value: 9, text: '9 anos' },
  { value: 10, text: '10 anos' },
]

export const tipoSeguroOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Vida Inteira', text: 'Vida Inteira' },
  { value: 'Temporário', text: 'Temporário' },
  { value: 'Temporário Decrescente', text: 'Temporário Decrescente' },
  { value: 'Dotal', text: 'Dotal' },
  { value: 'Vida Universal', text: 'Vida Universal' },
  { value: 'Tradicional', text: 'Tradicional' },
  {
    value: 'Assistência ou auxílio funeral',
    text: 'Assistência ou auxílio funeral',
  },
  { value: 'Doenças Graves', text: 'Doenças Graves' },
  { value: 'Invalidez por Acidente', text: 'Invalidez por Acidente' },
  { value: 'Invalidez por Doença', text: 'Invalidez por Doença' },
  { value: 'DIT', text: 'DIT (Diária por Incapacidade Temporária)' },
  { value: 'DIH', text: 'DIH (Diária por Internação Hospitalar)' },
  { value: 'Risco Cirúrgico', text: 'Risco Cirúrgico' },
  { value: 'Outros', text: 'Outros' },
]

export const tipoPessoaOptions = [
  { value: null, text: 'Selecione' },
  { value: 'PF', text: 'Pessoa física' },
  { value: 'PJ', text: 'Pessoa jurídica' },
]

export const tipoPessoaOptions2 = [
  { value: null, text: 'Selecione' },
  { value: 'Titular', text: 'Titular' },
  { value: 'Cônjuge', text: 'Cônjuge' },
  { value: 'PJ', text: 'Pessoa jurídica' },
]

export const cilindradaOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Ate 1.000 cc' },
  { value: 2, text: 'Acima de 1.000 cc' },
]

export const tipoPessoaSaudeOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Pessoa física', text: 'Pessoa física' },
  { value: 'Pessoa jurídica', text: 'Pessoa jurídica' },
  { value: 'Entidade de Classe', text: 'Entidade de Classe' },
]

export const fumanteOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Fumante' },
  { value: 2, text: 'Não fumante' },
  { value: 3, text: 'Ex fumante' },
]

export const tipoCoberturaOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Regional', text: 'Regional' },
  { value: 'Nacional', text: 'Nacional' },
  { value: 'Nacional + Exterior', text: 'Nacional + Exterior' },
]

export const acomodacaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Ambulatorial', text: 'Ambulatorial' },
  { value: 'Apartamento', text: 'Apartamento' },
  { value: 'Outros', text: 'Outros' },
]

export const tipoPlanoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'PGBL', text: 'PGBL' },
  { value: 'VGBL', text: 'VGBL' },
]

export const tributacaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Progressiva', text: 'Progressiva' },
  { value: 'Regressiva', text: 'Regressiva' },
]

export const tipoRendaOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Vitalícia', text: 'Vitalícia' },
  { value: 'Temporária', text: 'Temporária' },
]

export const tipoDeclaracaoIrOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Simplificada', text: 'Simplificada' },
  { value: 'Completa', text: 'Completa' },
]

export const formaConstrucaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Financiamento', text: 'Financiamento' },
  { value: 'Consórcio', text: 'Consórcio' },
  { value: 'Outro', text: 'Outro' },
]

export const formaCotacaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Financiamento', text: 'Financiamento' },
  { value: 'Consórcio', text: 'Consórcio' },
  { value: 'Ambos', text: 'Ambos' },
]

export const indiceCorrecaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'INCC', text: 'INCC' },
  { value: 'IPCA', text: 'IPCA' },
  { value: 'TR', text: 'TR' },
  { value: 'INPC', text: 'INPC' },
  { value: 'FIPE', text: 'FIPE' },
]

export const parentescoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Filho(a)', text: 'Filho(a)' },
  { value: 'Cônjuge', text: 'Cônjuge' },
  { value: 'Pai', text: 'Pai' },
  { value: 'Mãe', text: 'Mãe' },
  { value: 'Outro', text: 'Outro' },
]

export const parentescoOutroDependenteOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Pai', text: 'Pai' },
  { value: 'Mãe', text: 'Mãe' },
  { value: 'Outro', text: 'Outro' },
]

export const tempoProtecaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 5, text: '5 anos' },
  { value: 10, text: '10 anos' },
  { value: 15, text: '15 anos' },
  { value: 20, text: '20 anos' },
  { value: 25, text: '25 anos' },
  { value: 30, text: '30 anos' },
  { value: 999, text: 'Vitalício' },
]

export const sexOptions = [
  { value: null, text: 'Selecione' },
  { value: 'M', text: 'Masculino' },
  { value: 'F', text: 'Feminino' },
  { value: 'O', text: 'Outro' },
]

export const optionsStatus = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Prospecção' },
  { value: 2, text: 'Levantamento de necessidades' },
  { value: 3, text: 'Diagnóstico' },
  { value: 4, text: 'Cotação' },
  { value: 5, text: 'Resultado do estudo' },
  { value: 6, text: 'Apresentação do estudo' },
  { value: 7, text: 'Ajustes' },
  { value: 8, text: 'Fechamento' },
  { value: 9, text: 'Protocolar no fornecedor' },
  { value: 10, text: 'Efetivação no fornecedor' },
]

export const perfilInvestidorOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Conservador', text: 'Conservador' },
  { value: 'Moderado', text: 'Moderado' },
  { value: 'Balanceado', text: 'Balanceado' },
  { value: 'Sofisticado', text: 'Sofisticado' },
  { value: 'Especialista', text: 'Especialista' },
]

export const tipoBemOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Imóvel', text: 'Imóvel' },
  { value: 'Veículos', text: 'Veículos' },
  { value: 'Outros', text: 'Outros' },
]

export const liquidezOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Imediato' },
  { value: 9, text: 'até 5 dias' },
  { value: 2, text: 'até 30 dias' },
  { value: 3, text: 'até 60 dias' },
  { value: 4, text: 'até 90 dias' },
  { value: 5, text: 'até 120 dias' },
  { value: 6, text: 'até 180 dias' },
  { value: 7, text: 'até 360 dias' },
  { value: 8, text: 'acima de 360 dias' },
]

export const aportesOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Diário' },
  { value: 2, text: 'Semanal' },
  { value: 3, text: 'Mensal' },
  { value: 4, text: 'Anual' },
  { value: 5, text: 'Outros' },
]

export const tipoInvestimentoOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Renda fixa' },
  { value: 2, text: 'Multimercado' },
  { value: 3, text: 'Renda variável' },
  { value: 4, text: 'Outros' },
]

export const comunhaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Comunhão parcial', text: 'Comunhão parcial' },
  { value: 'Comunhão universal', text: 'Comunhão universal' },
  { value: 'Separação de bens', text: 'Separação de bens' },
  {
    value: 'Participação final nos aquestos',
    text: 'Participação final nos aquestos',
  },
  { value: 'União estável', text: 'União estável' },
  { value: 'Nenhum', text: 'Nenhum' },
]

export const estadoCivilOptions = [
  { value: null, text: 'Selecione' },
  { value: 'S', text: 'Solteiro' },
  { value: 'C', text: 'Casado' },
  { value: 'SJ', text: 'Separado' },
  { value: 'D', text: 'Divorciado' },
  { value: 'V', text: 'Viúvo' },
  { value: 'UE', text: 'União Estável' },
  { value: 'O', text: 'Outro' },
]

export const fields = [
  {
    key: 'user',
    label: 'Usuário',
  },
  {
    key: 'comment',
    label: 'Comentário',
  },
  {
    key: 'product',
    label: 'Produto',
  },
  {
    key: 'actions',
    label: 'Ações',
  },
]

export const optionsDateOrder = [
  { value: 'desc', text: 'Ordem decrescente' },
  { value: 'asc', text: 'Ordem crescente' },
]

export const stageOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Preparar cotação' },
  { value: 2, text: 'Verificar resposta do cliente' },
  { value: 3, text: 'Verificar documentos' },
  { value: 4, text: 'Imputar propostas' },
  { value: 5, text: 'Cadastrar contratos/apólices' },
  { value: 6, text: 'Concluído' },
  { value: 7, text: 'Recusado' },
]

export const statusStepOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'A fazer' },
  { value: 2, text: 'Em Andamento' },
  { value: 3, text: 'Concluídos' },
  { value: 4, text: 'Recusados' },
]

export const productOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Vida' },
  { value: 2, text: 'Sonhos e liberdade financeira' },
  { value: 3, text: 'Proteção de patrimônio' },
  { value: 4, text: 'Crédito' },
  { value: 5, text: 'Câmbio' },
  { value: 6, text: 'Real Estate' },
]

export const statusOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'CADASTRO DO DIAGNÓSTICO' },
  { value: 2, text: 'RESULTADO DO EXAME' },
  { value: 3, text: 'RECEITA/COTAÇÃO' },
]

export const solutionStatusOptions = [
  { value: null, text: 'Selecione...' },
  { value: 'approved', text: 'Aprovado' },
  { value: 'reproved', text: 'Reprovado' },
  { value: 'analisis', text: 'Em análise' },
  { value: 'adjust', text: 'Ajustar' },
]

export const diagnosisStatusOptions = [
  { value: 'analisis', text: 'Pendente' },
  { value: 'negotiation', text: 'Em negociação' },
  { value: 'approved', text: 'Aprovado' },
  { value: 'reproved', text: 'Recusado' },
]

export const contactOptions = [
  { value: null, text: 'Selecione' },
  { value: 'email', text: 'Email' },
  { value: 'celular', text: 'Celular' },
  { value: 'telefone', text: 'Telefone' },
  { value: 'whatsapp', text: 'Whatsapp' },
]

export const avaliacaoNecessidadeOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Individualmente', text: 'Individualmente' },
  { value: 'Com seu cônjuge', text: 'Com seu cônjuge' },
]

export const tempoDespesaOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: 'Menos de 5 anos' },
  { value: 5, text: '5 anos' },
  { value: 10, text: '10 anos' },
  { value: 15, text: '15 anos' },
  { value: 20, text: '20 anos' },
  { value: 25, text: '25 anos' },
  { value: 30, text: '30 anos' },
]

export const stateOptions = [
  { value: null, text: 'Selecione' },
  { value: 'AC', text: 'Acre' },
  { value: 'AL', text: 'Alagoas' },
  { value: 'AP', text: 'Amapá' },
  { value: 'AM', text: 'Amazonas' },
  { value: 'BA', text: 'Bahia' },
  { value: 'CE', text: 'Ceará' },
  { value: 'DF', text: 'Distrito Federal' },
  { value: 'ES', text: 'Espírito Santo' },
  { value: 'GO', text: 'Goiás' },
  { value: 'MA', text: 'Maranhão' },
  { value: 'MT', text: 'Mato Grosso' },
  { value: 'MS', text: 'Mato Grosso do Sul' },
  { value: 'MG', text: 'Minas Gerais' },
  { value: 'PA', text: 'Pará' },
  { value: 'PB', text: 'Paraíba' },
  { value: 'PR', text: 'Paraná' },
  { value: 'PE', text: 'Pernambuco' },
  { value: 'PI', text: 'Piauí' },
  { value: 'RJ', text: 'Rio de Janeiro' },
  { value: 'RN', text: 'Rio Grande do Norte' },
  { value: 'RS', text: 'Rio Grande do Sul' },
  { value: 'RO', text: 'Rondônia' },
  { value: 'RR', text: 'Roraima' },
  { value: 'SC', text: 'Santa Catarina' },
  { value: 'SP', text: 'São Paulo' },
  { value: 'SE', text: 'Sergipe' },
  { value: 'TO', text: 'Tocantins' },
]

export const citiesOptions = {
  AC: [
    { value: 'Assis Brasil', text: 'Assis Brasil' },
    { value: 'Acrelândia', text: 'Acrelândia' },
    { value: 'Brasileia', text: 'Brasileia' },
    { value: 'Bujari', text: 'Bujari' },
    { value: 'Capixaba', text: 'Capixaba' },
    { value: 'Cruzeiro do Sul', text: 'Cruzeiro do Sul' },
    { value: 'Epitaciolândia', text: 'Epitaciolândia' },
    { value: 'Feijó', text: 'Feijó' },
    { value: 'Jordão', text: 'Jordão' },
    { value: 'Mâncio Lima', text: 'Mâncio Lima' },
    { value: 'Marechal Thaumaturgo', text: 'Marechal Thaumaturgo' },
    { value: 'Manoel Urbano', text: 'Manoel Urbano' },
    { value: 'Plácido de Castro', text: 'Plácido de Castro' },
    { value: 'Porto Acre', text: 'Porto Acre' },
    { value: 'Porto Walter', text: 'Porto Walter' },
    { value: 'Rio Branco', text: 'Rio Branco' },
    { value: 'Rodrigo Alves', text: 'Rodrigo Alves' },
    { value: 'Santa Rosa do Purus', text: 'Santa Rosa do Purus' },
    { value: 'Sena Madureira', text: 'Sena Madureira' },
    { value: 'Senador Guiomard', text: 'Senador Guiomard' },
    { value: 'Tarauacá', text: 'Tarauacá' },
    { value: 'Xapuri', text: 'Xapuri' },
  ],
  AL: [
    { value: 'Água Branca', text: 'Água Branca' },
    { value: 'Anadia', text: 'Anadia' },
    { value: 'Arapiraca', text: 'Arapiraca' },
    { value: 'Atalaia', text: 'Atalaia' },
    { value: 'Barra de Santo Antônio', text: 'Barra de Santo Antônio' },
    { value: 'Barra de São Miguel', text: 'Barra de São Miguel' },
    { value: 'Batalha', text: 'Batalha' },
    { value: 'Belém', text: 'Belém' },
    { value: 'Belo Monte', text: 'Belo Monte' },
    { value: 'Boca da Mata', text: 'Boca da Mata' },
    { value: 'Branquinha', text: 'Branquinha' },
    { value: 'Cacimbinhas', text: 'Cacimbinhas' },
    { value: 'Cajueiro', text: 'Cajueiro' },
    { value: 'Campestre', text: 'Campestre' },
    { value: 'Campo Alegre', text: 'Campo Alegre' },
    { value: 'Campo Grande', text: 'Campo Grande' },
    { value: 'Canapi', text: 'Canapi' },
    { value: 'Capela', text: 'Capela' },
    { value: 'Carneiros', text: 'Carneiros' },
    { value: 'Chã Preta', text: 'Chã Preta' },
    { value: 'Coité do Nóia', text: 'Coité do Nóia' },
    { value: 'Colônia Leopoldina', text: 'Colônia Leopoldina' },
    { value: 'Coqueiro Seco', text: 'Coqueiro Seco' },
    { value: 'Coruripe', text: 'Coruripe' },
    { value: 'Craíbas', text: 'Craíbas' },
    { value: 'Delmiro Gouveia', text: 'Delmiro Gouveia' },
    { value: 'Dois Riachos', text: 'Dois Riachos' },
    { value: 'Estrela de Alagoas', text: 'Estrela de Alagoas' },
    { value: 'Feira Grande', text: 'Feira Grande' },
    { value: 'Feliz Deserto', text: 'Feliz Deserto' },
    { value: 'Flexeiras', text: 'Flexeiras' },
    { value: 'Girau do Ponciano', text: 'Girau do Ponciano' },
    { value: 'Ibateguara', text: 'Ibateguara' },
    { value: 'Igaci', text: 'Igaci' },
    { value: 'Igreja Nova', text: 'Igreja Nova' },
    { value: 'Inhapi', text: 'Inhapi' },
    { value: 'Jacaré dos Homens', text: 'Jacaré dos Homens' },
    { value: 'Jacuípe', text: 'Jacuípe' },
    { value: 'Japaratinga', text: 'Japaratinga' },
    { value: 'Jaramataia', text: 'Jaramataia' },
    { value: 'Jequiá da Praia', text: 'Jequiá da Praia' },
    { value: 'Joaquim Gomes', text: 'Joaquim Gomes' },
    { value: 'Jundiá', text: 'Jundiá' },
    { value: 'Junqueiro', text: 'Junqueiro' },
    { value: 'Lagoa da Canoa', text: 'Lagoa da Canoa' },
    { value: 'Limoeiro de Anadia', text: 'Limoeiro de Anadia' },
    { value: 'Maceió', text: 'Maceió' },
    { value: 'Major Isidoro', text: 'Major Isidoro' },
    { value: 'Mar Vermelho', text: 'Mar Vermelho' },
    { value: 'Maragogi', text: 'Maragogi' },
    { value: 'Maravilha', text: 'Maravilha' },
    { value: 'Marechal Deodoro', text: 'Marechal Deodoro' },
    { value: 'Maribondo', text: 'Maribondo' },
    { value: 'Mata Grande', text: 'Mata Grande' },
    { value: 'Matriz de Camaragibe', text: 'Matriz de Camaragibe' },
    { value: 'Messias', text: 'Messias' },
    { value: 'Minador do Negrão', text: 'Minador do Negrão' },
    { value: 'Monteirópolis', text: 'Monteirópolis' },
    { value: 'Murici', text: 'Murici' },
    { value: 'Novo Lino', text: 'Novo Lino' },
    { value: "Olho d'Água das Flores", text: "Olho d'Água das Flores" },
    { value: "Olho d'Água do Casado", text: '' },
    { value: "Olho d'Água Grande", text: "Olho d'Água Grande" },
    { value: 'Olivença', text: 'Olivença' },
    { value: 'Ouro Branco', text: 'Ouro Branco' },
    { value: 'Palestina', text: 'Palestina' },
    { value: 'Palmeira dos Índios', text: 'Palmeira dos Índios' },
    { value: 'Pão de Açúcar', text: 'Pão de Açúcar' },
    { value: 'Pariconha', text: 'Pariconha' },
    { value: 'Paripueira', text: 'Paripueira' },
    { value: 'Passo de Camaragibe', text: 'Passo de Camaragibe' },
    { value: 'Paulo Jacinto', text: 'Paulo Jacinto' },
    { value: 'Penedo', text: 'Penedo' },
    { value: 'Piaçabuçu', text: 'Piaçabuçu' },
    { value: 'Pilar', text: 'Pilar' },
    { value: 'Pindoba', text: 'Pindoba' },
    { value: 'Piranhas', text: 'Piranhas' },
    { value: 'Poço das Trincheiras', text: 'Poço das Trincheiras' },
    { value: 'Porto Calvo', text: 'Porto Calvo' },
    { value: 'Porto de Pedras', text: 'Porto de Pedras' },
    { value: 'Porto Real do Colégio', text: 'Porto Real do Colégio' },
    { value: 'Quebrangulo', text: 'Quebrangulo' },
    { value: 'Rio Largo', text: 'Rio Largo' },
    { value: 'Roteiro', text: 'Roteiro' },
    { value: 'Santa Luzia do Norte', text: 'Santa Luzia do Norte' },
    { value: 'Santana do Ipanema', text: 'Santana do Ipanema' },
    { value: 'Santana do Mundaú', text: 'Santana do Mundaú' },
    { value: 'São Brás', text: 'São Brás' },
    { value: 'São José da Laje', text: 'São José da Laje' },
    { value: 'São José da Tapera', text: 'São José da Tapera' },
    { value: 'São Luís do Quitunde', text: 'São Luís do Quitunde' },
    { value: 'São Miguel dos Campos', text: 'São Miguel dos Campos' },
    { value: 'São Miguel dos Milagres', text: 'São Miguel dos Milagres' },
    { value: 'São Sebastião', text: 'São Sebastião' },
    { value: 'Satuba', text: 'Satuba' },
    { value: 'Senador Rui Palmeira', text: 'Senador Rui Palmeira' },
    { value: "Tanque d'Arca", text: "Tanque d'Arca" },
    { value: 'Taquarana', text: 'Taquarana' },
    { value: 'Teotônio Vilela', text: 'Teotônio Vilela' },
    { value: 'Traipu', text: 'Traipu' },
    { value: 'União dos Palmares', text: 'União dos Palmares' },
    { value: 'Viçosa', text: 'Viçosa' },
  ],
  AP: [
    { value: 'Amapá', text: 'Amapá' },
    { value: 'Calçoene', text: 'Calçoene' },
    { value: 'Cutias', text: 'Cutias' },
    { value: 'Ferreira Gomes', text: 'Ferreira Gomes' },
    { value: 'Itaubal', text: 'Itaubal' },
    { value: 'Laranjal do Jari', text: 'Laranjal do Jari' },
    { value: 'Macapá', text: 'Macapá' },
    { value: 'Mazagão', text: 'Mazagão' },
    { value: 'Oiapoque', text: 'Oiapoque' },
    { value: 'Pedra Branca do Amapari', text: 'Pedra Branca do Amapari' },
    { value: 'Porto Grande', text: 'Porto Grande' },
    { value: 'Pracuúba', text: 'Pracuúba' },
    { value: 'Santana', text: 'Santana' },
    { value: 'Serra do Navio', text: 'Serra do Navio' },
    { value: 'Tartarugalzinho', text: 'Tartarugalzinho' },
    { value: 'Vitória do Jari', text: 'Vitória do Jari' },
  ],
  AM: [
    { value: 'Alvarães', text: 'Alvarães' },
    { value: 'Amaturá', text: 'Amaturá' },
    { value: 'Anamã', text: 'Anamã' },
    { value: 'Anori', text: 'Anori' },
    { value: 'Apuí', text: 'Apuí' },
    { value: 'Atalaia do Norte', text: 'Atalaia do Norte' },
    { value: 'Autazes', text: 'Autazes' },
    { value: 'Barcelos', text: 'Barcelos' },
    { value: 'Barreirinha', text: 'Barreirinha' },
    { value: 'Benjamin Constant', text: 'Benjamin Constant' },
    { value: 'Beruri', text: 'Beruri' },
    { value: 'Boa Vista do Ramos', text: 'Boa Vista do Ramos' },
    { value: 'Boca do Acre', text: 'Boca do Acre' },
    { value: 'Borba', text: 'Borba' },
    { value: 'Caapiranga', text: 'Caapiranga' },
    { value: 'Canutama', text: 'Canutama' },
    { value: 'Carauari', text: 'Carauari' },
    { value: 'Careiro', text: 'Careiro' },
    { value: 'Careiro da Várzea', text: 'Careiro da Várzea' },
    { value: 'Coari', text: 'Coari' },
    { value: 'Codajás', text: 'Codajás' },
    { value: 'Eirunepé', text: 'Eirunepé' },
    { value: 'Envira', text: 'Envira' },
    { value: 'Fonte Boa', text: 'Fonte Boa' },
    { value: 'Guajará', text: 'Guajará' },
    { value: 'Humaitá', text: 'Humaitá' },
    { value: 'Ipixuna', text: 'Ipixuna' },
    { value: 'Iranduba', text: 'Iranduba' },
    { value: 'Itacoatiara', text: 'Itacoatiara' },
    { value: 'Itamarati', text: 'Itamarati' },
    { value: 'Itapiranga', text: 'Itapiranga' },
    { value: 'Japurá', text: 'Japurá' },
    { value: 'Juruá', text: 'Juruá' },
    { value: 'Jutaí', text: 'Jutaí' },
    { value: 'Lábrea', text: 'Lábrea' },
    { value: 'Manacapuru', text: 'Manacapuru' },
    { value: 'Manaquiri', text: 'Manaquiri' },
    { value: 'Manaus', text: 'Manaus' },
    { value: 'Manicoré', text: 'Manicoré' },
    { value: 'Maraã', text: 'Maraã' },
    { value: 'Maués', text: 'Maués' },
    { value: 'Nhamundá', text: 'Nhamundá' },
    { value: 'Nova Olinda do Norte', text: 'Nova Olinda do Norte' },
    { value: 'Novo Airão', text: 'Novo Airão' },
    { value: 'Novo Aripuanã', text: 'Novo Aripuanã' },
    { value: 'Parintins', text: 'Parintins' },
    { value: 'Pauini', text: 'Pauini' },
    { value: 'Presidente Figueiredo', text: 'Presidente Figueiredo' },
    { value: 'Rio Preto da Eva', text: 'Rio Preto da Eva' },
    { value: 'Santa Isabel do Rio Negro', text: 'Santa Isabel do Rio Negro' },
    { value: 'Santo Antônio do Içá', text: 'Santo Antônio do Içá' },
    { value: 'São Gabriel da Cachoeira', text: 'São Gabriel da Cachoeira' },
    { value: 'São Paulo de Olivença', text: 'São Paulo de Olivença' },
    { value: 'São Sebastião do Uatumã', text: 'São Sebastião do Uatumã' },
    { value: 'Silves', text: 'Silves' },
    { value: 'Tabatinga', text: 'Tabatinga' },
    { value: 'Tapauá', text: 'Tapauá' },
    { value: 'Tefé', text: 'Tefé' },
    { value: 'Tonantins', text: 'Tonantins' },
    { value: 'Uarini', text: 'Uarini' },
    { value: 'Urucará', text: 'Urucará' },
    { value: 'Urucurituba', text: 'Urucurituba' },
  ],
  BA: [
    { value: 'Abaíra', text: 'Abaíra' },
    { value: 'Abaré', text: 'Abaré' },
    { value: 'Acajutiba', text: 'Acajutiba' },
    { value: 'Adustina', text: 'Adustina' },
    { value: 'Água Fria', text: 'Água Fria' },
    { value: 'Aiquara', text: 'Aiquara' },
    { value: 'Alagoinhas', text: 'Alagoinhas' },
    { value: 'Alcobaça', text: 'Alcobaça' },
    { value: 'Almadina', text: 'Almadina' },
    { value: 'Amargosa', text: 'Amargosa' },
    { value: 'Amélia Rodrigues', text: 'Amélia Rodrigues' },
    { value: 'América Dourada', text: 'América Dourada' },
    { value: 'Anagé', text: 'Anagé' },
    { value: 'Andaraí', text: 'Andaraí' },
    { value: 'Andorinha', text: 'Andorinha' },
    { value: 'Angical', text: 'Angical' },
    { value: 'Anguera', text: 'Anguera' },
    { value: 'Antas', text: 'Antas' },
    { value: 'Antônio Cardoso', text: 'Antônio Cardoso' },
    { value: 'Antônio Gonçalves', text: 'Antônio Gonçalves' },
    { value: 'Aporá', text: 'Aporá' },
    { value: 'Apuarema', text: 'Apuarema' },
    { value: 'Araças', text: 'Araças' },
    { value: 'Aracatu', text: 'Aracatu' },
    { value: 'Araci', text: 'Araci' },
    { value: 'Aramari', text: 'Aramari' },
    { value: 'Arataca', text: 'Arataca' },
    { value: 'Aratuípe', text: 'Aratuípe' },
    { value: 'Aurelino Leal', text: 'Aurelino Leal' },
    { value: 'Baianópolis', text: 'Baianópolis' },
    { value: 'Baixa Grande', text: 'Baixa Grande' },
    { value: 'Banzaê', text: 'Banzaê' },
    { value: 'Barra', text: 'Barra' },
    { value: 'Barra da Estiva', text: 'Barra da Estiva' },
    { value: 'Barra do Choça', text: 'Barra do Choça' },
    { value: 'Barra do Mendes', text: 'Barra do Mendes' },
    { value: 'Barra do Rocha', text: 'Barra do Rocha' },
    { value: 'Barreiras', text: 'Barreiras' },
    { value: 'Barro Alto', text: 'Barro Alto' },
    { value: 'Barrocas', text: 'Barrocas' },
    { value: 'Barro Preto', text: 'Barro Preto' },
    { value: 'Belmonte', text: 'Belmonte' },
    { value: 'Belo Campo', text: 'Belo Campo' },
    { value: 'Biritinga', text: 'Biritinga' },
    { value: 'Boa Nova', text: 'Boa Nova' },
    { value: 'Boa Vista do Tupim', text: 'Boa Vista do Tupim' },
    { value: 'Bom Jesus da Lapa', text: 'Bom Jesus da Lapa' },
    { value: 'Bom Jesus da Serra', text: 'Bom Jesus da Serra' },
    { value: 'Boninal', text: 'Boninal' },
    { value: 'Bonito', text: 'Bonito' },
    { value: 'Boquira', text: 'Boquira' },
    { value: 'Botuporã', text: 'Botuporã' },
    { value: 'Brejões', text: 'Brejões' },
    { value: 'Brejolândia', text: 'Brejolândia' },
    { value: 'Brotas de Macaúbas', text: 'Brotas de Macaúbas' },
    { value: 'Brumado', text: 'Brumado' },
    { value: 'Buerarema', text: 'Buerarema' },
    { value: 'Buritirama', text: 'Buritirama' },
    { value: 'Caatiba', text: 'Caatiba' },
    { value: 'Cabaceiras do Paraguaçu', text: 'Cabaceiras do Paraguaçu' },
    { value: 'Cachoeira', text: 'Cachoeira' },
    { value: 'Caculé', text: 'Caculé' },
    { value: 'Caém', text: 'Caém' },
    { value: 'Caetanos', text: 'Caetanos' },
    { value: 'Caetité', text: 'Caetité' },
    { value: 'Cafarnaum', text: 'Cafarnaum' },
    { value: 'Cairu', text: 'Cairu' },
    { value: 'Caldeirão Grande', text: 'Caldeirão Grande' },
    { value: 'Camacan', text: 'Camacan' },
    { value: 'Camaçari', text: 'Camaçari' },
    { value: 'Camamu', text: 'Camamu' },
    { value: 'Campo Alegre de Lourdes', text: 'Campo Alegre de Lourdes' },
    { value: 'Campo Formoso', text: 'Campo Formoso' },
    { value: 'Canápolis', text: 'Canápolis' },
    { value: 'Canarana', text: 'Canarana' },
    { value: 'Canavieiras', text: 'Canavieiras' },
    { value: 'Candeal', text: 'Candeal' },
    { value: 'Candeias', text: 'Candeias' },
    { value: 'Candiba', text: 'Candiba' },
    { value: 'Cândido Sales', text: 'Cândido Sales' },
    { value: 'Cansanção', text: 'Cansanção' },
    { value: 'Canudos', text: 'Canudos' },
    { value: 'Capela do Alto Alegre', text: 'Capela do Alto Alegre' },
    { value: 'Capim Grosso', text: 'Capim Grosso' },
    { value: 'Caraíbas', text: 'Caraíbas' },
    { value: 'Caravelas', text: 'Caravelas' },
    { value: 'Cardeal da Silva', text: 'Cardeal da Silva' },
    { value: 'Carinhanha', text: 'Carinhanha' },
    { value: 'Casa Nova', text: 'Casa Nova' },
    { value: 'Castro Alves', text: 'Castro Alves' },
    { value: 'Catolândia', text: 'Catolândia' },
    { value: 'Catu', text: 'Catu' },
    { value: 'Caturama', text: 'Caturama' },
    { value: 'Central', text: 'Central' },
    { value: 'Chorrochó', text: 'Chorrochó' },
    { value: 'Cícero Dantas', text: 'Cícero Dantas' },
    { value: 'Cipó', text: 'Cipó' },
    { value: 'Coaraci', text: 'Coaraci' },
    { value: 'Cocos', text: 'Cocos' },
    { value: 'Conceição da Feira', text: 'Conceição da Feira' },
    { value: 'Conceição do Almeida', text: 'Conceição do Almeida' },
    { value: 'Conceição do Coité', text: 'Conceição do Coité' },
    { value: 'Conceição do Jacuípe', text: 'Conceição do Jacuípe' },
    { value: 'Conde', text: 'Conde' },
    { value: 'Condeúba', text: 'Condeúba' },
    { value: 'Contendas do Sincorá', text: 'Contendas do Sincorá' },
    { value: 'Coração de Maria', text: 'Coração de Maria' },
    { value: 'Cordeiros', text: 'Cordeiros' },
    { value: 'Coribe', text: 'Coribe' },
    { value: 'Coronel João Sá', text: 'Coronel João Sá' },
    { value: 'Correntina', text: 'Correntina' },
    { value: 'Cotegipe', text: 'Cotegipe' },
    { value: 'Cravolândia', text: 'Cravolândia' },
    { value: 'Crisópolis', text: 'Crisópolis' },
    { value: 'Cristópolis', text: 'Cristópolis' },
    { value: 'Cruz das Almas', text: 'Cruz das Almas' },
    { value: 'Curaçá', text: 'Curaçá' },
    { value: 'Dário Meira', text: 'Dário Meira' },
    { value: "Dias d'Ávila", text: "Dias d'Ávila" },
    { value: 'Dom Basílio', text: 'Dom Basílio' },
    { value: 'Dom Macedo Costa', text: 'Dom Macedo Costa' },
    { value: 'Elísio Medrado', text: 'Elísio Medrado' },
    { value: 'Encruzilhada', text: 'Encruzilhada' },
    { value: 'Entre Rios', text: 'Entre Rios' },
    { value: 'Érico Cardoso', text: 'Érico Cardoso' },
    { value: 'Esplanada', text: 'Esplanada' },
    { value: 'Euclides da Cunha', text: 'Euclides da Cunha' },
    { value: 'Eunápolis', text: 'Eunápolis' },
    { value: 'Fátima', text: 'Fátima' },
    { value: 'Feira da Mata', text: 'Feira da Mata' },
    { value: 'Feira de Santana', text: 'Feira de Santana' },
    { value: 'Filadélfia', text: 'Filadélfia' },
    { value: 'Firmino Alves', text: 'Firmino Alves' },
    { value: 'Floresta Azul', text: 'Floresta Azul' },
    { value: 'Formosa do Rio Preto', text: 'Formosa do Rio Preto' },
    { value: 'Gandu', text: 'Gandu' },
    { value: 'Gavião', text: 'Gavião' },
    { value: 'Gentio do Ouro', text: 'Gentio do Ouro' },
    { value: 'Glória', text: 'Glória' },
    { value: 'Gongogi', text: 'Gongogi' },
    { value: 'Governador Mangabeira', text: 'Governador Mangabeira' },
    { value: 'Guajeru', text: 'Guajeru' },
    { value: 'Guanambi', text: 'Guanambi' },
    { value: 'Guaratinga', text: 'Guaratinga' },
    { value: 'Heliópolis', text: 'Heliópolis' },
    { value: 'Iaçu', text: 'Iaçu' },
    { value: 'Ibiassucê', text: 'Ibiassucê' },
    { value: 'Ibicaraí', text: 'Ibicaraí' },
    { value: 'Ibicoara', text: 'Ibicoara' },
    { value: 'Ibicuí', text: 'Ibicuí' },
    { value: 'Ibipeba', text: 'Ibipeba' },
    { value: 'Ibipitanga', text: 'Ibipitanga' },
    { value: 'Ibiquera', text: 'Ibiquera' },
    { value: 'Ibirapitanga', text: 'Ibirapitanga' },
    { value: 'Ibirapuã', text: 'Ibirapuã' },
    { value: 'Ibirataia', text: 'Ibirataia' },
    { value: 'Ibitiara', text: 'Ibitiara' },
    { value: 'Ibititá', text: 'Ibititá' },
    { value: 'Ibotirama', text: 'Ibotirama' },
    { value: 'Ichu', text: 'Ichu' },
    { value: 'Igaporã', text: 'Igaporã' },
    { value: 'Igrapiúna', text: 'Igrapiúna' },
    { value: 'Iguaí', text: 'Iguaí' },
    { value: 'Ilhéus', text: 'Ilhéus' },
    { value: 'Inhambupe', text: 'Inhambupe' },
    { value: 'Ipecaetá', text: 'Ipecaetá' },
    { value: 'Ipiaú', text: 'Ipiaú' },
    { value: 'Ipirá', text: 'Ipirá' },
    { value: 'Ipupiara', text: 'Ipupiara' },
    { value: 'Irajuba', text: 'Irajuba' },
    { value: 'Iramaia', text: 'Iramaia' },
    { value: 'Iraquara', text: 'Iraquara' },
    { value: 'Irará', text: 'Irará' },
    { value: 'Irecê', text: 'Irecê' },
    { value: 'Itabela', text: 'Itabela' },
    { value: 'Itaberaba', text: 'Itaberaba' },
    { value: 'Itabuna', text: 'Itabuna' },
    { value: 'Itacaré', text: 'Itacaré' },
    { value: 'Itaeté', text: 'Itaeté' },
    { value: 'Itagi', text: 'Itagi' },
    { value: 'Itagibá', text: 'Itagibá' },
    { value: 'Itagimirim', text: 'Itagimirim' },
    { value: 'Itaguaçu da Bahia', text: 'Itaguaçu da Bahia' },
    { value: 'Itaju do Colônia', text: 'Itaju do Colônia' },
    { value: 'Itajuípe', text: 'Itajuípe' },
    { value: 'Itamaraju', text: 'Itamaraju' },
    { value: 'Itamari', text: 'Itamari' },
    { value: 'Itambé', text: 'Itambé' },
    { value: 'Itanagra', text: 'Itanagra' },
    { value: 'Itanhém', text: 'Itanhém' },
    { value: 'Itaparica', text: 'Itaparica' },
    { value: 'Itapé', text: 'Itapé' },
    { value: 'Itapebi', text: 'Itapebi' },
    { value: 'Itapetinga', text: 'Itapetinga' },
    { value: 'Itapicuru', text: 'Itapicuru' },
    { value: 'Itapitanga', text: 'Itapitanga' },
    { value: 'Itaquara', text: 'Itaquara' },
    { value: 'Itarantim', text: 'Itarantim' },
    { value: 'Itatim', text: 'Itatim' },
    { value: 'Itiruçu', text: 'Itiruçu' },
    { value: 'Itiúba', text: 'Itiúba' },
    { value: 'Itororó', text: 'Itororó' },
    { value: 'Ituaçu', text: 'Ituaçu' },
    { value: 'Ituberá', text: 'Ituberá' },
    { value: 'Iuiú', text: 'Iuiú' },
    { value: 'Jaborandi', text: 'Jaborandi' },
    { value: 'Jacaraci', text: 'Jacaraci' },
    { value: 'Jacobina', text: 'Jacobina' },
    { value: 'Jaguaquara', text: 'Jaguaquara' },
    { value: 'Jaguarari', text: 'Jaguarari' },
    { value: 'Jaguaripe', text: 'Jaguaripe' },
    { value: 'Jandaíra', text: 'Jandaíra' },
    { value: 'Jequié', text: 'Jequié' },
    { value: 'Jeremoabo', text: 'Jeremoabo' },
    { value: 'Jiquiriçá', text: 'Jiquiriçá' },
    { value: 'Jitaúna', text: 'Jitaúna' },
    { value: 'João Dourado', text: 'João Dourado' },
    { value: 'Juazeiro', text: 'Juazeiro' },
    { value: 'Jucuruçu', text: 'Jucuruçu' },
    { value: 'Jussara', text: 'Jussara' },
    { value: 'Jussari', text: 'Jussari' },
    { value: 'Jussiape', text: 'Jussiape' },
    { value: 'Lafaiete Coutinho', text: 'Lafaiete Coutinho' },
    { value: 'Lagoa Real', text: 'Lagoa Real' },
    { value: 'Laje', text: 'Laje' },
    { value: 'Lajedão', text: 'Lajedão' },
    { value: 'Lajedinho', text: 'Lajedinho' },
    { value: 'Lajedo do Tabocal', text: 'Lajedo do Tabocal' },
    { value: 'Lamarão', text: 'Lamarão' },
    { value: 'Lapão', text: 'Lapão' },
    { value: 'Lauro de Freitas', text: 'Lauro de Freitas' },
    { value: 'Lençóis', text: 'Lençóis' },
    { value: 'Licínio de Almeida', text: 'Licínio de Almeida' },
    {
      value: 'Livramento de Nossa Senhora',
      text: 'Livramento de Nossa Senhora',
    },
    { value: 'Luís Eduardo Magalhães', text: 'Luís Eduardo Magalhães' },
    { value: 'Macajuba', text: 'Macajuba' },
    { value: 'Macarani', text: 'Macarani' },
    { value: 'Macaúbas', text: 'Macaúbas' },
    { value: 'Macururé', text: 'Macururé' },
    { value: 'Madre de Deus', text: 'Madre de Deus' },
    { value: 'Maetinga', text: 'Maetinga' },
    { value: 'Maiquinique', text: 'Maiquinique' },
    { value: 'Mairi', text: 'Mairi' },
    { value: 'Malhada', text: 'Malhada' },
    { value: 'Malhada de Pedras', text: 'Malhada de Pedras' },
    { value: 'Manoel Vitorino', text: 'Manoel Vitorino' },
    { value: 'Mansidão', text: 'Mansidão' },
    { value: 'Maracás', text: 'Maracás' },
    { value: 'Maragogipe', text: 'Maragogipe' },
    { value: 'Maraú', text: 'Maraú' },
    { value: 'Marcionílio Souza', text: 'Marcionílio Souza' },
    { value: 'Mascote', text: 'Mascote' },
    { value: 'Mata de São João', text: 'Mata de São João' },
    { value: 'Matina', text: 'Matina' },
    { value: 'Medeiros Neto', text: 'Medeiros Neto' },
    { value: 'Miguel Calmon', text: 'Miguel Calmon' },
    { value: 'Milagres', text: 'Milagres' },
    { value: 'Mirangaba', text: 'Mirangaba' },
    { value: 'Mirante', text: 'Mirante' },
    { value: 'Monte Santo', text: 'Monte Santo' },
    { value: 'Morpará', text: 'Morpará' },
    { value: 'Morro do Chapéu', text: 'Morro do Chapéu' },
    { value: 'Mortugaba', text: 'Mortugaba' },
    { value: 'Mucugê', text: 'Mucugê' },
    { value: 'Mucuri', text: 'Mucuri' },
    { value: 'Mulungu do Morro', text: 'Mulungu do Morro' },
    { value: 'Mundo Novo', text: 'Mundo Novo' },
    { value: 'Muniz Ferreira', text: 'Muniz Ferreira' },
    { value: 'Muquém de São Francisco', text: 'Muquém de São Francisco' },
    { value: 'Muritiba', text: 'Muritiba' },
    { value: 'Mutuípe', text: 'Mutuípe' },
    { value: 'Nazaré', text: 'Nazaré' },
    { value: 'Nilo Peçanha', text: 'Nilo Peçanha' },
    { value: 'Nordestina', text: 'Nordestina' },
    { value: 'Nova Canaã', text: 'Nova Canaã' },
    { value: 'Nova Fátima', text: 'Nova Fátima' },
    { value: 'Nova Ibiá', text: 'Nova Ibiá' },
    { value: 'Nova Itarana', text: 'Nova Itarana' },
    { value: 'Nova Redenção', text: 'Nova Redenção' },
    { value: 'Nova Soure', text: 'Nova Soure' },
    { value: 'Nova Viçosa', text: 'Nova Viçosa' },
    { value: 'Novo Horizonte', text: 'Novo Horizonte' },
    { value: 'Novo Triunfo', text: 'Novo Triunfo' },
    { value: 'Olindina', text: 'Olindina' },
    { value: 'Oliveira dos Brejinhos', text: 'Oliveira dos Brejinhos' },
    { value: 'Ouriçangas', text: 'Ouriçangas' },
    { value: 'Ourolândia', text: 'Ourolândia' },
    { value: 'Palmas de Monte Alto', text: 'Palmas de Monte Alto' },
    { value: 'Palmeiras', text: 'Palmeiras' },
    { value: 'Paramirim', text: 'Paramirim' },
    { value: 'Paratinga', text: 'Paratinga' },
    { value: 'Paripiranga', text: 'Paripiranga' },
    { value: 'Pau Brasil', text: 'Pau Brasil' },
    { value: 'Paulo Afonso', text: 'Paulo Afonso' },
    { value: 'Pé de Serra', text: 'Pé de Serra' },
    { value: 'Pedrão', text: 'Pedrão' },
    { value: 'Pedro Alexandre', text: 'Pedro Alexandre' },
    { value: 'Piatã', text: 'Piatã' },
    { value: 'Pilão Arcado', text: 'Pilão Arcado' },
    { value: 'Pindaí', text: 'Pindaí' },
    { value: 'Pindobaçu', text: 'Pindobaçu' },
    { value: 'Pintadas', text: 'Pintadas' },
    { value: 'Piraí do Norte', text: 'Piraí do Norte' },
    { value: 'Piripá', text: 'Piripá' },
    { value: 'Piritiba', text: 'Piritiba' },
    { value: 'Planaltino', text: 'Planaltino' },
    { value: 'Planalto', text: 'Planalto' },
    { value: 'Poções', text: 'Poções' },
    { value: 'Pojuca', text: 'Pojuca' },
    { value: 'Ponto Novo', text: 'Ponto Novo' },
    { value: 'Porto Seguro', text: 'Porto Seguro' },
    { value: 'Potiraguá', text: 'Potiraguá' },
    { value: 'Prado', text: 'Prado' },
    { value: 'Presidente Dutra', text: 'Presidente Dutra' },
    { value: 'Presidente Jânio Quadros', text: 'Presidente Jânio Quadros' },
    { value: 'Presidente Tancredo Neves', text: 'Presidente Tancredo Neves' },
    { value: 'Queimadas', text: 'Queimadas' },
    { value: 'Quijingue', text: 'Quijingue' },
    { value: 'Quixabeira', text: 'Quixabeira' },
    { value: 'Rafael Jambeiro', text: 'Rafael Jambeiro' },
    { value: 'Remanso', text: 'Remanso' },
    { value: 'Retirolândia', text: 'Retirolândia' },
    { value: 'Riachão das Neves', text: 'Riachão das Neves' },
    { value: 'Riachão do Jacuípe', text: 'Riachão do Jacuípe' },
    { value: 'Riacho de Santana', text: 'Riacho de Santana' },
    { value: 'Ribeira do Amparo', text: 'Ribeira do Amparo' },
    { value: 'Ribeira do Pombal', text: 'Ribeira do Pombal' },
    { value: 'Ribeirão do Largo', text: 'Ribeirão do Largo' },
    { value: 'Rio de Contas', text: 'Rio de Contas' },
    { value: 'Rio do Antônio', text: 'Rio do Antônio' },
    { value: 'Rio do Pires', text: 'Rio do Pires' },
    { value: 'Rio Real', text: 'Rio Real' },
    { value: 'Rodelas', text: 'Rodelas' },
    { value: 'Ruy Barbosa', text: 'Ruy Barbosa' },
    { value: 'Salinas da Margarida', text: 'Salinas da Margarida' },
    { value: 'Salvador', text: 'Salvador' },
    { value: 'Santa Bárbara', text: 'Santa Bárbara' },
    { value: 'Santa Brígida', text: 'Santa Brígida' },
    { value: 'Santa Cruz Cabrália', text: 'Santa Cruz Cabrália' },
    { value: 'Santa Cruz da Vitória', text: 'Santa Cruz da Vitória' },
    { value: 'Santa Inês', text: 'Santa Inês' },
    { value: 'Santa Luzia', text: 'Santa Luzia' },
    { value: 'Santa Maria da Vitória', text: 'Santa Maria da Vitória' },
    { value: 'Santa Rita de Cássia', text: 'Santa Rita de Cássia' },
    { value: 'Santa Teresinha', text: 'Santa Teresinha' },
    { value: 'Santaluz', text: 'Santaluz' },
    { value: 'Santana', text: 'Santana' },
    { value: 'Santanópolis', text: 'Santanópolis' },
    { value: 'Santo Amaro', text: 'Santo Amaro' },
    { value: 'Santo Antônio de Jesus', text: 'Santo Antônio de Jesus' },
    { value: 'Santo Estêvão', text: 'Santo Estêvão' },
    { value: 'São Desidério', text: 'São Desidério' },
    { value: 'São Domingos', text: 'São Domingos' },
    { value: 'São Felipe', text: 'São Felipe' },
    { value: 'São Félix', text: 'São Félix' },
    { value: 'São Félix do Coribe', text: 'São Félix do Coribe' },
    { value: 'São Francisco do Conde', text: 'São Francisco do Conde' },
    { value: 'São Gabriel', text: 'São Gabriel' },
    { value: 'São Gonçalo dos Campos', text: 'São Gonçalo dos Campos' },
    { value: 'São José da Vitória', text: 'São José da Vitória' },
    { value: 'São José do Jacuípe', text: 'São José do Jacuípe' },
    { value: 'São Miguel das Matas', text: 'São Miguel das Matas' },
    { value: 'São Sebastião do Passé', text: 'São Sebastião do Passé' },
    { value: 'Sapeaçu', text: 'Sapeaçu' },
    { value: 'Sátiro Dias', text: 'Sátiro Dias' },
    { value: 'Saubara', text: 'Saubara' },
    { value: 'Saúde', text: 'Saúde' },
    { value: 'Seabra', text: 'Seabra' },
    { value: 'Sebastião Laranjeiras', text: 'Sebastião Laranjeiras' },
    { value: 'Senhor do Bonfim', text: 'Senhor do Bonfim' },
    { value: 'Sento Sé', text: 'Sento Sé' },
    { value: 'Serra do Ramalho', text: 'Serra do Ramalho' },
    { value: 'Serra Dourada', text: 'Serra Dourada' },
    { value: 'Serra Preta', text: 'Serra Preta' },
    { value: 'Serrinha', text: 'Serrinha' },
    { value: 'Serrolândia', text: 'Serrolândia' },
    { value: 'Simões Filho', text: 'Simões Filho' },
    { value: 'Sítio do Mato', text: 'Sítio do Mato' },
    { value: 'Sítio do Quinto', text: 'Sítio do Quinto' },
    { value: 'Sobradinho', text: 'Sobradinho' },
    { value: 'Souto Soares', text: 'Souto Soares' },
    { value: 'Tabocas do Brejo Velho', text: 'Tabocas do Brejo Velho' },
    { value: 'Tanhaçu', text: 'Tanhaçu' },
    { value: 'Tanque Novo', text: 'Tanque Novo' },
    { value: 'Tanquinho', text: 'Tanquinho' },
    { value: 'Taperoá', text: 'Taperoá' },
    { value: 'Tapiramutá', text: 'Tapiramutá' },
    { value: 'Teixeira de Freitas', text: 'Teixeira de Freitas' },
    { value: 'Teodoro Sampaio', text: 'Teodoro Sampaio' },
    { value: 'Teofilândia', text: 'Teofilândia' },
    { value: 'Teolândia', text: 'Teolândia' },
    { value: 'Terra Nova', text: 'Terra Nova' },
    { value: 'Tremedal', text: 'Tremedal' },
    { value: 'Tucano', text: 'Tucano' },
    { value: 'Uauá', text: 'Uauá' },
    { value: 'Ubaíra', text: 'Ubaíra' },
    { value: 'Ubaitaba', text: 'Ubaitaba' },
    { value: 'Ubatã', text: 'Ubatã' },
    { value: 'Uibaí', text: 'Uibaí' },
    { value: 'Umburanas', text: 'Umburanas' },
    { value: 'Una', text: 'Una' },
    { value: 'Urandi', text: 'Urandi' },
    { value: 'Uruçuca', text: 'Uruçuca' },
    { value: 'Utinga', text: 'Utinga' },
    { value: 'Valença', text: 'Valença' },
    { value: 'Valente', text: 'Valente' },
    { value: 'Várzea da Roça', text: 'Várzea da Roça' },
    { value: 'Várzea do Poço', text: 'Várzea do Poço' },
    { value: 'Várzea Nova', text: 'Várzea Nova' },
    { value: 'Varzedo', text: 'Varzedo' },
    { value: 'Vera Cruz', text: 'Vera Cruz' },
    { value: 'Vereda', text: 'Vereda' },
    { value: 'Vitória da Conquista', text: 'Vitória da Conquista' },
    { value: 'Wagner', text: 'Wagner' },
    { value: 'Wanderley', text: 'Wanderley' },
    { value: 'Wenceslau Guimarães', text: 'Wenceslau Guimarães' },
    { value: 'Xique-Xique', text: 'Xique-Xique' },
  ],
  CE: [
    { value: 'Abaiara', text: 'Abaiara' },
    { value: 'Acarape', text: 'Acarape' },
    { value: 'Acaraú', text: 'Acaraú' },
    { value: 'Acopiara', text: 'Acopiara' },
    { value: 'Aiuaba', text: 'Aiuaba' },
    { value: 'Alcântaras', text: 'Alcântaras' },
    { value: 'Altaneira', text: 'Altaneira' },
    { value: 'Alto Santo', text: 'Alto Santo' },
    { value: 'Amontada', text: 'Amontada' },
    { value: 'Antonina do Norte', text: 'Antonina do Norte' },
    { value: 'Apuiarés', text: 'Apuiarés' },
    { value: 'Aquiraz', text: 'Aquiraz' },
    { value: 'Aracati', text: 'Aracati' },
    { value: 'Aracoiaba', text: 'Aracoiaba' },
    { value: 'Ararendá', text: 'Ararendá' },
    { value: 'Araripe', text: 'Araripe' },
    { value: 'Aratuba', text: 'Aratuba' },
    { value: 'Arneiroz', text: 'Arneiroz' },
    { value: 'Assaré', text: 'Assaré' },
    { value: 'Aurora', text: 'Aurora' },
    { value: 'Baixio', text: 'Baixio' },
    { value: 'Banabuiú', text: 'Banabuiú' },
    { value: 'Barbalha', text: 'Barbalha' },
    { value: 'Barreira', text: 'Barreira' },
    { value: 'Barro', text: 'Barro' },
    { value: 'Barroquinha', text: 'Barroquinha' },
    { value: 'Baturité', text: 'Baturité' },
    { value: 'Beberibe', text: 'Beberibe' },
    { value: 'Bela Cruz', text: 'Bela Cruz' },
    { value: 'Boa Viagem', text: 'Boa Viagem' },
    { value: 'Brejo Santo', text: 'Brejo Santo' },
    { value: 'Camocim', text: 'Camocim' },
    { value: 'Campos Sales', text: 'Campos Sales' },
    { value: 'Canindé', text: 'Canindé' },
    { value: 'Capistrano', text: 'Capistrano' },
    { value: 'Caridade', text: 'Caridade' },
    { value: 'Cariré', text: 'Cariré' },
    { value: 'Caririaçu', text: 'Caririaçu' },
    { value: 'Cariús', text: 'Cariús' },
    { value: 'Carnaubal', text: 'Carnaubal' },
    { value: 'Cascavel', text: 'Cascavel' },
    { value: 'Catarina', text: 'Catarina' },
    { value: 'Catunda', text: 'Catunda' },
    { value: 'Caucaia', text: 'Caucaia' },
    { value: 'Cedro', text: 'Cedro' },
    { value: 'Chaval', text: 'Chaval' },
    { value: 'Choró', text: 'Choró' },
    { value: 'Chorozinho', text: 'Chorozinho' },
    { value: 'Coreaú', text: 'Coreaú' },
    { value: 'Crateús', text: 'Crateús' },
    { value: 'Crato', text: 'Crato' },
    { value: 'Croatá', text: 'Croatá' },
    { value: 'Cruz', text: 'Cruz' },
    { value: 'Deputado Irapuan Pinheiro', text: 'Deputado Irapuan Pinheiro' },
    { value: 'Ererê', text: 'Ererê' },
    { value: 'Eusébio', text: 'Eusébio' },
    { value: 'Farias Brito', text: 'Farias Brito' },
    { value: 'Forquilha', text: 'Forquilha' },
    { value: 'Fortaleza', text: 'Fortaleza' },
    { value: 'Fortim', text: 'Fortim' },
    { value: 'Frecheirinha', text: 'Frecheirinha' },
    { value: 'General Sampaio', text: 'General Sampaio' },
    { value: 'Graça', text: 'Graça' },
    { value: 'Granja', text: 'Granja' },
    { value: 'Granjeiro', text: 'Granjeiro' },
    { value: 'Groaíras', text: 'Groaíras' },
    { value: 'Guaiúba', text: 'Guaiúba' },
    { value: 'Guaraciaba do Norte', text: 'Guaraciaba do Norte' },
    { value: 'Guaramiranga', text: 'Guaramiranga' },
    { value: 'Hidrolândia', text: 'Hidrolândia' },
    { value: 'Horizonte', text: 'Horizonte' },
    { value: 'Ibaretama', text: 'Ibaretama' },
    { value: 'Ibiapina', text: 'Ibiapina' },
    { value: 'Ibicuitinga', text: 'Ibicuitinga' },
    { value: 'Icapuí', text: 'Icapuí' },
    { value: 'Icó', text: 'Icó' },
    { value: 'Iguatu', text: 'Iguatu' },
    { value: 'Independência', text: 'Independência' },
    { value: 'Ipaporanga', text: 'Ipaporanga' },
    { value: 'Ipaumirim', text: 'Ipaumirim' },
    { value: 'Ipu', text: 'Ipu' },
    { value: 'Ipueiras', text: 'Ipueiras' },
    { value: 'Iracema', text: 'Iracema' },
    { value: 'Irauçuba', text: 'Irauçuba' },
    { value: 'Itaiçaba', text: 'Itaiçaba' },
    { value: 'Itaitinga', text: 'Itaitinga' },
    { value: 'Itapajé', text: 'Itapajé' },
    { value: 'Itapipoca', text: 'Itapipoca' },
    { value: 'Itapiúna', text: 'Itapiúna' },
    { value: 'Itarema', text: 'Itarema' },
    { value: 'Itatira', text: 'Itatira' },
    { value: 'Jaguaretama', text: 'Jaguaretama' },
    { value: 'Jaguaribara', text: 'Jaguaribara' },
    { value: 'Jaguaribe', text: 'Jaguaribe' },
    { value: 'Jaguaruana', text: 'Jaguaruana' },
    { value: 'Jardim', text: 'Jardim' },
    { value: 'Jati', text: 'Jati' },
    { value: 'Jijoca de Jericoaroara', text: 'Jijoca de Jericoaroara' },
    { value: 'Juazeiro do Norte', text: 'Juazeiro do Norte' },
    { value: 'Jucás', text: 'Jucás' },
    { value: 'Lavras da Mangabeira', text: 'Lavras da Mangabeira' },
    { value: 'Limoeiro do Norte', text: 'Limoeiro do Norte' },
    { value: 'Madalena', text: 'Madalena' },
    { value: 'Maracanaú', text: 'Maracanaú' },
    { value: 'Maranguape', text: 'Maranguape' },
    { value: 'Marco', text: 'Marco' },
    { value: 'Martinópole', text: 'Martinópole' },
    { value: 'Massapê', text: 'Massapê' },
    { value: 'Mauriti', text: 'Mauriti' },
    { value: 'Meruoca', text: 'Meruoca' },
    { value: 'Milagres', text: 'Milagres' },
    { value: 'Milhã', text: 'Milhã' },
    { value: 'Miraíma', text: 'Miraíma' },
    { value: 'Missão Velha', text: 'Missão Velha' },
    { value: 'Mombaça', text: 'Mombaça' },
    { value: 'Monsenhor Tabosa', text: 'Monsenhor Tabosa' },
    { value: 'Morada Nova', text: 'Morada Nova' },
    { value: 'Moraújo', text: 'Moraújo' },
    { value: 'Morrinhos', text: 'Morrinhos' },
    { value: 'Mucambo', text: 'Mucambo' },
    { value: 'Mulungu', text: 'Mulungu' },
    { value: 'Nova Olinda', text: 'Nova Olinda' },
    { value: 'Nova Russas', text: 'Nova Russas' },
    { value: 'Novo Oriente', text: 'Novo Oriente' },
    { value: 'Ocara', text: 'Ocara' },
    { value: 'Orós', text: 'Orós' },
    { value: 'Pacajus', text: 'Pacajus' },
    { value: 'Pacatuba', text: 'Pacatuba' },
    { value: 'Pacoti', text: 'Pacoti' },
    { value: 'Pacujá', text: 'Pacujá' },
    { value: 'Palhano', text: 'Palhano' },
    { value: 'Palmácia', text: 'Palmácia' },
    { value: 'Paracuru', text: 'Paracuru' },
    { value: 'Paraipaba', text: 'Paraipaba' },
    { value: 'Parambu', text: 'Parambu' },
    { value: 'Paramoti', text: 'Paramoti' },
    { value: 'Pedra Branca', text: 'Pedra Branca' },
    { value: 'Penaforte', text: 'Penaforte' },
    { value: 'Pentecoste', text: 'Pentecoste' },
    { value: 'Pereiro', text: 'Pereiro' },
    { value: 'Pindoretama', text: 'Pindoretama' },
    { value: 'Piquet Carneiro', text: 'Piquet Carneiro' },
    { value: 'Pires Ferreira', text: 'Pires Ferreira' },
    { value: 'Poranga', text: 'Poranga' },
    { value: 'Porteiras', text: 'Porteiras' },
    { value: 'Potengi', text: 'Potengi' },
    { value: 'Potiretama', text: 'Potiretama' },
    { value: 'Quiterianópolis', text: 'Quiterianópolis' },
    { value: 'Quixadá', text: 'Quixadá' },
    { value: 'Quixelô', text: 'Quixelô' },
    { value: 'Quixeramobim', text: 'Quixeramobim' },
    { value: 'Quixeré', text: 'Quixeré' },
    { value: 'Redenção', text: 'Redenção' },
    { value: 'Reriutaba', text: 'Reriutaba' },
    { value: 'Russas', text: 'Russas' },
    { value: 'Saboeiro', text: 'Saboeiro' },
    { value: 'Salitre', text: 'Salitre' },
    { value: 'Santa Quitéria', text: 'Santa Quitéria' },
    { value: 'Santana do Acaraú', text: 'Santana do Acaraú' },
    { value: 'Santana do Cariri', text: 'Santana do Cariri' },
    { value: 'São Benedito', text: 'São Benedito' },
    { value: 'São Gonçalo do Amarante', text: 'São Gonçalo do Amarante' },
    { value: 'São João do Jaguaribe', text: 'São João do Jaguaribe' },
    { value: 'São Luís do Curu', text: 'São Luís do Curu' },
    { value: 'Senador Pompeu', text: 'Senador Pompeu' },
    { value: 'Senador Sá', text: 'Senador Sá' },
    { value: 'Sobral', text: 'Sobral' },
    { value: 'Solonópole', text: 'Solonópole' },
    { value: 'Tabuleiro do Norte', text: 'Tabuleiro do Norte' },
    { value: 'Tamboril', text: 'Tamboril' },
    { value: 'Tarrafas', text: 'Tarrafas' },
    { value: 'Tauá', text: 'Tauá' },
    { value: 'Tejuçuoca', text: 'Tejuçuoca' },
    { value: 'Tianguá', text: 'Tianguá' },
    { value: 'Trairi', text: 'Trairi' },
    { value: 'Tururu', text: 'Tururu' },
    { value: 'Ubajara', text: 'Ubajara' },
    { value: 'Umari', text: 'Umari' },
    { value: 'Umirim', text: 'Umirim' },
    { value: 'Uruburetama', text: 'Uruburetama' },
    { value: 'Uruoca', text: 'Uruoca' },
    { value: 'Varjota', text: 'Varjota' },
    { value: 'Várzea Alegre', text: 'Várzea Alegre' },
    { value: 'Viçosa do Ceará', text: 'Viçosa do Ceará' },
  ],
  DF: [
    { value: 'Águas Claras', text: 'Águas Claras' },
    { value: 'Arniqueira', text: 'Arniqueira' },
    { value: 'Brasília', text: 'Brasília' },
    { value: 'Brazlândia', text: 'Brazlândia' },
    { value: 'Candangolândia', text: 'Candangolândia' },
    { value: 'Ceilândia', text: 'Ceilândia' },
    { value: 'Cruzeiro', text: 'Cruzeiro' },
    { value: 'Estrutural', text: 'Estrutural' },
    { value: 'Fercal', text: 'Fercal' },
    { value: 'Gama', text: 'Gama' },
    { value: 'Guará', text: 'Guará' },
    { value: 'Itapoã', text: 'Itapoã' },
    { value: 'Jardim Botânico', text: 'Jardim Botânico' },
    { value: 'Lago Norte', text: 'Lago Norte' },
    { value: 'Lago Sul', text: 'Lago Sul' },
    { value: 'Núcleo Bandeirante', text: 'Núcleo Bandeirante' },
    { value: 'Octogonal', text: 'Octogonal' },
    { value: 'Paranoá', text: 'Paranoá' },
    { value: 'Park Way', text: 'Park Way' },
    { value: 'Planaltina', text: 'Planaltina' },
    { value: 'Plano Piloto', text: 'Plano Piloto' },
    { value: 'Pôr do Sol', text: 'Pôr do Sol' },
    { value: 'Recanto das Emas', text: 'Recanto das Emas' },
    { value: 'Riacho Fundo II', text: 'Riacho Fundo II' },
    { value: 'Riacho Fundo', text: 'Riacho Fundo' },
    { value: 'Samambaia', text: 'Samambaia' },
    { value: 'Santa Maria', text: 'Santa Maria' },
    { value: 'São Sebastião', text: 'São Sebastião' },
    { value: 'SIA', text: 'SIA' },
    { value: 'Sobradinho II', text: 'Sobradinho II' },
    { value: 'Sobradinho', text: 'Sobradinho' },
    { value: 'Sol Nascente', text: 'Sol Nascente' },
    { value: 'Sudoeste', text: 'Sudoeste' },
    { value: 'Taguatinga', text: 'Taguatinga' },
    { value: 'Taguatinga', text: 'Taguatinga' },
    { value: 'Varjão', text: 'Varjão' },
    { value: 'Vicente Pires', text: 'Vicente Pires' },
  ],
  ES: [
    { value: 'Afonso Cláudio', text: 'Afonso Cláudio' },
    { value: 'Água Doce do Norte', text: 'Água Doce do Norte' },
    { value: 'Águia Branca', text: 'Águia Branca' },
    { value: 'Alegre', text: 'Alegre' },
    { value: 'Alfredo Chaves', text: 'Alfredo Chaves' },
    { value: 'Alto Rio Novo', text: 'Alto Rio Novo' },
    { value: 'Anchieta', text: 'Anchieta' },
    { value: 'Apiacá', text: 'Apiacá' },
    { value: 'Aracruz', text: 'Aracruz' },
    { value: 'Atilio Vivacqua', text: 'Atilio Vivacqua' },
    { value: 'Baixo Guandu', text: 'Baixo Guandu' },
    { value: 'Barra de São Francisco', text: 'Barra de São Francisco' },
    { value: 'Boa Esperança', text: 'Boa Esperança' },
    { value: 'Bom Jesus do Norte', text: 'Bom Jesus do Norte' },
    { value: 'Brejetuba', text: 'Brejetuba' },
    { value: 'Cachoeiro de Itapemirim', text: 'Cachoeiro de Itapemirim' },
    { value: 'Cariacica', text: 'Cariacica' },
    { value: 'Castelo', text: 'Castelo' },
    { value: 'Colatina', text: 'Colatina' },
    { value: 'Conceição da Barra', text: 'Conceição da Barra' },
    { value: 'Conceição do Castelo', text: 'Conceição do Castelo' },
    { value: 'Divino de São Lourenço', text: 'Divino de São Lourenço' },
    { value: 'Domingos Martins', text: 'Domingos Martins' },
    { value: 'Dores do Rio Preto', text: 'Dores do Rio Preto' },
    { value: 'Ecoporanga', text: 'Ecoporanga' },
    { value: 'Fundão', text: 'Fundão' },
    { value: 'Governador Lindenberg', text: 'Governador Lindenberg' },
    { value: 'Guaçuí', text: 'Guaçuí' },
    { value: 'Guarapari', text: 'Guarapari' },
    { value: 'Ibatiba', text: 'Ibatiba' },
    { value: 'Ibiraçu', text: 'Ibiraçu' },
    { value: 'Ibitirama', text: 'Ibitirama' },
    { value: 'Iconha', text: 'Iconha' },
    { value: 'Irupi', text: 'Irupi' },
    { value: 'Itaguaçu', text: 'Itaguaçu' },
    { value: 'Itapemirim', text: 'Itapemirim' },
    { value: 'Itarana', text: 'Itarana' },
    { value: 'Iúna', text: 'Iúna' },
    { value: 'Jaguaré', text: 'Jaguaré' },
    { value: 'Jerônimo Monteiro', text: 'Jerônimo Monteiro' },
    { value: 'João Neiva', text: 'João Neiva' },
    { value: 'Laranja da Terra', text: 'Laranja da Terra' },
    { value: 'Linhares', text: 'Linhares' },
    { value: 'Mantenópolis', text: 'Mantenópolis' },
    { value: 'Marataizes', text: 'Marataizes' },
    { value: 'Marechal Floriano', text: 'Marechal Floriano' },
    { value: 'Marilândia', text: 'Marilândia' },
    { value: 'Mimoso do Sul', text: 'Mimoso do Sul' },
    { value: 'Montanha', text: 'Montanha' },
    { value: 'Mucurici', text: 'Mucurici' },
    { value: 'Muniz Freire', text: 'Muniz Freire' },
    { value: 'Muqui', text: 'Muqui' },
    { value: 'Nova Venécia', text: 'Nova Venécia' },
    { value: 'Pancas', text: 'Pancas' },
    { value: 'Pedro Canário', text: 'Pedro Canário' },
    { value: 'Pinheiros', text: 'Pinheiros' },
    { value: 'Piúma', text: 'Piúma' },
    { value: 'Ponto Belo', text: 'Ponto Belo' },
    { value: 'Presidente Kennedy', text: 'Presidente Kennedy' },
    { value: 'Rio Bananal', text: 'Rio Bananal' },
    { value: 'Rio Novo do Sul', text: 'Rio Novo do Sul' },
    { value: 'Santa Leopoldina', text: 'Santa Leopoldina' },
    { value: 'Santa Maria de Jetibá', text: 'Santa Maria de Jetibá' },
    { value: 'Santa Teresa', text: 'Santa Teresa' },
    { value: 'São Domingos do Norte', text: 'São Domingos do Norte' },
    { value: 'São Gabriel da Palha', text: 'São Gabriel da Palha' },
    { value: 'São José do Calçado', text: 'São José do Calçado' },
    { value: 'São Mateus', text: 'São Mateus' },
    { value: 'São Roque do Canaã', text: 'São Roque do Canaã' },
    { value: 'Serra', text: 'Serra' },
    { value: 'Sooretama', text: 'Sooretama' },
    { value: 'Vargem Alta', text: 'Vargem Alta' },
    { value: 'Venda Nova do Imigrante', text: 'Venda Nova do Imigrante' },
    { value: 'Viana', text: 'Viana' },
    { value: 'Vila Pavão', text: 'Vila Pavão' },
    { value: 'Vila Valério', text: 'Vila Valério' },
    { value: 'Vila Velha', text: 'Vila Velha' },
    { value: 'Vitória', text: 'Vitória' },
  ],
  GO: [
    { value: 'Abadia de Goiás', text: 'Abadia de Goiás' },
    { value: 'Abadiânia', text: 'Abadiânia' },
    { value: 'Acreúna', text: 'Acreúna' },
    { value: 'Adelândia', text: 'Adelândia' },
    { value: 'Água Fria de Goiás', text: 'Água Fria de Goiás' },
    { value: 'Água Limpa', text: 'Água Limpa' },
    { value: 'Águas Lindas de Goiás', text: 'Águas Lindas de Goiás' },
    { value: 'Alexânia', text: 'Alexânia' },
    { value: 'Aloândia', text: 'Aloândia' },
    { value: 'Alto Horizonte', text: 'Alto Horizonte' },
    { value: 'Alto Paraíso de Goiás', text: 'Alto Paraíso de Goiás' },
    { value: 'Alvorada do Norte', text: 'Alvorada do Norte' },
    { value: 'Amaralina', text: 'Amaralina' },
    { value: 'Americano do Brasil', text: 'Americano do Brasil' },
    { value: 'Amorinópolis', text: 'Amorinópolis' },
    { value: 'Anápolis', text: 'Anápolis' },
    { value: 'Anhanguera', text: 'Anhanguera' },
    { value: 'Anicuns', text: 'Anicuns' },
    { value: 'Aparecida de Goiânia', text: 'Aparecida de Goiânia' },
    { value: 'Aparecida do Rio Doce', text: 'Aparecida do Rio Doce' },
    { value: 'Aporé', text: 'Aporé' },
    { value: 'Araçu', text: 'Araçu' },
    { value: 'Aragarças', text: 'Aragarças' },
    { value: 'Aragoiânia', text: 'Aragoiânia' },
    { value: 'Araguapaz', text: 'Araguapaz' },
    { value: 'Arenópolis', text: 'Arenópolis' },
    { value: 'Aruanã', text: 'Aruanã' },
    { value: 'Aurilândia', text: 'Aurilândia' },
    { value: 'Avelinópolis', text: 'Avelinópolis' },
    { value: 'Baliza', text: 'Baliza' },
    { value: 'Barro Alto', text: 'Barro Alto' },
    { value: 'Bela Vista de Goiás', text: 'Bela Vista de Goiás' },
    { value: 'Bom Jardim de Goiás', text: 'Bom Jardim de Goiás' },
    { value: 'Bom Jesus de Goiás', text: 'Bom Jesus de Goiás' },
    { value: 'Bonfinópolis', text: 'Bonfinópolis' },
    { value: 'Bonópolis', text: 'Bonópolis' },
    { value: 'Brazabrantes', text: 'Brazabrantes' },
    { value: 'Britânia', text: 'Britânia' },
    { value: 'Buriti Alegre', text: 'Buriti Alegre' },
    { value: 'Buriti de Goiás', text: 'Buriti de Goiás' },
    { value: 'Buritinópolis', text: 'Buritinópolis' },
    { value: 'Cabeceiras', text: 'Cabeceiras' },
    { value: 'Cachoeira Alta', text: 'Cachoeira Alta' },
    { value: 'Cachoeira de Goiás', text: 'Cachoeira de Goiás' },
    { value: 'Cachoeira Dourada', text: 'Cachoeira Dourada' },
    { value: 'Caçu', text: 'Caçu' },
    { value: 'Caiapônia', text: 'Caiapônia' },
    { value: 'Caldas Novas', text: 'Caldas Novas' },
    { value: 'Caldazinha', text: 'Caldazinha' },
    { value: 'Campestre de Goiás', text: 'Campestre de Goiás' },
    { value: 'Campinaçu', text: 'Campinaçu' },
    { value: 'Campinorte', text: 'Campinorte' },
    { value: 'Campo Alegre de Goiás', text: 'Campo Alegre de Goiás' },
    { value: 'Campos Limpo de Goiás', text: 'Campos Limpo de Goiás' },
    { value: 'Campos Belos', text: 'Campos Belos' },
    { value: 'Campos Verdes', text: 'Campos Verdes' },
    { value: 'Carmo do Rio Verde', text: 'Carmo do Rio Verde' },
    { value: 'Castelândia', text: 'Castelândia' },
    { value: 'Catalão', text: 'Catalão' },
    { value: 'Caturaí', text: 'Caturaí' },
    { value: 'Cavalcante', text: 'Cavalcante' },
    { value: 'Ceres', text: 'Ceres' },
    { value: 'Cezarina', text: 'Cezarina' },
    { value: 'Chapadão do Céu', text: 'Chapadão do Céu' },
    { value: 'Cidade Ocidental', text: 'Cidade Ocidental' },
    { value: 'Cocalzinho de Goiás', text: 'Cocalzinho de Goiás' },
    { value: 'Colinas do Sul', text: 'Colinas do Sul' },
    { value: 'Córrego do Ouro', text: 'Córrego do Ouro' },
    { value: 'Corumbá de Goiás', text: 'Corumbá de Goiás' },
    { value: 'Corumbaíba', text: 'Corumbaíba' },
    { value: 'Cristalina', text: 'Cristalina' },
    { value: 'Cristianópolis', text: 'Cristianópolis' },
    { value: 'Crixás', text: 'Crixás' },
    { value: 'Cromínia', text: 'Cromínia' },
    { value: 'Cumari', text: 'Cumari' },
    { value: 'Damianópolis', text: 'Damianópolis' },
    { value: 'Damolândia', text: 'Damolândia' },
    { value: 'Davinópolis', text: 'Davinópolis' },
    { value: 'Diorama', text: 'Diorama' },
    { value: 'Divinópolis de Goiás', text: 'Divinópolis de Goiás' },
    { value: 'Doverlândia', text: 'Doverlândia' },
    { value: 'Edealina', text: 'Edealina' },
    { value: 'Edéia', text: 'Edéia' },
    { value: 'Estrela do Norte', text: 'Estrela do Norte' },
    { value: 'Faina', text: 'Faina' },
    { value: 'Fazenda Nova', text: 'Fazenda Nova' },
    { value: 'Firminópolis', text: 'Firminópolis' },
    { value: 'Flores de Goiás', text: 'Flores de Goiás' },
    { value: 'Formosa', text: 'Formosa' },
    { value: 'Formoso', text: 'Formoso' },
    { value: 'Gameleira de Goiás', text: 'Gameleira de Goiás' },
    { value: 'Goianápolis', text: 'Goianápolis' },
    { value: 'Goiandira', text: 'Goiandira' },
    { value: 'Goianésia', text: 'Goianésia' },
    { value: 'Goiânia', text: 'Goiânia' },
    { value: 'Goianira', text: 'Goianira' },
    { value: 'Goiás', text: 'Goiás' },
    { value: 'Goiatuba', text: 'Goiatuba' },
    { value: 'Gouvelândia', text: 'Gouvelândia' },
    { value: 'Guapó', text: 'Guapó' },
    { value: 'Guaraíta', text: 'Guaraíta' },
    { value: 'Guarani de Goiás', text: 'Guarani de Goiás' },
    { value: 'Guarinos', text: 'Guarinos' },
    { value: 'Heitoraí', text: 'Heitoraí' },
    { value: 'Hidrolândia', text: 'Hidrolândia' },
    { value: 'Hidrolina', text: 'Hidrolina' },
    { value: 'Iaciara', text: 'Iaciara' },
    { value: 'Inaciolândia', text: 'Inaciolândia' },
    { value: 'Indiara', text: 'Indiara' },
    { value: 'Inhumas', text: 'Inhumas' },
    { value: 'Ipameri', text: 'Ipameri' },
    { value: 'Ipiranga de Goiás', text: 'Ipiranga de Goiás' },
    { value: 'Iporá', text: 'Iporá' },
    { value: 'Israelândia', text: 'Israelândia' },
    { value: 'Itaberaí', text: 'Itaberaí' },
    { value: 'Itaguari', text: 'Itaguari' },
    { value: 'Itaguaru', text: 'Itaguaru' },
    { value: 'Itajá', text: 'Itajá' },
    { value: 'Itapaci', text: 'Itapaci' },
    { value: 'Itapirapuã', text: 'Itapirapuã' },
    { value: 'Itapuranga', text: 'Itapuranga' },
    { value: 'Itarumã', text: 'Itarumã' },
    { value: 'Itauçu', text: 'Itauçu' },
    { value: 'Itumbiara', text: 'Itumbiara' },
    { value: 'Ivolândia', text: 'Ivolândia' },
    { value: 'Jandaia', text: 'Jandaia' },
    { value: 'Jaraguá', text: 'Jaraguá' },
    { value: 'Jataí', text: 'Jataí' },
    { value: 'Jaupaci', text: 'Jaupaci' },
    { value: 'Jesúpolis', text: 'Jesúpolis' },
    { value: 'Joviânia', text: 'Joviânia' },
    { value: 'Jussara', text: 'Jussara' },
    { value: 'Lagoa Santa', text: 'Lagoa Santa' },
    { value: 'Leopoldo de Bulhões', text: 'Leopoldo de Bulhões' },
    { value: 'Luziânia', text: 'Luziânia' },
    { value: 'Mairipotaba', text: 'Mairipotaba' },
    { value: 'Mambaí', text: 'Mambaí' },
    { value: 'Mara Rosa', text: 'Mara Rosa' },
    { value: 'Marzagão', text: 'Marzagão' },
    { value: 'Matrinchã', text: 'Matrinchã' },
    { value: 'Maurilândia', text: 'Maurilândia' },
    { value: 'Mimoso de Goiás', text: 'Mimoso de Goiás' },
    { value: 'Minaçu', text: 'Minaçu' },
    { value: 'Mineiros', text: 'Mineiros' },
    { value: 'Moiporá', text: 'Moiporá' },
    { value: 'Monte Alegre de Goiás', text: 'Monte Alegre de Goiás' },
    { value: 'Montes Claros de Goiás', text: 'Montes Claros de Goiás' },
    { value: 'Montividiu', text: 'Montividiu' },
    { value: 'Montividiu do Norte', text: 'Montividiu do Norte' },
    { value: 'Morrinhos', text: 'Morrinhos' },
    { value: 'Morro Agudo de Goiás', text: 'Morro Agudo de Goiás' },
    { value: 'Mossâmedes', text: 'Mossâmedes' },
    { value: 'Mozarlândia', text: 'Mozarlândia' },
    { value: 'Mundo Novo', text: 'Mundo Novo' },
    { value: 'Mutunópolis', text: 'Mutunópolis' },
    { value: 'Nazário', text: 'Nazário' },
    { value: 'Nerópolis', text: 'Nerópolis' },
    { value: 'Niquelândia', text: 'Niquelândia' },
    { value: 'Nova América', text: 'Nova América' },
    { value: 'Nova Aurora', text: 'Nova Aurora' },
    { value: 'Nova Crixás', text: 'Nova Crixás' },
    { value: 'Nova Glória', text: 'Nova Glória' },
    { value: 'Nova Iguaçu de Goiás', text: 'Nova Iguaçu de Goiás' },
    { value: 'Nova Roma', text: 'Nova Roma' },
    { value: 'Nova Veneza', text: 'Nova Veneza' },
    { value: 'Novo Brasil', text: 'Novo Brasil' },
    { value: 'Novo Gama', text: 'Novo Gama' },
    { value: 'Novo Planalto', text: 'Novo Planalto' },
    { value: 'Orizona', text: 'Orizona' },
    { value: 'Ouro Verde de Goiás', text: 'Ouro Verde de Goiás' },
    { value: 'Ouvidor', text: 'Ouvidor' },
    { value: 'Padre Bernardo', text: 'Padre Bernardo' },
    { value: 'Palestina de Goiás', text: 'Palestina de Goiás' },
    { value: 'Palmeiras de Goiás', text: 'Palmeiras de Goiás' },
    { value: 'Palmelo', text: 'Palmelo' },
    { value: 'Palminópolis', text: 'Palminópolis' },
    { value: 'Panamá', text: 'Panamá' },
    { value: 'Paranaiguara', text: 'Paranaiguara' },
    { value: 'Paraúna', text: 'Paraúna' },
    { value: 'Perolândia', text: 'Perolândia' },
    { value: 'Petrolina de Goiás', text: 'Petrolina de Goiás' },
    { value: 'Pilar de Goiás', text: 'Pilar de Goiás' },
    { value: 'Piracanjuba', text: 'Piracanjuba' },
    { value: 'Piranhas', text: 'Piranhas' },
    { value: 'Pirenópolis', text: 'Pirenópolis' },
    { value: 'Pires do Rio', text: 'Pires do Rio' },
    { value: 'Planaltina', text: 'Planaltina' },
    { value: 'Pontalina', text: 'Pontalina' },
    { value: 'Porangatu', text: 'Porangatu' },
    { value: 'Porteirão', text: 'Porteirão' },
    { value: 'Portelândia', text: 'Portelândia' },
    { value: 'Posse', text: 'Posse' },
    { value: 'Professor Jamil', text: 'Professor Jamil' },
    { value: 'Quirinópolis', text: 'Quirinópolis' },
    { value: 'Rialma', text: 'Rialma' },
    { value: 'Rianápolis', text: 'Rianápolis' },
    { value: 'Rio Quente', text: 'Rio Quente' },
    { value: 'Rio Verde', text: 'Rio Verde' },
    { value: 'Rubiataba', text: 'Rubiataba' },
    { value: 'Sanclerlândia', text: 'Sanclerlândia' },
    { value: 'Santa Bárbara de Goiás', text: 'Santa Bárbara de Goiás' },
    { value: 'Santa Cruz de Goiás', text: 'Santa Cruz de Goiás' },
    { value: 'Santa Fé de Goiás', text: 'Santa Fé de Goiás' },
    { value: 'Santa Helena de Goiás', text: 'Santa Helena de Goiás' },
    { value: 'Santa Isabel', text: 'Santa Isabel' },
    { value: 'Santa Rita do Araguaia', text: 'Santa Rita do Araguaia' },
    { value: 'Santa Rita do Novo Destino', text: 'Santa Rita do Novo Destino' },
    { value: 'Santa Rosa de Goiás', text: 'Santa Rosa de Goiás' },
    { value: 'Santa Tereza de Goiás', text: 'Santa Tereza de Goiás' },
    { value: 'Santa Terezinha de Goiás', text: 'Santa Terezinha de Goiás' },
    { value: 'Santo Antônio da Barra', text: 'Santo Antônio da Barra' },
    { value: 'Santo Antônio de Goiás', text: 'Santo Antônio de Goiás' },
    {
      value: 'Santo Antônio do Descoberto',
      text: 'Santo Antônio do Descoberto',
    },
    { value: 'São Domingos', text: 'São Domingos' },
    { value: 'São Francisco de Goiás', text: 'São Francisco de Goiás' },
    { value: "São João d'Aliança", text: "São João d'Aliança" },
    { value: 'São João da Paraúna', text: 'São João da Paraúna' },
    { value: 'São Luís de Montes Belos', text: 'São Luís de Montes Belos' },
    { value: 'São Luíz do Norte', text: 'São Luíz do Norte' },
    { value: 'São Miguel do Araguaia', text: 'São Miguel do Araguaia' },
    { value: 'São Miguel do Passa Quatro', text: 'São Miguel do Passa Quatro' },
    { value: 'São Patrício', text: 'São Patrício' },
    { value: 'São Simão', text: 'São Simão' },
    { value: 'Senador Canedo', text: 'Senador Canedo' },
    { value: 'Serranópolis', text: 'Serranópolis' },
    { value: 'Silvânia', text: 'Silvânia' },
    { value: 'Simolândia', text: 'Simolândia' },
    { value: "Sítio d'Abadia", text: "Sítio d'Abadia" },
    { value: 'Taquaral de Goiás', text: 'Taquaral de Goiás' },
    { value: 'Teresina de Goiás', text: 'Teresina de Goiás' },
    { value: 'Terezópolis de Goiás', text: 'Terezópolis de Goiás' },
    { value: 'Três Ranchos', text: 'Três Ranchos' },
    { value: 'Trindade', text: 'Trindade' },
    { value: 'Trombas', text: 'Trombas' },
    { value: 'Turvânia', text: 'Turvânia' },
    { value: 'Turvelândia', text: 'Turvelândia' },
    { value: 'Uirapuru', text: 'Uirapuru' },
    { value: 'Uruaçu', text: 'Uruaçu' },
    { value: 'Uruana', text: 'Uruana' },
    { value: 'Urutaí', text: 'Urutaí' },
    { value: 'Valparaíso de Goiás', text: 'Valparaíso de Goiás' },
    { value: 'Varjão', text: 'Varjão' },
    { value: 'Vianópolis', text: 'Vianópolis' },
    { value: 'Vicentinópolis', text: 'Vicentinópolis' },
    { value: 'Vila Boa', text: 'Vila Boa' },
    { value: 'Vila Propício', text: 'Vila Propício' },
  ],
  MA: [
    { value: 'Açailândia', text: 'Açailândia' },
    { value: 'Afonso Cunha', text: 'Afonso Cunha' },
    { value: 'Água Doce do Maranhão', text: 'Água Doce do Maranhão' },
    { value: 'Alcântara', text: 'Alcântara' },
    { value: 'Aldeias Altas', text: 'Aldeias Altas' },
    { value: 'Altamira do Maranhão', text: 'Altamira do Maranhão' },
    { value: 'Alto Alegre do Maranhão', text: 'Alto Alegre do Maranhão' },
    { value: 'Alto Alegre do Pindaré', text: 'Alto Alegre do Pindaré' },
    { value: 'Alto Parnaíba', text: 'Alto Parnaíba' },
    { value: 'Amapá do Maranhão', text: 'Amapá do Maranhão' },
    { value: 'Amarante do Maranhão', text: 'Amarante do Maranhão' },
    { value: 'Anajatuba', text: 'Anajatuba' },
    { value: 'Anapurus', text: 'Anapurus' },
    { value: 'Apicum-Açu', text: 'Apicum-Açu' },
    { value: 'Araguanã', text: 'Araguanã' },
    { value: 'Araioses', text: 'Araioses' },
    { value: 'Arame', text: 'Arame' },
    { value: 'Arari', text: 'Arari' },
    { value: 'Axixá', text: 'Axixá' },
    { value: 'Bacabal', text: 'Bacabal' },
    { value: 'Bacabeira', text: 'Bacabeira' },
    { value: 'Bacuri', text: 'Bacuri' },
    { value: 'Bacurituba', text: 'Bacurituba' },
    { value: 'Balsas', text: 'Balsas' },
    { value: 'Barão de Grajaú', text: 'Barão de Grajaú' },
    { value: 'Barra do Corda', text: 'Barra do Corda' },
    { value: 'Barreirinhas', text: 'Barreirinhas' },
    { value: 'Bela Vista do Maranhão', text: 'Bela Vista do Maranhão' },
    { value: 'Belágua', text: 'Belágua' },
    { value: 'Benedito Leite', text: 'Benedito Leite' },
    { value: 'Bequimão', text: 'Bequimão' },
    { value: 'Bernardo do Mearim', text: 'Bernardo do Mearim' },
    { value: 'Boa Vista do Gurupi', text: 'Boa Vista do Gurupi' },
    { value: 'Bom Jardim', text: 'Bom Jardim' },
    { value: 'Bom Jesus das Selvas', text: 'Bom Jesus das Selvas' },
    { value: 'Bom Lugar', text: 'Bom Lugar' },
    { value: 'Brejo', text: 'Brejo' },
    { value: 'Brejo de Areia', text: 'Brejo de Areia' },
    { value: 'Buriti', text: 'Buriti' },
    { value: 'Buriti Bravo', text: 'Buriti Bravo' },
    { value: 'Buriticupu', text: 'Buriticupu' },
    { value: 'Buritirana', text: 'Buritirana' },
    { value: 'Cachoeira Grande', text: 'Cachoeira Grande' },
    { value: 'Cajapió', text: 'Cajapió' },
    { value: 'Cajari', text: 'Cajari' },
    { value: 'Campestre do Maranhão', text: 'Campestre do Maranhão' },
    { value: 'Cândido Mendes', text: 'Cândido Mendes' },
    { value: 'Cantanhede', text: 'Cantanhede' },
    { value: 'Capinzal do Norte', text: 'Capinzal do Norte' },
    { value: 'Carolina', text: 'Carolina' },
    { value: 'Carutapera', text: 'Carutapera' },
    { value: 'Caxias', text: 'Caxias' },
    { value: 'Cedral', text: 'Cedral' },
    { value: 'Central do Maranhão', text: 'Central do Maranhão' },
    { value: 'Centro do Guilherme', text: 'Centro do Guilherme' },
    { value: 'Centro Novo do Maranhão', text: 'Centro Novo do Maranhão' },
    { value: 'Chapadinha', text: 'Chapadinha' },
    { value: 'Cidelândia', text: 'Cidelândia' },
    { value: 'Codó', text: 'Codó' },
    { value: 'Coelho Neto', text: 'Coelho Neto' },
    { value: 'Colinas', text: 'Colinas' },
    { value: 'Conceição do Lago-Açu', text: 'Conceição do Lago-Açu' },
    { value: 'Coroatá', text: 'Coroatá' },
    { value: 'Cururupu', text: 'Cururupu' },
    { value: 'Davinópolis', text: 'Davinópolis' },
    { value: 'Dom Pedro', text: 'Dom Pedro' },
    { value: 'Duque Bacelar', text: 'Duque Bacelar' },
    { value: 'Esperantinópolis', text: 'Esperantinópolis' },
    { value: 'Estreito', text: 'Estreito' },
    { value: 'Feira Nova do Maranhão', text: 'Feira Nova do Maranhão' },
    { value: 'Fernando Falcão', text: 'Fernando Falcão' },
    { value: 'Formosa da Serra Negra', text: 'Formosa da Serra Negra' },
    { value: 'Fortaleza dos Nogueiras', text: 'Fortaleza dos Nogueiras' },
    { value: 'Fortuna', text: 'Fortuna' },
    { value: 'Godofredo Viana', text: 'Godofredo Viana' },
    { value: 'Gonçalves Dias', text: 'Gonçalves Dias' },
    { value: 'Governador Archer', text: 'Governador Archer' },
    { value: 'Governador Edison Lobão', text: 'Governador Edison Lobão' },
    { value: 'Governador Eugênio Barros', text: 'Governador Eugênio Barros' },
    { value: 'Governador Luiz Rocha', text: 'Governador Luiz Rocha' },
    { value: 'Governador Newton Bello', text: 'Governador Newton Bello' },
    { value: 'Governador Nunes Freire', text: 'Governador Nunes Freire' },
    { value: 'Graça Aranha', text: 'Graça Aranha' },
    { value: 'Grajaú', text: 'Grajaú' },
    { value: 'Guimarães', text: 'Guimarães' },
    { value: 'Humberto de Campos', text: 'Humberto de Campos' },
    { value: 'Icatu', text: 'Icatu' },
    { value: 'Igarapé do Meio', text: 'Igarapé do Meio' },
    { value: 'Igarapé Grande', text: 'Igarapé Grande' },
    { value: 'Imperatriz', text: 'Imperatriz' },
    { value: 'Itaipava do Grajaú', text: 'Itaipava do Grajaú' },
    { value: 'Itapecuru Mirim', text: 'Itapecuru Mirim' },
    { value: 'Itinga do Maranhão', text: 'Itinga do Maranhão' },
    { value: 'Jatobá', text: 'Jatobá' },
    { value: 'Jenipapo dos Vieiras', text: 'Jenipapo dos Vieiras' },
    { value: 'João Lisboa', text: 'João Lisboa' },
    { value: 'Joselândia', text: 'Joselândia' },
    { value: 'Junco do Maranhão', text: 'Junco do Maranhão' },
    { value: 'Lago da Pedra', text: 'Lago da Pedra' },
    { value: 'Lago do Junco', text: 'Lago do Junco' },
    { value: 'Lago dos Rodrigues', text: 'Lago dos Rodrigues' },
    { value: 'Lago Verde', text: 'Lago Verde' },
    { value: 'Lagoa do Mato', text: 'Lagoa do Mato' },
    { value: 'Lagoa Grande do Maranhão', text: 'Lagoa Grande do Maranhão' },
    { value: 'Lajeado Novo', text: 'Lajeado Novo' },
    { value: 'Lima Campos', text: 'Lima Campos' },
    { value: 'Loreto', text: 'Loreto' },
    { value: 'Luís Domingues', text: 'Luís Domingues' },
    { value: 'Magalhães de Almeida', text: 'Magalhães de Almeida' },
    { value: 'Maracaçumé', text: 'Maracaçumé' },
    { value: 'Marajá do Sena', text: 'Marajá do Sena' },
    { value: 'Maranhãozinho', text: 'Maranhãozinho' },
    { value: 'Mata Roma', text: 'Mata Roma' },
    { value: 'Matinha', text: 'Matinha' },
    { value: 'Matões', text: 'Matões' },
    { value: 'Matões do Norte', text: 'Matões do Norte' },
    { value: 'Milagres do Maranhão', text: 'Milagres do Maranhão' },
    { value: 'Mirador', text: 'Mirador' },
    { value: 'Miranda do Norte', text: 'Miranda do Norte' },
    { value: 'Mirinzal', text: 'Mirinzal' },
    { value: 'Monção', text: 'Monção' },
    { value: 'Montes Altos', text: 'Montes Altos' },
    { value: 'Morros', text: 'Morros' },
    { value: 'Nina Rodrigues', text: 'Nina Rodrigues' },
    { value: 'Nova Colinas', text: 'Nova Colinas' },
    { value: 'Nova Iorque', text: 'Nova Iorque' },
    { value: 'Nova Olinda do Maranhão', text: 'Nova Olinda do Maranhão' },
    { value: "Olho d'Água das Cunhãs", text: "Olho d'Água das Cunhãs" },
    { value: 'Olinda Nova do Maranhão', text: 'Olinda Nova do Maranhão' },
    { value: 'Paço do Lumiar', text: 'Paço do Lumiar' },
    { value: 'Palmeirândia', text: 'Palmeirândia' },
    { value: 'Paraibano', text: 'Paraibano' },
    { value: 'Parnarama', text: 'Parnarama' },
    { value: 'Passagem Franca', text: 'Passagem Franca' },
    { value: 'Pastos Bons', text: 'Pastos Bons' },
    { value: 'Paulino Neves', text: 'Paulino Neves' },
    { value: 'Paulo Ramos', text: 'Paulo Ramos' },
    { value: 'Pedreiras', text: 'Pedreiras' },
    { value: 'Pedro do Rosário', text: 'Pedro do Rosário' },
    { value: 'Penalva', text: 'Penalva' },
    { value: 'Peri Mirim', text: 'Peri Mirim' },
    { value: 'Peritoró', text: 'Peritoró' },
    { value: 'Pindaré Mirim', text: 'Pindaré Mirim' },
    { value: 'Pinheiro', text: 'Pinheiro' },
    { value: 'Pio XII', text: 'Pio XII' },
    { value: 'Pirapemas', text: 'Pirapemas' },
    { value: 'Poção de Pedras', text: 'Poção de Pedras' },
    { value: 'Porto Franco', text: 'Porto Franco' },
    { value: 'Porto Rico do Maranhão', text: 'Porto Rico do Maranhão' },
    { value: 'Presidente Dutra', text: 'Presidente Dutra' },
    { value: 'Presidente Juscelino', text: 'Presidente Juscelino' },
    { value: 'Presidente Médici', text: 'Presidente Médici' },
    { value: 'Presidente Sarney', text: 'Presidente Sarney' },
    { value: 'Presidente Vargas', text: 'Presidente Vargas' },
    { value: 'Primeira Cruz', text: 'Primeira Cruz' },
    { value: 'Raposa', text: 'Raposa' },
    { value: 'Riachão', text: 'Riachão' },
    { value: 'Ribamar Fiquene', text: 'Ribamar Fiquene' },
    { value: 'Rosário', text: 'Rosário' },
    { value: 'Sambaíba', text: 'Sambaíba' },
    { value: 'Santa Filomena do Maranhão', text: 'Santa Filomena do Maranhão' },
    { value: 'Santa Helena', text: 'Santa Helena' },
    { value: 'Santa Inês', text: 'Santa Inês' },
    { value: 'Santa Luzia', text: 'Santa Luzia' },
    { value: 'Santa Luzia do Paruá', text: 'Santa Luzia do Paruá' },
    { value: 'Santa Quitéria do Maranhão', text: 'Santa Quitéria do Maranhão' },
    { value: 'Santa Rita', text: 'Santa Rita' },
    { value: 'Santana do Maranhão', text: 'Santana do Maranhão' },
    { value: 'Santo Amaro do Maranhão', text: 'Santo Amaro do Maranhão' },
    { value: 'Santo Antônio dos Lopes', text: 'Santo Antônio dos Lopes' },
    { value: 'São Benedito do Rio Preto', text: 'São Benedito do Rio Preto' },
    { value: 'São Bento', text: 'São Bento' },
    { value: 'São Bernardo', text: 'São Bernardo' },
    { value: 'São Domingos do Azeitão', text: 'São Domingos do Azeitão' },
    { value: 'São Domingos do Maranhão', text: 'São Domingos do Maranhão' },
    { value: 'São Félix de Balsas', text: 'São Félix de Balsas' },
    { value: 'São Francisco do Brejão', text: 'São Francisco do Brejão' },
    { value: 'São Francisco do Maranhão', text: 'São Francisco do Maranhão' },
    { value: 'São João Batista', text: 'São João Batista' },
    { value: 'São João do Carú', text: 'São João do Carú' },
    { value: 'São João do Paraíso', text: 'São João do Paraíso' },
    { value: 'São João do Soter', text: 'São João do Soter' },
    { value: 'São João dos Patos', text: 'São João dos Patos' },
    { value: 'São José de Ribamar', text: 'São José de Ribamar' },
    { value: 'São José dos Basílios', text: 'São José dos Basílios' },
    { value: 'São Luís', text: 'São Luís' },
    {
      value: 'São Luís Gonzaga do Maranhão',
      text: 'São Luís Gonzaga do Maranhão',
    },
    { value: 'São Mateus do Maranhão', text: 'São Mateus do Maranhão' },
    { value: 'São Pedro da Água Branca', text: 'São Pedro da Água Branca' },
    { value: 'São Pedro dos Crentes', text: 'São Pedro dos Crentes' },
    {
      value: 'São Raimundo das Mangabeiras',
      text: 'São Raimundo das Mangabeiras',
    },
    {
      value: 'São Raimundo do Doca Bezerra',
      text: 'São Raimundo do Doca Bezerra',
    },
    { value: 'São Roberto', text: 'São Roberto' },
    { value: 'São Vicente Ferrer', text: 'São Vicente Ferrer' },
    { value: 'Satubinha', text: 'Satubinha' },
    { value: 'Senador Alexandre Costa', text: 'Senador Alexandre Costa' },
    { value: 'Senador La Rocque', text: 'Senador La Rocque' },
    { value: 'Serrano do Maranhão', text: 'Serrano do Maranhão' },
    { value: 'Sítio Novo', text: 'Sítio Novo' },
    { value: 'Sucupira do Norte', text: 'Sucupira do Norte' },
    { value: 'Sucupira do Riachão', text: 'Sucupira do Riachão' },
    { value: 'Tasso Fragoso', text: 'Tasso Fragoso' },
    { value: 'Timbiras', text: 'Timbiras' },
    { value: 'Timon', text: 'Timon' },
    { value: 'Trizidela do Vale', text: 'Trizidela do Vale' },
    { value: 'Tufilândia', text: 'Tufilândia' },
    { value: 'Tuntum', text: 'Tuntum' },
    { value: 'Turiaçu', text: 'Turiaçu' },
    { value: 'Turilândia', text: 'Turilândia' },
    { value: 'Tutóia', text: 'Tutóia' },
    { value: 'Urbano Santos', text: 'Urbano Santos' },
    { value: 'Vargem Grande', text: 'Vargem Grande' },
    { value: 'Viana', text: 'Viana' },
    { value: 'Vila Nova dos Martírios', text: 'Vila Nova dos Martírios' },
    { value: 'Vitória do Mearim', text: 'Vitória do Mearim' },
    { value: 'Vitorino Freire', text: 'Vitorino Freire' },
    { value: 'Zé Doca', text: 'Zé Doca' },
  ],
  MG: [
    { value: 'Abadia dos Dourados', text: 'Abadia dos Dourados' },
    { value: 'Abaeté', text: 'Abaeté' },
    { value: 'Abre Campo', text: 'Abre Campo' },
    { value: 'Acaiaca', text: 'Acaiaca' },
    { value: 'Açucena', text: 'Açucena' },
    { value: 'Água Boa', text: 'Água Boa' },
    { value: 'Água Comprida', text: 'Água Comprida' },
    { value: 'Aguanil', text: 'Aguanil' },
    { value: 'Águas Formosas', text: 'Águas Formosas' },
    { value: 'Águas Vermelhas', text: 'Águas Vermelhas' },
    { value: 'Aimorés', text: 'Aimorés' },
    { value: 'Aiuruoca', text: 'Aiuruoca' },
    { value: 'Alagoa', text: 'Alagoa' },
    { value: 'Albertina', text: 'Albertina' },
    { value: 'Além Paraíba', text: 'Além Paraíba' },
    { value: 'Alfenas', text: 'Alfenas' },
    { value: 'Alfredo Vasconcelos', text: 'Alfredo Vasconcelos' },
    { value: 'Almenara', text: 'Almenara' },
    { value: 'Alpercata', text: 'Alpercata' },
    { value: 'Alpinópolis', text: 'Alpinópolis' },
    { value: 'Alterosa', text: 'Alterosa' },
    { value: 'Alto Caparaó', text: 'Alto Caparaó' },
    { value: 'Alto Jequitibá', text: 'Alto Jequitibá' },
    { value: 'Alto Rio Doce', text: 'Alto Rio Doce' },
    { value: 'Alvarenga', text: 'Alvarenga' },
    { value: 'Alvinópolis', text: 'Alvinópolis' },
    { value: 'Alvorada de Minas', text: 'Alvorada de Minas' },
    { value: 'Amparo do Serra', text: 'Amparo do Serra' },
    { value: 'Andradas', text: 'Andradas' },
    { value: 'Andrelândia', text: 'Andrelândia' },
    { value: 'Angelândia', text: 'Angelândia' },
    { value: 'Antônio Carlos', text: 'Antônio Carlos' },
    { value: 'Antônio Dias', text: 'Antônio Dias' },
    { value: 'Antônio Prado de Minas', text: 'Antônio Prado de Minas' },
    { value: 'Araçaí', text: 'Araçaí' },
    { value: 'Aracitaba', text: 'Aracitaba' },
    { value: 'Araçuaí', text: 'Araçuaí' },
    { value: 'Araguari', text: 'Araguari' },
    { value: 'Arantina', text: 'Arantina' },
    { value: 'Araponga', text: 'Araponga' },
    { value: 'Araporã', text: 'Araporã' },
    { value: 'Arapuá', text: 'Arapuá' },
    { value: 'Araújos', text: 'Araújos' },
    { value: 'Araxá', text: 'Araxá' },
    { value: 'Arceburgo', text: 'Arceburgo' },
    { value: 'Arcos', text: 'Arcos' },
    { value: 'Areado', text: 'Areado' },
    { value: 'Argirita', text: 'Argirita' },
    { value: 'Aricanduva', text: 'Aricanduva' },
    { value: 'Arinos', text: 'Arinos' },
    { value: 'Astolfo Dutra', text: 'Astolfo Dutra' },
    { value: 'Ataléia', text: 'Ataléia' },
    { value: 'Augusto de Lima', text: 'Augusto de Lima' },
    { value: 'Baependi', text: 'Baependi' },
    { value: 'Baldim', text: 'Baldim' },
    { value: 'Bambuí', text: 'Bambuí' },
    { value: 'Bandeira', text: 'Bandeira' },
    { value: 'Bandeira do Sul', text: 'Bandeira do Sul' },
    { value: 'Barão de Cocais', text: 'Barão de Cocais' },
    { value: 'Barão de Monte Alto', text: 'Barão de Monte Alto' },
    { value: 'Barbacena', text: 'Barbacena' },
    { value: 'Barra Longa', text: 'Barra Longa' },
    { value: 'Barroso', text: 'Barroso' },
    { value: 'Bela Vista de Minas', text: 'Bela Vista de Minas' },
    { value: 'Belmiro Braga', text: 'Belmiro Braga' },
    { value: 'Belo Horizonte', text: 'Belo Horizonte' },
    { value: 'Belo Oriente', text: 'Belo Oriente' },
    { value: 'Belo Vale', text: 'Belo Vale' },
    { value: 'Berilo', text: 'Berilo' },
    { value: 'Berizal', text: 'Berizal' },
    { value: 'Bertópolis', text: 'Bertópolis' },
    { value: 'Betim', text: 'Betim' },
    { value: 'Bias Fortes', text: 'Bias Fortes' },
    { value: 'Bicas', text: 'Bicas' },
    { value: 'Biquinhas', text: 'Biquinhas' },
    { value: 'Boa Esperança', text: 'Boa Esperança' },
    { value: 'Bocaina de Minas', text: 'Bocaina de Minas' },
    { value: 'Bocaiúva', text: 'Bocaiúva' },
    { value: 'Bom Despacho', text: 'Bom Despacho' },
    { value: 'Bom Jardim de Minas', text: 'Bom Jardim de Minas' },
    { value: 'Bom Jesus da Penha', text: 'Bom Jesus da Penha' },
    { value: 'Bom Jesus do Amparo', text: 'Bom Jesus do Amparo' },
    { value: 'Bom Jesus do Galho', text: 'Bom Jesus do Galho' },
    { value: 'Bom Repouso', text: 'Bom Repouso' },
    { value: 'Bom Sucesso', text: 'Bom Sucesso' },
    { value: 'Bonfim', text: 'Bonfim' },
    { value: 'Bonfinópolis de Minas', text: 'Bonfinópolis de Minas' },
    { value: 'Bonito de Minas', text: 'Bonito de Minas' },
    { value: 'Borda da Mata', text: 'Borda da Mata' },
    { value: 'Botelhos', text: 'Botelhos' },
    { value: 'Botumirim', text: 'Botumirim' },
    { value: 'Brás Pires', text: 'Brás Pires' },
    { value: 'Brasilândia de Minas', text: 'Brasilândia de Minas' },
    { value: 'Brasília de Minas', text: 'Brasília de Minas' },
    { value: 'Brasópolis', text: 'Brasópolis' },
    { value: 'Braúnas', text: 'Braúnas' },
    { value: 'Brumadinho', text: 'Brumadinho' },
    { value: 'Bueno Brandão', text: 'Bueno Brandão' },
    { value: 'Buenópolis', text: 'Buenópolis' },
    { value: 'Bugre', text: 'Bugre' },
    { value: 'Buritis', text: 'Buritis' },
    { value: 'Buritizeiro', text: 'Buritizeiro' },
    { value: 'Cabeceira Grande', text: 'Cabeceira Grande' },
    { value: 'Cabo Verde', text: 'Cabo Verde' },
    { value: 'Cachoeira da Prata', text: 'Cachoeira da Prata' },
    { value: 'Cachoeira de Minas', text: 'Cachoeira de Minas' },
    { value: 'Cachoeira de Pajeú', text: 'Cachoeira de Pajeú' },
    { value: 'Cachoeira Dourada', text: 'Cachoeira Dourada' },
    { value: 'Caetanópolis', text: 'Caetanópolis' },
    { value: 'Caeté', text: 'Caeté' },
    { value: 'Caiana', text: 'Caiana' },
    { value: 'Cajuri', text: 'Cajuri' },
    { value: 'Caldas', text: 'Caldas' },
    { value: 'Camacho', text: 'Camacho' },
    { value: 'Camanducaia', text: 'Camanducaia' },
    { value: 'Cambuí', text: 'Cambuí' },
    { value: 'Cambuquira', text: 'Cambuquira' },
    { value: 'Campanário', text: 'Campanário' },
    { value: 'Campanha', text: 'Campanha' },
    { value: 'Campestre', text: 'Campestre' },
    { value: 'Campina Verde', text: 'Campina Verde' },
    { value: 'Campo Azul', text: 'Campo Azul' },
    { value: 'Campo Belo', text: 'Campo Belo' },
    { value: 'Campo do Meio', text: 'Campo do Meio' },
    { value: 'Campo Florido', text: 'Campo Florido' },
    { value: 'Campos Altos', text: 'Campos Altos' },
    { value: 'Campos Gerais', text: 'Campos Gerais' },
    { value: 'Cana Verde', text: 'Cana Verde' },
    { value: 'Canaã', text: 'Canaã' },
    { value: 'Canápolis', text: 'Canápolis' },
    { value: 'Candeias', text: 'Candeias' },
    { value: 'Cantagalo', text: 'Cantagalo' },
    { value: 'Caparaó', text: 'Caparaó' },
    { value: 'Capela Nova', text: 'Capela Nova' },
    { value: 'Capelinha', text: 'Capelinha' },
    { value: 'Capetinga', text: 'Capetinga' },
    { value: 'Capim Branco', text: 'Capim Branco' },
    { value: 'Capinópolis', text: 'Capinópolis' },
    { value: 'Capitão Andrade', text: 'Capitão Andrade' },
    { value: 'Capitão Enéas', text: 'Capitão Enéas' },
    { value: 'Capitólio', text: 'Capitólio' },
    { value: 'Caputira', text: 'Caputira' },
    { value: 'Caraí', text: 'Caraí' },
    { value: 'Caranaíba', text: 'Caranaíba' },
    { value: 'Carandaí', text: 'Carandaí' },
    { value: 'Carangola', text: 'Carangola' },
    { value: 'Caratinga', text: 'Caratinga' },
    { value: 'Carbonita', text: 'Carbonita' },
    { value: 'Careaçu', text: 'Careaçu' },
    { value: 'Carlos Chagas', text: 'Carlos Chagas' },
    { value: 'Carmésia', text: 'Carmésia' },
    { value: 'Carmo da Cachoeira', text: 'Carmo da Cachoeira' },
    { value: 'Carmo da Mata', text: 'Carmo da Mata' },
    { value: 'Carmo de Minas', text: 'Carmo de Minas' },
    { value: 'Carmo do Cajuru', text: 'Carmo do Cajuru' },
    { value: 'Carmo do Paranaíba', text: 'Carmo do Paranaíba' },
    { value: 'Carmo do Rio Claro', text: 'Carmo do Rio Claro' },
    { value: 'Carmópolis de Minas', text: 'Carmópolis de Minas' },
    { value: 'Carneirinho', text: 'Carneirinho' },
    { value: 'Carrancas', text: 'Carrancas' },
    { value: 'Carvalhópolis', text: 'Carvalhópolis' },
    { value: 'Carvalhos', text: 'Carvalhos' },
    { value: 'Casa Grande', text: 'Casa Grande' },
    { value: 'Cascalho Rico', text: 'Cascalho Rico' },
    { value: 'Cássia', text: 'Cássia' },
    { value: 'Cataguases', text: 'Cataguases' },
    { value: 'Catas Altas', text: 'Catas Altas' },
    { value: 'Catas Altas da Noruega', text: 'Catas Altas da Noruega' },
    { value: 'Catuji', text: 'Catuji' },
    { value: 'Catuti', text: 'Catuti' },
    { value: 'Caxambu', text: 'Caxambu' },
    { value: 'Cedro do Abaeté', text: 'Cedro do Abaeté' },
    { value: 'Central de Minas', text: 'Central de Minas' },
    { value: 'Centralina', text: 'Centralina' },
    { value: 'Chácara', text: 'Chácara' },
    { value: 'Chalé', text: 'Chalé' },
    { value: 'Chapada do Norte', text: 'Chapada do Norte' },
    { value: 'Chapada Gaúcha', text: 'Chapada Gaúcha' },
    { value: 'Chiador', text: 'Chiador' },
    { value: 'Cipotânea', text: 'Cipotânea' },
    { value: 'Claraval', text: 'Claraval' },
    { value: 'Claro dos Poções', text: 'Claro dos Poções' },
    { value: 'Cláudio', text: 'Cláudio' },
    { value: 'Coimbra', text: 'Coimbra' },
    { value: 'Coluna', text: 'Coluna' },
    { value: 'Comendador Gomes', text: 'Comendador Gomes' },
    { value: 'Comercinho', text: 'Comercinho' },
    { value: 'Conceição da Aparecida', text: 'Conceição da Aparecida' },
    {
      value: 'Conceição da Barra de Minas',
      text: 'Conceição da Barra de Minas',
    },
    { value: 'Conceição das Alagoas', text: 'Conceição das Alagoas' },
    { value: 'Conceição das Pedras', text: 'Conceição das Pedras' },
    { value: 'Conceição de Ipanema', text: 'Conceição de Ipanema' },
    { value: 'Conceição do Mato Dentro', text: 'Conceição do Mato Dentro' },
    { value: 'Conceição do Pará', text: 'Conceição do Pará' },
    { value: 'Conceição do Rio Verde', text: 'Conceição do Rio Verde' },
    { value: 'Conceição dos Ouros', text: 'Conceição dos Ouros' },
    { value: 'Cônego Marinho', text: 'Cônego Marinho' },
    { value: 'Confins', text: 'Confins' },
    { value: 'Congonhal', text: 'Congonhal' },
    { value: 'Congonhas', text: 'Congonhas' },
    { value: 'Congonhas do Norte', text: 'Congonhas do Norte' },
    { value: 'Conquista', text: 'Conquista' },
    { value: 'Conselheiro Lafaiete', text: 'Conselheiro Lafaiete' },
    { value: 'Conselheiro Pena', text: 'Conselheiro Pena' },
    { value: 'Consolação', text: 'Consolação' },
    { value: 'Contagem', text: 'Contagem' },
    { value: 'Coqueiral', text: 'Coqueiral' },
    { value: 'Coração de Jesus', text: 'Coração de Jesus' },
    { value: 'Cordisburgo', text: 'Cordisburgo' },
    { value: 'Cordislândia', text: 'Cordislândia' },
    { value: 'Corinto', text: 'Corinto' },
    { value: 'Coroaci', text: 'Coroaci' },
    { value: 'Coromandel', text: 'Coromandel' },
    { value: 'Coronel Fabriciano', text: 'Coronel Fabriciano' },
    { value: 'Coronel Murta', text: 'Coronel Murta' },
    { value: 'Coronel Pacheco', text: 'Coronel Pacheco' },
    { value: 'Coronel Xavier Chaves', text: 'Coronel Xavier Chaves' },
    { value: 'Córrego Danta', text: 'Córrego Danta' },
    { value: 'Córrego do Bom Jesus', text: 'Córrego do Bom Jesus' },
    { value: 'Córrego Fundo', text: 'Córrego Fundo' },
    { value: 'Córrego Novo', text: 'Córrego Novo' },
    {
      value: 'Couto de Magalhães de Minas',
      text: 'Couto de Magalhães de Minas',
    },
    { value: 'Crisólita', text: 'Crisólita' },
    { value: 'Cristais', text: 'Cristais' },
    { value: 'Cristália', text: 'Cristália' },
    { value: 'Cristiano Otoni', text: 'Cristiano Otoni' },
    { value: 'Cristina', text: 'Cristina' },
    { value: 'Crucilândia', text: 'Crucilândia' },
    { value: 'Cruzeiro da Fortaleza', text: 'Cruzeiro da Fortaleza' },
    { value: 'Cruzília', text: 'Cruzília' },
    { value: 'Cuparaque', text: 'Cuparaque' },
    { value: 'Curral de Dentro', text: 'Curral de Dentro' },
    { value: 'Curvelo', text: 'Curvelo' },
    { value: 'Datas', text: 'Datas' },
    { value: 'Delfim Moreira', text: 'Delfim Moreira' },
    { value: 'Delfinópolis', text: 'Delfinópolis' },
    { value: 'Delta', text: 'Delta' },
    { value: 'Descoberto', text: 'Descoberto' },
    { value: 'Desterro de Entre Rios', text: 'Desterro de Entre Rios' },
    { value: 'Desterro do Melo', text: 'Desterro do Melo' },
    { value: 'Diamantina', text: 'Diamantina' },
    { value: 'Diogo de Vasconcelos', text: 'Diogo de Vasconcelos' },
    { value: 'Dionísio', text: 'Dionísio' },
    { value: 'Divinésia', text: 'Divinésia' },
    { value: 'Divino', text: 'Divino' },
    { value: 'Divino das Laranjeiras', text: 'Divino das Laranjeiras' },
    { value: 'Divinolândia de Minas', text: 'Divinolândia de Minas' },
    { value: 'Divinópolis', text: 'Divinópolis' },
    { value: 'Divisa Alegre', text: 'Divisa Alegre' },
    { value: 'Divisa Nova', text: 'Divisa Nova' },
    { value: 'Divisópolis', text: 'Divisópolis' },
    { value: 'Dom Bosco', text: 'Dom Bosco' },
    { value: 'Dom Cavati', text: 'Dom Cavati' },
    { value: 'Dom Joaquim', text: 'Dom Joaquim' },
    { value: 'Dom Silvério', text: 'Dom Silvério' },
    { value: 'Dom Viçoso', text: 'Dom Viçoso' },
    { value: 'Dona Euzébia', text: 'Dona Euzébia' },
    { value: 'Dores de Campos', text: 'Dores de Campos' },
    { value: 'Dores de Guanhães', text: 'Dores de Guanhães' },
    { value: 'Dores do Indaiá', text: 'Dores do Indaiá' },
    { value: 'Dores do Turvo', text: 'Dores do Turvo' },
    { value: 'Doresópolis', text: 'Doresópolis' },
    { value: 'Douradoquara', text: 'Douradoquara' },
    { value: 'Durandé', text: 'Durandé' },
    { value: 'Elói Mendes', text: 'Elói Mendes' },
    { value: 'Engenheiro Caldas', text: 'Engenheiro Caldas' },
    { value: 'Engenheiro Navarro', text: 'Engenheiro Navarro' },
    { value: 'Entre Folhas', text: 'Entre Folhas' },
    { value: 'Entre Rios de Minas', text: 'Entre Rios de Minas' },
    { value: 'Ervália', text: 'Ervália' },
    { value: 'Esmeraldas', text: 'Esmeraldas' },
    { value: 'Espera Feliz', text: 'Espera Feliz' },
    { value: 'Espinosa', text: 'Espinosa' },
    { value: 'Espírito Santo do Dourado', text: 'Espírito Santo do Dourado' },
    { value: 'Estiva', text: 'Estiva' },
    { value: 'Estrela Dalva', text: 'Estrela Dalva' },
    { value: 'Estrela do Indaiá', text: 'Estrela do Indaiá' },
    { value: 'Estrela do Sul', text: 'Estrela do Sul' },
    { value: 'Eugenópolis', text: 'Eugenópolis' },
    { value: 'Ewbank da Câmara', text: 'Ewbank da Câmara' },
    { value: 'Extrema', text: 'Extrema' },
    { value: 'Fama', text: 'Fama' },
    { value: 'Faria Lemos', text: 'Faria Lemos' },
    { value: 'Felício dos Santos', text: 'Felício dos Santos' },
    { value: 'Felisburgo', text: 'Felisburgo' },
    { value: 'Felixlândia', text: 'Felixlândia' },
    { value: 'Fernandes Tourinho', text: 'Fernandes Tourinho' },
    { value: 'Ferros', text: 'Ferros' },
    { value: 'Fervedouro', text: 'Fervedouro' },
    { value: 'Florestal', text: 'Florestal' },
    { value: 'Formiga', text: 'Formiga' },
    { value: 'Formoso', text: 'Formoso' },
    { value: 'Fortaleza de Minas', text: 'Fortaleza de Minas' },
    { value: 'Fortuna de Minas', text: 'Fortuna de Minas' },
    { value: 'Francisco Badaró', text: 'Francisco Badaró' },
    { value: 'Francisco Dumont', text: 'Francisco Dumont' },
    { value: 'Francisco Sá', text: 'Francisco Sá' },
    { value: 'Franciscópolis', text: 'Franciscópolis' },
    { value: 'Frei Gaspar', text: 'Frei Gaspar' },
    { value: 'Frei Inocêncio', text: 'Frei Inocêncio' },
    { value: 'Frei Lagonegro', text: 'Frei Lagonegro' },
    { value: 'Fronteira', text: 'Fronteira' },
    { value: 'Fronteira dos Vales', text: 'Fronteira dos Vales' },
    { value: 'Fruta de Leite', text: 'Fruta de Leite' },
    { value: 'Frutal', text: 'Frutal' },
    { value: 'Funilândia', text: 'Funilândia' },
    { value: 'Galiléia', text: 'Galiléia' },
    { value: 'Gameleiras', text: 'Gameleiras' },
    { value: 'Glaucilândia', text: 'Glaucilândia' },
    { value: 'Goiabeira', text: 'Goiabeira' },
    { value: 'Goianá', text: 'Goianá' },
    { value: 'Gonçalves', text: 'Gonçalves' },
    { value: 'Gonzaga', text: 'Gonzaga' },
    { value: 'Gouveia', text: 'Gouveia' },
    { value: 'Governador Valadares', text: 'Governador Valadares' },
    { value: 'Grão Mogol', text: 'Grão Mogol' },
    { value: 'Grupiara', text: 'Grupiara' },
    { value: 'Guanhães', text: 'Guanhães' },
    { value: 'Guapé', text: 'Guapé' },
    { value: 'Guaraciaba', text: 'Guaraciaba' },
    { value: 'Guaraciama', text: 'Guaraciama' },
    { value: 'Guaranésia', text: 'Guaranésia' },
    { value: 'Guarani', text: 'Guarani' },
    { value: 'Guarará', text: 'Guarará' },
    { value: 'Guarda-Mor', text: 'Guarda-Mor' },
    { value: 'Guaxupé', text: 'Guaxupé' },
    { value: 'Guidoval', text: 'Guidoval' },
    { value: 'Guimarânia', text: 'Guimarânia' },
    { value: 'Guiricema', text: 'Guiricema' },
    { value: 'Gurinhatã', text: 'Gurinhatã' },
    { value: 'Heliodora', text: 'Heliodora' },
    { value: 'Iapu', text: 'Iapu' },
    { value: 'Ibertioga', text: 'Ibertioga' },
    { value: 'Ibiá', text: 'Ibiá' },
    { value: 'Ibiaí', text: 'Ibiaí' },
    { value: 'Ibiracatu', text: 'Ibiracatu' },
    { value: 'Ibiraci', text: 'Ibiraci' },
    { value: 'Ibirité', text: 'Ibirité' },
    { value: 'Ibitiúra de Minas', text: 'Ibitiúra de Minas' },
    { value: 'Ibituruna', text: 'Ibituruna' },
    { value: 'Icaraí de Minas', text: 'Icaraí de Minas' },
    { value: 'Igarapé', text: 'Igarapé' },
    { value: 'Igaratinga', text: 'Igaratinga' },
    { value: 'Iguatama', text: 'Iguatama' },
    { value: 'Ijaci', text: 'Ijaci' },
    { value: 'Ilicínea', text: 'Ilicínea' },
    { value: 'Imbé de Minas', text: 'Imbé de Minas' },
    { value: 'Inconfidentes', text: 'Inconfidentes' },
    { value: 'Indaiabira', text: 'Indaiabira' },
    { value: 'Indianópolis', text: 'Indianópolis' },
    { value: 'Ingaí', text: 'Ingaí' },
    { value: 'Inhapim', text: 'Inhapim' },
    { value: 'Inhaúma', text: 'Inhaúma' },
    { value: 'Inimutaba', text: 'Inimutaba' },
    { value: 'Ipaba', text: 'Ipaba' },
    { value: 'Ipanema', text: 'Ipanema' },
    { value: 'Ipatinga', text: 'Ipatinga' },
    { value: 'Ipiaçu', text: 'Ipiaçu' },
    { value: 'Ipuiúna', text: 'Ipuiúna' },
    { value: 'Iraí de Minas', text: 'Iraí de Minas' },
    { value: 'Itabira', text: 'Itabira' },
    { value: 'Itabirinha de Mantena', text: 'Itabirinha de Mantena' },
    { value: 'Itabirito', text: 'Itabirito' },
    { value: 'Itacambira', text: 'Itacambira' },
    { value: 'Itacarambi', text: 'Itacarambi' },
    { value: 'Itaguara', text: 'Itaguara' },
    { value: 'Itaipé', text: 'Itaipé' },
    { value: 'Itajubá', text: 'Itajubá' },
    { value: 'Itamarandiba', text: 'Itamarandiba' },
    { value: 'Itamarati de Minas', text: 'Itamarati de Minas' },
    { value: 'Itambacuri', text: 'Itambacuri' },
    { value: 'Itambé do Mato Dentro', text: 'Itambé do Mato Dentro' },
    { value: 'Itamogi', text: 'Itamogi' },
    { value: 'Itamonte', text: 'Itamonte' },
    { value: 'Itanhandu', text: 'Itanhandu' },
    { value: 'Itanhomi', text: 'Itanhomi' },
    { value: 'Itaobim', text: 'Itaobim' },
    { value: 'Itapagipe', text: 'Itapagipe' },
    { value: 'Itapecerica', text: 'Itapecerica' },
    { value: 'Itapeva', text: 'Itapeva' },
    { value: 'Itatiaiuçu', text: 'Itatiaiuçu' },
    { value: 'Itaú de Minas', text: 'Itaú de Minas' },
    { value: 'Itaúna', text: 'Itaúna' },
    { value: 'Itaverava', text: 'Itaverava' },
    { value: 'Itinga', text: 'Itinga' },
    { value: 'Itueta', text: 'Itueta' },
    { value: 'Ituiutaba', text: 'Ituiutaba' },
    { value: 'Itumirim', text: 'Itumirim' },
    { value: 'Iturama', text: 'Iturama' },
    { value: 'Itutinga', text: 'Itutinga' },
    { value: 'Jaboticatubas', text: 'Jaboticatubas' },
    { value: 'Jacinto', text: 'Jacinto' },
    { value: 'Jacuí', text: 'Jacuí' },
    { value: 'Jacutinga', text: 'Jacutinga' },
    { value: 'Jaguaraçu', text: 'Jaguaraçu' },
    { value: 'Jaíba', text: 'Jaíba' },
    { value: 'Jampruca', text: 'Jampruca' },
    { value: 'Janaúba', text: 'Janaúba' },
    { value: 'Januária', text: 'Januária' },
    { value: 'Japaraíba', text: 'Japaraíba' },
    { value: 'Japonvar', text: 'Japonvar' },
    { value: 'Jeceaba', text: 'Jeceaba' },
    { value: 'Jenipapo de Minas', text: 'Jenipapo de Minas' },
    { value: 'Jequeri', text: 'Jequeri' },
    { value: 'Jequitaí', text: 'Jequitaí' },
    { value: 'Jequitibá', text: 'Jequitibá' },
    { value: 'Jequitinhonha', text: 'Jequitinhonha' },
    { value: 'Jesuânia', text: 'Jesuânia' },
    { value: 'Joaíma', text: 'Joaíma' },
    { value: 'Joanésia', text: 'Joanésia' },
    { value: 'João Monlevade', text: 'João Monlevade' },
    { value: 'João Pinheiro', text: 'João Pinheiro' },
    { value: 'Joaquim Felício', text: 'Joaquim Felício' },
    { value: 'Jordânia', text: 'Jordânia' },
    { value: 'José Gonçalves de Minas', text: 'José Gonçalves de Minas' },
    { value: 'José Raydan', text: 'José Raydan' },
    { value: 'Josenópolis', text: 'Josenópolis' },
    { value: 'Juatuba', text: 'Juatuba' },
    { value: 'Juiz de Fora', text: 'Juiz de Fora' },
    { value: 'Juramento', text: 'Juramento' },
    { value: 'Juruaia', text: 'Juruaia' },
    { value: 'Juvenília', text: 'Juvenília' },
    { value: 'Ladainha', text: 'Ladainha' },
    { value: 'Lagamar', text: 'Lagamar' },
    { value: 'Lagoa da Prata', text: 'Lagoa da Prata' },
    { value: 'Lagoa dos Patos', text: 'Lagoa dos Patos' },
    { value: 'Lagoa Dourada', text: 'Lagoa Dourada' },
    { value: 'Lagoa Formosa', text: 'Lagoa Formosa' },
    { value: 'Lagoa Grande', text: 'Lagoa Grande' },
    { value: 'Lagoa Santa', text: 'Lagoa Santa' },
    { value: 'Lajinha', text: 'Lajinha' },
    { value: 'Lambari', text: 'Lambari' },
    { value: 'Lamim', text: 'Lamim' },
    { value: 'Laranjal', text: 'Laranjal' },
    { value: 'Lassance', text: 'Lassance' },
    { value: 'Lavras', text: 'Lavras' },
    { value: 'Leandro Ferreira', text: 'Leandro Ferreira' },
    { value: 'Leme do Prado', text: 'Leme do Prado' },
    { value: 'Leopoldina', text: 'Leopoldina' },
    { value: 'Liberdade', text: 'Liberdade' },
    { value: 'Lima Duarte', text: 'Lima Duarte' },
    { value: 'Limeira do Oeste', text: 'Limeira do Oeste' },
    { value: 'Lontra', text: 'Lontra' },
    { value: 'Luisburgo', text: 'Luisburgo' },
    { value: 'Luislândia', text: 'Luislândia' },
    { value: 'Luminárias', text: 'Luminárias' },
    { value: 'Luz', text: 'Luz' },
    { value: 'Machacalis', text: 'Machacalis' },
    { value: 'Machado', text: 'Machado' },
    { value: 'Madre de Deus de Minas', text: 'Madre de Deus de Minas' },
    { value: 'Malacacheta', text: 'Malacacheta' },
    { value: 'Mamonas', text: 'Mamonas' },
    { value: 'Manga', text: 'Manga' },
    { value: 'Manhuaçu', text: 'Manhuaçu' },
    { value: 'Manhumirim', text: 'Manhumirim' },
    { value: 'Mantena', text: 'Mantena' },
    { value: 'Mar de Espanha', text: 'Mar de Espanha' },
    { value: 'Maravilhas', text: 'Maravilhas' },
    { value: 'Maria da Fé', text: 'Maria da Fé' },
    { value: 'Mariana', text: 'Mariana' },
    { value: 'Marilac', text: 'Marilac' },
    { value: 'Mário Campos', text: 'Mário Campos' },
    { value: 'Maripá de Minas', text: 'Maripá de Minas' },
    { value: 'Marliéria', text: 'Marliéria' },
    { value: 'Marmelópolis', text: 'Marmelópolis' },
    { value: 'Martinho Campos', text: 'Martinho Campos' },
    { value: 'Martins Soares', text: 'Martins Soares' },
    { value: 'Mata Verde', text: 'Mata Verde' },
    { value: 'Materlândia', text: 'Materlândia' },
    { value: 'Mateus Leme', text: 'Mateus Leme' },
    { value: 'Mathias Lobato', text: 'Mathias Lobato' },
    { value: 'Matias Barbosa', text: 'Matias Barbosa' },
    { value: 'Matias Cardoso', text: 'Matias Cardoso' },
    { value: 'Matipó', text: 'Matipó' },
    { value: 'Mato Verde', text: 'Mato Verde' },
    { value: 'Matozinhos', text: 'Matozinhos' },
    { value: 'Matutina', text: 'Matutina' },
    { value: 'Medeiros', text: 'Medeiros' },
    { value: 'Medina', text: 'Medina' },
    { value: 'Mendes Pimentel', text: 'Mendes Pimentel' },
    { value: 'Mercês', text: 'Mercês' },
    { value: 'Mesquita', text: 'Mesquita' },
    { value: 'Minas Novas', text: 'Minas Novas' },
    { value: 'Minduri', text: 'Minduri' },
    { value: 'Mirabela', text: 'Mirabela' },
    { value: 'Miradouro', text: 'Miradouro' },
    { value: 'Miraí', text: 'Miraí' },
    { value: 'Miravânia', text: 'Miravânia' },
    { value: 'Moeda', text: 'Moeda' },
    { value: 'Moema', text: 'Moema' },
    { value: 'Monjolos', text: 'Monjolos' },
    { value: 'Monsenhor Paulo', text: 'Monsenhor Paulo' },
    { value: 'Montalvânia', text: 'Montalvânia' },
    { value: 'Monte Alegre de Minas', text: 'Monte Alegre de Minas' },
    { value: 'Monte Azul', text: 'Monte Azul' },
    { value: 'Monte Belo', text: 'Monte Belo' },
    { value: 'Monte Carmelo', text: 'Monte Carmelo' },
    { value: 'Monte Formoso', text: 'Monte Formoso' },
    { value: 'Monte Santo de Minas', text: 'Monte Santo de Minas' },
    { value: 'Monte Sião', text: 'Monte Sião' },
    { value: 'Montes Claros', text: 'Montes Claros' },
    { value: 'Montezuma', text: 'Montezuma' },
    { value: 'Morada Nova de Minas', text: 'Morada Nova de Minas' },
    { value: 'Morro da Garça', text: 'Morro da Garça' },
    { value: 'Morro do Pilar', text: 'Morro do Pilar' },
    { value: 'Munhoz', text: 'Munhoz' },
    { value: 'Muriaé', text: 'Muriaé' },
    { value: 'Mutum', text: 'Mutum' },
    { value: 'Muzambinho', text: 'Muzambinho' },
    { value: 'Nacip Raydan', text: 'Nacip Raydan' },
    { value: 'Nanuque', text: 'Nanuque' },
    { value: 'Naque', text: 'Naque' },
    { value: 'Natalândia', text: 'Natalândia' },
    { value: 'Natércia', text: 'Natércia' },
    { value: 'Nazareno', text: 'Nazareno' },
    { value: 'Nepomuceno', text: 'Nepomuceno' },
    { value: 'Ninheira', text: 'Ninheira' },
    { value: 'Nova Belém', text: 'Nova Belém' },
    { value: 'Nova Era', text: 'Nova Era' },
    { value: 'Nova Lima', text: 'Nova Lima' },
    { value: 'Nova Módica', text: 'Nova Módica' },
    { value: 'Nova Ponte', text: 'Nova Ponte' },
    { value: 'Nova Porteirinha', text: 'Nova Porteirinha' },
    { value: 'Nova Resende', text: 'Nova Resende' },
    { value: 'Nova Serrana', text: 'Nova Serrana' },
    { value: 'Nova União', text: 'Nova União' },
    { value: 'Novo Cruzeiro', text: 'Novo Cruzeiro' },
    { value: 'Novo Oriente de Minas', text: 'Novo Oriente de Minas' },
    { value: 'Novorizonte', text: 'Novorizonte' },
    { value: 'Olaria', text: 'Olaria' },
    { value: "Olhos-d'Água", text: "Olhos-d'Água" },
    { value: 'Olímpio Noronha', text: 'Olímpio Noronha' },
    { value: 'Oliveira', text: 'Oliveira' },
    { value: 'Oliveira Fortes', text: 'Oliveira Fortes' },
    { value: 'Onça de Pitangui', text: 'Onça de Pitangui' },
    { value: 'Oratórios', text: 'Oratórios' },
    { value: 'Orizânia', text: 'Orizânia' },
    { value: 'Ouro Branco', text: 'Ouro Branco' },
    { value: 'Ouro Fino', text: 'Ouro Fino' },
    { value: 'Ouro Preto', text: 'Ouro Preto' },
    { value: 'Ouro Verde de Minas', text: 'Ouro Verde de Minas' },
    { value: 'Padre Carvalho', text: 'Padre Carvalho' },
    { value: 'Padre Paraíso', text: 'Padre Paraíso' },
    { value: 'Pai Pedro', text: 'Pai Pedro' },
    { value: 'Paineiras', text: 'Paineiras' },
    { value: 'Pains', text: 'Pains' },
    { value: 'Paiva', text: 'Paiva' },
    { value: 'Palma', text: 'Palma' },
    { value: 'Palmópolis', text: 'Palmópolis' },
    { value: 'Papagaios', text: 'Papagaios' },
    { value: 'Pará de Minas', text: 'Pará de Minas' },
    { value: 'Paracatu', text: 'Paracatu' },
    { value: 'Paraguaçu', text: 'Paraguaçu' },
    { value: 'Paraisópolis', text: 'Paraisópolis' },
    { value: 'Paraopeba', text: 'Paraopeba' },
    { value: 'Passa Quatro', text: 'Passa Quatro' },
    { value: 'Passa Tempo', text: 'Passa Tempo' },
    { value: 'Passa-Vinte', text: 'Passa-Vinte' },
    { value: 'Passabém', text: 'Passabém' },
    { value: 'Passos', text: 'Passos' },
    { value: 'Patis', text: 'Patis' },
    { value: 'Patos de Minas', text: 'Patos de Minas' },
    { value: 'Patrocínio', text: 'Patrocínio' },
    { value: 'Patrocínio do Muriaé', text: 'Patrocínio do Muriaé' },
    { value: 'Paula Cândido', text: 'Paula Cândido' },
    { value: 'Paulistas', text: 'Paulistas' },
    { value: 'Pavão', text: 'Pavão' },
    { value: 'Peçanha', text: 'Peçanha' },
    { value: 'Pedra Azul', text: 'Pedra Azul' },
    { value: 'Pedra Bonita', text: 'Pedra Bonita' },
    { value: 'Pedra do Anta', text: 'Pedra do Anta' },
    { value: 'Pedra do Indaiá', text: 'Pedra do Indaiá' },
    { value: 'Pedra Dourada', text: 'Pedra Dourada' },
    { value: 'Pedralva', text: 'Pedralva' },
    { value: 'Pedras de Maria da Cruz', text: 'Pedras de Maria da Cruz' },
    { value: 'Pedrinópolis', text: 'Pedrinópolis' },
    { value: 'Pedro Leopoldo', text: 'Pedro Leopoldo' },
    { value: 'Pedro Teixeira', text: 'Pedro Teixeira' },
    { value: 'Pequeri', text: 'Pequeri' },
    { value: 'Pequi', text: 'Pequi' },
    { value: 'Perdigão', text: 'Perdigão' },
    { value: 'Perdizes', text: 'Perdizes' },
    { value: 'Perdões', text: 'Perdões' },
    { value: 'Periquito', text: 'Periquito' },
    { value: 'Pescador', text: 'Pescador' },
    { value: 'Piau', text: 'Piau' },
    { value: 'Piedade de Caratinga', text: 'Piedade de Caratinga' },
    { value: 'Piedade de Ponte Nova', text: 'Piedade de Ponte Nova' },
    { value: 'Piedade do Rio Grande', text: 'Piedade do Rio Grande' },
    { value: 'Piedade dos Gerais', text: 'Piedade dos Gerais' },
    { value: 'Pimenta', text: 'Pimenta' },
    { value: "Pingo-d'Água", text: "Pingo-d'Água" },
    { value: 'Pintópolis', text: 'Pintópolis' },
    { value: 'Piracema', text: 'Piracema' },
    { value: 'Pirajuba', text: 'Pirajuba' },
    { value: 'Piranga', text: 'Piranga' },
    { value: 'Piranguçu', text: 'Piranguçu' },
    { value: 'Piranguinho', text: 'Piranguinho' },
    { value: 'Pirapetinga', text: 'Pirapetinga' },
    { value: 'Pirapora', text: 'Pirapora' },
    { value: 'Piraúba', text: 'Piraúba' },
    { value: 'Pitangui', text: 'Pitangui' },
    { value: 'Piumhi', text: 'Piumhi' },
    { value: 'Planura', text: 'Planura' },
    { value: 'Poço Fundo', text: 'Poço Fundo' },
    { value: 'Poços de Caldas', text: 'Poços de Caldas' },
    { value: 'Pocrane', text: 'Pocrane' },
    { value: 'Pompéu', text: 'Pompéu' },
    { value: 'Ponte Nova', text: 'Ponte Nova' },
    { value: 'Ponto Chique', text: 'Ponto Chique' },
    { value: 'Ponto dos Volantes', text: 'Ponto dos Volantes' },
    { value: 'Porteirinha', text: 'Porteirinha' },
    { value: 'Porto Firme', text: 'Porto Firme' },
    { value: 'Poté', text: 'Poté' },
    { value: 'Pouso Alegre', text: 'Pouso Alegre' },
    { value: 'Pouso Alto', text: 'Pouso Alto' },
    { value: 'Prados', text: 'Prados' },
    { value: 'Prata', text: 'Prata' },
    { value: 'Pratápolis', text: 'Pratápolis' },
    { value: 'Pratinha', text: 'Pratinha' },
    { value: 'Presidente Bernardes', text: 'Presidente Bernardes' },
    { value: 'Presidente Juscelino', text: 'Presidente Juscelino' },
    { value: 'Presidente Kubitschek', text: 'Presidente Kubitschek' },
    { value: 'Presidente Olegário', text: 'Presidente Olegário' },
    { value: 'Prudente de Morais', text: 'Prudente de Morais' },
    { value: 'Quartel Geral', text: 'Quartel Geral' },
    { value: 'Queluzito', text: 'Queluzito' },
    { value: 'Raposos', text: 'Raposos' },
    { value: 'Raul Soares', text: 'Raul Soares' },
    { value: 'Recreio', text: 'Recreio' },
    { value: 'Reduto', text: 'Reduto' },
    { value: 'Resende Costa', text: 'Resende Costa' },
    { value: 'Resplendor', text: 'Resplendor' },
    { value: 'Ressaquinha', text: 'Ressaquinha' },
    { value: 'Riachinho', text: 'Riachinho' },
    { value: 'Riacho dos Machados', text: 'Riacho dos Machados' },
    { value: 'Ribeirão das Neves', text: 'Ribeirão das Neves' },
    { value: 'Ribeirão Vermelho', text: 'Ribeirão Vermelho' },
    { value: 'Rio Acima', text: 'Rio Acima' },
    { value: 'Rio Casca', text: 'Rio Casca' },
    { value: 'Rio do Prado', text: 'Rio do Prado' },
    { value: 'Rio Doce', text: 'Rio Doce' },
    { value: 'Rio Espera', text: 'Rio Espera' },
    { value: 'Rio Manso', text: 'Rio Manso' },
    { value: 'Rio Novo', text: 'Rio Novo' },
    { value: 'Rio Paranaíba', text: 'Rio Paranaíba' },
    { value: 'Rio Pardo de Minas', text: 'Rio Pardo de Minas' },
    { value: 'Rio Piracicaba', text: 'Rio Piracicaba' },
    { value: 'Rio Pomba', text: 'Rio Pomba' },
    { value: 'Rio Preto', text: 'Rio Preto' },
    { value: 'Rio Vermelho', text: 'Rio Vermelho' },
    { value: 'Ritápolis', text: 'Ritápolis' },
    { value: 'Rochedo de Minas', text: 'Rochedo de Minas' },
    { value: 'Rodeiro', text: 'Rodeiro' },
    { value: 'Romaria', text: 'Romaria' },
    { value: 'Rosário da Limeira', text: 'Rosário da Limeira' },
    { value: 'Rubelita', text: 'Rubelita' },
    { value: 'Rubim', text: 'Rubim' },
    { value: 'Sabará', text: 'Sabará' },
    { value: 'Sabinópolis', text: 'Sabinópolis' },
    { value: 'Sacramento', text: 'Sacramento' },
    { value: 'Salinas', text: 'Salinas' },
    { value: 'Salto da Divisa', text: 'Salto da Divisa' },
    { value: 'Santa Bárbara', text: 'Santa Bárbara' },
    { value: 'Santa Bárbara do Leste', text: 'Santa Bárbara do Leste' },
    {
      value: 'Santa Bárbara do Monte Verde',
      text: 'Santa Bárbara do Monte Verde',
    },
    { value: 'Santa Bárbara do Tugúrio', text: 'Santa Bárbara do Tugúrio' },
    { value: 'Santa Cruz de Minas', text: 'Santa Cruz de Minas' },
    { value: 'Santa Cruz de Salinas', text: 'Santa Cruz de Salinas' },
    { value: 'Santa Cruz do Escalvado', text: 'Santa Cruz do Escalvado' },
    { value: 'Santa Efigênia de Minas', text: 'Santa Efigênia de Minas' },
    { value: 'Santa Fé de Minas', text: 'Santa Fé de Minas' },
    { value: 'Santa Helena de Minas', text: 'Santa Helena de Minas' },
    { value: 'Santa Juliana', text: 'Santa Juliana' },
    { value: 'Santa Luzia', text: 'Santa Luzia' },
    { value: 'Santa Margarida', text: 'Santa Margarida' },
    { value: 'Santa Maria de Itabira', text: 'Santa Maria de Itabira' },
    { value: 'Santa Maria do Salto', text: 'Santa Maria do Salto' },
    { value: 'Santa Maria do Suaçuí', text: 'Santa Maria do Suaçuí' },
    { value: 'Santa Rita de Caldas', text: 'Santa Rita de Caldas' },
    { value: 'Santa Rita de Ibitipoca', text: 'Santa Rita de Ibitipoca' },
    { value: 'Santa Rita de Jacutinga', text: 'Santa Rita de Jacutinga' },
    { value: 'Santa Rita de Minas', text: 'Santa Rita de Minas' },
    { value: 'Santa Rita do Itueto', text: 'Santa Rita do Itueto' },
    { value: 'Santa Rita do Sapucaí', text: 'Santa Rita do Sapucaí' },
    { value: 'Santa Rosa da Serra', text: 'Santa Rosa da Serra' },
    { value: 'Santa Vitória', text: 'Santa Vitória' },
    { value: 'Santana da Vargem', text: 'Santana da Vargem' },
    { value: 'Santana de Cataguases', text: 'Santana de Cataguases' },
    { value: 'Santana de Pirapama', text: 'Santana de Pirapama' },
    { value: 'Santana do Deserto', text: 'Santana do Deserto' },
    { value: 'Santana do Garambéu', text: 'Santana do Garambéu' },
    { value: 'Santana do Jacaré', text: 'Santana do Jacaré' },
    { value: 'Santana do Manhuaçu', text: 'Santana do Manhuaçu' },
    { value: 'Santana do Paraíso', text: 'Santana do Paraíso' },
    { value: 'Santana do Riacho', text: 'Santana do Riacho' },
    { value: 'Santana dos Montes', text: 'Santana dos Montes' },
    { value: 'Santo Antônio do Amparo', text: 'Santo Antônio do Amparo' },
    {
      value: 'Santo Antônio do Aventureiro',
      text: 'Santo Antônio do Aventureiro',
    },
    { value: 'Santo Antônio do Grama', text: 'Santo Antônio do Grama' },
    { value: 'Santo Antônio do Itambé', text: 'Santo Antônio do Itambé' },
    { value: 'Santo Antônio do Jacinto', text: 'Santo Antônio do Jacinto' },
    { value: 'Santo Antônio do Monte', text: 'Santo Antônio do Monte' },
    { value: 'Santo Antônio do Retiro', text: 'Santo Antônio do Retiro' },
    {
      value: 'Santo Antônio do Rio Abaixo',
      text: 'Santo Antônio do Rio Abaixo',
    },
    { value: 'Santo Hipólito', text: 'Santo Hipólito' },
    { value: 'Santos Dumont', text: 'Santos Dumont' },
    { value: 'São Bento Abade', text: 'São Bento Abade' },
    { value: 'São Brás do Suaçuí', text: 'São Brás do Suaçuí' },
    { value: 'São Domingos das Dores', text: 'São Domingos das Dores' },
    { value: 'São Domingos do Prata', text: 'São Domingos do Prata' },
    { value: 'São Félix de Minas', text: 'São Félix de Minas' },
    { value: 'São Francisco', text: 'São Francisco' },
    { value: 'São Francisco de Paula', text: 'São Francisco de Paula' },
    { value: 'São Francisco de Sales', text: 'São Francisco de Sales' },
    { value: 'São Francisco do Glória', text: 'São Francisco do Glória' },
    { value: 'São Geraldo', text: 'São Geraldo' },
    { value: 'São Geraldo da Piedade', text: 'São Geraldo da Piedade' },
    { value: 'São Geraldo do Baixio', text: 'São Geraldo do Baixio' },
    { value: 'São Gonçalo do Abaeté', text: 'São Gonçalo do Abaeté' },
    { value: 'São Gonçalo do Pará', text: 'São Gonçalo do Pará' },
    { value: 'São Gonçalo do Rio Abaixo', text: 'São Gonçalo do Rio Abaixo' },
    { value: 'São Gonçalo do Rio Preto', text: 'São Gonçalo do Rio Preto' },
    { value: 'São Gonçalo do Sapucaí', text: 'São Gonçalo do Sapucaí' },
    { value: 'São Gotardo', text: 'São Gotardo' },
    { value: 'São João Batista do Glória', text: 'São João Batista do Glória' },
    { value: 'São João da Lagoa', text: 'São João da Lagoa' },
    { value: 'São João da Mata', text: 'São João da Mata' },
    { value: 'São João da Ponte', text: 'São João da Ponte' },
    { value: 'São João das Missões', text: 'São João das Missões' },
    { value: 'São João del Rei', text: 'São João del Rei' },
    { value: 'São João do Manhuaçu', text: 'São João do Manhuaçu' },
    { value: 'São João do Manteninha', text: 'São João do Manteninha' },
    { value: 'São João do Oriente', text: 'São João do Oriente' },
    { value: 'São João do Pacuí', text: 'São João do Pacuí' },
    { value: 'São João do Paraíso', text: 'São João do Paraíso' },
    { value: 'São João Evangelista', text: 'São João Evangelista' },
    { value: 'São João Nepomuceno', text: 'São João Nepomuceno' },
    { value: 'São Joaquim de Bicas', text: 'São Joaquim de Bicas' },
    { value: 'São José da Barra', text: 'São José da Barra' },
    { value: 'São José da Lapa', text: 'São José da Lapa' },
    { value: 'São José da Safira', text: 'São José da Safira' },
    { value: 'São José da Varginha', text: 'São José da Varginha' },
    { value: 'São José do Alegre', text: 'São José do Alegre' },
    { value: 'São José do Divino', text: 'São José do Divino' },
    { value: 'São José do Goiabal', text: 'São José do Goiabal' },
    { value: 'São José do Jacuri', text: 'São José do Jacuri' },
    { value: 'São José do Mantimento', text: 'São José do Mantimento' },
    { value: 'São Lourenço', text: 'São Lourenço' },
    { value: 'São Miguel do Anta', text: 'São Miguel do Anta' },
    { value: 'São Pedro da União', text: 'São Pedro da União' },
    { value: 'São Pedro do Suaçuí', text: 'São Pedro do Suaçuí' },
    { value: 'São Pedro dos Ferros', text: 'São Pedro dos Ferros' },
    { value: 'São Romão', text: 'São Romão' },
    { value: 'São Roque de Minas', text: 'São Roque de Minas' },
    {
      value: 'São Sebastião da Bela Vista',
      text: 'São Sebastião da Bela Vista',
    },
    {
      value: 'São Sebastião da Vargem Alegre',
      text: 'São Sebastião da Vargem Alegre',
    },
    { value: 'São Sebastião do Anta', text: 'São Sebastião do Anta' },
    { value: 'São Sebastião do Maranhão', text: 'São Sebastião do Maranhão' },
    { value: 'São Sebastião do Oeste', text: 'São Sebastião do Oeste' },
    { value: 'São Sebastião do Paraíso', text: 'São Sebastião do Paraíso' },
    { value: 'São Sebastião do Rio Preto', text: 'São Sebastião do Rio Preto' },
    { value: 'São Sebastião do Rio Verde', text: 'São Sebastião do Rio Verde' },
    { value: 'São Thomé das Letras', text: 'São Thomé das Letras' },
    { value: 'São Tiago', text: 'São Tiago' },
    { value: 'São Tomás de Aquino', text: 'São Tomás de Aquino' },
    { value: 'São Vicente de Minas', text: 'São Vicente de Minas' },
    { value: 'Sapucaí-Mirim', text: 'Sapucaí-Mirim' },
    { value: 'Sardoá', text: 'Sardoá' },
    { value: 'Sarzedo', text: 'Sarzedo' },
    { value: 'Sem-Peixe', text: 'Sem-Peixe' },
    { value: 'Senador Amaral', text: 'Senador Amaral' },
    { value: 'Senador Cortes', text: 'Senador Cortes' },
    { value: 'Senador Firmino', text: 'Senador Firmino' },
    { value: 'Senador José Bento', text: 'Senador José Bento' },
    {
      value: 'Senador Modestino Gonçalves',
      text: 'Senador Modestino Gonçalves',
    },
    { value: 'Senhora de Oliveira', text: 'Senhora de Oliveira' },
    { value: 'Senhora do Porto', text: 'Senhora do Porto' },
    { value: 'Senhora dos Remédios', text: 'Senhora dos Remédios' },
    { value: 'Sericita', text: 'Sericita' },
    { value: 'Seritinga', text: 'Seritinga' },
    { value: 'Serra Azul de Minas', text: 'Serra Azul de Minas' },
    { value: 'Serra da Saudade', text: 'Serra da Saudade' },
    { value: 'Serra do Salitre', text: 'Serra do Salitre' },
    { value: 'Serra dos Aimorés', text: 'Serra dos Aimorés' },
    { value: 'Serrania', text: 'Serrania' },
    { value: 'Serranópolis de Minas', text: 'Serranópolis de Minas' },
    { value: 'Serranos', text: 'Serranos' },
    { value: 'Serro', text: 'Serro' },
    { value: 'Sete Lagoas', text: 'Sete Lagoas' },
    { value: 'Setubinha', text: 'Setubinha' },
    { value: 'Silveirânia', text: 'Silveirânia' },
    { value: 'Silvianópolis', text: 'Silvianópolis' },
    { value: 'Simão Pereira', text: 'Simão Pereira' },
    { value: 'Simonésia', text: 'Simonésia' },
    { value: 'Sobrália', text: 'Sobrália' },
    { value: 'Soledade de Minas', text: 'Soledade de Minas' },
    { value: 'Tabuleiro', text: 'Tabuleiro' },
    { value: 'Taiobeiras', text: 'Taiobeiras' },
    { value: 'Taparuba', text: 'Taparuba' },
    { value: 'Tapira', text: 'Tapira' },
    { value: 'Tapiraí', text: 'Tapiraí' },
    { value: 'Taquaraçu de Minas', text: 'Taquaraçu de Minas' },
    { value: 'Tarumirim', text: 'Tarumirim' },
    { value: 'Teixeiras', text: 'Teixeiras' },
    { value: 'Teófilo Otoni', text: 'Teófilo Otoni' },
    { value: 'Timóteo', text: 'Timóteo' },
    { value: 'Tiradentes', text: 'Tiradentes' },
    { value: 'Tiros', text: 'Tiros' },
    { value: 'Tocantins', text: 'Tocantins' },
    { value: 'Tocos do Moji', text: 'Tocos do Moji' },
    { value: 'Toledo', text: 'Toledo' },
    { value: 'Tombos', text: 'Tombos' },
    { value: 'Três Corações', text: 'Três Corações' },
    { value: 'Três Marias', text: 'Três Marias' },
    { value: 'Três Pontas', text: 'Três Pontas' },
    { value: 'Tumiritinga', text: 'Tumiritinga' },
    { value: 'Tupaciguara', text: 'Tupaciguara' },
    { value: 'Turmalina', text: 'Turmalina' },
    { value: 'Turvolândia', text: 'Turvolândia' },
    { value: 'Ubá', text: 'Ubá' },
    { value: 'Ubaí', text: 'Ubaí' },
    { value: 'Ubaporanga', text: 'Ubaporanga' },
    { value: 'Uberaba', text: 'Uberaba' },
    { value: 'Uberlândia', text: 'Uberlândia' },
    { value: 'Umburatiba', text: 'Umburatiba' },
    { value: 'Unaí', text: 'Unaí' },
    { value: 'União de Minas', text: 'União de Minas' },
    { value: 'Uruana de Minas', text: 'Uruana de Minas' },
    { value: 'Urucânia', text: 'Urucânia' },
    { value: 'Urucuia', text: 'Urucuia' },
    { value: 'Vargem Alegre', text: 'Vargem Alegre' },
    { value: 'Vargem Bonita', text: 'Vargem Bonita' },
    { value: 'Vargem Grande do Rio Pardo', text: 'Vargem Grande do Rio Pardo' },
    { value: 'Varginha', text: 'Varginha' },
    { value: 'Varjão de Minas', text: 'Varjão de Minas' },
    { value: 'Várzea da Palma', text: 'Várzea da Palma' },
    { value: 'Varzelândia', text: 'Varzelândia' },
    { value: 'Vazante', text: 'Vazante' },
    { value: 'Verdelândia', text: 'Verdelândia' },
    { value: 'Veredinha', text: 'Veredinha' },
    { value: 'Veríssimo', text: 'Veríssimo' },
    { value: 'Vermelho Novo', text: 'Vermelho Novo' },
    { value: 'Vespasiano', text: 'Vespasiano' },
    { value: 'Viçosa', text: 'Viçosa' },
    { value: 'Vieiras', text: 'Vieiras' },
    { value: 'Virgem da Lapa', text: 'Virgem da Lapa' },
    { value: 'Virgínia', text: 'Virgínia' },
    { value: 'Virginópolis', text: 'Virginópolis' },
    { value: 'Virgolândia', text: 'Virgolândia' },
    { value: 'Visconde do Rio Branco', text: 'Visconde do Rio Branco' },
    { value: 'Volta Grande', text: 'Volta Grande' },
    { value: 'Wenceslau Braz', text: 'Wenceslau Braz' },
  ],
  MS: [
    { value: 'Água Clara', text: 'Água Clara' },
    { value: 'Alcinópolis', text: 'Alcinópolis' },
    { value: 'Amambaí', text: 'Amambaí' },
    { value: 'Anastácio', text: 'Anastácio' },
    { value: 'Anaurilândia', text: 'Anaurilândia' },
    { value: 'Angélica', text: 'Angélica' },
    { value: 'Antônio João', text: 'Antônio João' },
    { value: 'Aparecida do Taboado', text: 'Aparecida do Taboado' },
    { value: 'Aquidauana', text: 'Aquidauana' },
    { value: 'Aral Moreira', text: 'Aral Moreira' },
    { value: 'Bandeirantes', text: 'Bandeirantes' },
    { value: 'Bataguassu', text: 'Bataguassu' },
    { value: 'Bataiporã', text: 'Bataiporã' },
    { value: 'Bela Vista', text: 'Bela Vista' },
    { value: 'Bodoquena', text: 'Bodoquena' },
    { value: 'Bonito', text: 'Bonito' },
    { value: 'Brasilândia', text: 'Brasilândia' },
    { value: 'Caarapó', text: 'Caarapó' },
    { value: 'Camapuã', text: 'Camapuã' },
    { value: 'Campo Grande', text: 'Campo Grande' },
    { value: 'Caracol', text: 'Caracol' },
    { value: 'Cassilândia', text: 'Cassilândia' },
    { value: 'Chapadão do Sul', text: 'Chapadão do Sul' },
    { value: 'Corguinho', text: 'Corguinho' },
    { value: 'Coronel Sapucaia', text: 'Coronel Sapucaia' },
    { value: 'Corumbá', text: 'Corumbá' },
    { value: 'Costa Rica', text: 'Costa Rica' },
    { value: 'Coxim', text: 'Coxim' },
    { value: 'Deodápolis', text: 'Deodápolis' },
    { value: 'Dois Irmãos do Buriti', text: 'Dois Irmãos do Buriti' },
    { value: 'Douradina', text: 'Douradina' },
    { value: 'Dourados', text: 'Dourados' },
    { value: 'Eldorado', text: 'Eldorado' },
    { value: 'Fátima do Sul', text: 'Fátima do Sul' },
    { value: 'Glória de Dourados', text: 'Glória de Dourados' },
    { value: 'Guia Lopes da Laguna', text: 'Guia Lopes da Laguna' },
    { value: 'Iguatemi', text: 'Iguatemi' },
    { value: 'Inocência', text: 'Inocência' },
    { value: 'Itaporã', text: 'Itaporã' },
    { value: 'Itaquiraí', text: 'Itaquiraí' },
    { value: 'Ivinhema', text: 'Ivinhema' },
    { value: 'Japorã', text: 'Japorã' },
    { value: 'Jaraguari', text: 'Jaraguari' },
    { value: 'Jardim', text: 'Jardim' },
    { value: 'Jateí', text: 'Jateí' },
    { value: 'Juti', text: 'Juti' },
    { value: 'Ladário', text: 'Ladário' },
    { value: 'Laguna Carapã', text: 'Laguna Carapã' },
    { value: 'Maracaju', text: 'Maracaju' },
    { value: 'Miranda', text: 'Miranda' },
    { value: 'Mundo Novo', text: 'Mundo Novo' },
    { value: 'Naviraí', text: 'Naviraí' },
    { value: 'Nioaque', text: 'Nioaque' },
    { value: 'Nova Alvorada do Sul', text: 'Nova Alvorada do Sul' },
    { value: 'Nova Andradina', text: 'Nova Andradina' },
    { value: 'Novo Horizonte do Sul', text: 'Novo Horizonte do Sul' },
    { value: 'Paranaíba', text: 'Paranaíba' },
    { value: 'Paranhos', text: 'Paranhos' },
    { value: 'Pedro Gomes', text: 'Pedro Gomes' },
    { value: 'Ponta Porã', text: 'Ponta Porã' },
    { value: 'Porto Murtinho', text: 'Porto Murtinho' },
    { value: 'Ribas do Rio Pardo', text: 'Ribas do Rio Pardo' },
    { value: 'Rio Brilhante', text: 'Rio Brilhante' },
    { value: 'Rio Negro', text: 'Rio Negro' },
    { value: 'Rio Verde de Mato Grosso', text: 'Rio Verde de Mato Grosso' },
    { value: 'Rochedo', text: 'Rochedo' },
    { value: 'Santa Rita do Pardo', text: 'Santa Rita do Pardo' },
    { value: 'São Gabriel do Oeste', text: 'São Gabriel do Oeste' },
    { value: 'Selvíria', text: 'Selvíria' },
    { value: 'Sete Quedas', text: 'Sete Quedas' },
    { value: 'Sidrolândia', text: 'Sidrolândia' },
    { value: 'Sonora', text: 'Sonora' },
    { value: 'Tacuru', text: 'Tacuru' },
    { value: 'Taquarussu', text: 'Taquarussu' },
    { value: 'Terenos', text: 'Terenos' },
    { value: 'Três Lagoas', text: 'Três Lagoas' },
    { value: 'Vicentina', text: 'Vicentina' },
  ],
  MT: [
    { value: 'Acorizal', text: 'Acorizal' },
    { value: 'Água Boa', text: 'Água Boa' },
    { value: 'Alta Floresta', text: 'Alta Floresta' },
    { value: 'Alto Araguaia', text: 'Alto Araguaia' },
    { value: 'Alto Boa Vista', text: 'Alto Boa Vista' },
    { value: 'Alto Garças', text: 'Alto Garças' },
    { value: 'Alto Paraguai', text: 'Alto Paraguai' },
    { value: 'Alto Taquari', text: 'Alto Taquari' },
    { value: 'Apiacás', text: 'Apiacás' },
    { value: 'Araguaiana', text: 'Araguaiana' },
    { value: 'Araguainha', text: 'Araguainha' },
    { value: 'Araputanga', text: 'Araputanga' },
    { value: 'Arenápolis', text: 'Arenápolis' },
    { value: 'Aripuanã', text: 'Aripuanã' },
    { value: 'Barão de Melgaço', text: 'Barão de Melgaço' },
    { value: 'Barra do Bugres', text: 'Barra do Bugres' },
    { value: 'Barra do Garças', text: 'Barra do Garças' },
    { value: 'Bom Jesus do Araguaia', text: 'Bom Jesus do Araguaia' },
    { value: 'Brasnorte', text: 'Brasnorte' },
    { value: 'Cáceres', text: 'Cáceres' },
    { value: 'Campinápolis', text: 'Campinápolis' },
    { value: 'Campo Novo do Parecis', text: 'Campo Novo do Parecis' },
    { value: 'Campo Verde', text: 'Campo Verde' },
    { value: 'Campos de Júlio', text: 'Campos de Júlio' },
    { value: 'Canabrava do Norte', text: 'Canabrava do Norte' },
    { value: 'Canarana', text: 'Canarana' },
    { value: 'Carlinda', text: 'Carlinda' },
    { value: 'Castanheira', text: 'Castanheira' },
    { value: 'Chapada dos Guimarães', text: 'Chapada dos Guimarães' },
    { value: 'Cláudia', text: 'Cláudia' },
    { value: 'Cocalinho', text: 'Cocalinho' },
    { value: 'Colíder', text: 'Colíder' },
    { value: 'Colniza', text: 'Colniza' },
    { value: 'Comodoro', text: 'Comodoro' },
    { value: 'Confresa', text: 'Confresa' },
    { value: "Conquista d'Oeste", text: "Conquista d'Oeste" },
    { value: 'Cotriguaçu', text: 'Cotriguaçu' },
    { value: 'Curvelândia', text: 'Curvelândia' },
    { value: 'Cuiabá', text: 'Cuiabá' },
    { value: 'Denise', text: 'Denise' },
    { value: 'Diamantino', text: 'Diamantino' },
    { value: 'Dom Aquino', text: 'Dom Aquino' },
    { value: 'Feliz Natal', text: 'Feliz Natal' },
    { value: "Figueirópolis d'Oeste", text: "Figueirópolis d'Oeste" },
    { value: 'Gaúcha do Norte', text: 'Gaúcha do Norte' },
    { value: 'General Carneiro', text: 'General Carneiro' },
    { value: "Glória d'Oeste", text: "Glória d'Oeste" },
    { value: 'Guarantã do Norte', text: 'Guarantã do Norte' },
    { value: 'Guiratinga', text: 'Guiratinga' },
    { value: 'Indiavaí', text: 'Indiavaí' },
    { value: 'Itaúba', text: 'Itaúba' },
    { value: 'Itiquira', text: 'Itiquira' },
    { value: 'Jaciara', text: 'Jaciara' },
    { value: 'Jangada', text: 'Jangada' },
    { value: 'Jauru', text: 'Jauru' },
    { value: 'Juara', text: 'Juara' },
    { value: 'Juína', text: 'Juína' },
    { value: 'Juruena', text: 'Juruena' },
    { value: 'Juscimeira', text: 'Juscimeira' },
    { value: "Lambari d'Oeste", text: "Lambari d'Oeste" },
    { value: 'Lucas do Rio Verde', text: 'Lucas do Rio Verde' },
    { value: 'Luciára', text: 'Luciára' },
    { value: 'Marcelândia', text: 'Marcelândia' },
    { value: 'Matupá', text: 'Matupá' },
    { value: "Mirassol d'Oeste", text: "Mirassol d'Oeste" },
    { value: 'Nobres', text: 'Nobres' },
    { value: 'Nortelândia', text: 'Nortelândia' },
    {
      value: 'Nossa Senhora do Livramento',
      text: 'Nossa Senhora do Livramento',
    },
    { value: 'Nova Bandeirantes', text: 'Nova Bandeirantes' },
    { value: 'Nova Brasilândia', text: 'Nova Brasilândia' },
    { value: 'Nova Canãa do Norte', text: 'Nova Canãa do Norte' },
    { value: 'Nova Guarita', text: 'Nova Guarita' },
    { value: 'Nova Lacerda', text: 'Nova Lacerda' },
    { value: 'Nova Marilândia', text: 'Nova Marilândia' },
    { value: 'Nova Maringá', text: 'Nova Maringá' },
    { value: 'Nova Monte Verde', text: 'Nova Monte Verde' },
    { value: 'Nova Mutum', text: 'Nova Mutum' },
    { value: 'Nova Nazaré', text: 'Nova Nazaré' },
    { value: 'Nova Olímpia', text: 'Nova Olímpia' },
    { value: 'Nova Santa Helena', text: 'Nova Santa Helena' },
    { value: 'Nova Ubiratã', text: 'Nova Ubiratã' },
    { value: 'Nova Xavantina', text: 'Nova Xavantina' },
    { value: 'Novo Horizonte do Norte', text: 'Novo Horizonte do Norte' },
    { value: 'Novo Mundo', text: 'Novo Mundo' },
    { value: 'Novo Santo Antônio', text: 'Novo Santo Antônio' },
    { value: 'Novo São Joaquim', text: 'Novo São Joaquim' },
    { value: 'Paranaíta', text: 'Paranaíta' },
    { value: 'Paranatinga', text: 'Paranatinga' },
    { value: 'Pedra Preta', text: 'Pedra Preta' },
    { value: 'Peixoto de Azevedo', text: 'Peixoto de Azevedo' },
    { value: 'Planalto da Serra', text: 'Planalto da Serra' },
    { value: 'Poconé', text: 'Poconé' },
    { value: 'Pontal do Araguaia', text: 'Pontal do Araguaia' },
    { value: 'Ponte Branca', text: 'Ponte Branca' },
    { value: 'Pontes e Lacerda', text: 'Pontes e Lacerda' },
    { value: 'Porto Alegre do Norte', text: 'Porto Alegre do Norte' },
    { value: 'Porto dos Gaúchos', text: 'Porto dos Gaúchos' },
    { value: 'Porto Esperidião', text: 'Porto Esperidião' },
    { value: 'Porto Estrela', text: 'Porto Estrela' },
    { value: 'Poxoréo', text: 'Poxoréo' },
    { value: 'Primavera do Leste', text: 'Primavera do Leste' },
    { value: 'Querência', text: 'Querência' },
    { value: 'Reserva do Cabaçal', text: 'Reserva do Cabaçal' },
    { value: 'Ribeirão Cascalheira', text: 'Ribeirão Cascalheira' },
    { value: 'Ribeirãozinho', text: 'Ribeirãozinho' },
    { value: 'Rio Branco', text: 'Rio Branco' },
    { value: 'Rondolândia', text: 'Rondolândia' },
    { value: 'Rondonópolis', text: 'Rondonópolis' },
    { value: 'Rosário Oeste', text: 'Rosário Oeste' },
    { value: 'Salto do Céu', text: 'Salto do Céu' },
    { value: 'Santa Carmem', text: 'Santa Carmem' },
    { value: 'Santa Cruz do Xingu', text: 'Santa Cruz do Xingu' },
    { value: 'Santa Rita do Trivelato', text: 'Santa Rita do Trivelato' },
    { value: 'Santa Terezinha', text: 'Santa Terezinha' },
    { value: 'Santo Afonso', text: 'Santo Afonso' },
    { value: 'Santo Antônio do Leste', text: 'Santo Antônio do Leste' },
    { value: 'Santo Antônio do Leverger', text: 'Santo Antônio do Leverger' },
    { value: 'São Félix do Araguaia', text: 'São Félix do Araguaia' },
    { value: 'São José do Povo', text: 'São José do Povo' },
    { value: 'São José do Rio Claro', text: 'São José do Rio Claro' },
    { value: 'São José do Xingu', text: 'São José do Xingu' },
    { value: 'São José dos Quatro Marcos', text: 'São José dos Quatro Marcos' },
    { value: 'São Pedro da Cipa', text: 'São Pedro da Cipa' },
    { value: 'Sapezal', text: 'Sapezal' },
    { value: 'Serra Nova Dourada', text: 'Serra Nova Dourada' },
    { value: 'Sinop', text: 'Sinop' },
    { value: 'Sorriso', text: 'Sorriso' },
    { value: 'Tabaporã', text: 'Tabaporã' },
    { value: 'Tangará da Serra', text: 'Tangará da Serra' },
    { value: 'Tapurah', text: 'Tapurah' },
    { value: 'Terra Nova do Norte', text: 'Terra Nova do Norte' },
    { value: 'Tesouro', text: 'Tesouro' },
    { value: 'Torixoréu', text: 'Torixoréu' },
    { value: 'União do Sul', text: 'União do Sul' },
    { value: 'Vale de São Domingos', text: 'Vale de São Domingos' },
    { value: 'Várzea Grande', text: 'Várzea Grande' },
    { value: 'Vera', text: 'Vera' },
    {
      value: 'Vila Bela da Santíssima Trindade',
      text: 'Vila Bela da Santíssima Trindade',
    },
    { value: 'Vila Rica', text: 'Vila Rica' },
  ],
  PA: [
    { value: 'Abaetetuba', text: 'Abaetetuba' },
    { value: 'Abel Figueiredo', text: 'Abel Figueiredo' },
    { value: 'Acará', text: 'Acará' },
    { value: 'Afuá', text: 'Afuá' },
    { value: 'Água Azul do Norte', text: 'Água Azul do Norte' },
    { value: 'Alenquer', text: 'Alenquer' },
    { value: 'Almeirim', text: 'Almeirim' },
    { value: 'Altamira', text: 'Altamira' },
    { value: 'Anajás', text: 'Anajás' },
    { value: 'Ananindeua', text: 'Ananindeua' },
    { value: 'Anapu', text: 'Anapu' },
    { value: 'Augusto Corrêa', text: 'Augusto Corrêa' },
    { value: 'Aurora do Pará', text: 'Aurora do Pará' },
    { value: 'Aveiro', text: 'Aveiro' },
    { value: 'Bagre', text: 'Bagre' },
    { value: 'Baião', text: 'Baião' },
    { value: 'Bannach', text: 'Bannach' },
    { value: 'Barcarena', text: 'Barcarena' },
    { value: 'Belém', text: 'Belém' },
    { value: 'Belterra', text: 'Belterra' },
    { value: 'Benevides', text: 'Benevides' },
    { value: 'Bom Jesus do Tocantins', text: 'Bom Jesus do Tocantins' },
    { value: 'Bonito', text: 'Bonito' },
    { value: 'Bragança', text: 'Bragança' },
    { value: 'Brasil Novo', text: 'Brasil Novo' },
    { value: 'Brejo Grande do Araguaia', text: 'Brejo Grande do Araguaia' },
    { value: 'Breu Branco', text: 'Breu Branco' },
    { value: 'Breves', text: 'Breves' },
    { value: 'Bujaru', text: 'Bujaru' },
    { value: 'Cachoeira do Arari', text: 'Cachoeira do Arari' },
    { value: 'Cachoeira do Piriá', text: 'Cachoeira do Piriá' },
    { value: 'Cametá', text: 'Cametá' },
    { value: 'Canaã dos Carajás', text: 'Canaã dos Carajás' },
    { value: 'Capanema', text: 'Capanema' },
    { value: 'Capitão Poço', text: 'Capitão Poço' },
    { value: 'Castanhal', text: 'Castanhal' },
    { value: 'Chaves', text: 'Chaves' },
    { value: 'Colares', text: 'Colares' },
    { value: 'Conceição do Araguaia', text: 'Conceição do Araguaia' },
    { value: 'Concórdia do Pará', text: 'Concórdia do Pará' },
    { value: 'Cumaru do Norte', text: 'Cumaru do Norte' },
    { value: 'Curionópolis', text: 'Curionópolis' },
    { value: 'Curralinho', text: 'Curralinho' },
    { value: 'Curuá', text: 'Curuá' },
    { value: 'Curuçá', text: 'Curuçá' },
    { value: 'Dom Eliseu', text: 'Dom Eliseu' },
    { value: 'Eldorado dos Carajás', text: 'Eldorado dos Carajás' },
    { value: 'Faro', text: 'Faro' },
    { value: 'Floresta do Araguaia', text: 'Floresta do Araguaia' },
    { value: 'Garrafão do Norte', text: 'Garrafão do Norte' },
    { value: 'Goianésia do Pará', text: 'Goianésia do Pará' },
    { value: 'Gurupá', text: 'Gurupá' },
    { value: 'Igarapé-Açu', text: 'Igarapé-Açu' },
    { value: 'Igarapé-Miri', text: 'Igarapé-Miri' },
    { value: 'Inhangapi', text: 'Inhangapi' },
    { value: 'Ipixuna do Pará', text: 'Ipixuna do Pará' },
    { value: 'Irituia', text: 'Irituia' },
    { value: 'Itaituba', text: 'Itaituba' },
    { value: 'Itupiranga', text: 'Itupiranga' },
    { value: 'Jacareacanga', text: 'Jacareacanga' },
    { value: 'Jacundá', text: 'Jacundá' },
    { value: 'Juruti', text: 'Juruti' },
    { value: 'Limoeiro do Ajuru', text: 'Limoeiro do Ajuru' },
    { value: 'Mãe do Rio', text: 'Mãe do Rio' },
    { value: 'Magalhães Barata', text: 'Magalhães Barata' },
    { value: 'Marabá', text: 'Marabá' },
    { value: 'Maracanã', text: 'Maracanã' },
    { value: 'Marapanim', text: 'Marapanim' },
    { value: 'Marituba', text: 'Marituba' },
    { value: 'Medicilândia', text: 'Medicilândia' },
    { value: 'Melgaço', text: 'Melgaço' },
    { value: 'Mocajuba', text: 'Mocajuba' },
    { value: 'Moju', text: 'Moju' },
    { value: 'Monte Alegre', text: 'Monte Alegre' },
    { value: 'Muaná', text: 'Muaná' },
    { value: 'Nova Esperança do Piriá', text: 'Nova Esperança do Piriá' },
    { value: 'Nova Ipixuna', text: 'Nova Ipixuna' },
    { value: 'Nova Timboteua', text: 'Nova Timboteua' },
    { value: 'Novo Progresso', text: 'Novo Progresso' },
    { value: 'Novo Repartimento', text: 'Novo Repartimento' },
    { value: 'Óbidos', text: 'Óbidos' },
    { value: 'Oeiras do Pará', text: 'Oeiras do Pará' },
    { value: 'Oriximiná', text: 'Oriximiná' },
    { value: 'Ourém', text: 'Ourém' },
    { value: 'Ourilândia do Norte', text: 'Ourilândia do Norte' },
    { value: 'Pacajá', text: 'Pacajá' },
    { value: 'Palestina do Pará', text: 'Palestina do Pará' },
    { value: 'Paragominas', text: 'Paragominas' },
    { value: 'Parauapebas', text: 'Parauapebas' },
    { value: "Pau d'Arco", text: "Pau d'Arco" },
    { value: 'Peixe-Boi', text: 'Peixe-Boi' },
    { value: 'Piçarra', text: 'Piçarra' },
    { value: 'Placas', text: 'Placas' },
    { value: 'Ponta de Pedras', text: 'Ponta de Pedras' },
    { value: 'Portel', text: 'Portel' },
    { value: 'Porto de Moz', text: 'Porto de Moz' },
    { value: 'Prainha', text: 'Prainha' },
    { value: 'Primavera', text: 'Primavera' },
    { value: 'Quatipuru', text: 'Quatipuru' },
    { value: 'Redenção', text: 'Redenção' },
    { value: 'Rio Maria', text: 'Rio Maria' },
    { value: 'Rondon do Pará', text: 'Rondon do Pará' },
    { value: 'Rurópolis', text: 'Rurópolis' },
    { value: 'Salinópolis', text: 'Salinópolis' },
    { value: 'Salvaterra', text: 'Salvaterra' },
    { value: 'Santa Bárbara do Pará', text: 'Santa Bárbara do Pará' },
    { value: 'Santa Cruz do Arari', text: 'Santa Cruz do Arari' },
    { value: 'Santa Isabel do Pará', text: 'Santa Isabel do Pará' },
    { value: 'Santa Luzia do Pará', text: 'Santa Luzia do Pará' },
    { value: 'Santa Maria das Barreiras', text: 'Santa Maria das Barreiras' },
    { value: 'Santa Maria do Pará', text: 'Santa Maria do Pará' },
    { value: 'Santana do Araguaia', text: 'Santana do Araguaia' },
    { value: 'Santarém', text: 'Santarém' },
    { value: 'Santarém Novo', text: 'Santarém Novo' },
    { value: 'Santo Antônio do Tauá', text: 'Santo Antônio do Tauá' },
    { value: 'São Caetano de Odivela', text: 'São Caetano de Odivela' },
    { value: 'São Domingos do Araguaia', text: 'São Domingos do Araguaia' },
    { value: 'São Domingos do Capim', text: 'São Domingos do Capim' },
    { value: 'São Félix do Xingu', text: 'São Félix do Xingu' },
    { value: 'São Francisco do Pará', text: 'São Francisco do Pará' },
    { value: 'São Geraldo do Araguaia', text: 'São Geraldo do Araguaia' },
    { value: 'São João da Ponta', text: 'São João da Ponta' },
    { value: 'São João de Pirabas', text: 'São João de Pirabas' },
    { value: 'São João do Araguaia', text: 'São João do Araguaia' },
    { value: 'São Miguel do Guamá', text: 'São Miguel do Guamá' },
    { value: 'São Sebastião da Boa Vista', text: 'São Sebastião da Boa Vista' },
    { value: 'Sapucaia', text: 'Sapucaia' },
    { value: 'Senador José Porfírio', text: 'Senador José Porfírio' },
    { value: 'Soure', text: 'Soure' },
    { value: 'Tailândia', text: 'Tailândia' },
    { value: 'Terra Alta', text: 'Terra Alta' },
    { value: 'Terra Santa', text: 'Terra Santa' },
    { value: 'Tomé-Açu', text: 'Tomé-Açu' },
    { value: 'Tracuateua', text: 'Tracuateua' },
    { value: 'Trairão', text: 'Trairão' },
    { value: 'Tucumã', text: 'Tucumã' },
    { value: 'Tucuruí', text: 'Tucuruí' },
    { value: 'Ulianópolis', text: 'Ulianópolis' },
    { value: 'Uruará', text: 'Uruará' },
    { value: 'Vigia', text: 'Vigia' },
    { value: 'Viseu', text: 'Viseu' },
    { value: 'Vitória do Xingu', text: 'Vitória do Xingu' },
    { value: 'Xinguara', text: 'Xinguara' },
  ],
  PB: [
    { value: 'Água Branca', text: 'Água Branca' },
    { value: 'Aguiar', text: 'Aguiar' },
    { value: 'Alagoa Grande', text: 'Alagoa Grande' },
    { value: 'Alagoa Nova', text: 'Alagoa Nova' },
    { value: 'Alagoinha', text: 'Alagoinha' },
    { value: 'Alcantil', text: 'Alcantil' },
    { value: 'Algodão de Jandaíra', text: 'Algodão de Jandaíra' },
    { value: 'Alhandra', text: 'Alhandra' },
    { value: 'Amparo', text: 'Amparo' },
    { value: 'Aparecida', text: 'Aparecida' },
    { value: 'Araçagi', text: 'Araçagi' },
    { value: 'Arara', text: 'Arara' },
    { value: 'Araruna', text: 'Araruna' },
    { value: 'Areia', text: 'Areia' },
    { value: 'Areia de Baraúnas', text: 'Areia de Baraúnas' },
    { value: 'Areial', text: 'Areial' },
    { value: 'Aroeiras', text: 'Aroeiras' },
    { value: 'Assunção', text: 'Assunção' },
    { value: 'Baía da Traição', text: 'Baía da Traição' },
    { value: 'Bananeiras', text: 'Bananeiras' },
    { value: 'Baraúna', text: 'Baraúna' },
    { value: 'Barra de Santa Rosa', text: 'Barra de Santa Rosa' },
    { value: 'Barra de Santana', text: 'Barra de Santana' },
    { value: 'Barra de São Miguel', text: 'Barra de São Miguel' },
    { value: 'Bayeux', text: 'Bayeux' },
    { value: 'Belém', text: 'Belém' },
    { value: 'Belém do Brejo do Cruz', text: 'Belém do Brejo do Cruz' },
    { value: 'Bernardino Batista', text: 'Bernardino Batista' },
    { value: 'Boa Ventura', text: 'Boa Ventura' },
    { value: 'Boa Vista', text: 'Boa Vista' },
    { value: 'Bom Jesus', text: 'Bom Jesus' },
    { value: 'Bom Sucesso', text: 'Bom Sucesso' },
    { value: 'Bonito de Santa Fé', text: 'Bonito de Santa Fé' },
    { value: 'Boqueirão', text: 'Boqueirão' },
    { value: 'Borborema', text: 'Borborema' },
    { value: 'Brejo do Cruz', text: 'Brejo do Cruz' },
    { value: 'Brejo dos Santos', text: 'Brejo dos Santos' },
    { value: 'Caaporã', text: 'Caaporã' },
    { value: 'Cabaceiras', text: 'Cabaceiras' },
    { value: 'Cabedelo', text: 'Cabedelo' },
    { value: 'Cachoeira dos Índios', text: 'Cachoeira dos Índios' },
    { value: 'Cacimba de Areia', text: 'Cacimba de Areia' },
    { value: 'Cacimba de Dentro', text: 'Cacimba de Dentro' },
    { value: 'Cacimbas', text: 'Cacimbas' },
    { value: 'Caiçara', text: 'Caiçara' },
    { value: 'Cajazeiras', text: 'Cajazeiras' },
    { value: 'Cajazeirinhas', text: 'Cajazeirinhas' },
    { value: 'Caldas Brandão', text: 'Caldas Brandão' },
    { value: 'Camalaú', text: 'Camalaú' },
    { value: 'Campina Grande', text: 'Campina Grande' },
    { value: 'Campo de Santana', text: 'Campo de Santana' },
    { value: 'Capim', text: 'Capim' },
    { value: 'Caraúbas', text: 'Caraúbas' },
    { value: 'Carrapateira', text: 'Carrapateira' },
    { value: 'Casserengue', text: 'Casserengue' },
    { value: 'Catingueira', text: 'Catingueira' },
    { value: 'Catolé do Rocha', text: 'Catolé do Rocha' },
    { value: 'Caturité', text: 'Caturité' },
    { value: 'Conceição', text: 'Conceição' },
    { value: 'Condado', text: 'Condado' },
    { value: 'Conde', text: 'Conde' },
    { value: 'Congo', text: 'Congo' },
    { value: 'Coremas', text: 'Coremas' },
    { value: 'Coxixola', text: 'Coxixola' },
    { value: 'Cruz do Espírito Santo', text: 'Cruz do Espírito Santo' },
    { value: 'Cubati', text: 'Cubati' },
    { value: 'Cuité', text: 'Cuité' },
    { value: 'Cuité de Mamanguape', text: 'Cuité de Mamanguape' },
    { value: 'Cuitegi', text: 'Cuitegi' },
    { value: 'Curral de Cima', text: 'Curral de Cima' },
    { value: 'Curral Velho', text: 'Curral Velho' },
    { value: 'Damião', text: 'Damião' },
    { value: 'Desterro', text: 'Desterro' },
    { value: 'Diamante', text: 'Diamante' },
    { value: 'Dona Inês', text: 'Dona Inês' },
    { value: 'Duas Estradas', text: 'Duas Estradas' },
    { value: 'Emas', text: 'Emas' },
    { value: 'Esperança', text: 'Esperança' },
    { value: 'Fagundes', text: 'Fagundes' },
    { value: 'Frei Martinho', text: 'Frei Martinho' },
    { value: 'Gado Bravo', text: 'Gado Bravo' },
    { value: 'Guarabira', text: 'Guarabira' },
    { value: 'Gurinhém', text: 'Gurinhém' },
    { value: 'Gurjão', text: 'Gurjão' },
    { value: 'Ibiara', text: 'Ibiara' },
    { value: 'Igaracy', text: 'Igaracy' },
    { value: 'Imaculada', text: 'Imaculada' },
    { value: 'Ingá', text: 'Ingá' },
    { value: 'Itabaiana', text: 'Itabaiana' },
    { value: 'Itaporanga', text: 'Itaporanga' },
    { value: 'Itapororoca', text: 'Itapororoca' },
    { value: 'Itatuba', text: 'Itatuba' },
    { value: 'Jacaraú', text: 'Jacaraú' },
    { value: 'Jericó', text: 'Jericó' },
    { value: 'João Pessoa', text: 'João Pessoa' },
    { value: 'Juarez Távora', text: 'Juarez Távora' },
    { value: 'Juazeirinho', text: 'Juazeirinho' },
    { value: 'Junco do Seridó', text: 'Junco do Seridó' },
    { value: 'Juripiranga', text: 'Juripiranga' },
    { value: 'Juru', text: 'Juru' },
    { value: 'Lagoa', text: 'Lagoa' },
    { value: 'Lagoa de Dentro', text: 'Lagoa de Dentro' },
    { value: 'Lagoa Seca', text: 'Lagoa Seca' },
    { value: 'Lastro', text: 'Lastro' },
    { value: 'Livramento', text: 'Livramento' },
    { value: 'Logradouro', text: 'Logradouro' },
    { value: 'Lucena', text: 'Lucena' },
    { value: "Mãe d'Água", text: "Mãe d'Água" },
    { value: 'Malta', text: 'Malta' },
    { value: 'Mamanguape', text: 'Mamanguape' },
    { value: 'Manaíra', text: 'Manaíra' },
    { value: 'Marcação', text: 'Marcação' },
    { value: 'Mari', text: 'Mari' },
    { value: 'Marizópolis', text: 'Marizópolis' },
    { value: 'Massaranduba', text: 'Massaranduba' },
    { value: 'Mataraca', text: 'Mataraca' },
    { value: 'Matinhas', text: 'Matinhas' },
    { value: 'Mato Grosso', text: 'Mato Grosso' },
    { value: 'Maturéia', text: 'Maturéia' },
    { value: 'Mogeiro', text: 'Mogeiro' },
    { value: 'Montadas', text: 'Montadas' },
    { value: 'Monte Horebe', text: 'Monte Horebe' },
    { value: 'Monteiro', text: 'Monteiro' },
    { value: 'Mulungu', text: 'Mulungu' },
    { value: 'Natuba', text: 'Natuba' },
    { value: 'Nazarezinho', text: 'Nazarezinho' },
    { value: 'Nova Floresta', text: 'Nova Floresta' },
    { value: 'Nova Olinda', text: 'Nova Olinda' },
    { value: 'Nova Palmeira', text: 'Nova Palmeira' },
    { value: "Olho d'Água", text: "Olho d'Água" },
    { value: 'Olivedos', text: 'Olivedos' },
    { value: 'Ouro Velho', text: 'Ouro Velho' },
    { value: 'Parari', text: 'Parari' },
    { value: 'Passagem', text: 'Passagem' },
    { value: 'Patos', text: 'Patos' },
    { value: 'Paulista', text: 'Paulista' },
    { value: 'Pedra Branca', text: 'Pedra Branca' },
    { value: 'Pedra Lavrada', text: 'Pedra Lavrada' },
    { value: 'Pedras de Fogo', text: 'Pedras de Fogo' },
    { value: 'Pedro Régis', text: 'Pedro Régis' },
    { value: 'Piancó', text: 'Piancó' },
    { value: 'Picuí', text: 'Picuí' },
    { value: 'Pilar', text: 'Pilar' },
    { value: 'Pilões', text: 'Pilões' },
    { value: 'Pilõezinhos', text: 'Pilõezinhos' },
    { value: 'Pirpirituba', text: 'Pirpirituba' },
    { value: 'Pitimbu', text: 'Pitimbu' },
    { value: 'Pocinhos', text: 'Pocinhos' },
    { value: 'Poço Dantas', text: 'Poço Dantas' },
    { value: 'Poço de José de Moura', text: 'Poço de José de Moura' },
    { value: 'Pombal', text: 'Pombal' },
    { value: 'Prata', text: 'Prata' },
    { value: 'Princesa Isabel', text: 'Princesa Isabel' },
    { value: 'Puxinanã', text: 'Puxinanã' },
    { value: 'Queimadas', text: 'Queimadas' },
    { value: 'Quixabá', text: 'Quixabá' },
    { value: 'Remígio', text: 'Remígio' },
    { value: 'Riachão', text: 'Riachão' },
    { value: 'Riachão do Bacamarte', text: 'Riachão do Bacamarte' },
    { value: 'Riachão do Poço', text: 'Riachão do Poço' },
    { value: 'Riacho de Santo Antônio', text: 'Riacho de Santo Antônio' },
    { value: 'Riacho dos Cavalos', text: 'Riacho dos Cavalos' },
    { value: 'Rio Tinto', text: 'Rio Tinto' },
    { value: 'Salgadinho', text: 'Salgadinho' },
    { value: 'Salgado de São Félix', text: 'Salgado de São Félix' },
    { value: 'Santa Cecília', text: 'Santa Cecília' },
    { value: 'Santa Cruz', text: 'Santa Cruz' },
    { value: 'Santa Helena', text: 'Santa Helena' },
    { value: 'Santa Inês', text: 'Santa Inês' },
    { value: 'Santa Luzia', text: 'Santa Luzia' },
    { value: 'Santa Rita', text: 'Santa Rita' },
    { value: 'Santa Teresinha', text: 'Santa Teresinha' },
    { value: 'Santana de Mangueira', text: 'Santana de Mangueira' },
    { value: 'Santana dos Garrotes', text: 'Santana dos Garrotes' },
    { value: 'Santarém', text: 'Santarém' },
    { value: 'Santo André', text: 'Santo André' },
    { value: 'São Bentinho', text: 'São Bentinho' },
    { value: 'São Bento', text: 'São Bento' },
    { value: 'São Domingos de Pombal', text: 'São Domingos de Pombal' },
    { value: 'São Domingos do Cariri', text: 'São Domingos do Cariri' },
    { value: 'São Francisco', text: 'São Francisco' },
    { value: 'São João do Cariri', text: 'São João do Cariri' },
    { value: 'São João do Rio do Peixe', text: 'São João do Rio do Peixe' },
    { value: 'São João do Tigre', text: 'São João do Tigre' },
    { value: 'São José da Lagoa Tapada', text: 'São José da Lagoa Tapada' },
    { value: 'São José de Caiana', text: 'São José de Caiana' },
    { value: 'São José de Espinharas', text: 'São José de Espinharas' },
    { value: 'São José de Piranhas', text: 'São José de Piranhas' },
    { value: 'São José de Princesa', text: 'São José de Princesa' },
    { value: 'São José do Bonfim', text: 'São José do Bonfim' },
    { value: 'São José do Brejo do Cruz', text: 'São José do Brejo do Cruz' },
    { value: 'São José do Sabugi', text: 'São José do Sabugi' },
    { value: 'São José dos Cordeiros', text: 'São José dos Cordeiros' },
    { value: 'São José dos Ramos', text: 'São José dos Ramos' },
    { value: 'São Mamede', text: 'São Mamede' },
    { value: 'São Miguel de Taipu', text: 'São Miguel de Taipu' },
    {
      value: 'São Sebastião de Lagoa de Roça',
      text: 'São Sebastião de Lagoa de Roça',
    },
    { value: 'São Sebastião do Umbuzeiro', text: 'São Sebastião do Umbuzeiro' },
    { value: 'Sapé', text: 'Sapé' },
    { value: 'Seridó', text: 'Seridó' },
    { value: 'Serra Branca', text: 'Serra Branca' },
    { value: 'Serra da Raiz', text: 'Serra da Raiz' },
    { value: 'Serra Grande', text: 'Serra Grande' },
    { value: 'Serra Redonda', text: 'Serra Redonda' },
    { value: 'Serraria', text: 'Serraria' },
    { value: 'Sertãozinho', text: 'Sertãozinho' },
    { value: 'Sobrado', text: 'Sobrado' },
    { value: 'Solânea', text: 'Solânea' },
    { value: 'Soledade', text: 'Soledade' },
    { value: 'Sossêgo', text: 'Sossêgo' },
    { value: 'Sousa', text: 'Sousa' },
    { value: 'Sumé', text: 'Sumé' },
    { value: 'Taperoá', text: 'Taperoá' },
    { value: 'Tavares', text: 'Tavares' },
    { value: 'Teixeira', text: 'Teixeira' },
    { value: 'Tenório', text: 'Tenório' },
    { value: 'Triunfo', text: 'Triunfo' },
    { value: 'Uiraúna', text: 'Uiraúna' },
    { value: 'Umbuzeiro', text: 'Umbuzeiro' },
    { value: 'Várzea', text: 'Várzea' },
    { value: 'Vieirópolis', text: 'Vieirópolis' },
    { value: 'Vista Serrana', text: 'Vista Serrana' },
    { value: 'Zabelê', text: 'Zabelê' },
  ],
  PE: [
    { value: 'Abreu e Lima', text: 'Abreu e Lima' },
    { value: 'Afogados da Ingazeira', text: 'Afogados da Ingazeira' },
    { value: 'Afrânio', text: 'Afrânio' },
    { value: 'Agrestina', text: 'Agrestina' },
    { value: 'Água Preta', text: 'Água Preta' },
    { value: 'Águas Belas', text: 'Águas Belas' },
    { value: 'Alagoinha', text: 'Alagoinha' },
    { value: 'Aliança', text: 'Aliança' },
    { value: 'Altinho', text: 'Altinho' },
    { value: 'Amaraji', text: 'Amaraji' },
    { value: 'Angelim', text: 'Angelim' },
    { value: 'Araçoiaba', text: 'Araçoiaba' },
    { value: 'Araripina', text: 'Araripina' },
    { value: 'Arcoverde', text: 'Arcoverde' },
    { value: 'Barra de Guabiraba', text: 'Barra de Guabiraba' },
    { value: 'Barreiros', text: 'Barreiros' },
    { value: 'Belém de Maria', text: 'Belém de Maria' },
    { value: 'Belém de São Francisco', text: 'Belém de São Francisco' },
    { value: 'Belo Jardim', text: 'Belo Jardim' },
    { value: 'Betânia', text: 'Betânia' },
    { value: 'Bezerros', text: 'Bezerros' },
    { value: 'Bodocó', text: 'Bodocó' },
    { value: 'Bom Conselho', text: 'Bom Conselho' },
    { value: 'Bom Jardim', text: 'Bom Jardim' },
    { value: 'Bonito', text: 'Bonito' },
    { value: 'Brejão', text: 'Brejão' },
    { value: 'Brejinho', text: 'Brejinho' },
    { value: 'Brejo da Madre de Deus', text: 'Brejo da Madre de Deus' },
    { value: 'Buenos Aires', text: 'Buenos Aires' },
    { value: 'Buíque', text: 'Buíque' },
    { value: 'Cabo de Santo Agostinho', text: 'Cabo de Santo Agostinho' },
    { value: 'Cabrobó', text: 'Cabrobó' },
    { value: 'Cachoeirinha', text: 'Cachoeirinha' },
    { value: 'Caetés', text: 'Caetés' },
    { value: 'Calçado', text: 'Calçado' },
    { value: 'Calumbi', text: 'Calumbi' },
    { value: 'Camaragibe', text: 'Camaragibe' },
    { value: 'Camocim de São Félix', text: 'Camocim de São Félix' },
    { value: 'Camutanga', text: 'Camutanga' },
    { value: 'Canhotinho', text: 'Canhotinho' },
    { value: 'Capoeiras', text: 'Capoeiras' },
    { value: 'Carnaíba', text: 'Carnaíba' },
    { value: 'Carnaubeira da Penha', text: 'Carnaubeira da Penha' },
    { value: 'Carpina', text: 'Carpina' },
    { value: 'Caruaru', text: 'Caruaru' },
    { value: 'Casinhas', text: 'Casinhas' },
    { value: 'Catende', text: 'Catende' },
    { value: 'Cedro', text: 'Cedro' },
    { value: 'Chã de Alegria', text: 'Chã de Alegria' },
    { value: 'Chã Grande', text: 'Chã Grande' },
    { value: 'Condado', text: 'Condado' },
    { value: 'Correntes', text: 'Correntes' },
    { value: 'Cortês', text: 'Cortês' },
    { value: 'Cumaru', text: 'Cumaru' },
    { value: 'Cupira', text: 'Cupira' },
    { value: 'Custódia', text: 'Custódia' },
    { value: 'Dormentes', text: 'Dormentes' },
    { value: 'Escada', text: 'Escada' },
    { value: 'Exu', text: 'Exu' },
    { value: 'Feira Nova', text: 'Feira Nova' },
    { value: 'Fernando de Noronha', text: 'Fernando de Noronha' },
    { value: 'Ferreiros', text: 'Ferreiros' },
    { value: 'Flores', text: 'Flores' },
    { value: 'Floresta', text: 'Floresta' },
    { value: 'Frei Miguelinho', text: 'Frei Miguelinho' },
    { value: 'Gameleira', text: 'Gameleira' },
    { value: 'Garanhuns', text: 'Garanhuns' },
    { value: 'Glória do Goitá', text: 'Glória do Goitá' },
    { value: 'Goiana', text: 'Goiana' },
    { value: 'Granito', text: 'Granito' },
    { value: 'Gravatá', text: 'Gravatá' },
    { value: 'Iati', text: 'Iati' },
    { value: 'Ibimirim', text: 'Ibimirim' },
    { value: 'Ibirajuba', text: 'Ibirajuba' },
    { value: 'Igarassu', text: 'Igarassu' },
    { value: 'Iguaraci', text: 'Iguaraci' },
    { value: 'Inajá', text: 'Inajá' },
    { value: 'Ingazeira', text: 'Ingazeira' },
    { value: 'Ipojuca', text: 'Ipojuca' },
    { value: 'Ipubi', text: 'Ipubi' },
    { value: 'Itacuruba', text: 'Itacuruba' },
    { value: 'Itaíba', text: 'Itaíba' },
    { value: 'Itamaracá', text: 'Itamaracá' },
    { value: 'Itambé', text: 'Itambé' },
    { value: 'Itapetim', text: 'Itapetim' },
    { value: 'Itapissuma', text: 'Itapissuma' },
    { value: 'Itaquitinga', text: 'Itaquitinga' },
    { value: 'Jaboatão dos Guararapes', text: 'Jaboatão dos Guararapes' },
    { value: 'Jaqueira', text: 'Jaqueira' },
    { value: 'Jataúba', text: 'Jataúba' },
    { value: 'Jatobá', text: 'Jatobá' },
    { value: 'João Alfredo', text: 'João Alfredo' },
    { value: 'Joaquim Nabuco', text: 'Joaquim Nabuco' },
    { value: 'Jucati', text: 'Jucati' },
    { value: 'Jupi', text: 'Jupi' },
    { value: 'Jurema', text: 'Jurema' },
    { value: 'Lagoa do Carro', text: 'Lagoa do Carro' },
    { value: 'Lagoa do Itaenga', text: 'Lagoa do Itaenga' },
    { value: 'Lagoa do Ouro', text: 'Lagoa do Ouro' },
    { value: 'Lagoa dos Gatos', text: 'Lagoa dos Gatos' },
    { value: 'Lagoa Grande', text: 'Lagoa Grande' },
    { value: 'Lajedo', text: 'Lajedo' },
    { value: 'Limoeiro', text: 'Limoeiro' },
    { value: 'Macaparana', text: 'Macaparana' },
    { value: 'Machados', text: 'Machados' },
    { value: 'Manari', text: 'Manari' },
    { value: 'Maraial', text: 'Maraial' },
    { value: 'Mirandiba', text: 'Mirandiba' },
    { value: 'Moreilândia', text: 'Moreilândia' },
    { value: 'Moreno', text: 'Moreno' },
    { value: 'Nazaré da Mata', text: 'Nazaré da Mata' },
    { value: 'Olinda', text: 'Olinda' },
    { value: 'Orobó', text: 'Orobó' },
    { value: 'Orocó', text: 'Orocó' },
    { value: 'Ouricuri', text: 'Ouricuri' },
    { value: 'Palmares', text: 'Palmares' },
    { value: 'Palmeirina', text: 'Palmeirina' },
    { value: 'Panelas', text: 'Panelas' },
    { value: 'Paranatama', text: 'Paranatama' },
    { value: 'Parnamirim', text: 'Parnamirim' },
    { value: 'Passira', text: 'Passira' },
    { value: 'Paudalho', text: 'Paudalho' },
    { value: 'Paulista', text: 'Paulista' },
    { value: 'Pedra', text: 'Pedra' },
    { value: 'Pesqueira', text: 'Pesqueira' },
    { value: 'Petrolândia', text: 'Petrolândia' },
    { value: 'Petrolina', text: 'Petrolina' },
    { value: 'Poção', text: 'Poção' },
    { value: 'Pombos', text: 'Pombos' },
    { value: 'Primavera', text: 'Primavera' },
    { value: 'Quipapá', text: 'Quipapá' },
    { value: 'Quixaba', text: 'Quixaba' },
    { value: 'Recife', text: 'Recife' },
    { value: 'Riacho das Almas', text: 'Riacho das Almas' },
    { value: 'Ribeirão', text: 'Ribeirão' },
    { value: 'Rio Formoso', text: 'Rio Formoso' },
    { value: 'Sairé', text: 'Sairé' },
    { value: 'Salgadinho', text: 'Salgadinho' },
    { value: 'Salgueiro', text: 'Salgueiro' },
    { value: 'Saloá', text: 'Saloá' },
    { value: 'Sanharó', text: 'Sanharó' },
    { value: 'Santa Cruz', text: 'Santa Cruz' },
    { value: 'Santa Cruz da Baixa Verde', text: 'Santa Cruz da Baixa Verde' },
    { value: 'Santa Cruz do Capibaribe', text: 'Santa Cruz do Capibaribe' },
    { value: 'Santa Filomena', text: 'Santa Filomena' },
    { value: 'Santa Maria da Boa Vista', text: 'Santa Maria da Boa Vista' },
    { value: 'Santa Maria do Cambucá', text: 'Santa Maria do Cambucá' },
    { value: 'Santa Terezinha', text: 'Santa Terezinha' },
    { value: 'São Benedito do Sul', text: 'São Benedito do Sul' },
    { value: 'São Bento do Una', text: 'São Bento do Una' },
    { value: 'São Caitano', text: 'São Caitano' },
    { value: 'São João', text: 'São João' },
    { value: 'São Joaquim do Monte', text: 'São Joaquim do Monte' },
    { value: 'São José da Coroa Grande', text: 'São José da Coroa Grande' },
    { value: 'São José do Belmonte', text: 'São José do Belmonte' },
    { value: 'São José do Egito', text: 'São José do Egito' },
    { value: 'São Lourenço da Mata', text: 'São Lourenço da Mata' },
    { value: 'São Vicente Ferrer', text: 'São Vicente Ferrer' },
    { value: 'Serra Talhada', text: 'Serra Talhada' },
    { value: 'Serrita', text: 'Serrita' },
    { value: 'Sertânia', text: 'Sertânia' },
    { value: 'Sirinhaém', text: 'Sirinhaém' },
    { value: 'Solidão', text: 'Solidão' },
    { value: 'Surubim', text: 'Surubim' },
    { value: 'Tabira', text: 'Tabira' },
    { value: 'Tacaimbó', text: 'Tacaimbó' },
    { value: 'Tacaratu', text: 'Tacaratu' },
    { value: 'Tamandaré', text: 'Tamandaré' },
    { value: 'Taquaritinga do Norte', text: 'Taquaritinga do Norte' },
    { value: 'Terezinha', text: 'Terezinha' },
    { value: 'Terra Nova', text: 'Terra Nova' },
    { value: 'Timbaúba', text: 'Timbaúba' },
    { value: 'Toritama', text: 'Toritama' },
    { value: 'Tracunhaém', text: 'Tracunhaém' },
    { value: 'Trindade', text: 'Trindade' },
    { value: 'Triunfo', text: 'Triunfo' },
    { value: 'Tupanatinga', text: 'Tupanatinga' },
    { value: 'Tuparetama', text: 'Tuparetama' },
    { value: 'Venturosa', text: 'Venturosa' },
    { value: 'Verdejante', text: 'Verdejante' },
    { value: 'Vertente do Lério', text: 'Vertente do Lério' },
    { value: 'Vertentes', text: 'Vertentes' },
    { value: 'Vicência', text: 'Vicência' },
    { value: 'Vitória de Santo Antão', text: 'Vitória de Santo Antão' },
    { value: 'Xexéu', text: 'Xexéu' },
  ],
  PI: [
    { value: 'Acauã', text: 'Acauã' },
    { value: 'Agricolândia', text: 'Agricolândia' },
    { value: 'Água Branca', text: 'Água Branca' },
    { value: 'Alagoinha do Piauí', text: 'Alagoinha do Piauí' },
    { value: 'Alegrete do Piauí', text: 'Alegrete do Piauí' },
    { value: 'Alto Longá', text: 'Alto Longá' },
    { value: 'Altos', text: 'Altos' },
    { value: 'Alvorada do Gurguéia', text: 'Alvorada do Gurguéia' },
    { value: 'Amarante', text: 'Amarante' },
    { value: 'Angical do Piauí', text: 'Angical do Piauí' },
    { value: 'Anísio de Abreu', text: 'Anísio de Abreu' },
    { value: 'Antônio Almeida', text: 'Antônio Almeida' },
    { value: 'Aroazes', text: 'Aroazes' },
    { value: 'Arraial', text: 'Arraial' },
    { value: 'Assunção do Piauí', text: 'Assunção do Piauí' },
    { value: 'Avelino Lopes', text: 'Avelino Lopes' },
    { value: 'Baixa Grande do Ribeiro', text: 'Baixa Grande do Ribeiro' },
    { value: "Barra d'Alcântara", text: "Barra d'Alcântara" },
    { value: 'Barras', text: 'Barras' },
    { value: 'Barreiras do Piauí', text: 'Barreiras do Piauí' },
    { value: 'Barro Duro', text: 'Barro Duro' },
    { value: 'Batalha', text: 'Batalha' },
    { value: 'Bela Vista do Piauí', text: 'Bela Vista do Piauí' },
    { value: 'Belém do Piauí', text: 'Belém do Piauí' },
    { value: 'Beneditinos', text: 'Beneditinos' },
    { value: 'Bertolínia', text: 'Bertolínia' },
    { value: 'Betânia do Piauí', text: 'Betânia do Piauí' },
    { value: 'Boa Hora', text: 'Boa Hora' },
    { value: 'Bocaina', text: 'Bocaina' },
    { value: 'Bom Jesus', text: 'Bom Jesus' },
    { value: 'Bom Princípio do Piauí', text: 'Bom Princípio do Piauí' },
    { value: 'Bonfim do Piauí', text: 'Bonfim do Piauí' },
    { value: 'Boqueirão do Piauí', text: 'Boqueirão do Piauí' },
    { value: 'Brasileira', text: 'Brasileira' },
    { value: 'Brejo do Piauí', text: 'Brejo do Piauí' },
    { value: 'Buriti dos Lopes', text: 'Buriti dos Lopes' },
    { value: 'Buriti dos Montes', text: 'Buriti dos Montes' },
    { value: 'Cabeceiras do Piauí', text: 'Cabeceiras do Piauí' },
    { value: 'Cajazeiras do Piauí', text: 'Cajazeiras do Piauí' },
    { value: 'Cajueiro da Praia', text: 'Cajueiro da Praia' },
    { value: 'Caldeirão Grande do Piauí', text: 'Caldeirão Grande do Piauí' },
    { value: 'Campinas do Piauí', text: 'Campinas do Piauí' },
    { value: 'Campo Alegre do Fidalgo', text: 'Campo Alegre do Fidalgo' },
    { value: 'Campo Grande do Piauí', text: 'Campo Grande do Piauí' },
    { value: 'Campo Largo do Piauí', text: 'Campo Largo do Piauí' },
    { value: 'Campo Maior', text: 'Campo Maior' },
    { value: 'Canavieira', text: 'Canavieira' },
    { value: 'Canto do Buriti', text: 'Canto do Buriti' },
    { value: 'Capitão de Campos', text: 'Capitão de Campos' },
    { value: 'Capitão Gervásio Oliveira', text: 'Capitão Gervásio Oliveira' },
    { value: 'Caracol', text: 'Caracol' },
    { value: 'Caraúbas do Piauí', text: 'Caraúbas do Piauí' },
    { value: 'Caridade do Piauí', text: 'Caridade do Piauí' },
    { value: 'Castelo do Piauí', text: 'Castelo do Piauí' },
    { value: 'Caxingó', text: 'Caxingó' },
    { value: 'Cocal', text: 'Cocal' },
    { value: 'Cocal de Telha', text: 'Cocal de Telha' },
    { value: 'Cocal dos Alves', text: 'Cocal dos Alves' },
    { value: 'Coivaras', text: 'Coivaras' },
    { value: 'Colônia do Gurguéia', text: 'Colônia do Gurguéia' },
    { value: 'Colônia do Piauí', text: 'Colônia do Piauí' },
    { value: 'Conceição do Canindé', text: 'Conceição do Canindé' },
    { value: 'Coronel José Dias', text: 'Coronel José Dias' },
    { value: 'Corrente', text: 'Corrente' },
    { value: 'Cristalândia do Piauí', text: 'Cristalândia do Piauí' },
    { value: 'Cristino Castro', text: 'Cristino Castro' },
    { value: 'Curimatá', text: 'Curimatá' },
    { value: 'Currais', text: 'Currais' },
    { value: 'Curral Novo do Piauí', text: 'Curral Novo do Piauí' },
    { value: 'Curralinhos', text: 'Curralinhos' },
    { value: 'Demerval Lobão', text: 'Demerval Lobão' },
    { value: 'Dirceu Arcoverde', text: 'Dirceu Arcoverde' },
    { value: 'Dom Expedito Lopes', text: 'Dom Expedito Lopes' },
    { value: 'Dom Inocêncio', text: 'Dom Inocêncio' },
    { value: 'Domingos Mourão', text: 'Domingos Mourão' },
    { value: 'Elesbão Veloso', text: 'Elesbão Veloso' },
    { value: 'Eliseu Martins', text: 'Eliseu Martins' },
    { value: 'Esperantina', text: 'Esperantina' },
    { value: 'Fartura do Piauí', text: 'Fartura do Piauí' },
    { value: 'Flores do Piauí', text: 'Flores do Piauí' },
    { value: 'Floresta do Piauí', text: 'Floresta do Piauí' },
    { value: 'Floriano', text: 'Floriano' },
    { value: 'Francinópolis', text: 'Francinópolis' },
    { value: 'Francisco Ayres', text: 'Francisco Ayres' },
    { value: 'Francisco Macedo', text: 'Francisco Macedo' },
    { value: 'Francisco Santos', text: 'Francisco Santos' },
    { value: 'Fronteiras', text: 'Fronteiras' },
    { value: 'Geminiano', text: 'Geminiano' },
    { value: 'Gilbués', text: 'Gilbués' },
    { value: 'Guadalupe', text: 'Guadalupe' },
    { value: 'Guaribas', text: 'Guaribas' },
    { value: 'Hugo Napoleão', text: 'Hugo Napoleão' },
    { value: 'Ilha Grande', text: 'Ilha Grande' },
    { value: 'Inhuma', text: 'Inhuma' },
    { value: 'Ipiranga do Piauí', text: 'Ipiranga do Piauí' },
    { value: 'Isaías Coelho', text: 'Isaías Coelho' },
    { value: 'Itainópolis', text: 'Itainópolis' },
    { value: 'Itaueira', text: 'Itaueira' },
    { value: 'Jacobina do Piauí', text: 'Jacobina do Piauí' },
    { value: 'Jaicós', text: 'Jaicós' },
    { value: 'Jardim do Mulato', text: 'Jardim do Mulato' },
    { value: 'Jatobá do Piauí', text: 'Jatobá do Piauí' },
    { value: 'Jerumenha', text: 'Jerumenha' },
    { value: 'João Costa', text: 'João Costa' },
    { value: 'Joaquim Pires', text: 'Joaquim Pires' },
    { value: 'Joca Marques', text: 'Joca Marques' },
    { value: 'José de Freitas', text: 'José de Freitas' },
    { value: 'Juazeiro do Piauí', text: 'Juazeiro do Piauí' },
    { value: 'Júlio Borges', text: 'Júlio Borges' },
    { value: 'Jurema', text: 'Jurema' },
    { value: 'Lagoa Alegre', text: 'Lagoa Alegre' },
    { value: 'Lagoa de São Francisco', text: 'Lagoa de São Francisco' },
    { value: 'Lagoa do Barro do Piauí', text: 'Lagoa do Barro do Piauí' },
    { value: 'Lagoa do Piauí', text: 'Lagoa do Piauí' },
    { value: 'Lagoa do Sítio', text: 'Lagoa do Sítio' },
    { value: 'Lagoinha do Piauí', text: 'Lagoinha do Piauí' },
    { value: 'Landri Sales', text: 'Landri Sales' },
    { value: 'Luís Correia', text: 'Luís Correia' },
    { value: 'Luzilândia', text: 'Luzilândia' },
    { value: 'Madeiro', text: 'Madeiro' },
    { value: 'Manoel Emídio', text: 'Manoel Emídio' },
    { value: 'Marcolândia', text: 'Marcolândia' },
    { value: 'Marcos Parente', text: 'Marcos Parente' },
    { value: 'Massapê do Piauí', text: 'Massapê do Piauí' },
    { value: 'Matias Olímpio', text: 'Matias Olímpio' },
    { value: 'Miguel Alves', text: 'Miguel Alves' },
    { value: 'Miguel Leão', text: 'Miguel Leão' },
    { value: 'Milton Brandão', text: 'Milton Brandão' },
    { value: 'Monsenhor Gil', text: 'Monsenhor Gil' },
    { value: 'Monsenhor Hipólito', text: 'Monsenhor Hipólito' },
    { value: 'Monte Alegre do Piauí', text: 'Monte Alegre do Piauí' },
    { value: 'Morro Cabeça no Tempo', text: 'Morro Cabeça no Tempo' },
    { value: 'Morro do Chapéu do Piauí', text: 'Morro do Chapéu do Piauí' },
    { value: 'Murici dos Portelas', text: 'Murici dos Portelas' },
    { value: 'Nazaré do Piauí', text: 'Nazaré do Piauí' },
    { value: 'Nossa Senhora de Nazaré', text: 'Nossa Senhora de Nazaré' },
    { value: 'Nossa Senhora dos Remédios', text: 'Nossa Senhora dos Remédios' },
    { value: 'Nova Santa Rita', text: 'Nova Santa Rita' },
    { value: 'Novo Oriente do Piauí', text: 'Novo Oriente do Piauí' },
    { value: 'Novo Santo Antônio', text: 'Novo Santo Antônio' },
    { value: 'Oeiras', text: 'Oeiras' },
    { value: "Olho d'Água do Piauí", text: "Olho d'Água do Piauí" },
    { value: 'Padre Marcos', text: 'Padre Marcos' },
    { value: 'Paes Landim', text: 'Paes Landim' },
    { value: 'Pajeú do Piauí', text: 'Pajeú do Piauí' },
    { value: 'Palmeira do Piauí', text: 'Palmeira do Piauí' },
    { value: 'Palmeirais', text: 'Palmeirais' },
    { value: 'Paquetá', text: 'Paquetá' },
    { value: 'Parnaguá', text: 'Parnaguá' },
    { value: 'Parnaíba', text: 'Parnaíba' },
    { value: 'Passagem Franca do Piauí', text: 'Passagem Franca do Piauí' },
    { value: 'Patos do Piauí', text: 'Patos do Piauí' },
    { value: "Pau d'Arco do Piauí", text: "Pau d'Arco do Piauí" },
    { value: 'Paulistana', text: 'Paulistana' },
    { value: 'Pavussu', text: 'Pavussu' },
    { value: 'Pedro II', text: 'Pedro II' },
    { value: 'Pedro Laurentino', text: 'Pedro Laurentino' },
    { value: 'Picos', text: 'Picos' },
    { value: 'Pimenteiras', text: 'Pimenteiras' },
    { value: 'Pio IX', text: 'Pio IX' },
    { value: 'Piracuruca', text: 'Piracuruca' },
    { value: 'Piripiri', text: 'Piripiri' },
    { value: 'Porto', text: 'Porto' },
    { value: 'Porto Alegre do Piauí', text: 'Porto Alegre do Piauí' },
    { value: 'Prata do Piauí', text: 'Prata do Piauí' },
    { value: 'Queimada Nova', text: 'Queimada Nova' },
    { value: 'Redenção do Gurguéia', text: 'Redenção do Gurguéia' },
    { value: 'Regeneração', text: 'Regeneração' },
    { value: 'Riacho Frio', text: 'Riacho Frio' },
    { value: 'Ribeira do Piauí', text: 'Ribeira do Piauí' },
    { value: 'Ribeiro Gonçalves', text: 'Ribeiro Gonçalves' },
    { value: 'Rio Grande do Piauí', text: 'Rio Grande do Piauí' },
    { value: 'Santa Cruz do Piauí', text: 'Santa Cruz do Piauí' },
    { value: 'Santa Cruz dos Milagres', text: 'Santa Cruz dos Milagres' },
    { value: 'Santa Filomena', text: 'Santa Filomena' },
    { value: 'Santa Luz', text: 'Santa Luz' },
    { value: 'Santa Rosa do Piauí', text: 'Santa Rosa do Piauí' },
    { value: 'Santana do Piauí', text: 'Santana do Piauí' },
    { value: 'Santo Antônio de Lisboa', text: 'Santo Antônio de Lisboa' },
    { value: 'Santo Antônio dos Milagres', text: 'Santo Antônio dos Milagres' },
    { value: 'Santo Inácio do Piauí', text: 'Santo Inácio do Piauí' },
    { value: 'São Braz do Piauí', text: 'São Braz do Piauí' },
    { value: 'São Félix do Piauí', text: 'São Félix do Piauí' },
    {
      value: 'São Francisco de Assis do Piauí',
      text: 'São Francisco de Assis do Piauí',
    },
    { value: 'São Francisco do Piauí', text: 'São Francisco do Piauí' },
    { value: 'São Gonçalo do Gurguéia', text: 'São Gonçalo do Gurguéia' },
    { value: 'São Gonçalo do Piauí', text: 'São Gonçalo do Piauí' },
    { value: 'São João da Canabrava', text: 'São João da Canabrava' },
    { value: 'São João da Fronteira', text: 'São João da Fronteira' },
    { value: 'São João da Serra', text: 'São João da Serra' },
    { value: 'São João da Varjota', text: 'São João da Varjota' },
    { value: 'São João do Arraial', text: 'São João do Arraial' },
    { value: 'São João do Piauí', text: 'São João do Piauí' },
    { value: 'São José do Divino', text: 'São José do Divino' },
    { value: 'São José do Peixe', text: 'São José do Peixe' },
    { value: 'São José do Piauí', text: 'São José do Piauí' },
    { value: 'São Julião', text: 'São Julião' },
    { value: 'São Lourenço do Piauí', text: 'São Lourenço do Piauí' },
    { value: 'São Luis do Piauí', text: 'São Luis do Piauí' },
    { value: 'São Miguel da Baixa Grande', text: 'São Miguel da Baixa Grande' },
    { value: 'São Miguel do Fidalgo', text: 'São Miguel do Fidalgo' },
    { value: 'São Miguel do Tapuio', text: 'São Miguel do Tapuio' },
    { value: 'São Pedro do Piauí', text: 'São Pedro do Piauí' },
    { value: 'São Raimundo Nonato', text: 'São Raimundo Nonato' },
    { value: 'Sebastião Barros', text: 'Sebastião Barros' },
    { value: 'Sebastião Leal', text: 'Sebastião Leal' },
    { value: 'Sigefredo Pacheco', text: 'Sigefredo Pacheco' },
    { value: 'Simões', text: 'Simões' },
    { value: 'Simplício Mendes', text: 'Simplício Mendes' },
    { value: 'Socorro do Piauí', text: 'Socorro do Piauí' },
    { value: 'Sussuapara', text: 'Sussuapara' },
    { value: 'Tamboril do Piauí', text: 'Tamboril do Piauí' },
    { value: 'Tanque do Piauí', text: 'Tanque do Piauí' },
    { value: 'Teresina', text: 'Teresina' },
    { value: 'União', text: 'União' },
    { value: 'Uruçuí', text: 'Uruçuí' },
    { value: 'Valença do Piauí', text: 'Valença do Piauí' },
    { value: 'Várzea Branca', text: 'Várzea Branca' },
    { value: 'Várzea Grande', text: 'Várzea Grande' },
    { value: 'Vera Mendes', text: 'Vera Mendes' },
    { value: 'Vila Nova do Piauí', text: 'Vila Nova do Piauí' },
    { value: 'Wall Ferraz', text: 'Wall Ferraz' },
  ],
  PR: [
    { value: 'Abatiá', text: 'Abatiá' },
    { value: 'Adrianópolis', text: 'Adrianópolis' },
    { value: 'Agudos do Sul', text: 'Agudos do Sul' },
    { value: 'Almirante Tamandaré', text: 'Almirante Tamandaré' },
    { value: 'Altamira do Paraná', text: 'Altamira do Paraná' },
    { value: 'Alto Paraná', text: 'Alto Paraná' },
    { value: 'Alto Piquiri', text: 'Alto Piquiri' },
    { value: 'Altônia', text: 'Altônia' },
    { value: 'Alvorada do Sul', text: 'Alvorada do Sul' },
    { value: 'Amaporã', text: 'Amaporã' },
    { value: 'Ampére', text: 'Ampére' },
    { value: 'Anahy', text: 'Anahy' },
    { value: 'Andirá', text: 'Andirá' },
    { value: 'Ângulo', text: 'Ângulo' },
    { value: 'Antonina', text: 'Antonina' },
    { value: 'Antônio Olinto', text: 'Antônio Olinto' },
    { value: 'Apucarana', text: 'Apucarana' },
    { value: 'Arapongas', text: 'Arapongas' },
    { value: 'Arapoti', text: 'Arapoti' },
    { value: 'Arapuã', text: 'Arapuã' },
    { value: 'Araruna', text: 'Araruna' },
    { value: 'Araucária', text: 'Araucária' },
    { value: 'Ariranha do Ivaí', text: 'Ariranha do Ivaí' },
    { value: 'Assaí', text: 'Assaí' },
    { value: 'Assis Chateaubriand', text: 'Assis Chateaubriand' },
    { value: 'Astorga', text: 'Astorga' },
    { value: 'Atalaia', text: 'Atalaia' },
    { value: 'Balsa Nova', text: 'Balsa Nova' },
    { value: 'Bandeirantes', text: 'Bandeirantes' },
    { value: 'Barbosa Ferraz', text: 'Barbosa Ferraz' },
    { value: 'Barra do Jacaré', text: 'Barra do Jacaré' },
    { value: 'Barracão', text: 'Barracão' },
    { value: 'Bela Vista da Caroba', text: 'Bela Vista da Caroba' },
    { value: 'Bela Vista do Paraíso', text: 'Bela Vista do Paraíso' },
    { value: 'Bituruna', text: 'Bituruna' },
    { value: 'Boa Esperança', text: 'Boa Esperança' },
    { value: 'Boa Esperança do Iguaçu', text: 'Boa Esperança do Iguaçu' },
    { value: 'Boa Ventura de São Roque', text: 'Boa Ventura de São Roque' },
    { value: 'Boa Vista da Aparecida', text: 'Boa Vista da Aparecida' },
    { value: 'Bocaiúva do Sul', text: 'Bocaiúva do Sul' },
    { value: 'Bom Jesus do Sul', text: 'Bom Jesus do Sul' },
    { value: 'Bom Sucesso', text: 'Bom Sucesso' },
    { value: 'Bom Sucesso do Sul', text: 'Bom Sucesso do Sul' },
    { value: 'Borrazópolis', text: 'Borrazópolis' },
    { value: 'Braganey', text: 'Braganey' },
    { value: 'Brasilândia do Sul', text: 'Brasilândia do Sul' },
    { value: 'Cafeara', text: 'Cafeara' },
    { value: 'Cafelândia', text: 'Cafelândia' },
    { value: 'Cafezal do Sul', text: 'Cafezal do Sul' },
    { value: 'Califórnia', text: 'Califórnia' },
    { value: 'Cambará', text: 'Cambará' },
    { value: 'Cambé', text: 'Cambé' },
    { value: 'Cambira', text: 'Cambira' },
    { value: 'Campina da Lagoa', text: 'Campina da Lagoa' },
    { value: 'Campina do Simão', text: 'Campina do Simão' },
    { value: 'Campina Grande do Sul', text: 'Campina Grande do Sul' },
    { value: 'Campo Bonito', text: 'Campo Bonito' },
    { value: 'Campo do Tenente', text: 'Campo do Tenente' },
    { value: 'Campo Largo', text: 'Campo Largo' },
    { value: 'Campo Magro', text: 'Campo Magro' },
    { value: 'Campo Mourão', text: 'Campo Mourão' },
    { value: 'Cândido de Abreu', text: 'Cândido de Abreu' },
    { value: 'Candói', text: 'Candói' },
    { value: 'Cantagalo', text: 'Cantagalo' },
    { value: 'Capanema', text: 'Capanema' },
    { value: 'Capitão Leônidas Marques', text: 'Capitão Leônidas Marques' },
    { value: 'Carambeí', text: 'Carambeí' },
    { value: 'Carlópolis', text: 'Carlópolis' },
    { value: 'Cascavel', text: 'Cascavel' },
    { value: 'Castro', text: 'Castro' },
    { value: 'Catanduvas', text: 'Catanduvas' },
    { value: 'Centenário do Sul', text: 'Centenário do Sul' },
    { value: 'Cerro Azul', text: 'Cerro Azul' },
    { value: 'Céu Azul', text: 'Céu Azul' },
    { value: 'Chopinzinho', text: 'Chopinzinho' },
    { value: 'Cianorte', text: 'Cianorte' },
    { value: 'Cidade Gaúcha', text: 'Cidade Gaúcha' },
    { value: 'Clevelândia', text: 'Clevelândia' },
    { value: 'Colombo', text: 'Colombo' },
    { value: 'Colorado', text: 'Colorado' },
    { value: 'Congonhinhas', text: 'Congonhinhas' },
    { value: 'Conselheiro Mairinck', text: 'Conselheiro Mairinck' },
    { value: 'Contenda', text: 'Contenda' },
    { value: 'Corbélia', text: 'Corbélia' },
    { value: 'Cornélio Procópio', text: 'Cornélio Procópio' },
    { value: 'Coronel Domingos Soares', text: 'Coronel Domingos Soares' },
    { value: 'Coronel Vivida', text: 'Coronel Vivida' },
    { value: 'Corumbataí do Sul', text: 'Corumbataí do Sul' },
    { value: 'Cruz Machado', text: 'Cruz Machado' },
    { value: 'Cruzeiro do Iguaçu', text: 'Cruzeiro do Iguaçu' },
    { value: 'Cruzeiro do Oeste', text: 'Cruzeiro do Oeste' },
    { value: 'Cruzeiro do Sul', text: 'Cruzeiro do Sul' },
    { value: 'Cruzmaltina', text: 'Cruzmaltina' },
    { value: 'Curitiba', text: 'Curitiba' },
    { value: 'Curiúva', text: 'Curiúva' },
    { value: "Diamante d'Oeste", text: "Diamante d'Oeste" },
    { value: 'Diamante do Norte', text: 'Diamante do Norte' },
    { value: 'Diamante do Sul', text: 'Diamante do Sul' },
    { value: 'Dois Vizinhos', text: 'Dois Vizinhos' },
    { value: 'Douradina', text: 'Douradina' },
    { value: 'Doutor Camargo', text: 'Doutor Camargo' },
    { value: 'Doutor Ulysses', text: 'Doutor Ulysses' },
    { value: 'Enéas Marques', text: 'Enéas Marques' },
    { value: 'Engenheiro Beltrão', text: 'Engenheiro Beltrão' },
    { value: 'Entre Rios do Oeste', text: 'Entre Rios do Oeste' },
    { value: 'Esperança Nova', text: 'Esperança Nova' },
    { value: 'Espigão Alto do Iguaçu', text: 'Espigão Alto do Iguaçu' },
    { value: 'Farol', text: 'Farol' },
    { value: 'Faxinal', text: 'Faxinal' },
    { value: 'Fazenda Rio Grande', text: 'Fazenda Rio Grande' },
    { value: 'Fênix', text: 'Fênix' },
    { value: 'Fernandes Pinheiro', text: 'Fernandes Pinheiro' },
    { value: 'Figueira', text: 'Figueira' },
    { value: 'Flor da Serra do Sul', text: 'Flor da Serra do Sul' },
    { value: 'Floraí', text: 'Floraí' },
    { value: 'Floresta', text: 'Floresta' },
    { value: 'Florestópolis', text: 'Florestópolis' },
    { value: 'Flórida', text: 'Flórida' },
    { value: 'Formosa do Oeste', text: 'Formosa do Oeste' },
    { value: 'Foz do Iguaçu', text: 'Foz do Iguaçu' },
    { value: 'Foz do Jordão', text: 'Foz do Jordão' },
    { value: 'Francisco Alves', text: 'Francisco Alves' },
    { value: 'Francisco Beltrão', text: 'Francisco Beltrão' },
    { value: 'General Carneiro', text: 'General Carneiro' },
    { value: 'Godoy Moreira', text: 'Godoy Moreira' },
    { value: 'Goioerê', text: 'Goioerê' },
    { value: 'Goioxim', text: 'Goioxim' },
    { value: 'Grandes Rios', text: 'Grandes Rios' },
    { value: 'Guaíra', text: 'Guaíra' },
    { value: 'Guairaçá', text: 'Guairaçá' },
    { value: 'Guamiranga', text: 'Guamiranga' },
    { value: 'Guapirama', text: 'Guapirama' },
    { value: 'Guaporema', text: 'Guaporema' },
    { value: 'Guaraci', text: 'Guaraci' },
    { value: 'Guaraniaçu', text: 'Guaraniaçu' },
    { value: 'Guarapuava', text: 'Guarapuava' },
    { value: 'Guaraqueçaba', text: 'Guaraqueçaba' },
    { value: 'Guaratuba', text: 'Guaratuba' },
    { value: 'Honório Serpa', text: 'Honório Serpa' },
    { value: 'Ibaiti', text: 'Ibaiti' },
    { value: 'Ibema', text: 'Ibema' },
    { value: 'Ibiporã', text: 'Ibiporã' },
    { value: 'Icaraíma', text: 'Icaraíma' },
    { value: 'Iguaraçu', text: 'Iguaraçu' },
    { value: 'Iguatu', text: 'Iguatu' },
    { value: 'Imbaú', text: 'Imbaú' },
    { value: 'Imbituva', text: 'Imbituva' },
    { value: 'Inácio Martins', text: 'Inácio Martins' },
    { value: 'Inajá', text: 'Inajá' },
    { value: 'Indianópolis', text: 'Indianópolis' },
    { value: 'Ipiranga', text: 'Ipiranga' },
    { value: 'Iporã', text: 'Iporã' },
    { value: 'Iracema do Oeste', text: 'Iracema do Oeste' },
    { value: 'Irati', text: 'Irati' },
    { value: 'Iretama', text: 'Iretama' },
    { value: 'Itaguajé', text: 'Itaguajé' },
    { value: 'Itaipulândia', text: 'Itaipulândia' },
    { value: 'Itambaracá', text: 'Itambaracá' },
    { value: 'Itambé', text: 'Itambé' },
    { value: "Itapejara d'Oeste", text: "Itapejara d'Oeste" },
    { value: 'Itaperuçu', text: 'Itaperuçu' },
    { value: 'Itaúna do Sul', text: 'Itaúna do Sul' },
    { value: 'Ivaí', text: 'Ivaí' },
    { value: 'Ivaiporã', text: 'Ivaiporã' },
    { value: 'Ivaté', text: 'Ivaté' },
    { value: 'Ivatuba', text: 'Ivatuba' },
    { value: 'Jaboti', text: 'Jaboti' },
    { value: 'Jacarezinho', text: 'Jacarezinho' },
    { value: 'Jaguapitã', text: 'Jaguapitã' },
    { value: 'Jaguariaíva', text: 'Jaguariaíva' },
    { value: 'Jandaia do Sul', text: 'Jandaia do Sul' },
    { value: 'Janiópolis', text: 'Janiópolis' },
    { value: 'Japira', text: 'Japira' },
    { value: 'Japurá', text: 'Japurá' },
    { value: 'Jardim Alegre', text: 'Jardim Alegre' },
    { value: 'Jardim Olinda', text: 'Jardim Olinda' },
    { value: 'Jataizinho', text: 'Jataizinho' },
    { value: 'Jesuítas', text: 'Jesuítas' },
    { value: 'Joaquim Távora', text: 'Joaquim Távora' },
    { value: 'Jundiaí do Sul', text: 'Jundiaí do Sul' },
    { value: 'Juranda', text: 'Juranda' },
    { value: 'Jussara', text: 'Jussara' },
    { value: 'Kaloré', text: 'Kaloré' },
    { value: 'Lapa', text: 'Lapa' },
    { value: 'Laranjal', text: 'Laranjal' },
    { value: 'Laranjeiras do Sul', text: 'Laranjeiras do Sul' },
    { value: 'Leópolis', text: 'Leópolis' },
    { value: 'Lidianópolis', text: 'Lidianópolis' },
    { value: 'Lindoeste', text: 'Lindoeste' },
    { value: 'Loanda', text: 'Loanda' },
    { value: 'Lobato', text: 'Lobato' },
    { value: 'Londrina', text: 'Londrina' },
    { value: 'Luiziana', text: 'Luiziana' },
    { value: 'Lunardelli', text: 'Lunardelli' },
    { value: 'Lupionópolis', text: 'Lupionópolis' },
    { value: 'Mallet', text: 'Mallet' },
    { value: 'Mamborê', text: 'Mamborê' },
    { value: 'Mandaguaçu', text: 'Mandaguaçu' },
    { value: 'Mandaguari', text: 'Mandaguari' },
    { value: 'Mandirituba', text: 'Mandirituba' },
    { value: 'Manfrinópolis', text: 'Manfrinópolis' },
    { value: 'Mangueirinha', text: 'Mangueirinha' },
    { value: 'Manoel Ribas', text: 'Manoel Ribas' },
    { value: 'Marechal Cândido Rondon', text: 'Marechal Cândido Rondon' },
    { value: 'Maria Helena', text: 'Maria Helena' },
    { value: 'Marialva', text: 'Marialva' },
    { value: 'Marilândia do Sul', text: 'Marilândia do Sul' },
    { value: 'Marilena', text: 'Marilena' },
    { value: 'Mariluz', text: 'Mariluz' },
    { value: 'Maringá', text: 'Maringá' },
    { value: 'Mariópolis', text: 'Mariópolis' },
    { value: 'Maripá', text: 'Maripá' },
    { value: 'Marmeleiro', text: 'Marmeleiro' },
    { value: 'Marquinho', text: 'Marquinho' },
    { value: 'Marumbi', text: 'Marumbi' },
    { value: 'Matelândia', text: 'Matelândia' },
    { value: 'Matinhos', text: 'Matinhos' },
    { value: 'Mato Rico', text: 'Mato Rico' },
    { value: 'Mauá da Serra', text: 'Mauá da Serra' },
    { value: 'Medianeira', text: 'Medianeira' },
    { value: 'Mercedes', text: 'Mercedes' },
    { value: 'Mirador', text: 'Mirador' },
    { value: 'Miraselva', text: 'Miraselva' },
    { value: 'Missal', text: 'Missal' },
    { value: 'Moreira Sales', text: 'Moreira Sales' },
    { value: 'Morretes', text: 'Morretes' },
    { value: 'Munhoz de Melo', text: 'Munhoz de Melo' },
    { value: 'Nossa Senhora das Graças', text: 'Nossa Senhora das Graças' },
    { value: 'Nova Aliança do Ivaí', text: 'Nova Aliança do Ivaí' },
    { value: 'Nova América da Colina', text: 'Nova América da Colina' },
    { value: 'Nova Aurora', text: 'Nova Aurora' },
    { value: 'Nova Cantu', text: 'Nova Cantu' },
    { value: 'Nova Esperança', text: 'Nova Esperança' },
    { value: 'Nova Esperança do Sudoeste', text: 'Nova Esperança do Sudoeste' },
    { value: 'Nova Fátima', text: 'Nova Fátima' },
    { value: 'Nova Laranjeiras', text: 'Nova Laranjeiras' },
    { value: 'Nova Londrina', text: 'Nova Londrina' },
    { value: 'Nova Olímpia', text: 'Nova Olímpia' },
    { value: 'Nova Prata do Iguaçu', text: 'Nova Prata do Iguaçu' },
    { value: 'Nova Santa Bárbara', text: 'Nova Santa Bárbara' },
    { value: 'Nova Santa Rosa', text: 'Nova Santa Rosa' },
    { value: 'Nova Tebas', text: 'Nova Tebas' },
    { value: 'Novo Itacolomi', text: 'Novo Itacolomi' },
    { value: 'Ortigueira', text: 'Ortigueira' },
    { value: 'Ourizona', text: 'Ourizona' },
    { value: 'Ouro Verde do Oeste', text: 'Ouro Verde do Oeste' },
    { value: 'Paiçandu', text: 'Paiçandu' },
    { value: 'Palmas', text: 'Palmas' },
    { value: 'Palmeira', text: 'Palmeira' },
    { value: 'Palmital', text: 'Palmital' },
    { value: 'Palotina', text: 'Palotina' },
    { value: 'Paraíso do Norte', text: 'Paraíso do Norte' },
    { value: 'Paranacity', text: 'Paranacity' },
    { value: 'Paranaguá', text: 'Paranaguá' },
    { value: 'Paranapoema', text: 'Paranapoema' },
    { value: 'Paranavaí', text: 'Paranavaí' },
    { value: 'Pato Bragado', text: 'Pato Bragado' },
    { value: 'Pato Branco', text: 'Pato Branco' },
    { value: 'Paula Freitas', text: 'Paula Freitas' },
    { value: 'Paulo Frontin', text: 'Paulo Frontin' },
    { value: 'Peabiru', text: 'Peabiru' },
    { value: 'Perobal', text: 'Perobal' },
    { value: 'Pérola', text: 'Pérola' },
    { value: "Pérola d'Oeste", text: "Pérola d'Oeste" },
    { value: 'Piên', text: 'Piên' },
    { value: 'Pinhais', text: 'Pinhais' },
    { value: 'Pinhal de São Bento', text: 'Pinhal de São Bento' },
    { value: 'Pinhalão', text: 'Pinhalão' },
    { value: 'Pinhão', text: 'Pinhão' },
    { value: 'Piraí do Sul', text: 'Piraí do Sul' },
    { value: 'Piraquara', text: 'Piraquara' },
    { value: 'Pitanga', text: 'Pitanga' },
    { value: 'Pitangueiras', text: 'Pitangueiras' },
    { value: 'Planaltina do Paraná', text: 'Planaltina do Paraná' },
    { value: 'Planalto', text: 'Planalto' },
    { value: 'Ponta Grossa', text: 'Ponta Grossa' },
    { value: 'Pontal do Paraná', text: 'Pontal do Paraná' },
    { value: 'Porecatu', text: 'Porecatu' },
    { value: 'Porto Amazonas', text: 'Porto Amazonas' },
    { value: 'Porto Barreiro', text: 'Porto Barreiro' },
    { value: 'Porto Rico', text: 'Porto Rico' },
    { value: 'Porto Vitória', text: 'Porto Vitória' },
    { value: 'Prado Ferreira', text: 'Prado Ferreira' },
    { value: 'Pranchita', text: 'Pranchita' },
    { value: 'Presidente Castelo Branco', text: 'Presidente Castelo Branco' },
    { value: 'Primeiro de Maio', text: 'Primeiro de Maio' },
    { value: 'Prudentópolis', text: 'Prudentópolis' },
    { value: 'Quarto Centenário', text: 'Quarto Centenário' },
    { value: 'Quatiguá', text: 'Quatiguá' },
    { value: 'Quatro Barras', text: 'Quatro Barras' },
    { value: 'Quatro Pontes', text: 'Quatro Pontes' },
    { value: 'Quedas do Iguaçu', text: 'Quedas do Iguaçu' },
    { value: 'Querência do Norte', text: 'Querência do Norte' },
    { value: 'Quinta do Sol', text: 'Quinta do Sol' },
    { value: 'Quitandinha', text: 'Quitandinha' },
    { value: 'Ramilândia', text: 'Ramilândia' },
    { value: 'Rancho Alegre', text: 'Rancho Alegre' },
    { value: "Rancho Alegre d'Oeste", text: "Rancho Alegre d'Oeste" },
    { value: 'Realeza', text: 'Realeza' },
    { value: 'Rebouças', text: 'Rebouças' },
    { value: 'Renascença', text: 'Renascença' },
    { value: 'Reserva', text: 'Reserva' },
    { value: 'Reserva do Iguaçu', text: 'Reserva do Iguaçu' },
    { value: 'Ribeirão Claro', text: 'Ribeirão Claro' },
    { value: 'Ribeirão do Pinhal', text: 'Ribeirão do Pinhal' },
    { value: 'Rio Azul', text: 'Rio Azul' },
    { value: 'Rio Bom', text: 'Rio Bom' },
    { value: 'Rio Bonito do Iguaçu', text: 'Rio Bonito do Iguaçu' },
    { value: 'Rio Branco do Ivaí', text: 'Rio Branco do Ivaí' },
    { value: 'Rio Branco do Sul', text: 'Rio Branco do Sul' },
    { value: 'Rio Negro', text: 'Rio Negro' },
    { value: 'Rolândia', text: 'Rolândia' },
    { value: 'Roncador', text: 'Roncador' },
    { value: 'Rondon', text: 'Rondon' },
    { value: 'Rosário do Ivaí', text: 'Rosário do Ivaí' },
    { value: 'Sabáudia', text: 'Sabáudia' },
    { value: 'Salgado Filho', text: 'Salgado Filho' },
    { value: 'Salto do Itararé', text: 'Salto do Itararé' },
    { value: 'Salto do Lontra', text: 'Salto do Lontra' },
    { value: 'Santa Amélia', text: 'Santa Amélia' },
    { value: 'Santa Cecília do Pavão', text: 'Santa Cecília do Pavão' },
    { value: 'Santa Cruz Monte Castelo', text: 'Santa Cruz Monte Castelo' },
    { value: 'Santa Fé', text: 'Santa Fé' },
    { value: 'Santa Helena', text: 'Santa Helena' },
    { value: 'Santa Inês', text: 'Santa Inês' },
    { value: 'Santa Isabel do Ivaí', text: 'Santa Isabel do Ivaí' },
    { value: 'Santa Izabel do Oeste', text: 'Santa Izabel do Oeste' },
    { value: 'Santa Lúcia', text: 'Santa Lúcia' },
    { value: 'Santa Maria do Oeste', text: 'Santa Maria do Oeste' },
    { value: 'Santa Mariana', text: 'Santa Mariana' },
    { value: 'Santa Mônica', text: 'Santa Mônica' },
    { value: 'Santa Tereza do Oeste', text: 'Santa Tereza do Oeste' },
    { value: 'Santa Terezinha de Itaipu', text: 'Santa Terezinha de Itaipu' },
    { value: 'Santana do Itararé', text: 'Santana do Itararé' },
    { value: 'Santo Antônio da Platina', text: 'Santo Antônio da Platina' },
    { value: 'Santo Antônio do Caiuá', text: 'Santo Antônio do Caiuá' },
    { value: 'Santo Antônio do Paraíso', text: 'Santo Antônio do Paraíso' },
    { value: 'Santo Antônio do Sudoeste', text: 'Santo Antônio do Sudoeste' },
    { value: 'Santo Inácio', text: 'Santo Inácio' },
    { value: 'São Carlos do Ivaí', text: 'São Carlos do Ivaí' },
    { value: 'São Jerônimo da Serra', text: 'São Jerônimo da Serra' },
    { value: 'São João', text: 'São João' },
    { value: 'São João do Caiuá', text: 'São João do Caiuá' },
    { value: 'São João do Ivaí', text: 'São João do Ivaí' },
    { value: 'São João do Triunfo', text: 'São João do Triunfo' },
    { value: "São Jorge d'Oeste", text: "São Jorge d'Oeste" },
    { value: 'São Jorge do Ivaí', text: 'São Jorge do Ivaí' },
    { value: 'São Jorge do Patrocínio', text: 'São Jorge do Patrocínio' },
    { value: 'São José da Boa Vista', text: 'São José da Boa Vista' },
    { value: 'São José das Palmeiras', text: 'São José das Palmeiras' },
    { value: 'São José dos Pinhais', text: 'São José dos Pinhais' },
    { value: 'São Manoel do Paraná', text: 'São Manoel do Paraná' },
    { value: 'São Mateus do Sul', text: 'São Mateus do Sul' },
    { value: 'São Miguel do Iguaçu', text: 'São Miguel do Iguaçu' },
    { value: 'São Pedro do Iguaçu', text: 'São Pedro do Iguaçu' },
    { value: 'São Pedro do Ivaí', text: 'São Pedro do Ivaí' },
    { value: 'São Pedro do Paraná', text: 'São Pedro do Paraná' },
    { value: 'São Sebastião da Amoreira', text: 'São Sebastião da Amoreira' },
    { value: 'São Tomé', text: 'São Tomé' },
    { value: 'Sapopema', text: 'Sapopema' },
    { value: 'Sarandi', text: 'Sarandi' },
    { value: 'Saudade do Iguaçu', text: 'Saudade do Iguaçu' },
    { value: 'Sengés', text: 'Sengés' },
    { value: 'Serranópolis do Iguaçu', text: 'Serranópolis do Iguaçu' },
    { value: 'Sertaneja', text: 'Sertaneja' },
    { value: 'Sertanópolis', text: 'Sertanópolis' },
    { value: 'Siqueira Campos', text: 'Siqueira Campos' },
    { value: 'Sulina', text: 'Sulina' },
    { value: 'Tamarana', text: 'Tamarana' },
    { value: 'Tamboara', text: 'Tamboara' },
    { value: 'Tapejara', text: 'Tapejara' },
    { value: 'Tapira', text: 'Tapira' },
    { value: 'Teixeira Soares', text: 'Teixeira Soares' },
    { value: 'Telêmaco Borba', text: 'Telêmaco Borba' },
    { value: 'Terra Boa', text: 'Terra Boa' },
    { value: 'Terra Rica', text: 'Terra Rica' },
    { value: 'Terra Roxa', text: 'Terra Roxa' },
    { value: 'Tibagi', text: 'Tibagi' },
    { value: 'Tijucas do Sul', text: 'Tijucas do Sul' },
    { value: 'Toledo', text: 'Toledo' },
    { value: 'Tomazina', text: 'Tomazina' },
    { value: 'Três Barras do Paraná', text: 'Três Barras do Paraná' },
    { value: 'Tunas do Paraná', text: 'Tunas do Paraná' },
    { value: 'Tuneiras do Oeste', text: 'Tuneiras do Oeste' },
    { value: 'Tupãssi', text: 'Tupãssi' },
    { value: 'Turvo', text: 'Turvo' },
    { value: 'Ubiratã', text: 'Ubiratã' },
    { value: 'Umuarama', text: 'Umuarama' },
    { value: 'União da Vitória', text: 'União da Vitória' },
    { value: 'Uniflor', text: 'Uniflor' },
    { value: 'Uraí', text: 'Uraí' },
    { value: 'Ventania', text: 'Ventania' },
    { value: 'Vera Cruz do Oeste', text: 'Vera Cruz do Oeste' },
    { value: 'Verê', text: 'Verê' },
    { value: 'Vila Alta', text: 'Vila Alta' },
    { value: 'Virmond', text: 'Virmond' },
    { value: 'Vitorino', text: 'Vitorino' },
    { value: 'Wenceslau Braz', text: 'Wenceslau Braz' },
    { value: 'Xambrê', text: 'Xambrê' },
  ],
  RJ: [
    { value: 'Angra dos Reis', text: 'Angra dos Reis' },
    { value: 'Aperibé', text: 'Aperibé' },
    { value: 'Araruama', text: 'Araruama' },
    { value: 'Areal', text: 'Areal' },
    { value: 'Armação de Búzios', text: 'Armação de Búzios' },
    { value: 'Arraial do Cabo', text: 'Arraial do Cabo' },
    { value: 'Barra do Piraí', text: 'Barra do Piraí' },
    { value: 'Barra Mansa', text: 'Barra Mansa' },
    { value: 'Belford Roxo', text: 'Belford Roxo' },
    { value: 'Bom Jardim', text: 'Bom Jardim' },
    { value: 'Bom Jesus do Itabapoana', text: 'Bom Jesus do Itabapoana' },
    { value: 'Cabo Frio', text: 'Cabo Frio' },
    { value: 'Cachoeiras de Macacu', text: 'Cachoeiras de Macacu' },
    { value: 'Cambuci', text: 'Cambuci' },
    { value: 'Campos dos Goytacazes', text: 'Campos dos Goytacazes' },
    { value: 'Cantagalo', text: 'Cantagalo' },
    { value: 'Carapebus', text: 'Carapebus' },
    { value: 'Cardoso Moreira', text: 'Cardoso Moreira' },
    { value: 'Carmo', text: 'Carmo' },
    { value: 'Casimiro de Abreu', text: 'Casimiro de Abreu' },
    { value: 'Comendador Levy Gasparian', text: 'Comendador Levy Gasparian' },
    { value: 'Conceição de Macabu', text: 'Conceição de Macabu' },
    { value: 'Cordeiro', text: 'Cordeiro' },
    { value: 'Duas Barras', text: 'Duas Barras' },
    { value: 'Duque de Caxias', text: 'Duque de Caxias' },
    {
      value: 'Engenheiro Paulo de Frontin',
      text: 'Engenheiro Paulo de Frontin',
    },
    { value: 'Guapimirim', text: 'Guapimirim' },
    { value: 'Iguaba Grande', text: 'Iguaba Grande' },
    { value: 'Itaboraí', text: 'Itaboraí' },
    { value: 'Itaguaí', text: 'Itaguaí' },
    { value: 'Italva', text: 'Italva' },
    { value: 'Itaocara', text: 'Itaocara' },
    { value: 'Itaperuna', text: 'Itaperuna' },
    { value: 'Itatiaia', text: 'Itatiaia' },
    { value: 'Japeri', text: 'Japeri' },
    { value: 'Laje do Muriaé', text: 'Laje do Muriaé' },
    { value: 'Macaé', text: 'Macaé' },
    { value: 'Macuco', text: 'Macuco' },
    { value: 'Magé', text: 'Magé' },
    { value: 'Mangaratiba', text: 'Mangaratiba' },
    { value: 'Maricá', text: 'Maricá' },
    { value: 'Mendes', text: 'Mendes' },
    { value: 'Mesquita', text: 'Mesquita' },
    { value: 'Miguel Pereira', text: 'Miguel Pereira' },
    { value: 'Miracema', text: 'Miracema' },
    { value: 'Natividade', text: 'Natividade' },
    { value: 'Nilópolis', text: 'Nilópolis' },
    { value: 'Niterói', text: 'Niterói' },
    { value: 'Nova Friburgo', text: 'Nova Friburgo' },
    { value: 'Nova Iguaçu', text: 'Nova Iguaçu' },
    { value: 'Paracambi', text: 'Paracambi' },
    { value: 'Paraíba do Sul', text: 'Paraíba do Sul' },
    { value: 'Parati', text: 'Parati' },
    { value: 'Paty do Alferes', text: 'Paty do Alferes' },
    { value: 'Petrópolis', text: 'Petrópolis' },
    { value: 'Pinheiral', text: 'Pinheiral' },
    { value: 'Piraí', text: 'Piraí' },
    { value: 'Porciúncula', text: 'Porciúncula' },
    { value: 'Porto Real', text: 'Porto Real' },
    { value: 'Quatis', text: 'Quatis' },
    { value: 'Queimados', text: 'Queimados' },
    { value: 'Quissamã', text: 'Quissamã' },
    { value: 'Resende', text: 'Resende' },
    { value: 'Rio Bonito', text: 'Rio Bonito' },
    { value: 'Rio Claro', text: 'Rio Claro' },
    { value: 'Rio das Flores', text: 'Rio das Flores' },
    { value: 'Rio das Ostras', text: 'Rio das Ostras' },
    { value: 'Rio de Janeiro', text: 'Rio de Janeiro' },
    { value: 'Santa Maria Madalena', text: 'Santa Maria Madalena' },
    { value: 'Santo Antônio de Pádua', text: 'Santo Antônio de Pádua' },
    { value: 'São Fidélis', text: 'São Fidélis' },
    {
      value: 'São Francisco de Itabapoana',
      text: 'São Francisco de Itabapoana',
    },
    { value: 'São Gonçalo', text: 'São Gonçalo' },
    { value: 'São João da Barra', text: 'São João da Barra' },
    { value: 'São João de Meriti', text: 'São João de Meriti' },
    { value: 'São José de Ubá', text: 'São José de Ubá' },
    {
      value: 'São José do Vale do Rio Preto',
      text: 'São José do Vale do Rio Preto',
    },
    { value: 'São Pedro da Aldeia', text: 'São Pedro da Aldeia' },
    { value: 'São Sebastião do Alto', text: 'São Sebastião do Alto' },
    { value: 'Sapucaia', text: 'Sapucaia' },
    { value: 'Saquarema', text: 'Saquarema' },
    { value: 'Seropédica', text: 'Seropédica' },
    { value: 'Silva Jardim', text: 'Silva Jardim' },
    { value: 'Sumidouro', text: 'Sumidouro' },
    { value: 'Tanguá', text: 'Tanguá' },
    { value: 'Teresópolis', text: 'Teresópolis' },
    { value: 'Trajano de Morais', text: 'Trajano de Morais' },
    { value: 'Três Rios', text: 'Três Rios' },
    { value: 'Valença', text: 'Valença' },
    { value: 'Varre-Sai', text: 'Varre-Sai' },
    { value: 'Vassouras', text: 'Vassouras' },
    { value: 'Volta Redonda', text: 'Volta Redonda' },
  ],
  RN: [
    { value: 'Acari', text: 'Acari' },
    { value: 'Açu', text: 'Açu' },
    { value: 'Afonso Bezerra', text: 'Afonso Bezerra' },
    { value: 'Água Nova', text: 'Água Nova' },
    { value: 'Alexandria', text: 'Alexandria' },
    { value: 'Almino Afonso', text: 'Almino Afonso' },
    { value: 'Alto do Rodrigues', text: 'Alto do Rodrigues' },
    { value: 'Angicos', text: 'Angicos' },
    { value: 'Antônio Martins', text: 'Antônio Martins' },
    { value: 'Apodi', text: 'Apodi' },
    { value: 'Areia Branca', text: 'Areia Branca' },
    { value: 'Arês', text: 'Arês' },
    { value: 'Augusto Severo', text: 'Augusto Severo' },
    { value: 'Baía Formosa', text: 'Baía Formosa' },
    { value: 'Baraúna', text: 'Baraúna' },
    { value: 'Barcelona', text: 'Barcelona' },
    { value: 'Bento Fernandes', text: 'Bento Fernandes' },
    { value: 'Bodó', text: 'Bodó' },
    { value: 'Bom Jesus', text: 'Bom Jesus' },
    { value: 'Brejinho', text: 'Brejinho' },
    { value: 'Caiçara do Norte', text: 'Caiçara do Norte' },
    { value: 'Caiçara do Rio do Vento', text: 'Caiçara do Rio do Vento' },
    { value: 'Caicó', text: 'Caicó' },
    { value: 'Campo Redondo', text: 'Campo Redondo' },
    { value: 'Canguaretama', text: 'Canguaretama' },
    { value: 'Caraúbas', text: 'Caraúbas' },
    { value: 'Carnaúba dos Dantas', text: 'Carnaúba dos Dantas' },
    { value: 'Carnaubais', text: 'Carnaubais' },
    { value: 'Ceará-Mirim', text: 'Ceará-Mirim' },
    { value: 'Cerro Corá', text: 'Cerro Corá' },
    { value: 'Coronel Ezequiel', text: 'Coronel Ezequiel' },
    { value: 'Coronel João Pessoa', text: 'Coronel João Pessoa' },
    { value: 'Cruzeta', text: 'Cruzeta' },
    { value: 'Currais Novos', text: 'Currais Novos' },
    { value: 'Doutor Severiano', text: 'Doutor Severiano' },
    { value: 'Encanto', text: 'Encanto' },
    { value: 'Equador', text: 'Equador' },
    { value: 'Espírito Santo', text: 'Espírito Santo' },
    { value: 'Extremoz', text: 'Extremoz' },
    { value: 'Felipe Guerra', text: 'Felipe Guerra' },
    { value: 'Fernando Pedroza', text: 'Fernando Pedroza' },
    { value: 'Florânia', text: 'Florânia' },
    { value: 'Francisco Dantas', text: 'Francisco Dantas' },
    { value: 'Frutuoso Gomes', text: 'Frutuoso Gomes' },
    { value: 'Galinhos', text: 'Galinhos' },
    { value: 'Goianinha', text: 'Goianinha' },
    { value: 'Governador Dix-Sept Rosado', text: 'Governador Dix-Sept Rosado' },
    { value: 'Grossos', text: 'Grossos' },
    { value: 'Guamaré', text: 'Guamaré' },
    { value: 'Ielmo Marinho', text: 'Ielmo Marinho' },
    { value: 'Ipanguaçu', text: 'Ipanguaçu' },
    { value: 'Ipueira', text: 'Ipueira' },
    { value: 'Itajá', text: 'Itajá' },
    { value: 'Itaú', text: 'Itaú' },
    { value: 'Jaçanã', text: 'Jaçanã' },
    { value: 'Jandaíra', text: 'Jandaíra' },
    { value: 'Janduís', text: 'Janduís' },
    { value: 'Januário Cicco', text: 'Januário Cicco' },
    { value: 'Japi', text: 'Japi' },
    { value: 'Jardim de Angicos', text: 'Jardim de Angicos' },
    { value: 'Jardim de Piranhas', text: 'Jardim de Piranhas' },
    { value: 'Jardim do Seridó', text: 'Jardim do Seridó' },
    { value: 'João Câmara', text: 'João Câmara' },
    { value: 'João Dias', text: 'João Dias' },
    { value: 'José da Penha', text: 'José da Penha' },
    { value: 'Jucurutu', text: 'Jucurutu' },
    { value: 'Jundiá', text: 'Jundiá' },
    { value: "Lagoa d'Anta", text: "Lagoa d'Anta" },
    { value: 'Lagoa de Pedras', text: 'Lagoa de Pedras' },
    { value: 'Lagoa de Velhos', text: 'Lagoa de Velhos' },
    { value: 'Lagoa Nova', text: 'Lagoa Nova' },
    { value: 'Lagoa Salgada', text: 'Lagoa Salgada' },
    { value: 'Lajes', text: 'Lajes' },
    { value: 'Lajes Pintadas', text: 'Lajes Pintadas' },
    { value: 'Lucrécia', text: 'Lucrécia' },
    { value: 'Luís Gomes', text: 'Luís Gomes' },
    { value: 'Macaíba', text: 'Macaíba' },
    { value: 'Macau', text: 'Macau' },
    { value: 'Major Sales', text: 'Major Sales' },
    { value: 'Marcelino Vieira', text: 'Marcelino Vieira' },
    { value: 'Martins', text: 'Martins' },
    { value: 'Maxaranguape', text: 'Maxaranguape' },
    { value: 'Messias Targino', text: 'Messias Targino' },
    { value: 'Montanhas', text: 'Montanhas' },
    { value: 'Monte Alegre', text: 'Monte Alegre' },
    { value: 'Monte das Gameleiras', text: 'Monte das Gameleiras' },
    { value: 'Mossoró', text: 'Mossoró' },
    { value: 'Natal', text: 'Natal' },
    { value: 'Nísia Floresta', text: 'Nísia Floresta' },
    { value: 'Nova Cruz', text: 'Nova Cruz' },
    { value: "Olho-d'Água do Borges", text: "Olho-d'Água do Borges" },
    { value: 'Ouro Branco', text: 'Ouro Branco' },
    { value: 'Paraná', text: 'Paraná' },
    { value: 'Paraú', text: 'Paraú' },
    { value: 'Parazinho', text: 'Parazinho' },
    { value: 'Parelhas', text: 'Parelhas' },
    { value: 'Parnamirim', text: 'Parnamirim' },
    { value: 'Passa e Fica', text: 'Passa e Fica' },
    { value: 'Passagem', text: 'Passagem' },
    { value: 'Patu', text: 'Patu' },
    { value: 'Pau dos Ferros', text: 'Pau dos Ferros' },
    { value: 'Pedra Grande', text: 'Pedra Grande' },
    { value: 'Pedra Preta', text: 'Pedra Preta' },
    { value: 'Pedro Avelino', text: 'Pedro Avelino' },
    { value: 'Pedro Velho', text: 'Pedro Velho' },
    { value: 'Pendências', text: 'Pendências' },
    { value: 'Pilões', text: 'Pilões' },
    { value: 'Poço Branco', text: 'Poço Branco' },
    { value: 'Portalegre', text: 'Portalegre' },
    { value: 'Porto do Mangue', text: 'Porto do Mangue' },
    { value: 'Presidente Juscelino', text: 'Presidente Juscelino' },
    { value: 'Pureza', text: 'Pureza' },
    { value: 'Rafael Fernandes', text: 'Rafael Fernandes' },
    { value: 'Rafael Godeiro', text: 'Rafael Godeiro' },
    { value: 'Riacho da Cruz', text: 'Riacho da Cruz' },
    { value: 'Riacho de Santana', text: 'Riacho de Santana' },
    { value: 'Riachuelo', text: 'Riachuelo' },
    { value: 'Rio do Fogo', text: 'Rio do Fogo' },
    { value: 'Rodolfo Fernandes', text: 'Rodolfo Fernandes' },
    { value: 'Ruy Barbosa', text: 'Ruy Barbosa' },
    { value: 'Santa Cruz', text: 'Santa Cruz' },
    { value: 'Santa Maria', text: 'Santa Maria' },
    { value: 'Santana do Matos', text: 'Santana do Matos' },
    { value: 'Santana do Seridó', text: 'Santana do Seridó' },
    { value: 'Santo Antônio', text: 'Santo Antônio' },
    { value: 'São Bento do Norte', text: 'São Bento do Norte' },
    { value: 'São Bento do Trairí', text: 'São Bento do Trairí' },
    { value: 'São Fernando', text: 'São Fernando' },
    { value: 'São Francisco do Oeste', text: 'São Francisco do Oeste' },
    { value: 'São Gonçalo do Amarante', text: 'São Gonçalo do Amarante' },
    { value: 'São João do Sabugi', text: 'São João do Sabugi' },
    { value: 'São José de Mipibu', text: 'São José de Mipibu' },
    { value: 'São José do Campestre', text: 'São José do Campestre' },
    { value: 'São José do Seridó', text: 'São José do Seridó' },
    { value: 'São Miguel', text: 'São Miguel' },
    { value: 'São Miguel de Touros', text: 'São Miguel de Touros' },
    { value: 'São Paulo do Potengi', text: 'São Paulo do Potengi' },
    { value: 'São Pedro', text: 'São Pedro' },
    { value: 'São Rafael', text: 'São Rafael' },
    { value: 'São Tomé', text: 'São Tomé' },
    { value: 'São Vicente', text: 'São Vicente' },
    { value: 'Senador Elói de Souza', text: 'Senador Elói de Souza' },
    { value: 'Senador Georgino Avelino', text: 'Senador Georgino Avelino' },
    { value: 'Serra de São Bento', text: 'Serra de São Bento' },
    { value: 'Serra do Mel', text: 'Serra do Mel' },
    { value: 'Serra Negra do Norte', text: 'Serra Negra do Norte' },
    { value: 'Serrinha', text: 'Serrinha' },
    { value: 'Serrinha dos Pintos', text: 'Serrinha dos Pintos' },
    { value: 'Severiano Melo', text: 'Severiano Melo' },
    { value: 'Sítio Novo', text: 'Sítio Novo' },
    { value: 'Taboleiro Grande', text: 'Taboleiro Grande' },
    { value: 'Taipu', text: 'Taipu' },
    { value: 'Tangará', text: 'Tangará' },
    { value: 'Tenente Ananias', text: 'Tenente Ananias' },
    { value: 'Tenente Laurentino Cruz', text: 'Tenente Laurentino Cruz' },
    { value: 'Tibau', text: 'Tibau' },
    { value: 'Tibau do Sul', text: 'Tibau do Sul' },
    { value: 'Timbaúba dos Batistas', text: 'Timbaúba dos Batistas' },
    { value: 'Touros', text: 'Touros' },
    { value: 'Triunfo Potiguar', text: 'Triunfo Potiguar' },
    { value: 'Umarizal', text: 'Umarizal' },
    { value: 'Upanema', text: 'Upanema' },
    { value: 'Várzea', text: 'Várzea' },
    { value: 'Venha-Ver', text: 'Venha-Ver' },
    { value: 'Vera Cruz', text: 'Vera Cruz' },
    { value: 'Viçosa', text: 'Viçosa' },
    { value: 'Vila Flor', text: 'Vila Flor' },
  ],
  RO: [
    { value: "Alta Floresta d'Oeste", text: "Alta Floresta d'Oeste" },
    { value: 'Alto Alegre do Parecis', text: 'Alto Alegre do Parecis' },
    { value: 'Alto Paraíso', text: 'Alto Paraíso' },
    { value: "Alvorada d'Oeste", text: "Alvorada d'Oeste" },
    { value: 'Ariquemes', text: 'Ariquemes' },
    { value: 'Buritis', text: 'Buritis' },
    { value: 'Cabixi', text: 'Cabixi' },
    { value: 'Cacaulândia', text: 'Cacaulândia' },
    { value: 'Cacoal', text: 'Cacoal' },
    { value: 'Campo Novo de Rondônia', text: 'Campo Novo de Rondônia' },
    { value: 'Candeias do Jamari', text: 'Candeias do Jamari' },
    { value: 'Castanheiras', text: 'Castanheiras' },
    { value: 'Cerejeiras', text: 'Cerejeiras' },
    { value: 'Chupinguaia', text: 'Chupinguaia' },
    { value: 'Colorado do Oeste', text: 'Colorado do Oeste' },
    { value: 'Corumbiara', text: 'Corumbiara' },
    { value: 'Costa Marques', text: 'Costa Marques' },
    { value: 'Cujubim', text: 'Cujubim' },
    { value: "Espigão d'Oeste", text: "Espigão d'Oeste" },
    { value: 'Governador Jorge Teixeira', text: 'Governador Jorge Teixeira' },
    { value: 'Guajará-Mirim', text: 'Guajará-Mirim' },
    { value: 'Itapuã do Oeste', text: 'Itapuã do Oeste' },
    { value: 'Jaru', text: 'Jaru' },
    { value: 'Ji-Paraná', text: 'Ji-Paraná' },
    { value: "Machadinho d'Oeste", text: "Machadinho d'Oeste" },
    { value: 'Ministro Andreazza', text: 'Ministro Andreazza' },
    { value: 'Mirante da Serra', text: 'Mirante da Serra' },
    { value: 'Monte Negro', text: 'Monte Negro' },
    { value: "Nova Brasilândia d'Oeste", text: "Nova Brasilândia d'Oeste" },
    { value: 'Nova Mamoré', text: 'Nova Mamoré' },
    { value: 'Nova União', text: 'Nova União' },
    { value: 'Novo Horizonte do Oeste', text: 'Novo Horizonte do Oeste' },
    { value: 'Ouro Preto do Oeste', text: 'Ouro Preto do Oeste' },
    { value: 'Parecis', text: 'Parecis' },
    { value: 'Pimenta Bueno', text: 'Pimenta Bueno' },
    { value: 'Pimenteiras do Oeste', text: 'Pimenteiras do Oeste' },
    { value: 'Porto Velho', text: 'Porto Velho' },
    { value: 'Presidente Médici', text: 'Presidente Médici' },
    { value: 'Primavera de Rondônia', text: 'Primavera de Rondônia' },
    { value: 'Rio Crespo', text: 'Rio Crespo' },
    { value: 'Rolim de Moura', text: 'Rolim de Moura' },
    { value: "Santa Luzia d'Oeste", text: "Santa Luzia d'Oeste" },
    { value: "São Felipe d'Oeste", text: "São Felipe d'Oeste" },
    { value: 'São Francisco do Guaporé', text: 'São Francisco do Guaporé' },
    { value: 'São Miguel do Guaporé', text: 'São Miguel do Guaporé' },
    { value: 'Seringueiras', text: 'Seringueiras' },
    { value: 'Teixeirópolis', text: 'Teixeirópolis' },
    { value: 'Theobroma', text: 'Theobroma' },
    { value: 'Urupá', text: 'Urupá' },
    { value: 'Vale do Anari', text: 'Vale do Anari' },
    { value: 'Vale do Paraíso', text: 'Vale do Paraíso' },
    { value: 'Vilhena', text: 'Vilhena' },
  ],
  RR: [
    { value: 'Alto Alegre', text: 'Alto Alegre' },
    { value: 'Amajari', text: 'Amajari' },
    { value: 'Boa Vista', text: 'Boa Vista' },
    { value: 'Bonfim', text: 'Bonfim' },
    { value: 'Cantá', text: 'Cantá' },
    { value: 'Caracaraí', text: 'Caracaraí' },
    { value: 'Caroebe', text: 'Caroebe' },
    { value: 'Iracema', text: 'Iracema' },
    { value: 'Mucajaí', text: 'Mucajaí' },
    { value: 'Normandia', text: 'Normandia' },
    { value: 'Pacaraima', text: 'Pacaraima' },
    { value: 'Rorainópolis', text: 'Rorainópolis' },
    { value: 'São João da Baliza', text: 'São João da Baliza' },
    { value: 'São Luiz', text: 'São Luiz' },
    { value: 'Uiramutã', text: 'Uiramutã' },
  ],
  RS: [
    { value: 'Aceguá', text: 'Aceguá' },
    { value: 'Água Santa', text: 'Água Santa' },
    { value: 'Agudo', text: 'Agudo' },
    { value: 'Ajuricaba', text: 'Ajuricaba' },
    { value: 'Alecrim', text: 'Alecrim' },
    { value: 'Alegrete', text: 'Alegrete' },
    { value: 'Alegria', text: 'Alegria' },
    { value: 'Almirante Tamandaré do Sul', text: 'Almirante Tamandaré do Sul' },
    { value: 'Alpestre', text: 'Alpestre' },
    { value: 'Alto Alegre', text: 'Alto Alegre' },
    { value: 'Alto Feliz', text: 'Alto Feliz' },
    { value: 'Alvorada', text: 'Alvorada' },
    { value: 'Amaral Ferrador', text: 'Amaral Ferrador' },
    { value: 'Ametista do Sul', text: 'Ametista do Sul' },
    { value: 'André da Rocha', text: 'André da Rocha' },
    { value: 'Anta Gorda', text: 'Anta Gorda' },
    { value: 'Antônio Prado', text: 'Antônio Prado' },
    { value: 'Arambaré', text: 'Arambaré' },
    { value: 'Araricá', text: 'Araricá' },
    { value: 'Aratiba', text: 'Aratiba' },
    { value: 'Arroio do Meio', text: 'Arroio do Meio' },
    { value: 'Arroio do Padre', text: 'Arroio do Padre' },
    { value: 'Arroio do Sal', text: 'Arroio do Sal' },
    { value: 'Arroio do Tigre', text: 'Arroio do Tigre' },
    { value: 'Arroio dos Ratos', text: 'Arroio dos Ratos' },
    { value: 'Arroio Grande', text: 'Arroio Grande' },
    { value: 'Arvorezinha', text: 'Arvorezinha' },
    { value: 'Augusto Pestana', text: 'Augusto Pestana' },
    { value: 'Áurea', text: 'Áurea' },
    { value: 'Bagé', text: 'Bagé' },
    { value: 'Balneário Pinhal', text: 'Balneário Pinhal' },
    { value: 'Barão', text: 'Barão' },
    { value: 'Barão de Cotegipe', text: 'Barão de Cotegipe' },
    { value: 'Barão do Triunfo', text: 'Barão do Triunfo' },
    { value: 'Barra do Guarita', text: 'Barra do Guarita' },
    { value: 'Barra do Quaraí', text: 'Barra do Quaraí' },
    { value: 'Barra do Ribeiro', text: 'Barra do Ribeiro' },
    { value: 'Barra do Rio Azul', text: 'Barra do Rio Azul' },
    { value: 'Barra Funda', text: 'Barra Funda' },
    { value: 'Barracão', text: 'Barracão' },
    { value: 'Barros Cassal', text: 'Barros Cassal' },
    { value: 'Benjamin Constan do Sul', text: 'Benjamin Constan do Sul' },
    { value: 'Bento Gonçalves', text: 'Bento Gonçalves' },
    { value: 'Boa Vista das Missões', text: 'Boa Vista das Missões' },
    { value: 'Boa Vista do Buricá', text: 'Boa Vista do Buricá' },
    { value: 'Boa Vista do Cadeado', text: 'Boa Vista do Cadeado' },
    { value: 'Boa Vista do Incra', text: 'Boa Vista do Incra' },
    { value: 'Boa Vista do Sul', text: 'Boa Vista do Sul' },
    { value: 'Bom Jesus', text: 'Bom Jesus' },
    { value: 'Bom Princípio', text: 'Bom Princípio' },
    { value: 'Bom Progresso', text: 'Bom Progresso' },
    { value: 'Bom Retiro do Sul', text: 'Bom Retiro do Sul' },
    { value: 'Boqueirão do Leão', text: 'Boqueirão do Leão' },
    { value: 'Bossoroca', text: 'Bossoroca' },
    { value: 'Bozano', text: 'Bozano' },
    { value: 'Braga', text: 'Braga' },
    { value: 'Brochier', text: 'Brochier' },
    { value: 'Butiá', text: 'Butiá' },
    { value: 'Caçapava do Sul', text: 'Caçapava do Sul' },
    { value: 'Cacequi', text: 'Cacequi' },
    { value: 'Cachoeira do Sul', text: 'Cachoeira do Sul' },
    { value: 'Cachoeirinha', text: 'Cachoeirinha' },
    { value: 'Cacique Doble', text: 'Cacique Doble' },
    { value: 'Caibaté', text: 'Caibaté' },
    { value: 'Caiçara', text: 'Caiçara' },
    { value: 'Camaquã', text: 'Camaquã' },
    { value: 'Camargo', text: 'Camargo' },
    { value: 'Cambará do Sul', text: 'Cambará do Sul' },
    { value: 'Campestre da Serra', text: 'Campestre da Serra' },
    { value: 'Campina das Missões', text: 'Campina das Missões' },
    { value: 'Campinas do Sul', text: 'Campinas do Sul' },
    { value: 'Campo Bom', text: 'Campo Bom' },
    { value: 'Campo Novo', text: 'Campo Novo' },
    { value: 'Campos Borges', text: 'Campos Borges' },
    { value: 'Candelária', text: 'Candelária' },
    { value: 'Cândido Godói', text: 'Cândido Godói' },
    { value: 'Candiota', text: 'Candiota' },
    { value: 'Canela', text: 'Canela' },
    { value: 'Canguçu', text: 'Canguçu' },
    { value: 'Canoas', text: 'Canoas' },
    { value: 'Canudos do Vale', text: 'Canudos do Vale' },
    { value: 'Capão Bonito do Sul', text: 'Capão Bonito do Sul' },
    { value: 'Capão da Canoa', text: 'Capão da Canoa' },
    { value: 'Capão do Cipó', text: 'Capão do Cipó' },
    { value: 'Capão do Leão', text: 'Capão do Leão' },
    { value: 'Capela de Santana', text: 'Capela de Santana' },
    { value: 'Capitão', text: 'Capitão' },
    { value: 'Capivari do Sul', text: 'Capivari do Sul' },
    { value: 'Caraá', text: 'Caraá' },
    { value: 'Carazinho', text: 'Carazinho' },
    { value: 'Carlos Barbosa', text: 'Carlos Barbosa' },
    { value: 'Carlos Gomes', text: 'Carlos Gomes' },
    { value: 'Casca', text: 'Casca' },
    { value: 'Caseiros', text: 'Caseiros' },
    { value: 'Catuípe', text: 'Catuípe' },
    { value: 'Caxias do Sul', text: 'Caxias do Sul' },
    { value: 'Centenário', text: 'Centenário' },
    { value: 'Cerrito', text: 'Cerrito' },
    { value: 'Cerro Branco', text: 'Cerro Branco' },
    { value: 'Cerro Grande', text: 'Cerro Grande' },
    { value: 'Cerro Grande do Sul', text: 'Cerro Grande do Sul' },
    { value: 'Cerro Largo', text: 'Cerro Largo' },
    { value: 'Chapada', text: 'Chapada' },
    { value: 'Charqueadas', text: 'Charqueadas' },
    { value: 'Charrua', text: 'Charrua' },
    { value: 'Chiapeta', text: 'Chiapeta' },
    { value: 'Chuí', text: 'Chuí' },
    { value: 'Chuvisca', text: 'Chuvisca' },
    { value: 'Cidreira', text: 'Cidreira' },
    { value: 'Ciríaco', text: 'Ciríaco' },
    { value: 'Colinas', text: 'Colinas' },
    { value: 'Colorado', text: 'Colorado' },
    { value: 'Condor', text: 'Condor' },
    { value: 'Constantina', text: 'Constantina' },
    { value: 'Coqueiro Baixo', text: 'Coqueiro Baixo' },
    { value: 'Coqueiros do Sul', text: 'Coqueiros do Sul' },
    { value: 'Coronel Barros', text: 'Coronel Barros' },
    { value: 'Coronel Bicaco', text: 'Coronel Bicaco' },
    { value: 'Coronel Pilar', text: 'Coronel Pilar' },
    { value: 'Cotiporã', text: 'Cotiporã' },
    { value: 'Coxilha', text: 'Coxilha' },
    { value: 'Crissiumal', text: 'Crissiumal' },
    { value: 'Cristal', text: 'Cristal' },
    { value: 'Cristal do Sul', text: 'Cristal do Sul' },
    { value: 'Cruz Alta', text: 'Cruz Alta' },
    { value: 'Cruzaltense', text: 'Cruzaltense' },
    { value: 'Cruzeiro do Sul', text: 'Cruzeiro do Sul' },
    { value: 'David Canabarro', text: 'David Canabarro' },
    { value: 'Derrubadas', text: 'Derrubadas' },
    { value: 'Dezesseis de Novembro', text: 'Dezesseis de Novembro' },
    { value: 'Dilermando de Aguiar', text: 'Dilermando de Aguiar' },
    { value: 'Dois Irmãos', text: 'Dois Irmãos' },
    { value: 'Dois Irmãos das Missões', text: 'Dois Irmãos das Missões' },
    { value: 'Dois Lajeados', text: 'Dois Lajeados' },
    { value: 'Dom Feliciano', text: 'Dom Feliciano' },
    { value: 'Dom Pedrito', text: 'Dom Pedrito' },
    { value: 'Dom Pedro de Alcântara', text: 'Dom Pedro de Alcântara' },
    { value: 'Dona Francisca', text: 'Dona Francisca' },
    { value: 'Doutor Maurício Cardoso', text: 'Doutor Maurício Cardoso' },
    { value: 'Doutor Ricardo', text: 'Doutor Ricardo' },
    { value: 'Eldorado do Sul', text: 'Eldorado do Sul' },
    { value: 'Encantado', text: 'Encantado' },
    { value: 'Encruzilhada do Sul', text: 'Encruzilhada do Sul' },
    { value: 'Engenho Velho', text: 'Engenho Velho' },
    { value: 'Entre Rios do Sul', text: 'Entre Rios do Sul' },
    { value: 'Entre-Ijuís', text: 'Entre-Ijuís' },
    { value: 'Erebango', text: 'Erebango' },
    { value: 'Erechim', text: 'Erechim' },
    { value: 'Ernestina', text: 'Ernestina' },
    { value: 'Erval Grande', text: 'Erval Grande' },
    { value: 'Erval Seco', text: 'Erval Seco' },
    { value: 'Esmeralda', text: 'Esmeralda' },
    { value: 'Esperança do Sul', text: 'Esperança do Sul' },
    { value: 'Espumoso', text: 'Espumoso' },
    { value: 'Estação', text: 'Estação' },
    { value: 'Estância Velha', text: 'Estância Velha' },
    { value: 'Esteio', text: 'Esteio' },
    { value: 'Estrela', text: 'Estrela' },
    { value: 'Estrela Velha', text: 'Estrela Velha' },
    { value: 'Eugênio de Castro', text: 'Eugênio de Castro' },
    { value: 'Fagundes Varela', text: 'Fagundes Varela' },
    { value: 'Farroupilha', text: 'Farroupilha' },
    { value: 'Faxinal do Soturno', text: 'Faxinal do Soturno' },
    { value: 'Faxinalzinho', text: 'Faxinalzinho' },
    { value: 'Fazenda Vilanova', text: 'Fazenda Vilanova' },
    { value: 'Feliz', text: 'Feliz' },
    { value: 'Flores da Cunha', text: 'Flores da Cunha' },
    { value: 'Floriano Peixoto', text: 'Floriano Peixoto' },
    { value: 'Fontoura Xavier', text: 'Fontoura Xavier' },
    { value: 'Formigueiro', text: 'Formigueiro' },
    { value: 'Forquetinha', text: 'Forquetinha' },
    { value: 'Fortaleza dos Valos', text: 'Fortaleza dos Valos' },
    { value: 'Frederico Westphalen', text: 'Frederico Westphalen' },
    { value: 'Garibaldi', text: 'Garibaldi' },
    { value: 'Garruchos', text: 'Garruchos' },
    { value: 'Gaurama', text: 'Gaurama' },
    { value: 'General Câmara', text: 'General Câmara' },
    { value: 'Gentil', text: 'Gentil' },
    { value: 'Getúlio Vargas', text: 'Getúlio Vargas' },
    { value: 'Giruá', text: 'Giruá' },
    { value: 'Glorinha', text: 'Glorinha' },
    { value: 'Gramado', text: 'Gramado' },
    { value: 'Gramado dos Loureiros', text: 'Gramado dos Loureiros' },
    { value: 'Gramado Xavier', text: 'Gramado Xavier' },
    { value: 'Gravataí', text: 'Gravataí' },
    { value: 'Guabiju', text: 'Guabiju' },
    { value: 'Guaíba', text: 'Guaíba' },
    { value: 'Guaporé', text: 'Guaporé' },
    { value: 'Guarani das Missões', text: 'Guarani das Missões' },
    { value: 'Harmonia', text: 'Harmonia' },
    { value: 'Herval', text: 'Herval' },
    { value: 'Herveiras', text: 'Herveiras' },
    { value: 'Horizontina', text: 'Horizontina' },
    { value: 'Hulha Negra', text: 'Hulha Negra' },
    { value: 'Humaitá', text: 'Humaitá' },
    { value: 'Ibarama', text: 'Ibarama' },
    { value: 'Ibiaçá', text: 'Ibiaçá' },
    { value: 'Ibiraiaras', text: 'Ibiraiaras' },
    { value: 'Ibirapuitã', text: 'Ibirapuitã' },
    { value: 'Ibirubá', text: 'Ibirubá' },
    { value: 'Igrejinha', text: 'Igrejinha' },
    { value: 'Ijuí', text: 'Ijuí' },
    { value: 'Ilópolis', text: 'Ilópolis' },
    { value: 'Imbé', text: 'Imbé' },
    { value: 'Imigrante', text: 'Imigrante' },
    { value: 'Independência', text: 'Independência' },
    { value: 'Inhacorá', text: 'Inhacorá' },
    { value: 'Ipê', text: 'Ipê' },
    { value: 'Ipiranga do Sul', text: 'Ipiranga do Sul' },
    { value: 'Iraí', text: 'Iraí' },
    { value: 'Itaara', text: 'Itaara' },
    { value: 'Itacurubi', text: 'Itacurubi' },
    { value: 'Itapuca', text: 'Itapuca' },
    { value: 'Itaqui', text: 'Itaqui' },
    { value: 'Itati', text: 'Itati' },
    { value: 'Itatiba do Sul', text: 'Itatiba do Sul' },
    { value: 'Ivorá', text: 'Ivorá' },
    { value: 'Ivoti', text: 'Ivoti' },
    { value: 'Jaboticaba', text: 'Jaboticaba' },
    { value: 'Jacuizinho', text: 'Jacuizinho' },
    { value: 'Jacutinga', text: 'Jacutinga' },
    { value: 'Jaguarão', text: 'Jaguarão' },
    { value: 'Jaguari', text: 'Jaguari' },
    { value: 'Jaquirana', text: 'Jaquirana' },
    { value: 'Jari', text: 'Jari' },
    { value: 'Jóia', text: 'Jóia' },
    { value: 'Júlio de Castilhos', text: 'Júlio de Castilhos' },
    { value: 'Lagoa Bonita do Sul', text: 'Lagoa Bonita do Sul' },
    { value: 'Lagoa dos Três Cantos', text: 'Lagoa dos Três Cantos' },
    { value: 'Lagoa Vermelha', text: 'Lagoa Vermelha' },
    { value: 'Lagoão', text: 'Lagoão' },
    { value: 'Lajeado', text: 'Lajeado' },
    { value: 'Lajeado do Bugre', text: 'Lajeado do Bugre' },
    { value: 'Lavras do Sul', text: 'Lavras do Sul' },
    { value: 'Liberato Salzano', text: 'Liberato Salzano' },
    { value: 'Lindolfo Collor', text: 'Lindolfo Collor' },
    { value: 'Linha Nova', text: 'Linha Nova' },
    { value: 'Maçambara', text: 'Maçambara' },
    { value: 'Machadinho', text: 'Machadinho' },
    { value: 'Mampituba', text: 'Mampituba' },
    { value: 'Manoel Viana', text: 'Manoel Viana' },
    { value: 'Maquiné', text: 'Maquiné' },
    { value: 'Maratá', text: 'Maratá' },
    { value: 'Marau', text: 'Marau' },
    { value: 'Marcelino Ramos', text: 'Marcelino Ramos' },
    { value: 'Mariana Pimentel', text: 'Mariana Pimentel' },
    { value: 'Mariano Moro', text: 'Mariano Moro' },
    { value: 'Marques de Souza', text: 'Marques de Souza' },
    { value: 'Mata', text: 'Mata' },
    { value: 'Mato Castelhano', text: 'Mato Castelhano' },
    { value: 'Mato Leitão', text: 'Mato Leitão' },
    { value: 'Mato Queimado', text: 'Mato Queimado' },
    { value: 'Maximiliano de Almeida', text: 'Maximiliano de Almeida' },
    { value: 'Minas do Leão', text: 'Minas do Leão' },
    { value: 'Miraguaí', text: 'Miraguaí' },
    { value: 'Montauri', text: 'Montauri' },
    { value: 'Monte Alegre dos Campos', text: 'Monte Alegre dos Campos' },
    { value: 'Monte Belo do Sul', text: 'Monte Belo do Sul' },
    { value: 'Montenegro', text: 'Montenegro' },
    { value: 'Mormaço', text: 'Mormaço' },
    { value: 'Morrinhos do Sul', text: 'Morrinhos do Sul' },
    { value: 'Morro Redondo', text: 'Morro Redondo' },
    { value: 'Morro Reuter', text: 'Morro Reuter' },
    { value: 'Mostardas', text: 'Mostardas' },
    { value: 'Muçum', text: 'Muçum' },
    { value: 'Muitos Capões', text: 'Muitos Capões' },
    { value: 'Muliterno', text: 'Muliterno' },
    { value: 'Não-Me-Toque', text: 'Não-Me-Toque' },
    { value: 'Nicolau Vergueiro', text: 'Nicolau Vergueiro' },
    { value: 'Nonoai', text: 'Nonoai' },
    { value: 'Nova Alvorada', text: 'Nova Alvorada' },
    { value: 'Nova Araçá', text: 'Nova Araçá' },
    { value: 'Nova Bassano', text: 'Nova Bassano' },
    { value: 'Nova Boa Vista', text: 'Nova Boa Vista' },
    { value: 'Nova Bréscia', text: 'Nova Bréscia' },
    { value: 'Nova Candelária', text: 'Nova Candelária' },
    { value: 'Nova Esperança do Sul', text: 'Nova Esperança do Sul' },
    { value: 'Nova Hartz', text: 'Nova Hartz' },
    { value: 'Nova Pádua', text: 'Nova Pádua' },
    { value: 'Nova Palma', text: 'Nova Palma' },
    { value: 'Nova Petrópolis', text: 'Nova Petrópolis' },
    { value: 'Nova Prata', text: 'Nova Prata' },
    { value: 'Nova Ramada', text: 'Nova Ramada' },
    { value: 'Nova Roma do Sul', text: 'Nova Roma do Sul' },
    { value: 'Nova Santa Rita', text: 'Nova Santa Rita' },
    { value: 'Novo Barreiro', text: 'Novo Barreiro' },
    { value: 'Novo Cabrais', text: 'Novo Cabrais' },
    { value: 'Novo Hamburgo', text: 'Novo Hamburgo' },
    { value: 'Novo Machado', text: 'Novo Machado' },
    { value: 'Novo Tiradentes', text: 'Novo Tiradentes' },
    { value: 'Novo Xingu', text: 'Novo Xingu' },
    { value: 'Osório', text: 'Osório' },
    { value: 'Paim Filho', text: 'Paim Filho' },
    { value: 'Palmares do Sul', text: 'Palmares do Sul' },
    { value: 'Palmeira das Missões', text: 'Palmeira das Missões' },
    { value: 'Palmitinho', text: 'Palmitinho' },
    { value: 'Panambi', text: 'Panambi' },
    { value: 'Pântano Grande', text: 'Pântano Grande' },
    { value: 'Paraí', text: 'Paraí' },
    { value: 'Paraíso do Sul', text: 'Paraíso do Sul' },
    { value: 'Pareci Novo', text: 'Pareci Novo' },
    { value: 'Parobé', text: 'Parobé' },
    { value: 'Passa Sete', text: 'Passa Sete' },
    { value: 'Passo do Sobrado', text: 'Passo do Sobrado' },
    { value: 'Passo Fundo', text: 'Passo Fundo' },
    { value: 'Paulo Bento', text: 'Paulo Bento' },
    { value: 'Paverama', text: 'Paverama' },
    { value: 'Pedras Altas', text: 'Pedras Altas' },
    { value: 'Pedro Osório', text: 'Pedro Osório' },
    { value: 'Pejuçara', text: 'Pejuçara' },
    { value: 'Pelotas', text: 'Pelotas' },
    { value: 'Picada Café', text: 'Picada Café' },
    { value: 'Pinhal', text: 'Pinhal' },
    { value: 'Pinhal da Serra', text: 'Pinhal da Serra' },
    { value: 'Pinhal Grande', text: 'Pinhal Grande' },
    { value: 'Pinheirinho do Vale', text: 'Pinheirinho do Vale' },
    { value: 'Pinheiro Machado', text: 'Pinheiro Machado' },
    { value: 'Pirapó', text: 'Pirapó' },
    { value: 'Piratini', text: 'Piratini' },
    { value: 'Planalto', text: 'Planalto' },
    { value: 'Poço das Antas', text: 'Poço das Antas' },
    { value: 'Pontão', text: 'Pontão' },
    { value: 'Ponte Preta', text: 'Ponte Preta' },
    { value: 'Portão', text: 'Portão' },
    { value: 'Porto Alegre', text: 'Porto Alegre' },
    { value: 'Porto Lucena', text: 'Porto Lucena' },
    { value: 'Porto Mauá', text: 'Porto Mauá' },
    { value: 'Porto Vera Cruz', text: 'Porto Vera Cruz' },
    { value: 'Porto Xavier', text: 'Porto Xavier' },
    { value: 'Pouso Novo', text: 'Pouso Novo' },
    { value: 'Presidente Lucena', text: 'Presidente Lucena' },
    { value: 'Progresso', text: 'Progresso' },
    { value: 'Protásio Alves', text: 'Protásio Alves' },
    { value: 'Putinga', text: 'Putinga' },
    { value: 'Quaraí', text: 'Quaraí' },
    { value: 'Quatro Irmãos', text: 'Quatro Irmãos' },
    { value: 'Quevedos', text: 'Quevedos' },
    { value: 'Quinze de Novembro', text: 'Quinze de Novembro' },
    { value: 'Redentora', text: 'Redentora' },
    { value: 'Relvado', text: 'Relvado' },
    { value: 'Restinga Seca', text: 'Restinga Seca' },
    { value: 'Rio dos Índios', text: 'Rio dos Índios' },
    { value: 'Rio Grande', text: 'Rio Grande' },
    { value: 'Rio Pardo', text: 'Rio Pardo' },
    { value: 'Riozinho', text: 'Riozinho' },
    { value: 'Roca Sales', text: 'Roca Sales' },
    { value: 'Rodeio Bonito', text: 'Rodeio Bonito' },
    { value: 'Rolador', text: 'Rolador' },
    { value: 'Rolante', text: 'Rolante' },
    { value: 'Ronda Alta', text: 'Ronda Alta' },
    { value: 'Rondinha', text: 'Rondinha' },
    { value: 'Roque Gonzales', text: 'Roque Gonzales' },
    { value: 'Rosário do Sul', text: 'Rosário do Sul' },
    { value: 'Sagrada Família', text: 'Sagrada Família' },
    { value: 'Saldanha Marinho', text: 'Saldanha Marinho' },
    { value: 'Salto do Jacuí', text: 'Salto do Jacuí' },
    { value: 'Salvador das Missões', text: 'Salvador das Missões' },
    { value: 'Salvador do Sul', text: 'Salvador do Sul' },
    { value: 'Sananduva', text: 'Sananduva' },
    { value: 'Santa Bárbara do Sul', text: 'Santa Bárbara do Sul' },
    { value: 'Santa Cecília do Sul', text: 'Santa Cecília do Sul' },
    { value: 'Santa Clara do Sul', text: 'Santa Clara do Sul' },
    { value: 'Santa Cruz do Sul', text: 'Santa Cruz do Sul' },
    { value: 'Santa Margarida do Sul', text: 'Santa Margarida do Sul' },
    { value: 'Santa Maria', text: 'Santa Maria' },
    { value: 'Santa Maria do Herval', text: 'Santa Maria do Herval' },
    { value: 'Santa Rosa', text: 'Santa Rosa' },
    { value: 'Santa Tereza', text: 'Santa Tereza' },
    { value: 'Santa Vitória do Palmar', text: 'Santa Vitória do Palmar' },
    { value: 'Santana da Boa Vista', text: 'Santana da Boa Vista' },
    { value: 'Santana do Livramento', text: 'Santana do Livramento' },
    { value: 'Santiago', text: 'Santiago' },
    { value: 'Santo Ângelo', text: 'Santo Ângelo' },
    { value: 'Santo Antônio da Patrulha', text: 'Santo Antônio da Patrulha' },
    { value: 'Santo Antônio das Missões', text: 'Santo Antônio das Missões' },
    { value: 'Santo Antônio do Palma', text: 'Santo Antônio do Palma' },
    { value: 'Santo Antônio do Planalto', text: 'Santo Antônio do Planalto' },
    { value: 'Santo Augusto', text: 'Santo Augusto' },
    { value: 'Santo Cristo', text: 'Santo Cristo' },
    { value: 'Santo Expedito do Sul', text: 'Santo Expedito do Sul' },
    { value: 'São Borja', text: 'São Borja' },
    { value: 'São Domingos do Sul', text: 'São Domingos do Sul' },
    { value: 'São Francisco de Assis', text: 'São Francisco de Assis' },
    { value: 'São Francisco de Paula', text: 'São Francisco de Paula' },
    { value: 'São Gabriel', text: 'São Gabriel' },
    { value: 'São Jerônimo', text: 'São Jerônimo' },
    { value: 'São João da Urtiga', text: 'São João da Urtiga' },
    { value: 'São João do Polêsine', text: 'São João do Polêsine' },
    { value: 'São Jorge', text: 'São Jorge' },
    { value: 'São José das Missões', text: 'São José das Missões' },
    { value: 'São José do Herval', text: 'São José do Herval' },
    { value: 'São José do Hortêncio', text: 'São José do Hortêncio' },
    { value: 'São José do Inhacorá', text: 'São José do Inhacorá' },
    { value: 'São José do Norte', text: 'São José do Norte' },
    { value: 'São José do Ouro', text: 'São José do Ouro' },
    { value: 'São José do Sul', text: 'São José do Sul' },
    { value: 'São José dos Ausentes', text: 'São José dos Ausentes' },
    { value: 'São Leopoldo', text: 'São Leopoldo' },
    { value: 'São Lourenço do Sul', text: 'São Lourenço do Sul' },
    { value: 'São Luiz Gonzaga', text: 'São Luiz Gonzaga' },
    { value: 'São Marcos', text: 'São Marcos' },
    { value: 'São Martinho', text: 'São Martinho' },
    { value: 'São Martinho da Serra', text: 'São Martinho da Serra' },
    { value: 'São Miguel das Missões', text: 'São Miguel das Missões' },
    { value: 'São Nicolau', text: 'São Nicolau' },
    { value: 'São Paulo das Missões', text: 'São Paulo das Missões' },
    { value: 'São Pedro da Serra', text: 'São Pedro da Serra' },
    { value: 'São Pedro das Missões', text: 'São Pedro das Missões' },
    { value: 'São Pedro do Butiá', text: 'São Pedro do Butiá' },
    { value: 'São Pedro do Sul', text: 'São Pedro do Sul' },
    { value: 'São Sebastião do Caí', text: 'São Sebastião do Caí' },
    { value: 'São Sepé', text: 'São Sepé' },
    { value: 'São Valentim', text: 'São Valentim' },
    { value: 'São Valentim do Sul', text: 'São Valentim do Sul' },
    { value: 'São Valério do Sul', text: 'São Valério do Sul' },
    { value: 'São Vendelino', text: 'São Vendelino' },
    { value: 'São Vicente do Sul', text: 'São Vicente do Sul' },
    { value: 'Sapiranga', text: 'Sapiranga' },
    { value: 'Sapucaia do Sul', text: 'Sapucaia do Sul' },
    { value: 'Sarandi', text: 'Sarandi' },
    { value: 'Seberi', text: 'Seberi' },
    { value: 'Sede Nova', text: 'Sede Nova' },
    { value: 'Segredo', text: 'Segredo' },
    { value: 'Selbach', text: 'Selbach' },
    { value: 'Senador Salgado Filho', text: 'Senador Salgado Filho' },
    { value: 'Sentinela do Sul', text: 'Sentinela do Sul' },
    { value: 'Serafina Corrêa', text: 'Serafina Corrêa' },
    { value: 'Sério', text: 'Sério' },
    { value: 'Sertão', text: 'Sertão' },
    { value: 'Sertão Santana', text: 'Sertão Santana' },
    { value: 'Sete de Setembro', text: 'Sete de Setembro' },
    { value: 'Severiano de Almeida', text: 'Severiano de Almeida' },
    { value: 'Silveira Martins', text: 'Silveira Martins' },
    { value: 'Sinimbu', text: 'Sinimbu' },
    { value: 'Sobradinho', text: 'Sobradinho' },
    { value: 'Soledade', text: 'Soledade' },
    { value: 'Tabaí', text: 'Tabaí' },
    { value: 'Tapejara', text: 'Tapejara' },
    { value: 'Tapera', text: 'Tapera' },
    { value: 'Tapes', text: 'Tapes' },
    { value: 'Taquara', text: 'Taquara' },
    { value: 'Taquari', text: 'Taquari' },
    { value: 'Taquaruçu do Sul', text: 'Taquaruçu do Sul' },
    { value: 'Tavares', text: 'Tavares' },
    { value: 'Tenente Portela', text: 'Tenente Portela' },
    { value: 'Terra de Areia', text: 'Terra de Areia' },
    { value: 'Teutônia', text: 'Teutônia' },
    { value: 'Tio Hugo', text: 'Tio Hugo' },
    { value: 'Tiradentes do Sul', text: 'Tiradentes do Sul' },
    { value: 'Toropi', text: 'Toropi' },
    { value: 'Torres', text: 'Torres' },
    { value: 'Tramandaí', text: 'Tramandaí' },
    { value: 'Travesseiro', text: 'Travesseiro' },
    { value: 'Três Arroios', text: 'Três Arroios' },
    { value: 'Três Cachoeiras', text: 'Três Cachoeiras' },
    { value: 'Três Coroas', text: 'Três Coroas' },
    { value: 'Três de Maio', text: 'Três de Maio' },
    { value: 'Três Forquilhas', text: 'Três Forquilhas' },
    { value: 'Três Palmeiras', text: 'Três Palmeiras' },
    { value: 'Três Passos', text: 'Três Passos' },
    { value: 'Trindade do Sul', text: 'Trindade do Sul' },
    { value: 'Triunfo', text: 'Triunfo' },
    { value: 'Tucunduva', text: 'Tucunduva' },
    { value: 'Tunas', text: 'Tunas' },
    { value: 'Tupanci do Sul', text: 'Tupanci do Sul' },
    { value: 'Tupanciretã', text: 'Tupanciretã' },
    { value: 'Tupandi', text: 'Tupandi' },
    { value: 'Tuparendi', text: 'Tuparendi' },
    { value: 'Turuçu', text: 'Turuçu' },
    { value: 'Ubiretama', text: 'Ubiretama' },
    { value: 'União da Serra', text: 'União da Serra' },
    { value: 'Unistalda', text: 'Unistalda' },
    { value: 'Uruguaiana', text: 'Uruguaiana' },
    { value: 'Vacaria', text: 'Vacaria' },
    { value: 'Vale do Sol', text: 'Vale do Sol' },
    { value: 'Vale Real', text: 'Vale Real' },
    { value: 'Vale Verde', text: 'Vale Verde' },
    { value: 'Vanini', text: 'Vanini' },
    { value: 'Venâncio Aires', text: 'Venâncio Aires' },
    { value: 'Vera Cruz', text: 'Vera Cruz' },
    { value: 'Veranópolis', text: 'Veranópolis' },
    { value: 'Vespasiano Correa', text: 'Vespasiano Correa' },
    { value: 'Viadutos', text: 'Viadutos' },
    { value: 'Viamão', text: 'Viamão' },
    { value: 'Vicente Dutra', text: 'Vicente Dutra' },
    { value: 'Victor Graeff', text: 'Victor Graeff' },
    { value: 'Vila Flores', text: 'Vila Flores' },
    { value: 'Vila Lângaro', text: 'Vila Lângaro' },
    { value: 'Vila Maria', text: 'Vila Maria' },
    { value: 'Vila Nova do Sul', text: 'Vila Nova do Sul' },
    { value: 'Vista Alegre', text: 'Vista Alegre' },
    { value: 'Vista Alegre do Prata', text: 'Vista Alegre do Prata' },
    { value: 'Vista Gaúcha', text: 'Vista Gaúcha' },
    { value: 'Vitória das Missões', text: 'Vitória das Missões' },
    { value: 'Westfália', text: 'Westfália' },
    { value: 'Xangri-lá', text: 'Xangri-lá' },
  ],
  SC: [
    { value: 'Abdon Batista', text: 'Abdon Batista' },
    { value: 'Abelardo Luz', text: 'Abelardo Luz' },
    { value: 'Agrolândia', text: 'Agrolândia' },
    { value: 'Agronômica', text: 'Agronômica' },
    { value: 'Água Doce', text: 'Água Doce' },
    { value: 'Águas de Chapecó', text: 'Águas de Chapecó' },
    { value: 'Águas Frias', text: 'Águas Frias' },
    { value: 'Águas Mornas', text: 'Águas Mornas' },
    { value: 'Alfredo Wagner', text: 'Alfredo Wagner' },
    { value: 'Alto Bela Vista', text: 'Alto Bela Vista' },
    { value: 'Anchieta', text: 'Anchieta' },
    { value: 'Angelina', text: 'Angelina' },
    { value: 'Anita Garibaldi', text: 'Anita Garibaldi' },
    { value: 'Anitápolis', text: 'Anitápolis' },
    { value: 'Antônio Carlos', text: 'Antônio Carlos' },
    { value: 'Apiúna', text: 'Apiúna' },
    { value: 'Arabutã', text: 'Arabutã' },
    { value: 'Araquari', text: 'Araquari' },
    { value: 'Araranguá', text: 'Araranguá' },
    { value: 'Armazém', text: 'Armazém' },
    { value: 'Arroio Trinta', text: 'Arroio Trinta' },
    { value: 'Arvoredo', text: 'Arvoredo' },
    { value: 'Ascurra', text: 'Ascurra' },
    { value: 'Atalanta', text: 'Atalanta' },
    { value: 'Aurora', text: 'Aurora' },
    { value: 'Balneário Arroio do Silva', text: 'Balneário Arroio do Silva' },
    { value: 'Balneário Barra do Sul', text: 'Balneário Barra do Sul' },
    { value: 'Balneário Camboriú', text: 'Balneário Camboriú' },
    { value: 'Balneário Gaivota', text: 'Balneário Gaivota' },
    { value: 'Bandeirante', text: 'Bandeirante' },
    { value: 'Barra Bonita', text: 'Barra Bonita' },
    { value: 'Barra Velha', text: 'Barra Velha' },
    { value: 'Bela Vista do Toldo', text: 'Bela Vista do Toldo' },
    { value: 'Belmonte', text: 'Belmonte' },
    { value: 'Benedito Novo', text: 'Benedito Novo' },
    { value: 'Biguaçu', text: 'Biguaçu' },
    { value: 'Blumenau', text: 'Blumenau' },
    { value: 'Bocaina do Sul', text: 'Bocaina do Sul' },
    { value: 'Bom Jardim da Serra', text: 'Bom Jardim da Serra' },
    { value: 'Bom Jesus', text: 'Bom Jesus' },
    { value: 'Bom Jesus do Oeste', text: 'Bom Jesus do Oeste' },
    { value: 'Bom Retiro', text: 'Bom Retiro' },
    { value: 'Bombinhas', text: 'Bombinhas' },
    { value: 'Botuverá', text: 'Botuverá' },
    { value: 'Braço do Norte', text: 'Braço do Norte' },
    { value: 'Braço do Trombudo', text: 'Braço do Trombudo' },
    { value: 'Brunópolis', text: 'Brunópolis' },
    { value: 'Brusque', text: 'Brusque' },
    { value: 'Caçador', text: 'Caçador' },
    { value: 'Caibi', text: 'Caibi' },
    { value: 'Calmon', text: 'Calmon' },
    { value: 'Camboriú', text: 'Camboriú' },
    { value: 'Campo Alegre', text: 'Campo Alegre' },
    { value: 'Campo Belo do Sul', text: 'Campo Belo do Sul' },
    { value: 'Campo Erê', text: 'Campo Erê' },
    { value: 'Campos Novos', text: 'Campos Novos' },
    { value: 'Canelinha', text: 'Canelinha' },
    { value: 'Canoinhas', text: 'Canoinhas' },
    { value: 'Capão Alto', text: 'Capão Alto' },
    { value: 'Capinzal', text: 'Capinzal' },
    { value: 'Capivari de Baixo', text: 'Capivari de Baixo' },
    { value: 'Catanduvas', text: 'Catanduvas' },
    { value: 'Caxambu do Sul', text: 'Caxambu do Sul' },
    { value: 'Celso Ramos', text: 'Celso Ramos' },
    { value: 'Cerro Negro', text: 'Cerro Negro' },
    { value: 'Chapadão do Lageado', text: 'Chapadão do Lageado' },
    { value: 'Chapecó', text: 'Chapecó' },
    { value: 'Cocal do Sul', text: 'Cocal do Sul' },
    { value: 'Concórdia', text: 'Concórdia' },
    { value: 'Cordilheira Alta', text: 'Cordilheira Alta' },
    { value: 'Coronel Freitas', text: 'Coronel Freitas' },
    { value: 'Coronel Martins', text: 'Coronel Martins' },
    { value: 'Correia Pinto', text: 'Correia Pinto' },
    { value: 'Corupá', text: 'Corupá' },
    { value: 'Criciúma', text: 'Criciúma' },
    { value: 'Cunha Porã', text: 'Cunha Porã' },
    { value: 'Cunhataí', text: 'Cunhataí' },
    { value: 'Curitibanos', text: 'Curitibanos' },
    { value: 'Descanso', text: 'Descanso' },
    { value: 'Dionísio Cerqueira', text: 'Dionísio Cerqueira' },
    { value: 'Dona Emma', text: 'Dona Emma' },
    { value: 'Doutor Pedrinho', text: 'Doutor Pedrinho' },
    { value: 'Entre Rios', text: 'Entre Rios' },
    { value: 'Ermo', text: 'Ermo' },
    { value: 'Erval Velho', text: 'Erval Velho' },
    { value: 'Faxinal dos Guedes', text: 'Faxinal dos Guedes' },
    { value: 'Flor do Sertão', text: 'Flor do Sertão' },
    { value: 'Florianópolis', text: 'Florianópolis' },
    { value: 'Formosa do Sul', text: 'Formosa do Sul' },
    { value: 'Forquilhinha', text: 'Forquilhinha' },
    { value: 'Fraiburgo', text: 'Fraiburgo' },
    { value: 'Frei Rogério', text: 'Frei Rogério' },
    { value: 'Galvão', text: 'Galvão' },
    { value: 'Garopaba', text: 'Garopaba' },
    { value: 'Garuva', text: 'Garuva' },
    { value: 'Gaspar', text: 'Gaspar' },
    { value: 'Governador Celso Ramos', text: 'Governador Celso Ramos' },
    { value: 'Grão Pará', text: 'Grão Pará' },
    { value: 'Gravatal', text: 'Gravatal' },
    { value: 'Guabiruba', text: 'Guabiruba' },
    { value: 'Guaraciaba', text: 'Guaraciaba' },
    { value: 'Guaramirim', text: 'Guaramirim' },
    { value: 'Guarujá do Sul', text: 'Guarujá do Sul' },
    { value: 'Guatambú', text: 'Guatambú' },
    { value: "Herval d'Oeste", text: "Herval d'Oeste" },
    { value: 'Ibiam', text: 'Ibiam' },
    { value: 'Ibicaré', text: 'Ibicaré' },
    { value: 'Ibirama', text: 'Ibirama' },
    { value: 'Içara', text: 'Içara' },
    { value: 'Ilhota', text: 'Ilhota' },
    { value: 'Imaruí', text: 'Imaruí' },
    { value: 'Imbituba', text: 'Imbituba' },
    { value: 'Imbuia', text: 'Imbuia' },
    { value: 'Indaial', text: 'Indaial' },
    { value: 'Iomerê', text: 'Iomerê' },
    { value: 'Ipira', text: 'Ipira' },
    { value: 'Iporã do Oeste', text: 'Iporã do Oeste' },
    { value: 'Ipuaçu', text: 'Ipuaçu' },
    { value: 'Ipumirim', text: 'Ipumirim' },
    { value: 'Iraceminha', text: 'Iraceminha' },
    { value: 'Irani', text: 'Irani' },
    { value: 'Irati', text: 'Irati' },
    { value: 'Irineópolis', text: 'Irineópolis' },
    { value: 'Itá', text: 'Itá' },
    { value: 'Itaiópolis', text: 'Itaiópolis' },
    { value: 'Itajaí', text: 'Itajaí' },
    { value: 'Itapema', text: 'Itapema' },
    { value: 'Itapiranga', text: 'Itapiranga' },
    { value: 'Itapoá', text: 'Itapoá' },
    { value: 'Ituporanga', text: 'Ituporanga' },
    { value: 'Jaborá', text: 'Jaborá' },
    { value: 'Jacinto Machado', text: 'Jacinto Machado' },
    { value: 'Jaguaruna', text: 'Jaguaruna' },
    { value: 'Jaraguá do Sul', text: 'Jaraguá do Sul' },
    { value: 'Jardinópolis', text: 'Jardinópolis' },
    { value: 'Joaçaba', text: 'Joaçaba' },
    { value: 'Joinville', text: 'Joinville' },
    { value: 'José Boiteux', text: 'José Boiteux' },
    { value: 'Jupiá', text: 'Jupiá' },
    { value: 'Lacerdópolis', text: 'Lacerdópolis' },
    { value: 'Lages', text: 'Lages' },
    { value: 'Laguna', text: 'Laguna' },
    { value: 'Lajeado Grande', text: 'Lajeado Grande' },
    { value: 'Laurentino', text: 'Laurentino' },
    { value: 'Lauro Muller', text: 'Lauro Muller' },
    { value: 'Lebon Régis', text: 'Lebon Régis' },
    { value: 'Leoberto Leal', text: 'Leoberto Leal' },
    { value: 'Lindóia do Sul', text: 'Lindóia do Sul' },
    { value: 'Lontras', text: 'Lontras' },
    { value: 'Luiz Alves', text: 'Luiz Alves' },
    { value: 'Luzerna', text: 'Luzerna' },
    { value: 'Macieira', text: 'Macieira' },
    { value: 'Mafra', text: 'Mafra' },
    { value: 'Major Gercino', text: 'Major Gercino' },
    { value: 'Major Vieira', text: 'Major Vieira' },
    { value: 'Maracajá', text: 'Maracajá' },
    { value: 'Maravilha', text: 'Maravilha' },
    { value: 'Marema', text: 'Marema' },
    { value: 'Massaranduba', text: 'Massaranduba' },
    { value: 'Matos Costa', text: 'Matos Costa' },
    { value: 'Meleiro', text: 'Meleiro' },
    { value: 'Mirim Doce', text: 'Mirim Doce' },
    { value: 'Modelo', text: 'Modelo' },
    { value: 'Mondaí', text: 'Mondaí' },
    { value: 'Monte Carlo', text: 'Monte Carlo' },
    { value: 'Monte Castelo', text: 'Monte Castelo' },
    { value: 'Morro da Fumaça', text: 'Morro da Fumaça' },
    { value: 'Morro Grande', text: 'Morro Grande' },
    { value: 'Navegantes', text: 'Navegantes' },
    { value: 'Nova Erechim', text: 'Nova Erechim' },
    { value: 'Nova Itaberaba', text: 'Nova Itaberaba' },
    { value: 'Nova Trento', text: 'Nova Trento' },
    { value: 'Nova Veneza', text: 'Nova Veneza' },
    { value: 'Novo Horizonte', text: 'Novo Horizonte' },
    { value: 'Orleans', text: 'Orleans' },
    { value: 'Otacílio Costa', text: 'Otacílio Costa' },
    { value: 'Ouro', text: 'Ouro' },
    { value: 'Ouro Verde', text: 'Ouro Verde' },
    { value: 'Paial', text: 'Paial' },
    { value: 'Painel', text: 'Painel' },
    { value: 'Palhoça', text: 'Palhoça' },
    { value: 'Palma Sola', text: 'Palma Sola' },
    { value: 'Palmeira', text: 'Palmeira' },
    { value: 'Palmitos', text: 'Palmitos' },
    { value: 'Papanduva', text: 'Papanduva' },
    { value: 'Paraíso', text: 'Paraíso' },
    { value: 'Passo de Torres', text: 'Passo de Torres' },
    { value: 'Passos Maia', text: 'Passos Maia' },
    { value: 'Paulo Lopes', text: 'Paulo Lopes' },
    { value: 'Pedras Grandes', text: 'Pedras Grandes' },
    { value: 'Penha', text: 'Penha' },
    { value: 'Peritiba', text: 'Peritiba' },
    { value: 'Petrolândia', text: 'Petrolândia' },
    { value: 'Piçarras', text: 'Piçarras' },
    { value: 'Pinhalzinho', text: 'Pinhalzinho' },
    { value: 'Pinheiro Preto', text: 'Pinheiro Preto' },
    { value: 'Piratuba', text: 'Piratuba' },
    { value: 'Planalto Alegre', text: 'Planalto Alegre' },
    { value: 'Pomerode', text: 'Pomerode' },
    { value: 'Ponte Alta', text: 'Ponte Alta' },
    { value: 'Ponte Alta do Norte', text: 'Ponte Alta do Norte' },
    { value: 'Ponte Serrada', text: 'Ponte Serrada' },
    { value: 'Porto Belo', text: 'Porto Belo' },
    { value: 'Porto União', text: 'Porto União' },
    { value: 'Pouso Redondo', text: 'Pouso Redondo' },
    { value: 'Praia Grande', text: 'Praia Grande' },
    { value: 'Presidente Castelo Branco', text: 'Presidente Castelo Branco' },
    { value: 'Presidente Getúlio', text: 'Presidente Getúlio' },
    { value: 'Presidente Nereu', text: 'Presidente Nereu' },
    { value: 'Princesa', text: 'Princesa' },
    { value: 'Quilombo', text: 'Quilombo' },
    { value: 'Rancho Queimado', text: 'Rancho Queimado' },
    { value: 'Rio das Antas', text: 'Rio das Antas' },
    { value: 'Rio do Campo', text: 'Rio do Campo' },
    { value: 'Rio do Oeste', text: 'Rio do Oeste' },
    { value: 'Rio do Sul', text: 'Rio do Sul' },
    { value: 'Rio dos Cedros', text: 'Rio dos Cedros' },
    { value: 'Rio Fortuna', text: 'Rio Fortuna' },
    { value: 'Rio Negrinho', text: 'Rio Negrinho' },
    { value: 'Rio Rufino', text: 'Rio Rufino' },
    { value: 'Riqueza', text: 'Riqueza' },
    { value: 'Rodeio', text: 'Rodeio' },
    { value: 'Romelândia', text: 'Romelândia' },
    { value: 'Salete', text: 'Salete' },
    { value: 'Saltinho', text: 'Saltinho' },
    { value: 'Salto Veloso', text: 'Salto Veloso' },
    { value: 'Sangão', text: 'Sangão' },
    { value: 'Santa Cecília', text: 'Santa Cecília' },
    { value: 'Santa Helena', text: 'Santa Helena' },
    { value: 'Santa Rosa de Lima', text: 'Santa Rosa de Lima' },
    { value: 'Santa Rosa do Sul', text: 'Santa Rosa do Sul' },
    { value: 'Santa Terezinha', text: 'Santa Terezinha' },
    {
      value: 'Santa Terezinha do Progresso',
      text: 'Santa Terezinha do Progresso',
    },
    { value: 'Santiago do Sul', text: 'Santiago do Sul' },
    { value: 'Santo Amaro da Imperatriz', text: 'Santo Amaro da Imperatriz' },
    { value: 'São Bento do Sul', text: 'São Bento do Sul' },
    { value: 'São Bernardino', text: 'São Bernardino' },
    { value: 'São Bonifácio', text: 'São Bonifácio' },
    { value: 'São Carlos', text: 'São Carlos' },
    { value: 'São Cristovão do Sul', text: 'São Cristovão do Sul' },
    { value: 'São Domingos', text: 'São Domingos' },
    { value: 'São Francisco do Sul', text: 'São Francisco do Sul' },
    { value: 'São João Batista', text: 'São João Batista' },
    { value: 'São João do Itaperiú', text: 'São João do Itaperiú' },
    { value: 'São João do Oeste', text: 'São João do Oeste' },
    { value: 'São João do Sul', text: 'São João do Sul' },
    { value: 'São Joaquim', text: 'São Joaquim' },
    { value: 'São José', text: 'São José' },
    { value: 'São José do Cedro', text: 'São José do Cedro' },
    { value: 'São José do Cerrito', text: 'São José do Cerrito' },
    { value: 'São Lourenço do Oeste', text: 'São Lourenço do Oeste' },
    { value: 'São Ludgero', text: 'São Ludgero' },
    { value: 'São Martinho', text: 'São Martinho' },
    { value: 'São Miguel da Boa Vista', text: 'São Miguel da Boa Vista' },
    { value: 'São Miguel do Oeste', text: 'São Miguel do Oeste' },
    { value: 'São Pedro de Alcântara', text: 'São Pedro de Alcântara' },
    { value: 'Saudades', text: 'Saudades' },
    { value: 'Schroeder', text: 'Schroeder' },
    { value: 'Seara', text: 'Seara' },
    { value: 'Serra Alta', text: 'Serra Alta' },
    { value: 'Siderópolis', text: 'Siderópolis' },
    { value: 'Sombrio', text: 'Sombrio' },
    { value: 'Sul Brasil', text: 'Sul Brasil' },
    { value: 'Taió', text: 'Taió' },
    { value: 'Tangará', text: 'Tangará' },
    { value: 'Tigrinhos', text: 'Tigrinhos' },
    { value: 'Tijucas', text: 'Tijucas' },
    { value: 'Timbé do Sul', text: 'Timbé do Sul' },
    { value: 'Timbó', text: 'Timbó' },
    { value: 'Timbó Grande', text: 'Timbó Grande' },
    { value: 'Três Barras', text: 'Três Barras' },
    { value: 'Treviso', text: 'Treviso' },
    { value: 'Treze de Maio', text: 'Treze de Maio' },
    { value: 'Treze Tílias', text: 'Treze Tílias' },
    { value: 'Trombudo Central', text: 'Trombudo Central' },
    { value: 'Tubarão', text: 'Tubarão' },
    { value: 'Tunápolis', text: 'Tunápolis' },
    { value: 'Turvo', text: 'Turvo' },
    { value: 'União do Oeste', text: 'União do Oeste' },
    { value: 'Urubici', text: 'Urubici' },
    { value: 'Urupema', text: 'Urupema' },
    { value: 'Urussanga', text: 'Urussanga' },
    { value: 'Vargeão', text: 'Vargeão' },
    { value: 'Vargem', text: 'Vargem' },
    { value: 'Vargem Bonita', text: 'Vargem Bonita' },
    { value: 'Vidal Ramos', text: 'Vidal Ramos' },
    { value: 'Videira', text: 'Videira' },
    { value: 'Vitor Meireles', text: 'Vitor Meireles' },
    { value: 'Witmarsum', text: 'Witmarsum' },
    { value: 'Xanxerê', text: 'Xanxerê' },
    { value: 'Xavantina', text: 'Xavantina' },
    { value: 'Xaxim', text: 'Xaxim' },
    { value: 'Zortéa', text: 'Zortéa' },
  ],
  SE: [
    { value: 'Amparo de São Francisco', text: 'Amparo de São Francisco' },
    { value: 'Aquidabã', text: 'Aquidabã' },
    { value: 'Aracaju', text: 'Aracaju' },
    { value: 'Arauá', text: 'Arauá' },
    { value: 'Areia Branca', text: 'Areia Branca' },
    { value: 'Barra dos Coqueiros', text: 'Barra dos Coqueiros' },
    { value: 'Boquim', text: 'Boquim' },
    { value: 'Brejo Grande', text: 'Brejo Grande' },
    { value: 'Campo do Brito', text: 'Campo do Brito' },
    { value: 'Canhoba', text: 'Canhoba' },
    { value: 'Canindé de São Francisco', text: 'Canindé de São Francisco' },
    { value: 'Capela', text: 'Capela' },
    { value: 'Carira', text: 'Carira' },
    { value: 'Carmópolis', text: 'Carmópolis' },
    { value: 'Cedro de São João', text: 'Cedro de São João' },
    { value: 'Cristinápolis', text: 'Cristinápolis' },
    { value: 'Cumbe', text: 'Cumbe' },
    { value: 'Divina Pastora', text: 'Divina Pastora' },
    { value: 'Estância', text: 'Estância' },
    { value: 'Feira Nova', text: 'Feira Nova' },
    { value: 'Frei Paulo', text: 'Frei Paulo' },
    { value: 'Gararu', text: 'Gararu' },
    { value: 'General Maynard', text: 'General Maynard' },
    { value: 'Gracho Cardoso', text: 'Gracho Cardoso' },
    { value: 'Ilha das Flores', text: 'Ilha das Flores' },
    { value: 'Indiaroba', text: 'Indiaroba' },
    { value: 'Itabaiana', text: 'Itabaiana' },
    { value: 'Itabaianinha', text: 'Itabaianinha' },
    { value: 'Itabi', text: 'Itabi' },
    { value: "Itaporanga d'Ajuda", text: "Itaporanga d'Ajuda" },
    { value: 'Japaratuba', text: 'Japaratuba' },
    { value: 'Japoatã', text: 'Japoatã' },
    { value: 'Lagarto', text: 'Lagarto' },
    { value: 'Laranjeiras', text: 'Laranjeiras' },
    { value: 'Macambira', text: 'Macambira' },
    { value: 'Malhada dos Bois', text: 'Malhada dos Bois' },
    { value: 'Malhador', text: 'Malhador' },
    { value: 'Maruim', text: 'Maruim' },
    { value: 'Moita Bonita', text: 'Moita Bonita' },
    { value: 'Monte Alegre de Sergipe', text: 'Monte Alegre de Sergipe' },
    { value: 'Muribeca', text: 'Muribeca' },
    { value: 'Neópolis', text: 'Neópolis' },
    { value: 'Nossa Senhora Aparecida', text: 'Nossa Senhora Aparecida' },
    { value: 'Nossa Senhora da Glória', text: 'Nossa Senhora da Glória' },
    { value: 'Nossa Senhora das Dores', text: 'Nossa Senhora das Dores' },
    { value: 'Nossa Senhora de Lourdes', text: 'Nossa Senhora de Lourdes' },
    { value: 'Nossa Senhora do Socorro', text: 'Nossa Senhora do Socorro' },
    { value: 'Pacatuba', text: 'Pacatuba' },
    { value: 'Pedra Mole', text: 'Pedra Mole' },
    { value: 'Pedrinhas', text: 'Pedrinhas' },
    { value: 'Pinhão', text: 'Pinhão' },
    { value: 'Pirambu', text: 'Pirambu' },
    { value: 'Poço Redondo', text: 'Poço Redondo' },
    { value: 'Poço Verde', text: 'Poço Verde' },
    { value: 'Porto da Folha', text: 'Porto da Folha' },
    { value: 'Propriá', text: 'Propriá' },
    { value: 'Riachão do Dantas', text: 'Riachão do Dantas' },
    { value: 'Riachuelo', text: 'Riachuelo' },
    { value: 'Ribeirópolis', text: 'Ribeirópolis' },
    { value: 'Rosário do Catete', text: 'Rosário do Catete' },
    { value: 'Salgado', text: 'Salgado' },
    { value: 'Santa Luzia do Itanhy', text: 'Santa Luzia do Itanhy' },
    { value: 'Santa Rosa de Lima', text: 'Santa Rosa de Lima' },
    { value: 'Santana do São Francisco', text: 'Santana do São Francisco' },
    { value: 'Santo Amaro das Brotas', text: 'Santo Amaro das Brotas' },
    { value: 'São Cristóvão', text: 'São Cristóvão' },
    { value: 'São Domingos', text: 'São Domingos' },
    { value: 'São Francisco', text: 'São Francisco' },
    { value: 'São Miguel do Aleixo', text: 'São Miguel do Aleixo' },
    { value: 'Simão Dias', text: 'Simão Dias' },
    { value: 'Siriri', text: 'Siriri' },
    { value: 'Telha', text: 'Telha' },
    { value: 'Tobias Barreto', text: 'Tobias Barreto' },
    { value: 'Tomar do Geru', text: 'Tomar do Geru' },
    { value: 'Umbaúba', text: 'Umbaúba' },
  ],
  SP: [
    { value: 'Adamantina', text: 'Adamantina' },
    { value: 'Adolfo', text: 'Adolfo' },
    { value: 'Aguaí', text: 'Aguaí' },
    { value: 'Águas da Prata', text: 'Águas da Prata' },
    { value: 'Águas de Lindóia', text: 'Águas de Lindóia' },
    { value: 'Águas de Santa Bárbara', text: 'Águas de Santa Bárbara' },
    { value: 'Águas de São Pedro', text: 'Águas de São Pedro' },
    { value: 'Agudos', text: 'Agudos' },
    { value: 'Alambari', text: 'Alambari' },
    { value: 'Alfredo Marcondes', text: 'Alfredo Marcondes' },
    { value: 'Altair', text: 'Altair' },
    { value: 'Altinópolis', text: 'Altinópolis' },
    { value: 'Alto Alegre', text: 'Alto Alegre' },
    { value: 'Alumínio', text: 'Alumínio' },
    { value: 'Álvares Florence', text: 'Álvares Florence' },
    { value: 'Álvares Machado', text: 'Álvares Machado' },
    { value: 'Álvaro de Carvalho', text: 'Álvaro de Carvalho' },
    { value: 'Alvinlândia', text: 'Alvinlândia' },
    { value: 'Americana', text: 'Americana' },
    { value: 'Américo Brasiliense', text: 'Américo Brasiliense' },
    { value: 'Américo de Campos', text: 'Américo de Campos' },
    { value: 'Amparo', text: 'Amparo' },
    { value: 'Analândia', text: 'Analândia' },
    { value: 'Andradina', text: 'Andradina' },
    { value: 'Angatuba', text: 'Angatuba' },
    { value: 'Anhembi', text: 'Anhembi' },
    { value: 'Anhumas', text: 'Anhumas' },
    { value: 'Aparecida', text: 'Aparecida' },
    { value: "Aparecida d'Oeste", text: "Aparecida d'Oeste" },
    { value: 'Apiaí', text: 'Apiaí' },
    { value: 'Araçariguama', text: 'Araçariguama' },
    { value: 'Araçatuba', text: 'Araçatuba' },
    { value: 'Araçoiaba da Serra', text: 'Araçoiaba da Serra' },
    { value: 'Aramina', text: 'Aramina' },
    { value: 'Arandu', text: 'Arandu' },
    { value: 'Arapeí', text: 'Arapeí' },
    { value: 'Araraquara', text: 'Araraquara' },
    { value: 'Araras', text: 'Araras' },
    { value: 'Arco-Íris', text: 'Arco-Íris' },
    { value: 'Arealva', text: 'Arealva' },
    { value: 'Areias', text: 'Areias' },
    { value: 'Areiópolis', text: 'Areiópolis' },
    { value: 'Ariranha', text: 'Ariranha' },
    { value: 'Artur Nogueira', text: 'Artur Nogueira' },
    { value: 'Arujá', text: 'Arujá' },
    { value: 'Aspásia', text: 'Aspásia' },
    { value: 'Assis', text: 'Assis' },
    { value: 'Atibaia', text: 'Atibaia' },
    { value: 'Auriflama', text: 'Auriflama' },
    { value: 'Avaí', text: 'Avaí' },
    { value: 'Avanhandava', text: 'Avanhandava' },
    { value: 'Avaré', text: 'Avaré' },
    { value: 'Bady Bassitt', text: 'Bady Bassitt' },
    { value: 'Balbinos', text: 'Balbinos' },
    { value: 'Bálsamo', text: 'Bálsamo' },
    { value: 'Bananal', text: 'Bananal' },
    { value: 'Barão de Antonina', text: 'Barão de Antonina' },
    { value: 'Barbosa', text: 'Barbosa' },
    { value: 'Bariri', text: 'Bariri' },
    { value: 'Barra Bonita', text: 'Barra Bonita' },
    { value: 'Barra do Chapéu', text: 'Barra do Chapéu' },
    { value: 'Barra do Turvo', text: 'Barra do Turvo' },
    { value: 'Barretos', text: 'Barretos' },
    { value: 'Barrinha', text: 'Barrinha' },
    { value: 'Barueri', text: 'Barueri' },
    { value: 'Bastos', text: 'Bastos' },
    { value: 'Batatais', text: 'Batatais' },
    { value: 'Bauru', text: 'Bauru' },
    { value: 'Bebedouro', text: 'Bebedouro' },
    { value: 'Bento de Abreu', text: 'Bento de Abreu' },
    { value: 'Bernardino de Campos', text: 'Bernardino de Campos' },
    { value: 'Bertioga', text: 'Bertioga' },
    { value: 'Bilac', text: 'Bilac' },
    { value: 'Birigui', text: 'Birigui' },
    { value: 'Biritiba-Mirim', text: 'Biritiba-Mirim' },
    { value: 'Boa Esperança do Sul', text: 'Boa Esperança do Sul' },
    { value: 'Bocaina', text: 'Bocaina' },
    { value: 'Bofete', text: 'Bofete' },
    { value: 'Boituva', text: 'Boituva' },
    { value: 'Bom Jesus dos Perdões', text: 'Bom Jesus dos Perdões' },
    { value: 'Bom Sucesso de Itararé', text: 'Bom Sucesso de Itararé' },
    { value: 'Borá', text: 'Borá' },
    { value: 'Boracéia', text: 'Boracéia' },
    { value: 'Borborema', text: 'Borborema' },
    { value: 'Borebi', text: 'Borebi' },
    { value: 'Botucatu', text: 'Botucatu' },
    { value: 'Bragança Paulista', text: 'Bragança Paulista' },
    { value: 'Braúna', text: 'Braúna' },
    { value: 'Brejo Alegre', text: 'Brejo Alegre' },
    { value: 'Brodowski', text: 'Brodowski' },
    { value: 'Brotas', text: 'Brotas' },
    { value: 'Buri', text: 'Buri' },
    { value: 'Buritama', text: 'Buritama' },
    { value: 'Buritizal', text: 'Buritizal' },
    { value: 'Cabrália Paulista', text: 'Cabrália Paulista' },
    { value: 'Cabreúva', text: 'Cabreúva' },
    { value: 'Caçapava', text: 'Caçapava' },
    { value: 'Cachoeira Paulista', text: 'Cachoeira Paulista' },
    { value: 'Caconde', text: 'Caconde' },
    { value: 'Cafelândia', text: 'Cafelândia' },
    { value: 'Caiabu', text: 'Caiabu' },
    { value: 'Caieiras', text: 'Caieiras' },
    { value: 'Caiuá', text: 'Caiuá' },
    { value: 'Cajamar', text: 'Cajamar' },
    { value: 'Cajati', text: 'Cajati' },
    { value: 'Cajobi', text: 'Cajobi' },
    { value: 'Cajuru', text: 'Cajuru' },
    { value: 'Campina do Monte Alegre', text: 'Campina do Monte Alegre' },
    { value: 'Campinas', text: 'Campinas' },
    { value: 'Campo Limpo Paulista', text: 'Campo Limpo Paulista' },
    { value: 'Campos do Jordão', text: 'Campos do Jordão' },
    { value: 'Campos Novos Paulista', text: 'Campos Novos Paulista' },
    { value: 'Cananéia', text: 'Cananéia' },
    { value: 'Canas', text: 'Canas' },
    { value: 'Cândido Mota', text: 'Cândido Mota' },
    { value: 'Cândido Rodrigues', text: 'Cândido Rodrigues' },
    { value: 'Canitar', text: 'Canitar' },
    { value: 'Capão Bonito', text: 'Capão Bonito' },
    { value: 'Capela do Alto', text: 'Capela do Alto' },
    { value: 'Capivari', text: 'Capivari' },
    { value: 'Caraguatatuba', text: 'Caraguatatuba' },
    { value: 'Carapicuíba', text: 'Carapicuíba' },
    { value: 'Cardoso', text: 'Cardoso' },
    { value: 'Casa Branca', text: 'Casa Branca' },
    { value: 'Cássia dos Coqueiros', text: 'Cássia dos Coqueiros' },
    { value: 'Castilho', text: 'Castilho' },
    { value: 'Catanduva', text: 'Catanduva' },
    { value: 'Catiguá', text: 'Catiguá' },
    { value: 'Cedral', text: 'Cedral' },
    { value: 'Cerqueira César', text: 'Cerqueira César' },
    { value: 'Cerquilho', text: 'Cerquilho' },
    { value: 'Cesário Lange', text: 'Cesário Lange' },
    { value: 'Charqueada', text: 'Charqueada' },
    { value: 'Chavantes', text: 'Chavantes' },
    { value: 'Clementina', text: 'Clementina' },
    { value: 'Colina', text: 'Colina' },
    { value: 'Colômbia', text: 'Colômbia' },
    { value: 'Conchal', text: 'Conchal' },
    { value: 'Conchas', text: 'Conchas' },
    { value: 'Cordeirópolis', text: 'Cordeirópolis' },
    { value: 'Coroados', text: 'Coroados' },
    { value: 'Coronel Macedo', text: 'Coronel Macedo' },
    { value: 'Corumbataí', text: 'Corumbataí' },
    { value: 'Cosmópolis', text: 'Cosmópolis' },
    { value: 'Cosmorama', text: 'Cosmorama' },
    { value: 'Cotia', text: 'Cotia' },
    { value: 'Cravinhos', text: 'Cravinhos' },
    { value: 'Cristais Paulista', text: 'Cristais Paulista' },
    { value: 'Cruzália', text: 'Cruzália' },
    { value: 'Cruzeiro', text: 'Cruzeiro' },
    { value: 'Cubatão', text: 'Cubatão' },
    { value: 'Cunha', text: 'Cunha' },
    { value: 'Descalvado', text: 'Descalvado' },
    { value: 'Diadema', text: 'Diadema' },
    { value: 'Dirce Reis', text: 'Dirce Reis' },
    { value: 'Divinolândia', text: 'Divinolândia' },
    { value: 'Dobrada', text: 'Dobrada' },
    { value: 'Dois Córregos', text: 'Dois Córregos' },
    { value: 'Dolcinópolis', text: 'Dolcinópolis' },
    { value: 'Dourado', text: 'Dourado' },
    { value: 'Dracena', text: 'Dracena' },
    { value: 'Duartina', text: 'Duartina' },
    { value: 'Dumont', text: 'Dumont' },
    { value: 'Echaporã', text: 'Echaporã' },
    { value: 'Eldorado', text: 'Eldorado' },
    { value: 'Elias Fausto', text: 'Elias Fausto' },
    { value: 'Elisiário', text: 'Elisiário' },
    { value: 'Embaúba', text: 'Embaúba' },
    { value: 'Embu', text: 'Embu' },
    { value: 'Embu-Guaçu', text: 'Embu-Guaçu' },
    { value: 'Emilianópolis', text: 'Emilianópolis' },
    { value: 'Engenheiro Coelho', text: 'Engenheiro Coelho' },
    { value: 'Espírito Santo do Pinhal', text: 'Espírito Santo do Pinhal' },
    { value: 'Espírito Santo do Turvo', text: 'Espírito Santo do Turvo' },
    { value: 'Estiva Gerbi', text: 'Estiva Gerbi' },
    { value: "Estrela d'Oeste", text: "Estrela d'Oeste" },
    { value: 'Estrela do Norte', text: 'Estrela do Norte' },
    { value: 'Euclides da Cunha Paulista', text: 'Euclides da Cunha Paulista' },
    { value: 'Fartura', text: 'Fartura' },
    { value: 'Fernando Prestes', text: 'Fernando Prestes' },
    { value: 'Fernandópolis', text: 'Fernandópolis' },
    { value: 'Fernão', text: 'Fernão' },
    { value: 'Ferraz de Vasconcelos', text: 'Ferraz de Vasconcelos' },
    { value: 'Flora Rica', text: 'Flora Rica' },
    { value: 'Floreal', text: 'Floreal' },
    { value: 'Florínia', text: 'Florínia' },
    { value: 'Flórida Paulista', text: 'Flórida Paulista' },
    { value: 'Franca', text: 'Franca' },
    { value: 'Francisco Morato', text: 'Francisco Morato' },
    { value: 'Franco da Rocha', text: 'Franco da Rocha' },
    { value: 'Gabriel Monteiro', text: 'Gabriel Monteiro' },
    { value: 'Gália', text: 'Gália' },
    { value: 'Garça', text: 'Garça' },
    { value: 'Gastão Vidigal', text: 'Gastão Vidigal' },
    { value: 'Gavião Peixoto', text: 'Gavião Peixoto' },
    { value: 'General Salgado', text: 'General Salgado' },
    { value: 'Getulina', text: 'Getulina' },
    { value: 'Glicério', text: 'Glicério' },
    { value: 'Guaiçara', text: 'Guaiçara' },
    { value: 'Guaimbê', text: 'Guaimbê' },
    { value: 'Guaíra', text: 'Guaíra' },
    { value: 'Guapiaçu', text: 'Guapiaçu' },
    { value: 'Guapiara', text: 'Guapiara' },
    { value: 'Guará', text: 'Guará' },
    { value: 'Guaraçaí', text: 'Guaraçaí' },
    { value: 'Guaraci', text: 'Guaraci' },
    { value: "Guarani d'Oeste", text: "Guarani d'Oeste" },
    { value: 'Guarantã', text: 'Guarantã' },
    { value: 'Guararapes', text: 'Guararapes' },
    { value: 'Guararema', text: 'Guararema' },
    { value: 'Guaratinguetá', text: 'Guaratinguetá' },
    { value: 'Guareí', text: 'Guareí' },
    { value: 'Guariba', text: 'Guariba' },
    { value: 'Guarujá', text: 'Guarujá' },
    { value: 'Guarulhos', text: 'Guarulhos' },
    { value: 'Guatapará', text: 'Guatapará' },
    { value: 'Guzolândia', text: 'Guzolândia' },
    { value: 'Herculândia', text: 'Herculândia' },
    { value: 'Holambra', text: 'Holambra' },
    { value: 'Hortolândia', text: 'Hortolândia' },
    { value: 'Iacanga', text: 'Iacanga' },
    { value: 'Iacri', text: 'Iacri' },
    { value: 'Iaras', text: 'Iaras' },
    { value: 'Ibaté', text: 'Ibaté' },
    { value: 'Ibirá', text: 'Ibirá' },
    { value: 'Ibirarema', text: 'Ibirarema' },
    { value: 'Ibitinga', text: 'Ibitinga' },
    { value: 'Ibiúna', text: 'Ibiúna' },
    { value: 'Icém', text: 'Icém' },
    { value: 'Iepê', text: 'Iepê' },
    { value: 'Igaraçu do Tietê', text: 'Igaraçu do Tietê' },
    { value: 'Igarapava', text: 'Igarapava' },
    { value: 'Igaratá', text: 'Igaratá' },
    { value: 'Iguape', text: 'Iguape' },
    { value: 'Ilha Comprida', text: 'Ilha Comprida' },
    { value: 'Ilha Solteira', text: 'Ilha Solteira' },
    { value: 'Ilhabela', text: 'Ilhabela' },
    { value: 'Indaiatuba', text: 'Indaiatuba' },
    { value: 'Indiana', text: 'Indiana' },
    { value: 'Indiaporã', text: 'Indiaporã' },
    { value: 'Inúbia Paulista', text: 'Inúbia Paulista' },
    { value: 'Ipauçu', text: 'Ipauçu' },
    { value: 'Iperó', text: 'Iperó' },
    { value: 'Ipeúna', text: 'Ipeúna' },
    { value: 'Ipiguá', text: 'Ipiguá' },
    { value: 'Iporanga', text: 'Iporanga' },
    { value: 'Ipuã', text: 'Ipuã' },
    { value: 'Iracemápolis', text: 'Iracemápolis' },
    { value: 'Irapuã', text: 'Irapuã' },
    { value: 'Irapuru', text: 'Irapuru' },
    { value: 'Itaberá', text: 'Itaberá' },
    { value: 'Itaí', text: 'Itaí' },
    { value: 'Itajobi', text: 'Itajobi' },
    { value: 'Itaju', text: 'Itaju' },
    { value: 'Itanhaém', text: 'Itanhaém' },
    { value: 'Itaóca', text: 'Itaóca' },
    { value: 'Itapecerica da Serra', text: 'Itapecerica da Serra' },
    { value: 'Itapetininga', text: 'Itapetininga' },
    { value: 'Itapeva', text: 'Itapeva' },
    { value: 'Itapevi', text: 'Itapevi' },
    { value: 'Itapira', text: 'Itapira' },
    { value: 'Itapirapuã Paulista', text: 'Itapirapuã Paulista' },
    { value: 'Itápolis', text: 'Itápolis' },
    { value: 'Itaporanga', text: 'Itaporanga' },
    { value: 'Itapuí', text: 'Itapuí' },
    { value: 'Itapura', text: 'Itapura' },
    { value: 'Itaquaquecetuba', text: 'Itaquaquecetuba' },
    { value: 'Itararé', text: 'Itararé' },
    { value: 'Itariri', text: 'Itariri' },
    { value: 'Itatiba', text: 'Itatiba' },
    { value: 'Itatinga', text: 'Itatinga' },
    { value: 'Itirapina', text: 'Itirapina' },
    { value: 'Itirapuã', text: 'Itirapuã' },
    { value: 'Itobi', text: 'Itobi' },
    { value: 'Itu', text: 'Itu' },
    { value: 'Itupeva', text: 'Itupeva' },
    { value: 'Ituverava', text: 'Ituverava' },
    { value: 'Jaborandi', text: 'Jaborandi' },
    { value: 'Jaboticabal', text: 'Jaboticabal' },
    { value: 'Jacareí', text: 'Jacareí' },
    { value: 'Jaci', text: 'Jaci' },
    { value: 'Jacupiranga', text: 'Jacupiranga' },
    { value: 'Jaguariúna', text: 'Jaguariúna' },
    { value: 'Jales', text: 'Jales' },
    { value: 'Jambeiro', text: 'Jambeiro' },
    { value: 'Jandira', text: 'Jandira' },
    { value: 'Jardinópolis', text: 'Jardinópolis' },
    { value: 'Jarinu', text: 'Jarinu' },
    { value: 'Jaú', text: 'Jaú' },
    { value: 'Jeriquara', text: 'Jeriquara' },
    { value: 'Joanópolis', text: 'Joanópolis' },
    { value: 'João Ramalho', text: 'João Ramalho' },
    { value: 'José Bonifácio', text: 'José Bonifácio' },
    { value: 'Júlio Mesquita', text: 'Júlio Mesquita' },
    { value: 'Jumirim', text: 'Jumirim' },
    { value: 'Jundiaí', text: 'Jundiaí' },
    { value: 'Junqueirópolis', text: 'Junqueirópolis' },
    { value: 'Juquiá', text: 'Juquiá' },
    { value: 'Juquitiba', text: 'Juquitiba' },
    { value: 'Lagoinha', text: 'Lagoinha' },
    { value: 'Laranjal Paulista', text: 'Laranjal Paulista' },
    { value: 'Lavínia', text: 'Lavínia' },
    { value: 'Lavrinhas', text: 'Lavrinhas' },
    { value: 'Leme', text: 'Leme' },
    { value: 'Lençóis Paulista', text: 'Lençóis Paulista' },
    { value: 'Limeira', text: 'Limeira' },
    { value: 'Lindóia', text: 'Lindóia' },
    { value: 'Lins', text: 'Lins' },
    { value: 'Lorena', text: 'Lorena' },
    { value: 'Lourdes', text: 'Lourdes' },
    { value: 'Louveira', text: 'Louveira' },
    { value: 'Lucélia', text: 'Lucélia' },
    { value: 'Lucianópolis', text: 'Lucianópolis' },
    { value: 'Luís Antônio', text: 'Luís Antônio' },
    { value: 'Luiziânia', text: 'Luiziânia' },
    { value: 'Lupércio', text: 'Lupércio' },
    { value: 'Lutécia', text: 'Lutécia' },
    { value: 'Macatuba', text: 'Macatuba' },
    { value: 'Macaubal', text: 'Macaubal' },
    { value: 'Macedônia', text: 'Macedônia' },
    { value: 'Magda', text: 'Magda' },
    { value: 'Mairinque', text: 'Mairinque' },
    { value: 'Mairiporã', text: 'Mairiporã' },
    { value: 'Manduri', text: 'Manduri' },
    { value: 'Marabá Paulista', text: 'Marabá Paulista' },
    { value: 'Maracaí', text: 'Maracaí' },
    { value: 'Marapoama', text: 'Marapoama' },
    { value: 'Mariápolis', text: 'Mariápolis' },
    { value: 'Marília', text: 'Marília' },
    { value: 'Marinópolis', text: 'Marinópolis' },
    { value: 'Martinópolis', text: 'Martinópolis' },
    { value: 'Matão', text: 'Matão' },
    { value: 'Mauá', text: 'Mauá' },
    { value: 'Mendonça', text: 'Mendonça' },
    { value: 'Meridiano', text: 'Meridiano' },
    { value: 'Mesópolis', text: 'Mesópolis' },
    { value: 'Miguelópolis', text: 'Miguelópolis' },
    { value: 'Mineiros do Tietê', text: 'Mineiros do Tietê' },
    { value: 'Mira Estrela', text: 'Mira Estrela' },
    { value: 'Miracatu', text: 'Miracatu' },
    { value: 'Mirandópolis', text: 'Mirandópolis' },
    { value: 'Mirante do Paranapanema', text: 'Mirante do Paranapanema' },
    { value: 'Mirassol', text: 'Mirassol' },
    { value: 'Mirassolândia', text: 'Mirassolândia' },
    { value: 'Mococa', text: 'Mococa' },
    { value: 'Mogi das Cruzes', text: 'Mogi das Cruzes' },
    { value: 'Mogi-Guaçu', text: 'Mogi-Guaçu' },
    { value: 'Mogi-Mirim', text: 'Mogi-Mirim' },
    { value: 'Mombuca', text: 'Mombuca' },
    { value: 'Monções', text: 'Monções' },
    { value: 'Mongaguá', text: 'Mongaguá' },
    { value: 'Monte Alegre do Sul', text: 'Monte Alegre do Sul' },
    { value: 'Monte Alto', text: 'Monte Alto' },
    { value: 'Monte Aprazível', text: 'Monte Aprazível' },
    { value: 'Monte Azul Paulista', text: 'Monte Azul Paulista' },
    { value: 'Monte Castelo', text: 'Monte Castelo' },
    { value: 'Monte Mor', text: 'Monte Mor' },
    { value: 'Monteiro Lobato', text: 'Monteiro Lobato' },
    { value: 'Morro Agudo', text: 'Morro Agudo' },
    { value: 'Morungaba', text: 'Morungaba' },
    { value: 'Motuca', text: 'Motuca' },
    { value: 'Murutinga do Sul', text: 'Murutinga do Sul' },
    { value: 'Nantes', text: 'Nantes' },
    { value: 'Narandiba', text: 'Narandiba' },
    { value: 'Natividade da Serra', text: 'Natividade da Serra' },
    { value: 'Nazaré Paulista', text: 'Nazaré Paulista' },
    { value: 'Neves Paulista', text: 'Neves Paulista' },
    { value: 'Nhandeara', text: 'Nhandeara' },
    { value: 'Nipoã', text: 'Nipoã' },
    { value: 'Nova Aliança', text: 'Nova Aliança' },
    { value: 'Nova Campina', text: 'Nova Campina' },
    { value: 'Nova Canaã Paulista', text: 'Nova Canaã Paulista' },
    { value: 'Nova Castilho', text: 'Nova Castilho' },
    { value: 'Nova Europa', text: 'Nova Europa' },
    { value: 'Nova Granada', text: 'Nova Granada' },
    { value: 'Nova Guataporanga', text: 'Nova Guataporanga' },
    { value: 'Nova Independência', text: 'Nova Independência' },
    { value: 'Nova Luzitânia', text: 'Nova Luzitânia' },
    { value: 'Nova Odessa', text: 'Nova Odessa' },
    { value: 'Novais', text: 'Novais' },
    { value: 'Novo Horizonte', text: 'Novo Horizonte' },
    { value: 'Nuporanga', text: 'Nuporanga' },
    { value: 'Ocauçu', text: 'Ocauçu' },
    { value: 'Óleo', text: 'Óleo' },
    { value: 'Olímpia', text: 'Olímpia' },
    { value: 'Onda Verde', text: 'Onda Verde' },
    { value: 'Oriente', text: 'Oriente' },
    { value: 'Orindiúva', text: 'Orindiúva' },
    { value: 'Orlândia', text: 'Orlândia' },
    { value: 'Osasco', text: 'Osasco' },
    { value: 'Oscar Bressane', text: 'Oscar Bressane' },
    { value: 'Osvaldo Cruz', text: 'Osvaldo Cruz' },
    { value: 'Ourinhos', text: 'Ourinhos' },
    { value: 'Ouro Verde', text: 'Ouro Verde' },
    { value: 'Ouroeste', text: 'Ouroeste' },
    { value: 'Pacaembu', text: 'Pacaembu' },
    { value: 'Palestina', text: 'Palestina' },
    { value: 'Palmares Paulista', text: 'Palmares Paulista' },
    { value: "Palmeira d'Oeste", text: "Palmeira d'Oeste" },
    { value: 'Palmital', text: 'Palmital' },
    { value: 'Panorama', text: 'Panorama' },
    { value: 'Paraguaçu Paulista', text: 'Paraguaçu Paulista' },
    { value: 'Paraibuna', text: 'Paraibuna' },
    { value: 'Paraíso', text: 'Paraíso' },
    { value: 'Paranapanema', text: 'Paranapanema' },
    { value: 'Paranapuã', text: 'Paranapuã' },
    { value: 'Parapuã', text: 'Parapuã' },
    { value: 'Pardinho', text: 'Pardinho' },
    { value: 'Pariquera-Açu', text: 'Pariquera-Açu' },
    { value: 'Parisi', text: 'Parisi' },
    { value: 'Patrocínio Paulista', text: 'Patrocínio Paulista' },
    { value: 'Paulicéia', text: 'Paulicéia' },
    { value: 'Paulínia', text: 'Paulínia' },
    { value: 'Paulistânia', text: 'Paulistânia' },
    { value: 'Paulo de Faria', text: 'Paulo de Faria' },
    { value: 'Pederneiras', text: 'Pederneiras' },
    { value: 'Pedra Bela', text: 'Pedra Bela' },
    { value: 'Pedranópolis', text: 'Pedranópolis' },
    { value: 'Pedregulho', text: 'Pedregulho' },
    { value: 'Pedreira', text: 'Pedreira' },
    { value: 'Pedrinhas Paulista', text: 'Pedrinhas Paulista' },
    { value: 'Pedro de Toledo', text: 'Pedro de Toledo' },
    { value: 'Penápolis', text: 'Penápolis' },
    { value: 'Pereira Barreto', text: 'Pereira Barreto' },
    { value: 'Pereiras', text: 'Pereiras' },
    { value: 'Peruíbe', text: 'Peruíbe' },
    { value: 'Piacatu', text: 'Piacatu' },
    { value: 'Piedade', text: 'Piedade' },
    { value: 'Pilar do Sul', text: 'Pilar do Sul' },
    { value: 'Pindamonhangaba', text: 'Pindamonhangaba' },
    { value: 'Pindorama', text: 'Pindorama' },
    { value: 'Pinhalzinho', text: 'Pinhalzinho' },
    { value: 'Piquerobi', text: 'Piquerobi' },
    { value: 'Piquete', text: 'Piquete' },
    { value: 'Piracaia', text: 'Piracaia' },
    { value: 'Piracicaba', text: 'Piracicaba' },
    { value: 'Piraju', text: 'Piraju' },
    { value: 'Pirajuí', text: 'Pirajuí' },
    { value: 'Pirangi', text: 'Pirangi' },
    { value: 'Pirapora do Bom Jesus', text: 'Pirapora do Bom Jesus' },
    { value: 'Pirapozinho', text: 'Pirapozinho' },
    { value: 'Pirassununga', text: 'Pirassununga' },
    { value: 'Piratininga', text: 'Piratininga' },
    { value: 'Pitangueiras', text: 'Pitangueiras' },
    { value: 'Planalto', text: 'Planalto' },
    { value: 'Platina', text: 'Platina' },
    { value: 'Poá', text: 'Poá' },
    { value: 'Poloni', text: 'Poloni' },
    { value: 'Pompéia', text: 'Pompéia' },
    { value: 'Pongaí', text: 'Pongaí' },
    { value: 'Pontal', text: 'Pontal' },
    { value: 'Pontalinda', text: 'Pontalinda' },
    { value: 'Pontes Gestal', text: 'Pontes Gestal' },
    { value: 'Populina', text: 'Populina' },
    { value: 'Porangaba', text: 'Porangaba' },
    { value: 'Porto Feliz', text: 'Porto Feliz' },
    { value: 'Porto Ferreira', text: 'Porto Ferreira' },
    { value: 'Potim', text: 'Potim' },
    { value: 'Potirendaba', text: 'Potirendaba' },
    { value: 'Pracinha', text: 'Pracinha' },
    { value: 'Pradópolis', text: 'Pradópolis' },
    { value: 'Praia Grande', text: 'Praia Grande' },
    { value: 'Pratânia', text: 'Pratânia' },
    { value: 'Presidente Alves', text: 'Presidente Alves' },
    { value: 'Presidente Bernardes', text: 'Presidente Bernardes' },
    { value: 'Presidente Epitácio', text: 'Presidente Epitácio' },
    { value: 'Presidente Prudente', text: 'Presidente Prudente' },
    { value: 'Presidente Venceslau', text: 'Presidente Venceslau' },
    { value: 'Promissão', text: 'Promissão' },
    { value: 'Quadra', text: 'Quadra' },
    { value: 'Quatá', text: 'Quatá' },
    { value: 'Queiroz', text: 'Queiroz' },
    { value: 'Queluz', text: 'Queluz' },
    { value: 'Quintana', text: 'Quintana' },
    { value: 'Rafard', text: 'Rafard' },
    { value: 'Rancharia', text: 'Rancharia' },
    { value: 'Redenção da Serra', text: 'Redenção da Serra' },
    { value: 'Regente Feijó', text: 'Regente Feijó' },
    { value: 'Reginópolis', text: 'Reginópolis' },
    { value: 'Registro', text: 'Registro' },
    { value: 'Restinga', text: 'Restinga' },
    { value: 'Ribeira', text: 'Ribeira' },
    { value: 'Ribeirão Bonito', text: 'Ribeirão Bonito' },
    { value: 'Ribeirão Branco', text: 'Ribeirão Branco' },
    { value: 'Ribeirão Corrente', text: 'Ribeirão Corrente' },
    { value: 'Ribeirão do Sul', text: 'Ribeirão do Sul' },
    { value: 'Ribeirão dos Índios', text: 'Ribeirão dos Índios' },
    { value: 'Ribeirão Grande', text: 'Ribeirão Grande' },
    { value: 'Ribeirão Pires', text: 'Ribeirão Pires' },
    { value: 'Ribeirão Preto', text: 'Ribeirão Preto' },
    { value: 'Rifaina', text: 'Rifaina' },
    { value: 'Rincão', text: 'Rincão' },
    { value: 'Rinópolis', text: 'Rinópolis' },
    { value: 'Rio Claro', text: 'Rio Claro' },
    { value: 'Rio das Pedras', text: 'Rio das Pedras' },
    { value: 'Rio Grande da Serra', text: 'Rio Grande da Serra' },
    { value: 'Riolândia', text: 'Riolândia' },
    { value: 'Riversul', text: 'Riversul' },
    { value: 'Rosana', text: 'Rosana' },
    { value: 'Roseira', text: 'Roseira' },
    { value: 'Rubiácea', text: 'Rubiácea' },
    { value: 'Rubinéia', text: 'Rubinéia' },
    { value: 'Sabino', text: 'Sabino' },
    { value: 'Sagres', text: 'Sagres' },
    { value: 'Sales', text: 'Sales' },
    { value: 'Sales Oliveira', text: 'Sales Oliveira' },
    { value: 'Salesópolis', text: 'Salesópolis' },
    { value: 'Salmourão', text: 'Salmourão' },
    { value: 'Saltinho', text: 'Saltinho' },
    { value: 'Salto', text: 'Salto' },
    { value: 'Salto de Pirapora', text: 'Salto de Pirapora' },
    { value: 'Salto Grande', text: 'Salto Grande' },
    { value: 'Sandovalina', text: 'Sandovalina' },
    { value: 'Santa Adélia', text: 'Santa Adélia' },
    { value: 'Santa Albertina', text: 'Santa Albertina' },
    { value: "Santa Bárbara d'Oeste", text: "Santa Bárbara d'Oeste" },
    { value: 'Santa Branca', text: 'Santa Branca' },
    { value: "Santa Clara d'Oeste", text: "Santa Clara d'Oeste" },
    { value: 'Santa Cruz da Conceição', text: 'Santa Cruz da Conceição' },
    { value: 'Santa Cruz da Esperança', text: 'Santa Cruz da Esperança' },
    { value: 'Santa Cruz das Palmeiras', text: 'Santa Cruz das Palmeiras' },
    { value: 'Santa Cruz do Rio Pardo', text: 'Santa Cruz do Rio Pardo' },
    { value: 'Santa Ernestina', text: 'Santa Ernestina' },
    { value: 'Santa Fé do Sul', text: 'Santa Fé do Sul' },
    { value: 'Santa Gertrudes', text: 'Santa Gertrudes' },
    { value: 'Santa Isabel', text: 'Santa Isabel' },
    { value: 'Santa Lúcia', text: 'Santa Lúcia' },
    { value: 'Santa Maria da Serra', text: 'Santa Maria da Serra' },
    { value: 'Santa Mercedes', text: 'Santa Mercedes' },
    { value: "Santa Rita d'Oeste", text: "Santa Rita d'Oeste" },
    { value: 'Santa Rita do Passa Quatro', text: 'Santa Rita do Passa Quatro' },
    { value: 'Santa Rosa de Viterbo', text: 'Santa Rosa de Viterbo' },
    { value: 'Santa Salete', text: 'Santa Salete' },
    { value: 'Santana da Ponte Pensa', text: 'Santana da Ponte Pensa' },
    { value: 'Santana de Parnaíba', text: 'Santana de Parnaíba' },
    { value: 'Santo Anastácio', text: 'Santo Anastácio' },
    { value: 'Santo André', text: 'Santo André' },
    { value: 'Santo Antônio da Alegria', text: 'Santo Antônio da Alegria' },
    { value: 'Santo Antônio de Posse', text: 'Santo Antônio de Posse' },
    { value: 'Santo Antônio do Aracanguá', text: 'Santo Antônio do Aracanguá' },
    { value: 'Santo Antônio do Jardim', text: 'Santo Antônio do Jardim' },
    { value: 'Santo Antônio do Pinhal', text: 'Santo Antônio do Pinhal' },
    { value: 'Santo Expedito', text: 'Santo Expedito' },
    { value: 'Santópolis do Aguapeí', text: 'Santópolis do Aguapeí' },
    { value: 'Santos', text: 'Santos' },
    { value: 'São Bento do Sapucaí', text: 'São Bento do Sapucaí' },
    { value: 'São Bernardo do Campo', text: 'São Bernardo do Campo' },
    { value: 'São Caetano do Sul', text: 'São Caetano do Sul' },
    { value: 'São Carlos', text: 'São Carlos' },
    { value: 'São Francisco', text: 'São Francisco' },
    { value: 'São João da Boa Vista', text: 'São João da Boa Vista' },
    { value: 'São João das Duas Pontes', text: 'São João das Duas Pontes' },
    { value: 'São João de Iracema', text: 'São João de Iracema' },
    { value: "São João do Pau d'Alho", text: "São João do Pau d'Alho" },
    { value: 'São Joaquim da Barra', text: 'São Joaquim da Barra' },
    { value: 'São José da Bela Vista', text: 'São José da Bela Vista' },
    { value: 'São José do Barreiro', text: 'São José do Barreiro' },
    { value: 'São José do Rio Pardo', text: 'São José do Rio Pardo' },
    { value: 'São José do Rio Preto', text: 'São José do Rio Preto' },
    { value: 'São José dos Campos', text: 'São José dos Campos' },
    { value: 'São Lourenço da Serra', text: 'São Lourenço da Serra' },
    { value: 'São Luís do Paraitinga', text: 'São Luís do Paraitinga' },
    { value: 'São Manuel', text: 'São Manuel' },
    { value: 'São Miguel Arcanjo', text: 'São Miguel Arcanjo' },
    { value: 'São Paulo', text: 'São Paulo' },
    { value: 'São Pedro', text: 'São Pedro' },
    { value: 'São Pedro do Turvo', text: 'São Pedro do Turvo' },
    { value: 'São Roque', text: 'São Roque' },
    { value: 'São Sebastião', text: 'São Sebastião' },
    { value: 'São Sebastião da Grama', text: 'São Sebastião da Grama' },
    { value: 'São Simão', text: 'São Simão' },
    { value: 'São Vicente', text: 'São Vicente' },
    { value: 'Sarapuí', text: 'Sarapuí' },
    { value: 'Sarutaiá', text: 'Sarutaiá' },
    { value: 'Sebastianópolis do Sul', text: 'Sebastianópolis do Sul' },
    { value: 'Serra Azul', text: 'Serra Azul' },
    { value: 'Serra Negra', text: 'Serra Negra' },
    { value: 'Serrana', text: 'Serrana' },
    { value: 'Sertãozinho', text: 'Sertãozinho' },
    { value: 'Sete Barras', text: 'Sete Barras' },
    { value: 'Severínia', text: 'Severínia' },
    { value: 'Silveiras', text: 'Silveiras' },
    { value: 'Socorro', text: 'Socorro' },
    { value: 'Sorocaba', text: 'Sorocaba' },
    { value: 'Sud Mennucci', text: 'Sud Mennucci' },
    { value: 'Sumaré', text: 'Sumaré' },
    { value: 'Suzanápolis', text: 'Suzanápolis' },
    { value: 'Suzano', text: 'Suzano' },
    { value: 'Tabapuã', text: 'Tabapuã' },
    { value: 'Tabatinga', text: 'Tabatinga' },
    { value: 'Taboão da Serra', text: 'Taboão da Serra' },
    { value: 'Taciba', text: 'Taciba' },
    { value: 'Taguaí', text: 'Taguaí' },
    { value: 'Taiaçu', text: 'Taiaçu' },
    { value: 'Taiúva', text: 'Taiúva' },
    { value: 'Tambaú', text: 'Tambaú' },
    { value: 'Tanabi', text: 'Tanabi' },
    { value: 'Tapiraí', text: 'Tapiraí' },
    { value: 'Tapiratiba', text: 'Tapiratiba' },
    { value: 'Taquaral', text: 'Taquaral' },
    { value: 'Taquaritinga', text: 'Taquaritinga' },
    { value: 'Taquarituba', text: 'Taquarituba' },
    { value: 'Taquarivaí', text: 'Taquarivaí' },
    { value: 'Tarabai', text: 'Tarabai' },
    { value: 'Tarumã', text: 'Tarumã' },
    { value: 'Tatuí', text: 'Tatuí' },
    { value: 'Taubaté', text: 'Taubaté' },
    { value: 'Tejupá', text: 'Tejupá' },
    { value: 'Teodoro Sampaio', text: 'Teodoro Sampaio' },
    { value: 'Terra Roxa', text: 'Terra Roxa' },
    { value: 'Tietê', text: 'Tietê' },
    { value: 'Timburi', text: 'Timburi' },
    { value: 'Torre de Pedra', text: 'Torre de Pedra' },
    { value: 'Torrinha', text: 'Torrinha' },
    { value: 'Trabiju', text: 'Trabiju' },
    { value: 'Tremembé', text: 'Tremembé' },
    { value: 'Três Fronteiras', text: 'Três Fronteiras' },
    { value: 'Tuiuti', text: 'Tuiuti' },
    { value: 'Tupã', text: 'Tupã' },
    { value: 'Tupi Paulista', text: 'Tupi Paulista' },
    { value: 'Turiúba', text: 'Turiúba' },
    { value: 'Turmalina', text: 'Turmalina' },
    { value: 'Ubarana', text: 'Ubarana' },
    { value: 'Ubatuba', text: 'Ubatuba' },
    { value: 'Ubirajara', text: 'Ubirajara' },
    { value: 'Uchoa', text: 'Uchoa' },
    { value: 'União Paulista', text: 'União Paulista' },
    { value: 'Urânia', text: 'Urânia' },
    { value: 'Uru', text: 'Uru' },
    { value: 'Urupês', text: 'Urupês' },
    { value: 'Valentim Gentil', text: 'Valentim Gentil' },
    { value: 'Valinhos', text: 'Valinhos' },
    { value: 'Valparaíso', text: 'Valparaíso' },
    { value: 'Vargem', text: 'Vargem' },
    { value: 'Vargem Grande do Sul', text: 'Vargem Grande do Sul' },
    { value: 'Vargem Grande Paulista', text: 'Vargem Grande Paulista' },
    { value: 'Várzea Paulista', text: 'Várzea Paulista' },
    { value: 'Vera Cruz', text: 'Vera Cruz' },
    { value: 'Vinhedo', text: 'Vinhedo' },
    { value: 'Viradouro', text: 'Viradouro' },
    { value: 'Vista Alegre do Alto', text: 'Vista Alegre do Alto' },
    { value: 'Vitória Brasil', text: 'Vitória Brasil' },
    { value: 'Votorantim', text: 'Votorantim' },
    { value: 'Votuporanga', text: 'Votuporanga' },
    { value: 'Zacarias', text: 'Zacarias' },
  ],
  TO: [
    { value: 'Abreulândia', text: 'Abreulândia' },
    { value: 'Aguiarnópolis', text: 'Aguiarnópolis' },
    { value: 'Aliança do Tocantins', text: 'Aliança do Tocantins' },
    { value: 'Almas', text: 'Almas' },
    { value: 'Alvorada', text: 'Alvorada' },
    { value: 'Ananás', text: 'Ananás' },
    { value: 'Angico', text: 'Angico' },
    { value: 'Aparecida do Rio Negro', text: 'Aparecida do Rio Negro' },
    { value: 'Aragominas', text: 'Aragominas' },
    { value: 'Araguacema', text: 'Araguacema' },
    { value: 'Araguaçu', text: 'Araguaçu' },
    { value: 'Araguaína', text: 'Araguaína' },
    { value: 'Araguanã', text: 'Araguanã' },
    { value: 'Araguatins', text: 'Araguatins' },
    { value: 'Arapoema', text: 'Arapoema' },
    { value: 'Arraias', text: 'Arraias' },
    { value: 'Augustinópolis', text: 'Augustinópolis' },
    { value: 'Aurora do Tocantins', text: 'Aurora do Tocantins' },
    { value: 'Axixá do Tocantins', text: 'Axixá do Tocantins' },
    { value: 'Babaçulândia', text: 'Babaçulândia' },
    { value: 'Bandeirantes do Tocantins', text: 'Bandeirantes do Tocantins' },
    { value: 'Barra do Ouro', text: 'Barra do Ouro' },
    { value: 'Barrolândia', text: 'Barrolândia' },
    { value: 'Bernardo Sayão', text: 'Bernardo Sayão' },
    { value: 'Bom Jesus do Tocantins', text: 'Bom Jesus do Tocantins' },
    { value: 'Brasilândia do Tocantins', text: 'Brasilândia do Tocantins' },
    { value: 'Brejinho de Nazaré', text: 'Brejinho de Nazaré' },
    { value: 'Buriti do Tocantins', text: 'Buriti do Tocantins' },
    { value: 'Cachoeirinha', text: 'Cachoeirinha' },
    { value: 'Campos Lindos', text: 'Campos Lindos' },
    { value: 'Cariri do Tocantins', text: 'Cariri do Tocantins' },
    { value: 'Carmolândia', text: 'Carmolândia' },
    { value: 'Carrasco Bonito', text: 'Carrasco Bonito' },
    { value: 'Caseara', text: 'Caseara' },
    { value: 'Centenário', text: 'Centenário' },
    { value: 'Chapada da Natividade', text: 'Chapada da Natividade' },
    { value: 'Chapada de Areia', text: 'Chapada de Areia' },
    { value: 'Colinas do Tocantins', text: 'Colinas do Tocantins' },
    { value: 'Colméia', text: 'Colméia' },
    { value: 'Combinado', text: 'Combinado' },
    { value: 'Conceição do Tocantins', text: 'Conceição do Tocantins' },
    { value: 'Couto Magalhães', text: 'Couto Magalhães' },
    { value: 'Cristalândia', text: 'Cristalândia' },
    { value: 'Crixás do Tocantins', text: 'Crixás do Tocantins' },
    { value: 'Darcinópolis', text: 'Darcinópolis' },
    { value: 'Dianópolis', text: 'Dianópolis' },
    { value: 'Divinópolis do Tocantins', text: 'Divinópolis do Tocantins' },
    { value: 'Dois Irmãos do Tocantins', text: 'Dois Irmãos do Tocantins' },
    { value: 'Dueré', text: 'Dueré' },
    { value: 'Esperantina', text: 'Esperantina' },
    { value: 'Fátima', text: 'Fátima' },
    { value: 'Figueirópolis', text: 'Figueirópolis' },
    { value: 'Filadélfia', text: 'Filadélfia' },
    { value: 'Formoso do Araguaia', text: 'Formoso do Araguaia' },
    { value: 'Fortaleza do Tabocão', text: 'Fortaleza do Tabocão' },
    { value: 'Goianorte', text: 'Goianorte' },
    { value: 'Goiatins', text: 'Goiatins' },
    { value: 'Guaraí', text: 'Guaraí' },
    { value: 'Gurupi', text: 'Gurupi' },
    { value: 'Ipueiras', text: 'Ipueiras' },
    { value: 'Itacajá', text: 'Itacajá' },
    { value: 'Itaguatins', text: 'Itaguatins' },
    { value: 'Itapiratins', text: 'Itapiratins' },
    { value: 'Itaporã do Tocantins', text: 'Itaporã do Tocantins' },
    { value: 'Jaú do Tocantins', text: 'Jaú do Tocantins' },
    { value: 'Juarina', text: 'Juarina' },
    { value: 'Lagoa da Confusão', text: 'Lagoa da Confusão' },
    { value: 'Lagoa do Tocantins', text: 'Lagoa do Tocantins' },
    { value: 'Lajeado', text: 'Lajeado' },
    { value: 'Lavandeira', text: 'Lavandeira' },
    { value: 'Lizarda', text: 'Lizarda' },
    { value: 'Luzinópolis', text: 'Luzinópolis' },
    { value: 'Marianópolis do Tocantins', text: 'Marianópolis do Tocantins' },
    { value: 'Mateiros', text: 'Mateiros' },
    { value: 'Maurilândia do Tocantins', text: 'Maurilândia do Tocantins' },
    { value: 'Miracema do Tocantins', text: 'Miracema do Tocantins' },
    { value: 'Miranorte', text: 'Miranorte' },
    { value: 'Monte do Carmo', text: 'Monte do Carmo' },
    { value: 'Monte Santo do Tocantins', text: 'Monte Santo do Tocantins' },
    { value: 'Muricilândia', text: 'Muricilândia' },
    { value: 'Natividade', text: 'Natividade' },
    { value: 'Nazaré', text: 'Nazaré' },
    { value: 'Nova Olinda', text: 'Nova Olinda' },
    { value: 'Nova Rosalândia', text: 'Nova Rosalândia' },
    { value: 'Novo Acordo', text: 'Novo Acordo' },
    { value: 'Novo Alegre', text: 'Novo Alegre' },
    { value: 'Novo Jardim', text: 'Novo Jardim' },
    { value: 'Oliveira de Fátima', text: 'Oliveira de Fátima' },
    { value: 'Palmas', text: 'Palmas' },
    { value: 'Palmeirante', text: 'Palmeirante' },
    { value: 'Palmeiras do Tocantins', text: 'Palmeiras do Tocantins' },
    { value: 'Palmeirópolis', text: 'Palmeirópolis' },
    { value: 'Paraíso do Tocantins', text: 'Paraíso do Tocantins' },
    { value: 'Paranã', text: 'Paranã' },
    { value: "Pau d'Arco", text: "Pau d'Arco" },
    { value: 'Pedro Afonso', text: 'Pedro Afonso' },
    { value: 'Peixe', text: 'Peixe' },
    { value: 'Pequizeiro', text: 'Pequizeiro' },
    { value: 'Pindorama do Tocantins', text: 'Pindorama do Tocantins' },
    { value: 'Piraquê', text: 'Piraquê' },
    { value: 'Pium', text: 'Pium' },
    { value: 'Ponte Alta do Bom Jesus', text: 'Ponte Alta do Bom Jesus' },
    { value: 'Ponte Alta do Tocantins', text: 'Ponte Alta do Tocantins' },
    { value: 'Porto Alegre do Tocantins', text: 'Porto Alegre do Tocantins' },
    { value: 'Porto Nacional', text: 'Porto Nacional' },
    { value: 'Praia Norte', text: 'Praia Norte' },
    { value: 'Presidente Kennedy', text: 'Presidente Kennedy' },
    { value: 'Pugmil', text: 'Pugmil' },
    { value: 'Recursolândia', text: 'Recursolândia' },
    { value: 'Riachinho', text: 'Riachinho' },
    { value: 'Rio da Conceição', text: 'Rio da Conceição' },
    { value: 'Rio dos Bois', text: 'Rio dos Bois' },
    { value: 'Rio Sono', text: 'Rio Sono' },
    { value: 'Sampaio', text: 'Sampaio' },
    { value: 'Sandolândia', text: 'Sandolândia' },
    { value: 'Santa Fé do Araguaia', text: 'Santa Fé do Araguaia' },
    { value: 'Santa Maria do Tocantins', text: 'Santa Maria do Tocantins' },
    { value: 'Santa Rita do Tocantins', text: 'Santa Rita do Tocantins' },
    { value: 'Santa Rosa do Tocantins', text: 'Santa Rosa do Tocantins' },
    { value: 'Santa Tereza do Tocantins', text: 'Santa Tereza do Tocantins' },
    {
      value: 'Santa Terezinha do Tocantins',
      text: 'Santa Terezinha do Tocantins',
    },
    { value: 'São Bento do Tocantins', text: 'São Bento do Tocantins' },
    { value: 'São Félix do Tocantins', text: 'São Félix do Tocantins' },
    { value: 'São Miguel do Tocantins', text: 'São Miguel do Tocantins' },
    { value: 'São Salvador do Tocantins', text: 'São Salvador do Tocantins' },
    { value: 'São Sebastião do Tocantins', text: 'São Sebastião do Tocantins' },
    { value: 'São Valério', text: 'São Valério' },
    { value: 'Silvanópolis', text: 'Silvanópolis' },
    { value: 'Sítio Novo do Tocantins', text: 'Sítio Novo do Tocantins' },
    { value: 'Sucupira', text: 'Sucupira' },
    { value: 'Taguatinga', text: 'Taguatinga' },
    { value: 'Taipas do Tocantins', text: 'Taipas do Tocantins' },
    { value: 'Talismã', text: 'Talismã' },
    { value: 'Tocantínia', text: 'Tocantínia' },
    { value: 'Tocantinópolis', text: 'Tocantinópolis' },
    { value: 'Tupirama', text: 'Tupirama' },
    { value: 'Tupiratins', text: 'Tupiratins' },
    { value: 'Wanderlândia', text: 'Wanderlândia' },
    { value: 'Xambioá', text: 'Xambioá' },
  ],
}

export const educationLevelOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Educação Infantil', text: 'Educação Infantil' },
  { value: 'Ensino Fundamental', text: 'Ensino Fundamental' },
  { value: 'Ensino Médio', text: 'Ensino Médio' },
  { value: 'Superior (Graduação)', text: 'Superior (Graduação)' },
  { value: 'Pós-graduação', text: 'Pós-graduação' },
  { value: 'Mestrado', text: 'Mestrado' },
  { value: 'Doutorado', text: 'Doutorado' },
]

export const workSituationOptions = [
  { value: null, text: 'Selecione' },
  { value: 'CLT', text: 'CLT' },
  { value: 'Autonômo', text: 'Autonômo' },
  { value: 'Desempregado', text: 'Desempregado' },
  { value: 'Empresário', text: 'Empresário' },
  { value: 'Vive de renda', text: 'Vive de renda' },
]

export const periodoCoberturaMorte = [
  { value: null, text: 'Selecione' },
  { value: 5, text: '5 anos' },
  { value: 10, text: '10 anos' },
  { value: 15, text: '15 anos' },
  { value: 20, text: '20 anos' },
  { value: 25, text: '25 anos' },
  { value: 30, text: '30 anos' },
  { value: 999, text: 'Vitalício' },
]

export const periodoCoberturaVida = [
  { value: null, text: 'Selecione' },
  { value: 1, text: '1 ano' },
  { value: 5, text: '5 anos' },
  { value: 999, text: 'Vitalício' },
]

export const periodoCoberturaInvalidez = [
  { value: null, text: 'Selecione' },
  { value: 1, text: '1 ano' },
  { value: 5, text: '5 anos' },
  { value: 60, text: 'até 60 anos' },
  { value: 65, text: 'até 65 anos' },
  { value: 70, text: 'até 70 anos' },
  { value: 75, text: 'até 75 anos' },
  { value: 999, text: 'Vitalício' },
]

export const prazoPagamentoOptions = [
  { value: null, text: 'Selecione' },
  { value: 5, text: '5 anos' },
  { value: 10, text: '10 anos' },
  { value: 15, text: '15 anos' },
  { value: 20, text: '20 anos' },
  { value: 25, text: '25 anos' },
  { value: 30, text: '30 anos' },
  { value: 60, text: 'até 60 anos' },
  { value: 65, text: 'até 65 anos' },
  { value: 70, text: 'até 70 anos' },
  { value: 80, text: 'até 80 anos' },
  { value: 85, text: 'até 85 anos' },
  { value: 999, text: 'Vitalício' },
]

export const prazoCoberturaOptions = [
  { value: null, text: 'Selecione' },
  { value: 0, text: 'Vitalício' },
  { value: 1, text: '1 ano' },
  { value: 2, text: '2 anos' },
  { value: 3, text: '3 anos' },
  { value: 4, text: '4 anos' },
  { value: 5, text: '5 anos' },
  { value: 6, text: '6 anos' },
  { value: 7, text: '7 anos' },
  { value: 8, text: '8 anos' },
  { value: 9, text: '9 anos' },
  { value: 10, text: '10 anos' },
  { value: 11, text: '11 anos' },
  { value: 12, text: '12 anos' },
  { value: 13, text: '13 anos' },
  { value: 14, text: '14 anos' },
  { value: 15, text: '15 anos' },
  { value: 16, text: '16 anos' },
  { value: 17, text: '17 anos' },
  { value: 18, text: '18 anos' },
  { value: 19, text: '19 anos' },
  { value: 20, text: '20 anos' },
  { value: 21, text: '21 anos' },
  { value: 22, text: '22 anos' },
  { value: 23, text: '23 anos' },
  { value: 24, text: '24 anos' },
  { value: 25, text: '25 anos' },
  { value: 26, text: '26 anos' },
  { value: 27, text: '27 anos' },
  { value: 28, text: '28 anos' },
  { value: 29, text: '29 anos' },
  { value: 30, text: '30 anos' },
]

export const formaPagamentoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Débito', text: 'Débito' },
  { value: 'Boleto', text: 'Boleto' },
  { value: 'Cartão', text: 'Cartão' },
]

export const periodicidadePagamentoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Mensal', text: 'Mensal' },
  { value: 'Anual', text: 'Anual' },
  { value: 'Outros', text: 'Outros' },
]

export const tipoDocumentoIdentificacaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'RG', text: 'RG' },
  { value: 'CNH', text: 'CNH' },
  { value: 'Identificação Funcional', text: 'Identificação Funcional' },
  { value: 'Passaporte', text: 'Passaporte' },
]

export const tempoParaConclusaoOptions = [
  { value: null, text: 'Selecione' },
  { value: 1, text: '1 ano' },
  { value: 2, text: '2 anos' },
  { value: 3, text: '3 anos' },
  { value: 4, text: '4 anos' },
  { value: 5, text: '5 anos' },
  { value: 6, text: '6 anos' },
  { value: 7, text: '7 anos' },
  { value: 8, text: '8 anos' },
  { value: 9, text: '9 anos' },
  { value: 10, text: '10 anos' },
  { value: 11, text: '11 anos' },
  { value: 12, text: '12 anos' },
  { value: 13, text: '13 anos' },
  { value: 14, text: '14 anos' },
  { value: 15, text: '15 anos' },
  { value: 16, text: '16 anos' },
  { value: 17, text: '17 anos' },
  { value: 18, text: '18 anos' },
  { value: 19, text: '19 anos' },
  { value: 20, text: '20 anos' },
  { value: 21, text: '21 anos' },
  { value: 22, text: '22 anos' },
  { value: 23, text: '23 anos' },
  { value: 24, text: '24 anos' },
  { value: 25, text: '25 anos' },
]

export const responsavelPagamentoOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Titular', text: 'Titular' },
  { value: 'Cônjuge', text: 'Cônjuge' },
  { value: 'Casal', text: 'Casal' },
]

export const responsavelDependenteOptions = [
  { value: null, text: 'Selecione' },
  { value: 'Titular', text: 'Titular' },
  { value: 'Cônjuge', text: 'Cônjuge' },
  { value: 'Casal', text: 'Casal' },
]

export const selectAYear = [
  { value: null, text: 'Selecione' },
  { value: 2022, text: '2022' },
  { value: 2023, text: '2023' },
  { value: 2024, text: '2024' },
  { value: 2025, text: '2025' },
  { value: 2026, text: '2026' },
  { value: 2027, text: '2027' },
  { value: 2028, text: '2028' },
  { value: 2029, text: '2029' },
  { value: 2030, text: '2030' },
  { value: 2031, text: '2031' },
  { value: 2032, text: '2032' },
  { value: 2033, text: '2033' },
  { value: 2034, text: '2034' },
  { value: 2035, text: '2035' },
  { value: 2036, text: '2036' },
  { value: 2037, text: '2037' },
  { value: 2038, text: '2038' },
  { value: 2039, text: '2039' },
  { value: 2040, text: '2040' },
]

export const yesOrNoOptions = [
  { value: true, text: 'Sim' },
  { value: false, text: 'Não' },
]

export const notasOptions = [
  { value: 10, text: '10' },
  { value: 9, text: '9' },
  { value: 8, text: '8' },
  { value: 7, text: '7' },
  { value: 6, text: '6' },
  { value: 5, text: '5' },
  { value: 4, text: '4' },
  { value: 3, text: '3' },
  { value: 2, text: '2' },
  { value: 1, text: '1' },
  { value: 0, text: '0' },
]

export const incomeTypeOptions = [
  { value: null, text: 'Selecione' },
  { value: 'CLT', text: 'CLT' },
  { value: 'PJ', text: 'PJ' },
  { value: 'MEI', text: 'MEI' },
  { value: 'Funcionário Público', text: 'Funcionário Público' },
]

export const ownerEquityOptions = [
  { value: 1, text: 'Titular' },
  { value: 2, text: 'Cônjuge' },
  { value: 3, text: 'Casal' },
]

export const selectYesOrNotOptions = [
  { value: true, text: 'Sim' },
  { value: false, text: 'Não' },
]

export const occupationOptions = [
  {
    value: null,
    text: 'Selecione',
  },
  {
    value: 'Administrador de Banco de Dados',
    text: 'Administrador de Banco de Dados',
  },
  {
    value: 'Açougueiro',
    text: 'Açougueiro',
  },
  {
    value: 'Acompanhante de Idosos',
    text: 'Acompanhante de Idosos',
  },
  {
    value: 'Acupunturista',
    text: 'Acupunturista',
  },
  {
    value: 'Administrador de Empresas',
    text: 'Administrador de Empresas',
  },
  {
    value: 'Administrador de Redes',
    text: 'Administrador de Redes',
  },
  {
    value: 'Administrador de Redes Junior',
    text: 'Administrador de Redes Junior',
  },
  {
    value: 'Administrador de Redes Pleno',
    text: 'Administrador de Redes Pleno',
  },
  {
    value: 'Administrador de Redes Sênior',
    text: 'Administrador de Redes Sênior',
  },
  {
    value: 'Administrador de Sistemas',
    text: 'Administrador de Sistemas',
  },
  {
    value: 'Administrador Hospitalar',
    text: 'Administrador Hospitalar',
  },
  {
    value: 'Administrador Público',
    text: 'Administrador Público',
  },
  {
    value: 'Advogado',
    text: 'Advogado',
  },
  {
    value: 'Advogado Civil',
    text: 'Advogado Civil',
  },
  {
    value: 'Aeronauta',
    text: 'Aeronauta',
  },
  {
    value: 'Afiador de Ferramentas',
    text: 'Afiador de Ferramentas',
  },
  {
    value: 'Agente Comunitário de Saúde',
    text: 'Agente Comunitário de Saúde',
  },
  {
    value: 'Agente de Defesa Sanitária',
    text: 'Agente de Defesa Sanitária',
  },
  {
    value: 'Agente de Viagens',
    text: 'Agente de Viagens',
  },
  {
    value: 'Agente Funerário',
    text: 'Agente Funerário',
  },
  {
    value: 'Agente Penitenciário',
    text: 'Agente Penitenciário',
  },
  {
    value: 'Agricultor',
    text: 'Agricultor',
  },
  {
    value: 'Agrônomo',
    text: 'Agrônomo',
  },
  {
    value: 'Ajudante de Motorista',
    text: 'Ajudante de Motorista',
  },
  {
    value: 'Ajudante de Transportes',
    text: 'Ajudante de Transportes',
  },
  {
    value: 'Alfaiate',
    text: 'Alfaiate',
  },
  {
    value: 'Almirante',
    text: 'Almirante',
  },
  {
    value: 'Almoxarife',
    text: 'Almoxarife',
  },
  {
    value: 'Ambientalista',
    text: 'Ambientalista',
  },
  {
    value: 'Ambulante',
    text: 'Ambulante',
  },
  {
    value: 'Analista Administrativo',
    text: 'Analista Administrativo',
  },
  {
    value: 'Analista Administrativo de Vendas',
    text: 'Analista Administrativo de Vendas',
  },
  {
    value: 'Analista Administrativo Financeiro',
    text: 'Analista Administrativo Financeiro',
  },
  {
    value: 'Analista Ambiental',
    text: 'Analista Ambiental',
  },
  {
    value: 'Analista Comercial Interno',
    text: 'Analista Comercial Interno',
  },
  {
    value: 'Analista Comercial Externo',
    text: 'Analista Comercial Externo',
  },
  {
    value: 'Analista de Atendimento a Clientes',
    text: 'Analista de Atendimento a Clientes',
  },
  {
    value: 'Analista de Atendimento Comercial',
    text: 'Analista de Atendimento Comercial',
  },
  {
    value: 'Analista de Banco',
    text: 'Analista de Banco',
  },
  {
    value: 'Analista de Banco de Dados',
    text: 'Analista de Banco de Dados',
  },
  {
    value: 'Analista de Cargos e Salários',
    text: 'Analista de Cargos e Salários',
  },
  {
    value: 'Analista de Cobrança',
    text: 'Analista de Cobrança',
  },
  {
    value: 'Analista de Comércio Exterior',
    text: 'Analista de Comércio Exterior',
  },
  {
    value: 'Analista de Compras',
    text: 'Analista de Compras',
  },
  {
    value: 'Analista de Comunicação',
    text: 'Analista de Comunicação',
  },
  {
    value: 'Analista de Contabilidade',
    text: 'Analista de Contabilidade',
  },
  {
    value: 'Analista de Contas a Pagar e Receber',
    text: 'Analista de Contas a Pagar e Receber',
  },
  {
    value: 'Analista de Controladoria',
    text: 'Analista de Controladoria',
  },
  {
    value: 'Analista de Controladoria Junior',
    text: 'Analista de Controladoria Junior',
  },
  {
    value: 'Analista de Controladoria Pleno',
    text: 'Analista de Controladoria Pleno',
  },
  {
    value: 'Analista de Controladoria Sênior',
    text: 'Analista de Controladoria Sênior',
  },
  {
    value: 'Analista de Controle de Qualidade',
    text: 'Analista de Controle de Qualidade',
  },
  {
    value: 'Analista de Crédito e Cobrança',
    text: 'Analista de Crédito e Cobrança',
  },
  {
    value: 'Analista de Custos',
    text: 'Analista de Custos',
  },
  {
    value: 'Analista de Departamento Pessoal',
    text: 'Analista de Departamento Pessoal',
  },
  {
    value: 'Analista de Desenvolvimento de Produto',
    text: 'Analista de Desenvolvimento de Produto',
  },
  {
    value: 'Analista de Desenvolvimento de Sistemas',
    text: 'Analista de Desenvolvimento de Sistemas',
  },
  {
    value: 'Analista de Desenvolvimento Web',
    text: 'Analista de Desenvolvimento Web',
  },
  {
    value: 'Analista de Distribuição',
    text: 'Analista de Distribuição',
  },
  {
    value: 'Analista de Ecommerce',
    text: 'Analista de Ecommerce',
  },
  {
    value: 'Analista de Estoque',
    text: 'Analista de Estoque',
  },
  {
    value: 'Analista de Engenharia Elétrica',
    text: 'Analista de Engenharia Elétrica',
  },
  {
    value: 'Analista Financeiro',
    text: 'Analista Financeiro',
  },
  {
    value: 'Analista de Fiscal',
    text: 'Analista de Fiscal',
  },
  {
    value: 'Analista Químico',
    text: 'Analista Químico',
  },
  {
    value: 'Anestesiologista',
    text: 'Anestesiologista',
  },
  {
    value: 'Angiologista',
    text: 'Angiologista',
  },
  {
    value: 'Antropólogo',
    text: 'Antropólogo',
  },
  {
    value: 'Apicultor',
    text: 'Apicultor',
  },
  {
    value: 'Apresentador',
    text: 'Apresentador',
  },
  {
    value: 'Argumentista',
    text: 'Argumentista',
  },
  {
    value: 'Armador',
    text: 'Armador',
  },
  {
    value: 'Armeiro',
    text: 'Armeiro',
  },
  {
    value: 'Arquiteto',
    text: 'Arquiteto',
  },
  {
    value: 'Arquiteto de Interiores',
    text: 'Arquiteto de Interiores',
  },
  {
    value: 'Arquiteto Paisagista',
    text: 'Arquiteto Paisagista',
  },
  {
    value: 'Arquiteto Urbanista',
    text: 'Arquiteto Urbanista',
  },
  {
    value: 'Arquiteto Projetista',
    text: 'Arquiteto Projetista',
  },
  {
    value: 'Arquiteto Vendedor',
    text: 'Arquiteto Vendedor',
  },
  {
    value: 'Arquivista',
    text: 'Arquivista',
  },
  {
    value: 'Arrumadeira',
    text: 'Arrumadeira',
  },
  {
    value: 'Artista de Espetáculo de Diversões',
    text: 'Artista de Espetáculo de Diversões',
  },
  {
    value: 'Artista Plástico',
    text: 'Artista Plástico',
  },
  {
    value: 'Ascensorista',
    text: 'Ascensorista',
  },
  {
    value: 'Assessor de Imprensa',
    text: 'Assessor de Imprensa',
  },
  {
    value: 'Analista de Fundição',
    text: 'Analista de Fundição',
  },
  {
    value: 'Analista de Informática',
    text: 'Analista de Informática',
  },
  {
    value: 'Analista de Investimentos',
    text: 'Analista de Investimentos',
  },
  {
    value: 'Analista de Logística',
    text: 'Analista de Logística',
  },
  {
    value: 'Analista de Loja',
    text: 'Analista de Loja',
  },
  {
    value: 'Analista de Manutenção',
    text: 'Analista de Manutenção',
  },
  {
    value: 'Analista de Manutenção Elétrica',
    text: 'Analista de Manutenção Elétrica',
  },
  {
    value: 'Analista de Marketing',
    text: 'Analista de Marketing',
  },
  {
    value: 'Analista de PCP',
    text: 'Analista de PCP',
  },
  {
    value: 'Analista de Produção',
    text: 'Analista de Produção',
  },
  {
    value: 'Analista de Programação',
    text: 'Analista de Programação',
  },
  {
    value: 'Analista de Publicidade e Propaganda',
    text: 'Analista de Publicidade e Propaganda',
  },
  {
    value: 'Analista de Qualidade',
    text: 'Analista de Qualidade',
  },
  {
    value: 'Analista de Recrutamento e Seleção',
    text: 'Analista de Recrutamento e Seleção',
  },
  {
    value: 'Analista de Recursos Humanos',
    text: 'Analista de Recursos Humanos',
  },
  {
    value: 'Analista de Redes',
    text: 'Analista de Redes',
  },
  {
    value: 'Analista de Relações Públicas',
    text: 'Analista de Relações Públicas',
  },
  {
    value: 'Analista de Representação Comercial',
    text: 'Analista de Representação Comercial',
  },
  {
    value: 'Analista de SAC',
    text: 'Analista de SAC',
  },
  {
    value: 'Analista de Segurança Patrimonial',
    text: 'Analista de Segurança Patrimonial',
  },
  {
    value: 'Analista de Seguros',
    text: 'Analista de Seguros',
  },
  {
    value: 'Analista de Sistemas',
    text: 'Analista de Sistemas',
  },
  {
    value: 'Analista de Suporte Técnico',
    text: 'Analista de Suporte Técnico',
  },
  {
    value: 'Analista de Suprimentos',
    text: 'Analista de Suprimentos',
  },
  {
    value: 'Analista de Tecnologia da Informação (TI)',
    text: 'Analista de Tecnologia da Informação (TI)',
  },
  {
    value: 'Analista de Telecomunicações',
    text: 'Analista de Telecomunicações',
  },
  {
    value: 'Analista de Telemarketing Ativo',
    text: 'Analista de Telemarketing Ativo',
  },
  {
    value: 'Analista de Telemarketing Receptivo',
    text: 'Analista de Telemarketing Receptivo',
  },
  {
    value: 'Analista de Tesouraria',
    text: 'Analista de Tesouraria',
  },
  {
    value: 'Analista de Transportes',
    text: 'Analista de Transportes',
  },
  {
    value: 'Analista de Treinamento',
    text: 'Analista de Treinamento',
  },
  {
    value: 'Analista de Treinamento Sênior',
    text: 'Analista de Treinamento Sênior',
  },
  {
    value: 'Analista Econômico',
    text: 'Analista Econômico',
  },
  {
    value: 'Assistente Comercial Interno',
    text: 'Assistente Comercial Interno',
  },
  {
    value: 'Assistente de Almoxarifado',
    text: 'Assistente de Almoxarifado',
  },
  {
    value: 'Assistente de Arquitetura',
    text: 'Assistente de Arquitetura',
  },
  {
    value: 'Assistente Administrativo',
    text: 'Assistente Administrativo',
  },
  {
    value: 'Assistente de Atendimento a Clientes',
    text: 'Assistente de Atendimento a Clientes',
  },
  {
    value: 'Assistente de Atendimento Comercial',
    text: 'Assistente de Atendimento Comercial',
  },
  {
    value: 'Assistente de Cobrança',
    text: 'Assistente de Cobrança',
  },
  {
    value: 'Assistente de Comércio Exterior',
    text: 'Assistente de Comércio Exterior',
  },
  {
    value: 'Assistente de Compras',
    text: 'Assistente de Compras',
  },
  {
    value: 'Assistente de Comunicação',
    text: 'Assistente de Comunicação',
  },
  {
    value: 'Assistente de Contabilidade',
    text: 'Assistente de Contabilidade',
  },
  {
    value: 'Assistente de Contas a Pagar e Receber',
    text: 'Assistente de Contas a Pagar e Receber',
  },
  {
    value: 'Assistente de Controladoria',
    text: 'Assistente de Controladoria',
  },
  {
    value: 'Assistente de Controle de Qualidade',
    text: 'Assistente de Controle de Qualidade',
  },
  {
    value: 'Assistente de Cozinha',
    text: 'Assistente de Cozinha',
  },
  {
    value: 'Assistente de Crédito e Cobrança',
    text: 'Assistente de Crédito e Cobrança',
  },
  {
    value: 'Assistente de Custos',
    text: 'Assistente de Custos',
  },
  {
    value: 'Assistente de Departamento Pessoal',
    text: 'Assistente de Departamento Pessoal',
  },
  {
    value: 'Assistente de Desenvolvimento de Produto',
    text: 'Assistente de Desenvolvimento de Produto',
  },
  {
    value: 'Assistente de Desenvolvimento de Sistemas',
    text: 'Assistente de Desenvolvimento de Sistemas',
  },
  {
    value: 'Assistente de Distribuição',
    text: 'Assistente de Distribuição',
  },
  {
    value: 'Assistente de Ecommerce',
    text: 'Assistente de Ecommerce',
  },
  {
    value: 'Assistente de Enfermagem',
    text: 'Assistente de Enfermagem',
  },
  {
    value: 'Assistente de Engenharia Elétrica',
    text: 'Assistente de Engenharia Elétrica',
  },
  {
    value: 'Assistente de Estoque',
    text: 'Assistente de Estoque',
  },
  {
    value: 'Assistente Financeiro',
    text: 'Assistente Financeiro',
  },
  {
    value: 'Assistente de Informática',
    text: 'Assistente de Informática',
  },
  {
    value: 'Assistente de Jornalismo',
    text: 'Assistente de Jornalismo',
  },
  {
    value: 'Assistente de Limpeza',
    text: 'Assistente de Limpeza',
  },
  {
    value: 'Assistente de Logística',
    text: 'Assistente de Logística',
  },
  {
    value: 'Assistente de Loja',
    text: 'Assistente de Loja',
  },
  {
    value: 'Assistente de Manutenção',
    text: 'Assistente de Manutenção',
  },
  {
    value: 'Assistente de Manutenção Elétrica',
    text: 'Assistente de Manutenção Elétrica',
  },
  {
    value: 'Assistente de Marketing',
    text: 'Assistente de Marketing',
  },
  {
    value: 'Assistente de PCP',
    text: 'Assistente de PCP',
  },
  {
    value: 'Assistente de Produção',
    text: 'Assistente de Produção',
  },
  {
    value: 'Assistente de Publicidade e Propaganda',
    text: 'Assistente de Publicidade e Propaganda',
  },
  {
    value: 'Assistente de Qualidade',
    text: 'Assistente de Qualidade',
  },
  {
    value: 'Assistente de Recrutamento e Seleção',
    text: 'Assistente de Recrutamento e Seleção',
  },
  {
    value: 'Assistente de Recursos Humanos',
    text: 'Assistente de Recursos Humanos',
  },
  {
    value: 'Assistente de Representação Comercial',
    text: 'Assistente de Representação Comercial',
  },
  {
    value: 'Assistente de SAC',
    text: 'Assistente de SAC',
  },
  {
    value: 'Assistente de Seguros',
    text: 'Assistente de Seguros',
  },
  {
    value: 'Assistente de Sistemas',
    text: 'Assistente de Sistemas',
  },
  {
    value: 'Assistente de Suporte Técnico',
    text: 'Assistente de Suporte Técnico',
  },
  {
    value: 'Assistente de Suprimentos',
    text: 'Assistente de Suprimentos',
  },
  {
    value: 'Assistente de Tecnologia da Informação (TI)',
    text: 'Assistente de Tecnologia da Informação (TI)',
  },
  {
    value: 'Assistente de Telecomunicações',
    text: 'Assistente de Telecomunicações',
  },
  {
    value: 'Assistente de Telemarketing Ativo',
    text: 'Assistente de Telemarketing Ativo',
  },
  {
    value: 'Assistente de Telemarketing Receptivo',
    text: 'Assistente de Telemarketing Receptivo',
  },
  {
    value: 'Assistente de Tesouraria',
    text: 'Assistente de Tesouraria',
  },
  {
    value: 'Assistente de Transportes',
    text: 'Assistente de Transportes',
  },
  {
    value: 'Assistente de Treinamento',
    text: 'Assistente de Treinamento',
  },
  {
    value: 'Assistente de Vendas Externas',
    text: 'Assistente de Vendas Externas',
  },
  {
    value: 'Assistente Editorial',
    text: 'Assistente Editorial',
  },
  {
    value: 'Assistente Fiscal',
    text: 'Assistente Fiscal',
  },
  {
    value: 'Assistente Social',
    text: 'Assistente Social',
  },
  {
    value: 'Astrônomo',
    text: 'Astrônomo',
  },
  {
    value: 'Atendente',
    text: 'Atendente',
  },
  {
    value: 'Atendente de Agência',
    text: 'Atendente de Agência',
  },
  {
    value: 'Atendente Distribuidor',
    text: 'Atendente Distribuidor',
  },
  {
    value: 'Auditor Ambiental',
    text: 'Auditor Ambiental',
  },
  {
    value: 'Auditor de Qualidade',
    text: 'Auditor de Qualidade',
  },
  {
    value: 'Auditor Externo',
    text: 'Auditor Externo',
  },
  {
    value: 'Auditor Interno',
    text: 'Auditor Interno',
  },
  {
    value: 'Auxiliar de Almoxarifado',
    text: 'Auxiliar de Almoxarifado',
  },
  {
    value: 'Auxiliar de Almoxarife',
    text: 'Auxiliar de Almoxarife',
  },
  {
    value: 'Auxiliar de Arquivo',
    text: 'Auxiliar de Arquivo',
  },
  {
    value: 'Auxiliar de Banco de Dados',
    text: 'Auxiliar de Banco de Dados',
  },
  {
    value: 'Auxiliar de Biblioteca',
    text: 'Auxiliar de Biblioteca',
  },
  {
    value: 'Auxiliar de Comércio Exterior',
    text: 'Auxiliar de Comércio Exterior',
  },
  {
    value: 'Auxiliar de Contas a Pagar e Receber',
    text: 'Auxiliar de Contas a Pagar e Receber',
  },
  {
    value: 'Auxiliar de Controle de Qualidade',
    text: 'Auxiliar de Controle de Qualidade',
  },
  {
    value: 'Auxiliar de Cozinha',
    text: 'Auxiliar de Cozinha',
  },
  {
    value: 'Auxiliar de Crédito e Cobrança',
    text: 'Auxiliar de Crédito e Cobrança',
  },
  {
    value: 'Auxiliar de Custos',
    text: 'Auxiliar de Custos',
  },
  {
    value: 'Auxiliar de Distribuição',
    text: 'Auxiliar de Distribuição',
  },
  {
    value: 'Auxiliar de Engenharia de Segurança do Trabalho',
    text: 'Auxiliar de Engenharia de Segurança do Trabalho',
  },
  {
    value: 'Auviliar de Escritório',
    text: 'Auviliar de Escritório',
  },
  {
    value: 'Auxiliar de Fundição',
    text: 'Auxiliar de Fundição',
  },
  {
    value: 'Auxiliar de Instalação',
    text: 'Auxiliar de Instalação',
  },
  {
    value: 'Auxiliar de Limpeza',
    text: 'Auxiliar de Limpeza',
  },
  {
    value: 'Auxiliar de Manutenção Hidráulica',
    text: 'Auxiliar de Manutenção Hidráulica',
  },
  {
    value: 'Auxiliar de Padeiro',
    text: 'Auxiliar de Padeiro',
  },
  {
    value: 'Auxiliar de PCP',
    text: 'Auxiliar de PCP',
  },
  {
    value: 'Auxiliar de Programação',
    text: 'Auxiliar de Programação',
  },
  {
    value: 'Auxiliar de SAC',
    text: 'Auxiliar de SAC',
  },
  {
    value: 'Auxiliar de Segurança',
    text: 'Auxiliar de Segurança',
  },
  {
    value: 'Auxiliar de Serralheiro',
    text: 'Auxiliar de Serralheiro',
  },
  {
    value: 'Auxiliar de Suprimentos',
    text: 'Auxiliar de Suprimentos',
  },
  {
    value: 'Auxiliar de Topografia',
    text: 'Auxiliar de Topografia',
  },
  {
    value: 'Auxiliar de Transportes',
    text: 'Auxiliar de Transportes',
  },
  {
    value: 'Auxiliar de Usinagem',
    text: 'Auxiliar de Usinagem',
  },
  {
    value: 'Auxiliar de Veterinária',
    text: 'Auxiliar de Veterinária',
  },
  {
    value: 'Auxiliar de Web Design',
    text: 'Auxiliar de Web Design',
  },
  {
    value: 'Auxiliar Econômico',
    text: 'Auxiliar Econômico',
  },
  {
    value: 'Auxiliar Fiscal',
    text: 'Auxiliar Fiscal',
  },
  {
    value: 'Auxiliar Jurídico',
    text: 'Auxiliar Jurídico',
  },
  {
    value: 'Bartender',
    text: 'Bartender',
  },
  {
    value: 'Babá',
    text: 'Babá',
  },
  {
    value: 'Balconista',
    text: 'Balconista',
  },
  {
    value: 'Bancário',
    text: 'Bancário',
  },
  {
    value: 'Barman',
    text: 'Barman',
  },
  {
    value: 'Biblioteconista',
    text: 'Biblioteconista',
  },
  {
    value: 'Biólogo',
    text: 'Biólogo',
  },
  {
    value: 'Biomédico',
    text: 'Biomédico',
  },
  {
    value: 'Bioquímico',
    text: 'Bioquímico',
  },
  {
    value: 'Biotecnólogo',
    text: 'Biotecnólogo',
  },
  {
    value: 'Bombeiro',
    text: 'Bombeiro',
  },
  {
    value: 'Borracheiro',
    text: 'Borracheiro',
  },
  {
    value: 'Cabeleireiro',
    text: 'Cabeleireiro',
  },
  {
    value: 'Cadista',
    text: 'Cadista',
  },
  {
    value: 'Cardiologista',
    text: 'Cardiologista',
  },
  {
    value: 'Cartazista',
    text: 'Cartazista',
  },
  {
    value: 'Carteiro',
    text: 'Carteiro',
  },
  {
    value: 'Cenógrafo',
    text: 'Cenógrafo',
  },
  {
    value: 'Ceramista',
    text: 'Ceramista',
  },
  {
    value: 'Cineasta',
    text: 'Cineasta',
  },
  {
    value: 'Cinegrafista',
    text: 'Cinegrafista',
  },
  {
    value: 'Cirurgião Plástico',
    text: 'Cirurgião Plástico',
  },
  {
    value: 'Clínico Geral',
    text: 'Clínico Geral',
  },
  {
    value: 'Cobrador de Coletivo',
    text: 'Cobrador de Coletivo',
  },
  {
    value: 'Comissário de Bordo',
    text: 'Comissário de Bordo',
  },
  {
    value: 'Confeiteiro',
    text: 'Confeiteiro',
  },
  {
    value: 'Conferente',
    text: 'Conferente',
  },
  {
    value: 'Conferente de Loja',
    text: 'Conferente de Loja',
  },
  {
    value: 'Consultor Contábil',
    text: 'Consultor Contábil',
  },
  {
    value: 'Consultor de Marketing',
    text: 'Consultor de Marketing',
  },
  {
    value: 'Consultor de Moda',
    text: 'Consultor de Moda',
  },
  {
    value: 'Consultor de Qualidade',
    text: 'Consultor de Qualidade',
  },
  {
    value: 'Consultor de Vendas Externas',
    text: 'Consultor de Vendas Externas',
  },
  {
    value: 'Consultor de Viagens',
    text: 'Consultor de Viagens',
  },
  {
    value: 'Contador',
    text: 'Contador',
  },
  {
    value: 'Controlador de Tráfego',
    text: 'Controlador de Tráfego',
  },
  {
    value: 'Coordenador Administrativo',
    text: 'Coordenador Administrativo',
  },
  {
    value: 'Coordenador Comercial Externo',
    text: 'Coordenador Comercial Externo',
  },
  {
    value: 'Coordenador Comercial Interno',
    text: 'Coordenador Comercial Interno',
  },
  {
    value: 'Coordenador de Almoxarifado',
    text: 'Coordenador de Almoxarifado',
  },
  {
    value: 'Coordenador de Arquitetura',
    text: 'Coordenador de Arquitetura',
  },
  {
    value: 'Coordenador de Atendimento Comercial',
    text: 'Coordenador de Atendimento Comercial',
  },
  {
    value: 'Coordenador de Atendimento a Clientes',
    text: 'Coordenador de Atendimento a Clientes',
  },
  {
    value: 'Coordenador de Banco',
    text: 'Coordenador de Banco',
  },
  {
    value: 'Coordenador de Cargos e Salários',
    text: 'Coordenador de Cargos e Salários',
  },
  {
    value: 'Coordenador de Cobrança',
    text: 'Coordenador de Cobrança',
  },
  {
    value: 'Coordenador de Comércio Exterior',
    text: 'Coordenador de Comércio Exterior',
  },
  {
    value: 'Coordenador de Compras',
    text: 'Coordenador de Compras',
  },
  {
    value: 'Coordenador de Comunicação',
    text: 'Coordenador de Comunicação',
  },
  {
    value: 'Coordenador de Contabilidade',
    text: 'Coordenador de Contabilidade',
  },
  {
    value: 'Coordenador de Controladoria',
    text: 'Coordenador de Controladoria',
  },
  {
    value: 'Coordenador de Controle de Qualidade',
    text: 'Coordenador de Controle de Qualidade',
  },
  {
    value: 'Coordenador de Cozinha',
    text: 'Coordenador de Cozinha',
  },
  {
    value: 'Coordenador de Crédito e Cobrança',
    text: 'Coordenador de Crédito e Cobrança',
  },
  {
    value: 'Coordenador de Custos',
    text: 'Coordenador de Custos',
  },
  {
    value: 'Coordenador de Departamento Pessoal',
    text: 'Coordenador de Departamento Pessoal',
  },
  {
    value: 'Coordenador de Desenvolvimento de Produto',
    text: 'Coordenador de Desenvolvimento de Produto',
  },
  {
    value: 'Coordenador de Desenvolvimento de Sistemas',
    text: 'Coordenador de Desenvolvimento de Sistemas',
  },
  {
    value: 'Coordenador de Diagramação',
    text: 'Coordenador de Diagramação',
  },
  {
    value: 'Coordenador de Ecommerce',
    text: 'Coordenador de Ecommerce',
  },
  {
    value: 'Coordenador de Enfermagem',
    text: 'Coordenador de Enfermagem',
  },
  {
    value: 'Coordenador de Engenharia e Segurança do Trabalho',
    text: 'Coordenador de Engenharia e Segurança do Trabalho',
  },
  {
    value: 'Coordenador de Engenharia Elétrica',
    text: 'Coordenador de Engenharia Elétrica',
  },
  {
    value: 'Coordenador de Estoque',
    text: 'Coordenador de Estoque',
  },
  {
    value: 'Coordenador de Fundição',
    text: 'Coordenador de Fundição',
  },
  {
    value: 'Coordenador de Informática',
    text: 'Coordenador de Informática',
  },
  {
    value: 'Coordenador de Jornalismo',
    text: 'Coordenador de Jornalismo',
  },
  {
    value: 'Coordenador de Limpeza',
    text: 'Coordenador de Limpeza',
  },
  {
    value: 'Coordenador de Logística',
    text: 'Coordenador de Logística',
  },
  {
    value: 'Coordenador de Loja',
    text: 'Coordenador de Loja',
  },
  {
    value: 'Coordenador de Manutenção',
    text: 'Coordenador de Manutenção',
  },
  {
    value: 'Coordenador de Manutenção Elétrica',
    text: 'Coordenador de Manutenção Elétrica',
  },
  {
    value: 'Coordenador de Marketing',
    text: 'Coordenador de Marketing',
  },
  {
    value: 'Coordenador de PCP',
    text: 'Coordenador de PCP',
  },
  {
    value: 'Coordenador de Produção',
    text: 'Coordenador de Produção',
  },
  {
    value: 'Coordenador de Publicidade e Propaganda',
    text: 'Coordenador de Publicidade e Propaganda',
  },
  {
    value: 'Coordenador de Qualidade',
    text: 'Coordenador de Qualidade',
  },
  {
    value: 'Coordenador de Recrutamento e Seleção',
    text: 'Coordenador de Recrutamento e Seleção',
  },
  {
    value: 'Coordenador de Recursos Humanos',
    text: 'Coordenador de Recursos Humanos',
  },
  {
    value: 'Coordenador de Representação Comercial',
    text: 'Coordenador de Representação Comercial',
  },
  {
    value: 'Coordenador de SAC',
    text: 'Coordenador de SAC',
  },
  {
    value: 'Coordenador de Salão',
    text: 'Coordenador de Salão',
  },
  {
    value: 'Coordenador de Segurança Patrimonial',
    text: 'Coordenador de Segurança Patrimonial',
  },
  {
    value: 'Coordenador de Seguros',
    text: 'Coordenador de Seguros',
  },
  {
    value: 'Coordenador de Sistemas',
    text: 'Coordenador de Sistemas',
  },
  {
    value: 'Coordenador de Suporte Técnico',
    text: 'Coordenador de Suporte Técnico',
  },
  {
    value: 'Coordenador de Suprimentos',
    text: 'Coordenador de Suprimentos',
  },
  {
    value: 'Coordenador de Tecnologia da Informação',
    text: 'Coordenador de Tecnologia da Informação',
  },
  {
    value: 'Coordenador de Telecomunicações',
    text: 'Coordenador de Telecomunicações',
  },
  {
    value: 'Coordenador de Telemarketing Ativo',
    text: 'Coordenador de Telemarketing Ativo',
  },
  {
    value: 'Coordenador de Telemarketing Receptivo',
    text: 'Coordenador de Telemarketing Receptivo',
  },
  {
    value: 'Coordenador de Tesouraraia',
    text: 'Coordenador de Tesouraraia',
  },
  {
    value: 'Coordenador de Transportes',
    text: 'Coordenador de Transportes',
  },
  {
    value: 'Coordenador de Treinamento',
    text: 'Coordenador de Treinamento',
  },
  {
    value: 'Coordenador de Usinagem',
    text: 'Coordenador de Usinagem',
  },
  {
    value: 'Coordenador Econômico',
    text: 'Coordenador Econômico',
  },
  {
    value: 'Coordenador Financeiro',
    text: 'Coordenador Financeiro',
  },
  {
    value: 'Coordenador Fiscal',
    text: 'Coordenador Fiscal',
  },
  {
    value: 'Coordenador Químico',
    text: 'Coordenador Químico',
  },
  {
    value: 'Corretor de Seguros',
    text: 'Corretor de Seguros',
  },
  {
    value: 'Cozinheiro',
    text: 'Cozinheiro',
  },
  {
    value: 'Diretor Administrativo',
    text: 'Diretor Administrativo',
  },
  {
    value: 'Dentista',
    text: 'Dentista',
  },
  {
    value: 'Desenhista',
    text: 'Desenhista',
  },
  {
    value: 'Designer de Interiores',
    text: 'Designer de Interiores',
  },
  {
    value: 'Designer de Moda',
    text: 'Designer de Moda',
  },
  {
    value: 'Diagramador',
    text: 'Diagramador',
  },
  {
    value: 'Diretor Comercial Externo',
    text: 'Diretor Comercial Externo',
  },
  {
    value: 'Diretor Comercial Interno',
    text: 'Diretor Comercial Interno',
  },
  {
    value: 'Diretor de Cinema',
    text: 'Diretor de Cinema',
  },
  {
    value: 'Diretor de Prdução',
    text: 'Diretor de Prdução',
  },
  {
    value: 'Diretor de Representação Comercial',
    text: 'Diretor de Representação Comercial',
  },
  {
    value: 'Diretor de Recursos Humanos',
    text: 'Diretor de Recursos Humanos',
  },
  {
    value: 'Diretor de Tecnologia da Informação (TI)',
    text: 'Diretor de Tecnologia da Informação (TI)',
  },
  {
    value: 'Diretor de Marketing',
    text: 'Diretor de Marketing',
  },
  {
    value: 'Diretor Financeiro',
    text: 'Diretor Financeiro',
  },
  {
    value: 'Diretor de Informática',
    text: 'Diretor de Informática',
  },
  {
    value: 'Diretor de Jornalismo',
    text: 'Diretor de Jornalismo',
  },
  {
    value: 'Diretor de Suporte Técnico',
    text: 'Diretor de Suporte Técnico',
  },
  {
    value: 'Diretor de Logística',
    text: 'Diretor de Logística',
  },
  {
    value: 'Diretor de Departamento Pessoal',
    text: 'Diretor de Departamento Pessoal',
  },
  {
    value: 'Ecologista',
    text: 'Ecologista',
  },
  {
    value: 'Economista',
    text: 'Economista',
  },
  {
    value: 'Editor',
    text: 'Editor',
  },
  {
    value: 'Educador',
    text: 'Educador',
  },
  {
    value: 'Eletricista',
    text: 'Eletricista',
  },
  {
    value: 'Eletricista de Manutenção',
    text: 'Eletricista de Manutenção',
  },
  {
    value: 'Embalador',
    text: 'Embalador',
  },
  {
    value: 'Empreagdo (a) Doméstico (a)',
    text: 'Empreagdo (a) Doméstico (a)',
  },
  {
    value: 'Encanador',
    text: 'Encanador',
  },
  {
    value: 'Encarregado de Almoxarifado',
    text: 'Encarregado de Almoxarifado',
  },
  {
    value: 'Encarregado de PCP',
    text: 'Encarregado de PCP',
  },
  {
    value: 'Encarregado de Recursos Humanos',
    text: 'Encarregado de Recursos Humanos',
  },
  {
    value: 'Encarregado de Segurança',
    text: 'Encarregado de Segurança',
  },
  {
    value: 'Encarregado de Transporte',
    text: 'Encarregado de Transporte',
  },
  {
    value: 'Encarregado de Usinagem',
    text: 'Encarregado de Usinagem',
  },
  {
    value: 'Endocrinologista',
    text: 'Endocrinologista',
  },
  {
    value: 'Engenheiro Têxtil',
    text: 'Engenheiro Têxtil',
  },
  {
    value: 'Enfermeiro',
    text: 'Enfermeiro',
  },
  {
    value: 'Engenheiro Aeronáutico',
    text: 'Engenheiro Aeronáutico',
  },
  {
    value: 'Engenheiro Agrimensor',
    text: 'Engenheiro Agrimensor',
  },
  {
    value: 'Engenheiro Agrônomo',
    text: 'Engenheiro Agrônomo',
  },
  {
    value: 'Engenheiro Ambiental',
    text: 'Engenheiro Ambiental',
  },
  {
    value: 'Engenheiro Civil',
    text: 'Engenheiro Civil',
  },
  {
    value: 'Engenheiro Civil Junior',
    text: 'Engenheiro Civil Junior',
  },
  {
    value: 'Engenheiro Civil Pleno',
    text: 'Engenheiro Civil Pleno',
  },
  {
    value: 'Engenheiro Civil Sênior',
    text: 'Engenheiro Civil Sênior',
  },
  {
    value: 'Engenheiro da Computação',
    text: 'Engenheiro da Computação',
  },
  {
    value: 'Engenheiro de Alimentos',
    text: 'Engenheiro de Alimentos',
  },
  {
    value: 'Engenheiro de Engenharia e Segurança do Trabalho',
    text: 'Engenheiro de Engenharia e Segurança do Trabalho',
  },
  {
    value: 'Engenheiro de Produção',
    text: 'Engenheiro de Produção',
  },
  {
    value: 'Engenheiro de Suprimentos',
    text: 'Engenheiro de Suprimentos',
  },
  {
    value: 'Engenheiro de Telecomunicações',
    text: 'Engenheiro de Telecomunicações',
  },
  {
    value: 'Engenheiro Eletricista',
    text: 'Engenheiro Eletricista',
  },
  {
    value: 'Engenheiro Metalúrgico',
    text: 'Engenheiro Metalúrgico',
  },
  {
    value: 'Engenheiro Naval',
    text: 'Engenheiro Naval',
  },
  {
    value: 'Engenheiro Nuclear',
    text: 'Engenheiro Nuclear',
  },
  {
    value: 'Estagiário Comercial Externo',
    text: 'Estagiário Comercial Externo',
  },
  {
    value: 'Estagiário Comercial Interno',
    text: 'Estagiário Comercial Interno',
  },
  {
    value: 'Estagiário Administrativo',
    text: 'Estagiário Administrativo',
  },
  {
    value: 'Estagiário de Arquitetura',
    text: 'Estagiário de Arquitetura',
  },
  {
    value: 'Estagiário de Atendimento Comercial',
    text: 'Estagiário de Atendimento Comercial',
  },
  {
    value: 'Estagiário de Atendimento a Clientes',
    text: 'Estagiário de Atendimento a Clientes',
  },
  {
    value: 'Estagiário de Banco',
    text: 'Estagiário de Banco',
  },
  {
    value: 'Estagiário de Banco de Dados',
    text: 'Estagiário de Banco de Dados',
  },
  {
    value: 'Estagiário de Biomedicina',
    text: 'Estagiário de Biomedicina',
  },
  {
    value: 'Estagiário de Cobrança',
    text: 'Estagiário de Cobrança',
  },
  {
    value: 'Estagiário de Comércio Exterior',
    text: 'Estagiário de Comércio Exterior',
  },
  {
    value: 'Estagiário de Compras',
    text: 'Estagiário de Compras',
  },
  {
    value: 'Estagiário de Comunicação',
    text: 'Estagiário de Comunicação',
  },
  {
    value: 'Estagiário de Contabilidade',
    text: 'Estagiário de Contabilidade',
  },
  {
    value: 'Estagiário de Controladoria',
    text: 'Estagiário de Controladoria',
  },
  {
    value: 'Estagiário de Controle de Qualidade',
    text: 'Estagiário de Controle de Qualidade',
  },
  {
    value: 'Estagiário de Cozinha',
    text: 'Estagiário de Cozinha',
  },
  {
    value: 'Estagiário de Custos',
    text: 'Estagiário de Custos',
  },
  {
    value: 'Estagiário de Departamento Pessoal',
    text: 'Estagiário de Departamento Pessoal',
  },
  {
    value: 'Estagiário de Desenho Industrial',
    text: 'Estagiário de Desenho Industrial',
  },
  {
    value: 'Estagiário de Desenvolvimento de Produto',
    text: 'Estagiário de Desenvolvimento de Produto',
  },
  {
    value: 'Estagiário de Desenvolvimento de Sistemas',
    text: 'Estagiário de Desenvolvimento de Sistemas',
  },
  {
    value: 'Estagiário de Diagramação',
    text: 'Estagiário de Diagramação',
  },
  {
    value: 'Estagiário de Distribuição',
    text: 'Estagiário de Distribuição',
  },
  {
    value: 'Estagiário de Ecommerce',
    text: 'Estagiário de Ecommerce',
  },
  {
    value: 'Estagiário de Economia',
    text: 'Estagiário de Economia',
  },
  {
    value: 'Estagiário de Edificações',
    text: 'Estagiário de Edificações',
  },
  {
    value: 'Estagiário de Educação Física',
    text: 'Estagiário de Educação Física',
  },
  {
    value: 'Estagiário de Enfermagem',
    text: 'Estagiário de Enfermagem',
  },
  {
    value: 'Estagiário de Engenharia de Alimentos',
    text: 'Estagiário de Engenharia de Alimentos',
  },
  {
    value: 'Estagiário de Engenharia de Telecomunicações',
    text: 'Estagiário de Engenharia de Telecomunicações',
  },
  {
    value: 'Estagiário de Engenharia e Segurança do Trabalho',
    text: 'Estagiário de Engenharia e Segurança do Trabalho',
  },
  {
    value: 'Estagiário de Engenharia Elétrica',
    text: 'Estagiário de Engenharia Elétrica',
  },
  {
    value: 'Estagiário de Estoque',
    text: 'Estagiário de Estoque',
  },
  {
    value: 'Estagiário de Gastronomia',
    text: 'Estagiário de Gastronomia',
  },
  {
    value: 'Estagiário de Gestão Abiental',
    text: 'Estagiário de Gestão Abiental',
  },
  {
    value: 'Estagiário de Informática',
    text: 'Estagiário de Informática',
  },
  {
    value: 'Estagiário de Jornalismo',
    text: 'Estagiário de Jornalismo',
  },
  {
    value: 'Estagiário de Letras',
    text: 'Estagiário de Letras',
  },
  {
    value: 'Estagiário de Logística',
    text: 'Estagiário de Logística',
  },
  {
    value: 'Estagiário de Manutenção',
    text: 'Estagiário de Manutenção',
  },
  {
    value: 'Estagiário de Manutenção Elétrica',
    text: 'Estagiário de Manutenção Elétrica',
  },
  {
    value: 'Estagiário de Marketing',
    text: 'Estagiário de Marketing',
  },
  {
    value: 'Estagiário de PCP',
    text: 'Estagiário de PCP',
  },
  {
    value: 'Estagiário de Produção',
    text: 'Estagiário de Produção',
  },
  {
    value: 'Estagiário de Publicidade e Propaganda',
    text: 'Estagiário de Publicidade e Propaganda',
  },
  {
    value: 'Estagiário de Qualidade',
    text: 'Estagiário de Qualidade',
  },
  {
    value: 'Estagiário de Recrutamento e Seleção',
    text: 'Estagiário de Recrutamento e Seleção',
  },
  {
    value: 'Estagiário de Recursos Humanos',
    text: 'Estagiário de Recursos Humanos',
  },
  {
    value: 'Estagiário de Representação Comercial',
    text: 'Estagiário de Representação Comercial',
  },
  {
    value: 'Estagiário de SAC',
    text: 'Estagiário de SAC',
  },
  {
    value: 'Estagiário de Sistemas',
    text: 'Estagiário de Sistemas',
  },
  {
    value: 'Estagiário de Suporte Técnico',
    text: 'Estagiário de Suporte Técnico',
  },
  {
    value: 'Estagiário de Suprimentos',
    text: 'Estagiário de Suprimentos',
  },
  {
    value: 'Estagiário de Tecnologia da Informação (TI)',
    text: 'Estagiário de Tecnologia da Informação (TI)',
  },
  {
    value: 'Estagiário de Telecomunicaçãoes',
    text: 'Estagiário de Telecomunicaçãoes',
  },
  {
    value: 'Estagiário de Telemarketing Ativo',
    text: 'Estagiário de Telemarketing Ativo',
  },
  {
    value: 'Estagiário de Telemarketing Receptivo',
    text: 'Estagiário de Telemarketing Receptivo',
  },
  {
    value: 'Estagiário de Tesouraria',
    text: 'Estagiário de Tesouraria',
  },
  {
    value: 'Estagiário de Veterinária',
    text: 'Estagiário de Veterinária',
  },
  {
    value: 'Estagiário de Web Design',
    text: 'Estagiário de Web Design',
  },
  {
    value: 'Estagiário Financeiro',
    text: 'Estagiário Financeiro',
  },
  {
    value: 'Estagiário Fiscal',
    text: 'Estagiário Fiscal',
  },
  {
    value: 'Estagiário Químico',
    text: 'Estagiário Químico',
  },
  {
    value: 'Esteticista',
    text: 'Esteticista',
  },
  {
    value: 'Estilista',
    text: 'Estilista',
  },
  {
    value: 'Estoquista',
    text: 'Estoquista',
  },
  {
    value: 'Estoquista de Loja',
    text: 'Estoquista de Loja',
  },
  {
    value: 'Fundidor',
    text: 'Fundidor',
  },
  {
    value: 'Farmacêutico',
    text: 'Farmacêutico',
  },
  {
    value: 'Feirante',
    text: 'Feirante',
  },
  {
    value: 'Filósofo',
    text: 'Filósofo',
  },
  {
    value: 'Físico',
    text: 'Físico',
  },
  {
    value: 'Fisioterapeuta',
    text: 'Fisioterapeuta',
  },
  {
    value: 'Fonoaudiólogo',
    text: 'Fonoaudiólogo',
  },
  {
    value: 'Fotógrafo',
    text: 'Fotógrafo',
  },
  {
    value: 'Fresador',
    text: 'Fresador',
  },
  {
    value: 'Fresador CNC',
    text: 'Fresador CNC',
  },
  {
    value: 'Fresador Ferramenteiro',
    text: 'Fresador Ferramenteiro',
  },
  {
    value: 'Garçom',
    text: 'Garçom',
  },
  {
    value: 'Gari',
    text: 'Gari',
  },
  {
    value: 'Gastrônomo',
    text: 'Gastrônomo',
  },
  {
    value: 'Geofísico',
    text: 'Geofísico',
  },
  {
    value: 'Geográfo',
    text: 'Geográfo',
  },
  {
    value: 'Geólogo',
    text: 'Geólogo',
  },
  {
    value: 'Gerente Administrativo',
    text: 'Gerente Administrativo',
  },
  {
    value: 'Gerente Comercial Externo',
    text: 'Gerente Comercial Externo',
  },
  {
    value: 'Gerente Comercial Interno',
    text: 'Gerente Comercial Interno',
  },
  {
    value: 'Gerente Comercial Interno',
    text: 'Gerente Comercial Interno',
  },
  {
    value: 'Gerente de Atendimento a Clientes',
    text: 'Gerente de Atendimento a Clientes',
  },
  {
    value: 'Gerente de Atendimento Comercial',
    text: 'Gerente de Atendimento Comercial',
  },
  {
    value: 'Gerente de Banco',
    text: 'Gerente de Banco',
  },
  {
    value: 'Gerente de Cobrança',
    text: 'Gerente de Cobrança',
  },
  {
    value: 'Gerente de Comércio Exterior',
    text: 'Gerente de Comércio Exterior',
  },
  {
    value: 'Gerente de Compras',
    text: 'Gerente de Compras',
  },
  {
    value: 'Gerente de Comunicação',
    text: 'Gerente de Comunicação',
  },
  {
    value: 'Gerente de Contabilidade',
    text: 'Gerente de Contabilidade',
  },
  {
    value: 'Gerente de Contas',
    text: 'Gerente de Contas',
  },
  {
    value: 'Gerente de Controladoria',
    text: 'Gerente de Controladoria',
  },
  {
    value: 'Gerente de Controle de Qualidade',
    text: 'Gerente de Controle de Qualidade',
  },
  {
    value: 'Gerente de Cozinha',
    text: 'Gerente de Cozinha',
  },
  {
    value: 'Gerente de Departamento Pessoal',
    text: 'Gerente de Departamento Pessoal',
  },
  {
    value: 'Gerente de Desenvolvimento de Produto',
    text: 'Gerente de Desenvolvimento de Produto',
  },
  {
    value: 'Gerente de Desenvolvimento de Sistemas',
    text: 'Gerente de Desenvolvimento de Sistemas',
  },
  {
    value: 'Gerente de Distribuição',
    text: 'Gerente de Distribuição',
  },
  {
    value: 'Gerente de Ecommerce',
    text: 'Gerente de Ecommerce',
  },
  {
    value: 'Gerente de Enfermagem',
    text: 'Gerente de Enfermagem',
  },
  {
    value: 'Gerente de Engenharia Elétrica',
    text: 'Gerente de Engenharia Elétrica',
  },
  {
    value: 'Gerente de Estoque',
    text: 'Gerente de Estoque',
  },
  {
    value: 'Gerente de Hotelaria',
    text: 'Gerente de Hotelaria',
  },
  {
    value: 'Gerente de Informática',
    text: 'Gerente de Informática',
  },
  {
    value: 'Gerente de Jornalismo',
    text: 'Gerente de Jornalismo',
  },
  {
    value: 'Gerente de Logística',
    text: 'Gerente de Logística',
  },
  {
    value: 'Gerente de Loja',
    text: 'Gerente de Loja',
  },
  {
    value: 'Gerente de Manutenção',
    text: 'Gerente de Manutenção',
  },
  {
    value: 'Gerente de Manutenção Elétrica',
    text: 'Gerente de Manutenção Elétrica',
  },
  {
    value: 'Gerente de Marketing',
    text: 'Gerente de Marketing',
  },
  {
    value: 'Gerente de PCP',
    text: 'Gerente de PCP',
  },
  {
    value: 'Gerente de Produção',
    text: 'Gerente de Produção',
  },
  {
    value: 'Gerente de Publicidade e Propaganda',
    text: 'Gerente de Publicidade e Propaganda',
  },
  {
    value: 'Gerente de Qualidade',
    text: 'Gerente de Qualidade',
  },
  {
    value: 'Gerente de Recrutamento e Seleção',
    text: 'Gerente de Recrutamento e Seleção',
  },
  {
    value: 'Gerente de Recursos Humanos',
    text: 'Gerente de Recursos Humanos',
  },
  {
    value: 'Gerente de Representação Comercial',
    text: 'Gerente de Representação Comercial',
  },
  {
    value: 'Gerente de SAC',
    text: 'Gerente de SAC',
  },
  {
    value: 'Gerente de Salão',
    text: 'Gerente de Salão',
  },
  {
    value: 'Gerente de Segurança Patrimonial',
    text: 'Gerente de Segurança Patrimonial',
  },
  {
    value: 'Gerente de Seguros',
    text: 'Gerente de Seguros',
  },
  {
    value: 'Gerente de Sistemas',
    text: 'Gerente de Sistemas',
  },
  {
    value: 'Gerente de Suporte Técnico',
    text: 'Gerente de Suporte Técnico',
  },
  {
    value: 'Gerente de Suprimentos',
    text: 'Gerente de Suprimentos',
  },
  {
    value: 'Gerente de Tecnologia da Informação (TI)',
    text: 'Gerente de Tecnologia da Informação (TI)',
  },
  {
    value: 'Gerente de Telecomunicações',
    text: 'Gerente de Telecomunicações',
  },
  {
    value: 'Gerente de Telemarketing Ativo',
    text: 'Gerente de Telemarketing Ativo',
  },
  {
    value: 'Gerente de Telemarketing Receptivo',
    text: 'Gerente de Telemarketing Receptivo',
  },
  {
    value: 'Gerente de Tesouraria',
    text: 'Gerente de Tesouraria',
  },
  {
    value: 'Gerente de Transportes',
    text: 'Gerente de Transportes',
  },
  {
    value: 'Gerente Financeiro',
    text: 'Gerente Financeiro',
  },
  {
    value: 'Gerente Fiscal',
    text: 'Gerente Fiscal',
  },
  {
    value: 'Gerente Químico',
    text: 'Gerente Químico',
  },
  {
    value: 'Geriatra',
    text: 'Geriatra',
  },
  {
    value: 'Gestor Ambiental',
    text: 'Gestor Ambiental',
  },
  {
    value: 'Ginecologista',
    text: 'Ginecologista',
  },
  {
    value: 'Governanta',
    text: 'Governanta',
  },
  {
    value: 'Homeopata',
    text: 'Homeopata',
  },
  {
    value: 'Historiador',
    text: 'Historiador',
  },
  {
    value: 'Inspetor de Controle de Qualidade',
    text: 'Inspetor de Controle de Qualidade',
  },
  {
    value: 'Instalador de Acessórios Automotivos',
    text: 'Instalador de Acessórios Automotivos',
  },
  {
    value: 'Instalador de Alarmes',
    text: 'Instalador de Alarmes',
  },
  {
    value: 'Instalador de Cabeamento Estruturado',
    text: 'Instalador de Cabeamento Estruturado',
  },
  {
    value: 'Instalador de Telecomunicações',
    text: 'Instalador de Telecomunicações',
  },
  {
    value: 'Instrutor de Treinamento',
    text: 'Instrutor de Treinamento',
  },
  {
    value: 'Jornalista',
    text: 'Jornalista',
  },
  {
    value: 'Jardineiro',
    text: 'Jardineiro',
  },
  {
    value: 'Líder de Produção',
    text: 'Líder de Produção',
  },
  {
    value: 'Líder de Usinagem',
    text: 'Líder de Usinagem',
  },
  {
    value: 'Locutor',
    text: 'Locutor',
  },
  {
    value: 'Maestro',
    text: 'Maestro',
  },
  {
    value: 'Mandrilhador',
    text: 'Mandrilhador',
  },
  {
    value: 'Manicure/Pedicure',
    text: 'Manicure/Pedicure',
  },
  {
    value: 'Manobrista',
    text: 'Manobrista',
  },
  {
    value: 'Maquiador',
    text: 'Maquiador',
  },
  {
    value: 'Marceneiro',
    text: 'Marceneiro',
  },
  {
    value: 'Marinheiro',
    text: 'Marinheiro',
  },
  {
    value: 'Matemático',
    text: 'Matemático',
  },
  {
    value: 'Mecânico',
    text: 'Mecânico',
  },
  {
    value: 'Mecânico de Automóveis',
    text: 'Mecânico de Automóveis',
  },
  {
    value: 'Mecânico de Manutenção Hidráulica',
    text: 'Mecânico de Manutenção Hidráulica',
  },
  {
    value: 'Médico',
    text: 'Médico',
  },
  {
    value: 'MestredeObras',
    text: 'MestredeObras',
  },
  {
    value: 'Modelo',
    text: 'Modelo',
  },
  {
    value: 'Monitor de Atendimento a Clientes',
    text: 'Monitor de Atendimento a Clientes',
  },
  {
    value: 'Monitor de Telemarketing Ativo',
    text: 'Monitor de Telemarketing Ativo',
  },
  {
    value: 'Monitor de Telemarketing Receptivo',
    text: 'Monitor de Telemarketing Receptivo',
  },
  {
    value: 'Motoboy',
    text: 'Motoboy',
  },
  {
    value: 'Motorista',
    text: 'Motorista',
  },
  {
    value: 'Neurologista',
    text: 'Neurologista',
  },
  {
    value: 'Nutricionista',
    text: 'Nutricionista',
  },
  {
    value: 'Obstetra',
    text: 'Obstetra',
  },
  {
    value: 'Oftalmologista',
    text: 'Oftalmologista',
  },
  {
    value: 'Operador de Atendimento a Clientes',
    text: 'Operador de Atendimento a Clientes',
  },
  {
    value: 'Operador de Caixa',
    text: 'Operador de Caixa',
  },
  {
    value: 'Operador de Fundição',
    text: 'Operador de Fundição',
  },
  {
    value: 'Operador de Guilhotina',
    text: 'Operador de Guilhotina',
  },
  {
    value: 'Operador de Logística',
    text: 'Operador de Logística',
  },
  {
    value: 'Operador de Produção',
    text: 'Operador de Produção',
  },
  {
    value: 'Operador de SAC',
    text: 'Operador de SAC',
  },
  {
    value: 'Operador de Telemarketing Ativo',
    text: 'Operador de Telemarketing Ativo',
  },
  {
    value: 'Operador de Telemarketing Receptivo',
    text: 'Operador de Telemarketing Receptivo',
  },
  {
    value: 'Operador de Torno',
    text: 'Operador de Torno',
  },
  {
    value: 'Operador de Usinagem',
    text: 'Operador de Usinagem',
  },
  {
    value: 'Ortopedista',
    text: 'Ortopedista',
  },
  {
    value: 'Otorrinolaringologista',
    text: 'Otorrinolaringologista',
  },
  {
    value: 'Psicólogo',
    text: 'Psicólogo',
  },
  {
    value: 'Padeiro',
    text: 'Padeiro',
  },
  {
    value: 'Pescador',
    text: 'Pescador',
  },
  {
    value: 'Paisagista',
    text: 'Paisagista',
  },
  {
    value: 'Pediatra',
    text: 'Pediatra',
  },
  {
    value: 'Pedreiro',
    text: 'Pedreiro',
  },
  {
    value: 'Pintor',
    text: 'Pintor',
  },
  {
    value: 'Podólogo',
    text: 'Podólogo',
  },
  {
    value: 'Polidor',
    text: 'Polidor',
  },
  {
    value: 'Produtor Editorial',
    text: 'Produtor Editorial',
  },
  {
    value: 'Professor de Educação',
    text: 'Professor de Educação',
  },
  {
    value: 'Professor de Letras',
    text: 'Professor de Letras',
  },
  {
    value: 'Programador de Centro de Usinagem',
    text: 'Programador de Centro de Usinagem',
  },
  {
    value: 'Programador de PCP',
    text: 'Programador de PCP',
  },
  {
    value: 'Promotor Bancário',
    text: 'Promotor Bancário',
  },
  {
    value: 'Químico',
    text: 'Químico',
  },
  {
    value: 'Recepcionista',
    text: 'Recepcionista',
  },
  {
    value: 'Radialista',
    text: 'Radialista',
  },
  {
    value: 'Radiologista',
    text: 'Radiologista',
  },
  {
    value: 'Redator',
    text: 'Redator',
  },
  {
    value: 'Repórter',
    text: 'Repórter',
  },
  {
    value: 'Representante Comercial',
    text: 'Representante Comercial',
  },
  {
    value: 'Supervisor Administrativo',
    text: 'Supervisor Administrativo',
  },
  {
    value: 'Secretária',
    text: 'Secretária',
  },
  {
    value: 'Serralheiro',
    text: 'Serralheiro',
  },
  {
    value: 'Supervisor Comercial Externo',
    text: 'Supervisor Comercial Externo',
  },
  {
    value: 'Supervisor Comercial Interno',
    text: 'Supervisor Comercial Interno',
  },
  {
    value: 'Supervisor de Almoxarifado',
    text: 'Supervisor de Almoxarifado',
  },
  {
    value: 'Supervisor de Atendimento a Clientes',
    text: 'Supervisor de Atendimento a Clientes',
  },
  {
    value: 'Supervisor de Atendimento Comercial',
    text: 'Supervisor de Atendimento Comercial',
  },
  {
    value: 'Supervisor de Banco',
    text: 'Supervisor de Banco',
  },
  {
    value: 'Supervisor de Cargos e Salários',
    text: 'Supervisor de Cargos e Salários',
  },
  {
    value: 'Supervisor de Cobrança',
    text: 'Supervisor de Cobrança',
  },
  {
    value: 'Supervisor de Comércio Exterior',
    text: 'Supervisor de Comércio Exterior',
  },
  {
    value: 'Supervisor de Compras',
    text: 'Supervisor de Compras',
  },
  {
    value: 'Supervisor de Comunicação',
    text: 'Supervisor de Comunicação',
  },
  {
    value: 'Supervisor de Contabilidade',
    text: 'Supervisor de Contabilidade',
  },
  {
    value: 'Supervisor de Controladoria',
    text: 'Supervisor de Controladoria',
  },
  {
    value: 'Supervisor de Controle de Qualidade',
    text: 'Supervisor de Controle de Qualidade',
  },
  {
    value: 'Supervisor de Cozinha',
    text: 'Supervisor de Cozinha',
  },
  {
    value: 'Supervisor de Crédito e Cobrança',
    text: 'Supervisor de Crédito e Cobrança',
  },
  {
    value: 'Supervisor de Custos',
    text: 'Supervisor de Custos',
  },
  {
    value: 'Supervisor de Departamento Pessoal',
    text: 'Supervisor de Departamento Pessoal',
  },
  {
    value: 'Supervisor de Desenvolvimento de Produto',
    text: 'Supervisor de Desenvolvimento de Produto',
  },
  {
    value: 'Supervisor de Desenvolvimento de Sistemas',
    text: 'Supervisor de Desenvolvimento de Sistemas',
  },
  {
    value: 'Supervisor de Distribuição',
    text: 'Supervisor de Distribuição',
  },
  {
    value: 'Supervisor de Ecommerce',
    text: 'Supervisor de Ecommerce',
  },
  {
    value: 'Supervisor de Enfermagem',
    text: 'Supervisor de Enfermagem',
  },
  {
    value: 'Supervisor de Engenharia e Segurança do Trabalho',
    text: 'Supervisor de Engenharia e Segurança do Trabalho',
  },
  {
    value: 'Supervisor de Engenharia Elétrica',
    text: 'Supervisor de Engenharia Elétrica',
  },
  {
    value: 'Supervisor de Estoque',
    text: 'Supervisor de Estoque',
  },
  {
    value: 'Supervisor de Fundição',
    text: 'Supervisor de Fundição',
  },
  {
    value: 'Supervisor de Informática',
    text: 'Supervisor de Informática',
  },
  {
    value: 'Supervisor de Limpeza',
    text: 'Supervisor de Limpeza',
  },
  {
    value: 'Supervisor de Logística',
    text: 'Supervisor de Logística',
  },
  {
    value: 'Supervisor de loja',
    text: 'Supervisor de loja',
  },
  {
    value: 'Supervisor de Manutenção',
    text: 'Supervisor de Manutenção',
  },
  {
    value: 'Supervisor de Marketing',
    text: 'Supervisor de Marketing',
  },
  {
    value: 'Supervisor de Manutenção Elétrica',
    text: 'Supervisor de Manutenção Elétrica',
  },
  {
    value: 'Supervisor de PCP',
    text: 'Supervisor de PCP',
  },
  {
    value: 'Supervisor de Produção',
    text: 'Supervisor de Produção',
  },
  {
    value: 'Supervisor de Publicidade e Propaganda',
    text: 'Supervisor de Publicidade e Propaganda',
  },
  {
    value: 'Supervisor de Qualidade',
    text: 'Supervisor de Qualidade',
  },
  {
    value: 'Supervisor de Recrutamento e Seleção',
    text: 'Supervisor de Recrutamento e Seleção',
  },
  {
    value: 'Supervisor de Recursos Humanos',
    text: 'Supervisor de Recursos Humanos',
  },
  {
    value: 'Supervisor de Representação Comercial',
    text: 'Supervisor de Representação Comercial',
  },
  {
    value: 'Supervisor de SAC',
    text: 'Supervisor de SAC',
  },
  {
    value: 'Supervisor de Salão',
    text: 'Supervisor de Salão',
  },
  {
    value: 'Supervisor de Segurança',
    text: 'Supervisor de Segurança',
  },
  {
    value: 'Supervisor de Segurança Patrimonial',
    text: 'Supervisor de Segurança Patrimonial',
  },
  {
    value: 'Supervisor de Seguros',
    text: 'Supervisor de Seguros',
  },
  {
    value: 'Supervisor de Sistemas',
    text: 'Supervisor de Sistemas',
  },
  {
    value: 'Supervisor de Suporte Técnico',
    text: 'Supervisor de Suporte Técnico',
  },
  {
    value: 'Supervisor de Suprimentos',
    text: 'Supervisor de Suprimentos',
  },
  {
    value: 'Supervisor de Tecnologia da Informação (TI)',
    text: 'Supervisor de Tecnologia da Informação (TI)',
  },
  {
    value: 'Supervisor de Telecomunicações',
    text: 'Supervisor de Telecomunicações',
  },
  {
    value: 'Supervisor de Telemarketing Ativo',
    text: 'Supervisor de Telemarketing Ativo',
  },
  {
    value: 'Supervisor de Telemarketing Receptivo',
    text: 'Supervisor de Telemarketing Receptivo',
  },
  {
    value: 'Supervisor de Tesouraria',
    text: 'Supervisor de Tesouraria',
  },
  {
    value: 'Supervisor de Transportes',
    text: 'Supervisor de Transportes',
  },
  {
    value: 'Supervisor de Usinagem',
    text: 'Supervisor de Usinagem',
  },
  {
    value: 'Supervisor Econômico',
    text: 'Supervisor Econômico',
  },
  {
    value: 'Supervisor Financeiro',
    text: 'Supervisor Financeiro',
  },
  {
    value: 'Supervisor Fiscal',
    text: 'Supervisor Fiscal',
  },
  {
    value: 'Técnico Ambiental',
    text: 'Técnico Ambiental',
  },
  {
    value: 'Técnico de Controle de Qualidade',
    text: 'Técnico de Controle de Qualidade',
  },
  {
    value: 'Técnico de Edificações',
    text: 'Técnico de Edificações',
  },
  {
    value: 'Técnico de Suporte',
    text: 'Técnico de Suporte',
  },
  {
    value: 'Técnico em Manutenção Elétrica',
    text: 'Técnico em Manutenção Elétrica',
  },
  {
    value: 'Técnico em Análise Clínica',
    text: 'Técnico em Análise Clínica',
  },
  {
    value: 'Técnico em Análise Química',
    text: 'Técnico em Análise Química',
  },
  {
    value: 'Técnico em Automação',
    text: 'Técnico em Automação',
  },
  {
    value: 'Técnico em Guia de Turismo',
    text: 'Técnico em Guia de Turismo',
  },
  {
    value: 'Técnico em Hardware',
    text: 'Técnico em Hardware',
  },
  {
    value: 'Técnico em Metalúrgica',
    text: 'Técnico em Metalúrgica',
  },
  {
    value: 'Técnico em Redes',
    text: 'Técnico em Redes',
  },
  {
    value: 'Técnico em Secretariado',
    text: 'Técnico em Secretariado',
  },
  {
    value: 'Técnico em Secretariado Escolar',
    text: 'Técnico em Secretariado Escolar',
  },
  {
    value: 'Técnico em Transações Imobiliárias',
    text: 'Técnico em Transações Imobiliárias',
  },
  {
    value: 'Técnico em Zootecnia',
    text: 'Técnico em Zootecnia',
  },
  {
    value: 'Técnico Instalador',
    text: 'Técnico Instalador',
  },
  {
    value: 'Técnico em Telecomunicações',
    text: 'Técnico em Telecomunicações',
  },
  {
    value: 'Tesoureiro',
    text: 'Tesoureiro',
  },
  {
    value: 'Topógrafo',
    text: 'Topógrafo',
  },
  {
    value: 'Tradutor',
    text: 'Tradutor',
  },
  {
    value: 'Traumatologista',
    text: 'Traumatologista',
  },
  {
    value: 'Turismólogo',
    text: 'Turismólogo',
  },
  {
    value: 'Urologista',
    text: 'Urologista',
  },
  {
    value: 'Web Design',
    text: 'Web Design',
  },
  {
    value: 'Web Design Sênior',
    text: 'Web Design Sênior',
  },
  {
    value: 'Web Design Junior',
    text: 'Web Design Junior',
  },
  {
    value: 'Zelador',
    text: 'Zelador',
  },
  {
    value: 'Zootecnista',
    text: 'Zootecnista',
  },
  {
    value: 'Outra',
    text: 'Outra',
  },
]
